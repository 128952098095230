var game;
var sh;
function aeroPlane(json) {
    /**
     * @author Roman Parada
     * This class is created to fix overlapping of bottom part of canvas by navigation bar in iOS.
     * It make a delayed resize of the canvas (like Phaser-3 FIT methods does) and it overrides Phaser-3 input window to Phaser-3 core transform methods
     *
     * How to use:
     * Just call the code line below in Boot scene's init() method of your project:
     * new FitScaleManager(this.game).setup();
     */
    class FitScaleManager {
        constructor(game) {
            this.doResize = () => {
                let scale = this.calculateScale();
                let newCanvasWidth = this.phaserScaleManager.width * scale;
                let newCanvasHeight = this.phaserScaleManager.height * scale;
                this.canvasStyle.width = newCanvasWidth + 'px';
                this.canvasStyle.height = newCanvasHeight + 'px';
                this.canvasStyle.marginLeft = (window.innerWidth - newCanvasWidth) / 2 + 'px';
                this.canvasStyle.marginTop = (window.innerHeight - newCanvasHeight) / 2 + 'px';
            };
            this.game = game;
            this.canvasStyle = this.game.canvas.style;
            this.phaserScaleManager = this.game.scale;
        }
        static detectBestScaleMode() {
            let iOS = /iPad|iPhone|iPod/.test(navigator.platform || "");
            let isAndroid = window.navigator.userAgent.toLowerCase().indexOf("android") > -1;
            return iOS || isAndroid ? Phaser.Scale.FIT : Phaser.Scale.NONE;
        }
        ;
        /**
         * Just call this method once in Boot scene's init() method
         */
        setup() {
            this.phaserScaleManager.addListener(Phaser.Scale.Events.RESIZE, this.onResize, this);
            this.overridePhaserTransformMethods();
            this.onResize();
        }
        calculateScale() {
            if (game.scale.scaleMode === Phaser.Scale.NONE)
                return 1;
            return Math.min(window.innerWidth / this.phaserScaleManager.width, window.innerHeight / this.phaserScaleManager.height);
        }
        overridePhaserTransformMethods() {
            this.game.scale.transformX = (pageX) => {
                return (pageX - parseInt(this.canvasStyle.marginLeft.split("px")[0])) / this.calculateScale();
            };
            this.game.scale.transformY = (pageY) => {
                return (pageY - parseInt(this.canvasStyle.marginTop.split("px")[0])) / this.calculateScale();
            };
        }
        onResize() {
            setTimeout(this.doResize, FitScaleManager.RESIZE_DELAY);
        }
    }
    FitScaleManager.RESIZE_DELAY = 500;

    (function (sh) {
        class App extends Phaser.Game {
            constructor(json) {
                App.json = json;
                // console.log(App.json);

                let config = {
                    type: Phaser.AUTO,
                    width: App.CANVAS_WIDTH,
                    height: App.CANVAS_HEIGHT,
                    parent: 'game-container',
                    dom: {
                        createContainer: false
                    },
                    scale: {
                        mode: FitScaleManager.detectBestScaleMode(),
                        autoCenter: Phaser.Scale.CENTER_BOTH
                    },
                    transparent: true,
                    scene: {
                        create: () => {
                            this.scene.add('Boot', sh.scene.Boot, true);
                        }
                    }
                };
                super(config);
            }
        }
        App.CANVAS_WIDTH = 980;
        App.CANVAS_HEIGHT = 600;
        sh.App = App;
    })(sh || (sh = {}));

    game = new sh.App(json);

    var delayedCalls = [];
    function delayedCall(delay, callback, args, callbackScope) {
        let scene = game.scene.getAt(0);
        if (scene) {
            let dc = scene.time.delayedCall(delay, callback, args, callbackScope);
            delayedCalls.push(dc);
            return dc;
        }
        let t = setTimeout(callback, delay);
        delayedCalls.push(t);
        return t;
    }

    function pauseAllDelayedCalls() {
        for (let dc of delayedCalls) {
            if (dc instanceof Phaser.Time.TimerEvent) {
                dc.paused = true;
            }
        }
    }

    function resumeAllDelayedCalls() {
        for (let dc of delayedCalls) {
            if (dc instanceof Phaser.Time.TimerEvent) {
                dc.paused = false;
            }
        }
    }

    function destroyAllDelayedCalls() {
        for (let dc of delayedCalls) {
            if (dc instanceof Phaser.Time.TimerEvent) {
                dc.remove(false);
            }
            else {
                clearTimeout(dc);
            }
        }
        delayedCalls = [];
    }

    function setPageBackground(bg) {
        // document.querySelector("html").style.backgroundImage = "url(assets/games/aeroplane/imgs/" + bg + ".jpg)";
    }

    function setupButton(btn, frame) {
        btn.on('pointerdown', () => { btn.setFrame(frame + '_hover' + '0000'); });
        btn.on('pointerover', () => { btn.setFrame(frame + '_hover' + '0000'); game.scene.getAt(0).sound.add("button hover").play(); });
        btn.on('pointerout', () => { btn.setFrame(frame + '0000'); });
        btn.on('pointerup', () => { btn.setFrame(frame + '0000'); game.scene.getAt(0).sound.add('activity selection - button selection').play(); });
    }

    function setupButtonTextureBased(btn, texture, hoverTexture) {
        btn.on('pointerdown', () => { btn.setTexture(hoverTexture); });
        btn.on('pointerover', () => { btn.setTexture(hoverTexture); game.scene.getAt(0).sound.add("button hover").play(); });
        btn.on('pointerout', () => { btn.setTexture(texture); });
        btn.on('pointerup', () => { btn.setTexture(texture); game.scene.getAt(0).sound.add('activity selection - button selection').play(); });
    }

    (function (sh) {
        var core;
        (function (core) {
            class Gameplay {
                constructor() {
                    this.currentRound = 0;
                    this.currentLetter = 0;
                    this.correctAnswersCount = 0;
                    this.wrongAnswersCount = 0;
                    this.correctAnswersCountThisRound = 0;
                    this.wrongAnswersCountThisRound = 0;
                    this.reset();
                    let json = game.cache.json.get('gameplay');
                    this.wrongAnswersToLose = json["wrongAnswersToLose"];
                    this.numberOfQuestionsPerRound = json["numberOfQuestionsPerRound"];
                    this.lettersConfig = JSON.parse(JSON.stringify(json["lettersConfig"]));
                    this.rounds = JSON.parse(JSON.stringify(json["rounds"]));
                    this.numberOfRounds = json["numberOfRounds"] ?? this.rounds.length;
                    this.useImages = Boolean(game.cache.json.get('gameplay')["useImages"]);
                }
                setupCallbacks(onComplete, onLose) {
                    this.onComplete = onComplete;
                    this.onLose = onLose;
                }
                onLetterChosen(forceNewRound) {
                    if (forceNewRound || this.isNewRound()) {
                        return this.nextRound();
                    }
                    return false;
                }
                nextRound() {
                    this.correctAnswersCountThisRound = 0;
                    this.wrongAnswersCountThisRound = 0;
                    this.currentRound++;
                    if (this.isRoundsComplete()) {
                        this.onComplete(this.correctAnswersCount, this.correctAnswersCount);
                        return true;
                    }

                    return false;
                }
                getCorrectLetters() {
                    return this.rounds[this.currentRound]['correctLetters'];
                }
                isCorrectLetterAudioQuestionEnable() {
                    return this.rounds[this.currentRound]['audioQuestionEnable'];
                }
                getCorrectAudioKey() {
                    return this.rounds[this.currentRound]["correctAudioKey"];
                }
                onCorrectAnswer() {
                    this.correctAnswersCountThisRound++;
                    this.correctAnswersCount++;
                    return this.onLetterChosen();
                }
                onWrongAnswer(forceNewRound) {
                    this.wrongAnswersCountThisRound++;
                    this.wrongAnswersCount++;
                    if (this.wrongAnswersCount >= this.wrongAnswersToLose) {
                        this.onLose(this.correctAnswersCount, this.correctAnswersCount);
                        return true;
                    }
                    else {
                        this.onLetterChosen(forceNewRound);
                    }
                    return false;
                }

                getMaxTotalAnswersCount() {
                    return this.wrongAnswersToLose + this.numberOfRounds - 1;
                }

                getCurrentTotalAnswersCount() {
                    return this.correctAnswersCount + this.wrongAnswersCount;
                }
                isNewRound() {
                    return this.correctAnswersCountThisRound >= 1 || (this.correctAnswersCountThisRound + this.wrongAnswersCountThisRound) === 4 || (this.correctAnswersCountThisRound + this.wrongAnswersCountThisRound) === 0;
                }
                isRoundsComplete() {
                    return this.currentRound >= this.numberOfRounds && this.wrongAnswersCount < this.wrongAnswersToLose;
                }
                reset() {
                    this.currentRound = 0;
                    this.correctAnswersCount = 0;
                    this.wrongAnswersCount = 0;
                }
                getCurrentRoundLetters() {
                    return this.rounds[this.currentRound]["letters"];
                }
            }
            core.Gameplay = Gameplay;
        })(core = sh.core || (sh.core = {}));
    })(sh || (sh = {}));

    (function (sh) {
        var scene;
        (function (scene) {
            class Boot extends Phaser.Scene {
                init() {
                    this.game.scale.transformX = (pageX) => {
                        let offsetLeft = 0;
                        let parentElement = game.canvas.parentElement;
                        while (parentElement) {
                            if (parentElement.offsetLeft) {
                                offsetLeft = parentElement.offsetLeft;
                                break;
                            }
                            parentElement = parentElement.parentElement;
                        }
                        return (pageX - offsetLeft) * this.game.scale.displayScale.x;
                    };
                    this.game.scale.transformY = (pageY) => {
                        let offsetTop = 0;
                        let parentElement = game.canvas.parentElement;
                        while (parentElement) {
                            if (parentElement.offsetTop) {
                                offsetTop = parentElement.offsetTop;
                                break;
                            }
                            parentElement = parentElement.parentElement;
                        }
                        return (pageY - offsetTop) * this.game.scale.displayScale.y;
                    };
                }
                create() {
                    game.scene.remove('Boot');
                    game.scene.add('Preloader', sh.scene.Preloader, true);
                }
            }
            scene.Boot = Boot;
        })(scene = sh.scene || (sh.scene = {}));
    })(sh || (sh = {}));

    (function (sh) {
        var scene;
        (function (scene) {
            var Gameplay = sh.core.Gameplay;
            class MainScene extends Phaser.Scene {
                create() {
                    this.gameplay = new Gameplay();
                    this.gameplayScreen = new sh.screen.GameplayScreen(this, this.gameplay);
                    this.children.add(this.gameplayScreen);
                    this.gameplayScreen.showInstructionPage();
                }
            }
            scene.MainScene = MainScene;
        })(scene = sh.scene || (sh.scene = {}));
    })(sh || (sh = {}));

    (function (sh) {
        var scene;
        (function (scene) {
            class Preloader extends Phaser.Scene {
                preload() {
                    if (typeof sh.App.json == "string") this.load.json('gameplay', sh.App.json); else this.game.cache.json.add('gameplay', sh.App.json);
                }
                create() {
                    let json = game.cache.json.get('gameplay');
                    let lettersConfig = json["lettersConfig"];
                    this.load.audio("englQuestion", "assets/games/aeroplane/sound/mp3/letters/englQuestion.mp3");
                    for (let round of json["rounds"]) {
                        let correctAudioKey = round["correctAudioKey"];
                        this.load.audio(correctAudioKey, "assets/games/aeroplane/sound/mp3/letters/Sounds of the letters/" + correctAudioKey.replace(".wav", ".mp3"));
                    }
                    for (let letterName in lettersConfig) {
                        if (json["useImages"]) this.load.image(letterName, "assets/games/aeroplane/imgs/letters/" + letterName + ".png");
                    }
                    let progressTxt = this.add.text(game.scale.width / 2, game.scale.height / 2 + 50, "", {
                        "fontFamily": "QuranEraV3",
                        "fontSize": 25,
                        "color": "#333333",
                        "align": 'center'
                    });
                    progressTxt.setOrigin(0.5, 0.5);
                    this.load.pack('preloader', 'assets/games/aeroplane/pack.json');
                    this.load.on('progress', (value) => {
                        progressTxt.text = Math.ceil(value * 100) + "%";
                    }, this);
                    this.load.on('complete', () => {
                        this.nextScene();
                    });
                    this.load.start();
                }
                static playAnim(animKey, sprite, onComplete = null) {
                    let mainScene = game.scene.getScene('ScreenMain');
                    let data = Preloader.ANIMS_DATA[animKey];
                    if (!mainScene.anims.exists(animKey)) {
                        mainScene.anims.create({
                            key: animKey,
                            frames: mainScene.anims.generateFrameNames(data['atlas'], {
                                start: data['start'], end: data['end'], zeroPad: data['padNum'],
                                prefix: data['prefix'], suffix: ''
                            }),
                            frameRate: data['frameRate'],
                            repeat: data['repeat']
                        });
                    }
                    if (sprite.anims.currentAnim) {
                        sprite.anims.currentAnim.off('complete');
                    }
                    sprite.anims.stop();
                    // sprite.setTexture(data['atlas']);
                    sprite.play(animKey);
                    sprite.anims.currentAnim.once('complete', () => {
                        if (onComplete)
                            onComplete();
                    });
                    return sprite;
                }
                nextScene() {
                    game.scene.remove('Preloader');
                    game.scene.add('ScreenMain', sh.scene.MainScene, true);
                }
            }
            Preloader.ANIMS_DATA = {
                'wrong': {
                    'start': 0,
                    'end': 24,
                    'padNum': 2,
                    'prefix': 'Nah Animation_3',
                    'repeat': 0,
                    'frameRate': 30,
                    'atlas': 'wrong-atlas'
                },
                'idle': {
                    'start': 0,
                    'end': 124,
                    'padNum': 3,
                    'prefix': 'Plane iddle_3',
                    'repeat': -1,
                    'frameRate': 30,
                    'atlas': 'idle-atlas'
                }
            };
            scene.Preloader = Preloader;
        })(scene = sh.scene || (sh.scene = {}));
    })(sh || (sh = {}));
    (function (sh) {
        var screen;
        (function (screen) {
            class AreYouSureWindow extends Phaser.GameObjects.Container {
                constructor(scene, onYes, onNo) {
                    super(scene);
                    this._areYouSurePage = new Phaser.GameObjects.Image(this.scene, -105, 0 - 48, 'Exit warning');
                    this._areYouSurePage.setOrigin(0, 0);
                    this._areYouSurePage.setInteractive();
                    this._btnSureYes = new Phaser.GameObjects.Image(this.scene, game.scale.width / 2 - 95, 485 - 50, 'btnYES1');
                    this._btnSureYes.setInteractive({ cursor: 'pointer' });
                    this._btnSureYes.on('pointerup', () => {
                        onYes;
                        angularComponentRef.backToMenu();
                    });
                    var modalTextStyle = {
                        backgroundColor: 'transparent',
                        stroke: '#ffffff',
                        strokeThickness: 2,
                        lineSpacing: 15,
                        fontFamily: 'Kids Rock DEMO',
                        fontSize: 27,
                        color: '#43425d',
                        wordWrap: true,
                        align: 'center'
                    };
                    var modalText = this.scene.add.text(game.scale.width / 2 + 12, game.scale.height / 2, "Your progress in this activity will not be saved. \n Are you sure you want to exit? ", modalTextStyle);
                    modalText.setOrigin(0.5, 0.5);

                    setupButtonTextureBased(this._btnSureYes, 'btnYES1', 'btnYES2');
                    this._btnSureNo = new Phaser.GameObjects.Image(this.scene, game.scale.width / 2 + 95, 485 - 50, 'btnNO1');
                    this._btnSureNo.setInteractive({ cursor: 'pointer' });
                    this._btnSureNo.on('pointerup', onNo);
                    setupButtonTextureBased(this._btnSureNo, 'btnNO1', 'btnNO2');
                    this.add(this._areYouSurePage);
                    this.add(this._btnSureYes);
                    this.add(this._btnSureNo);
                    this.add(modalText);
                }
            }
            screen.AreYouSureWindow = AreYouSureWindow;
        })(screen = sh.screen || (sh.screen = {}));
    })(sh || (sh = {}));

    (function (sh) {
        var screen;
        (function (screen) {
            class CompleteWindow extends Phaser.GameObjects.Container {
                constructor(scene, onBack, onReplay, onNext) {
                    super(scene);
                    this.music = null;
                    this.setPosition(-104.5, -48);
                    this._bgComplete = new Phaser.GameObjects.Image(this.scene, 0, 0, 'Completion page LATEST UPDATED');
                    this._bgComplete.setOrigin(0, 0);
                    this._bgComplete.setInteractive();
                    this._cup = new Phaser.GameObjects.Image(this.scene, 400, 410, 'Trophy');
                    this._btnBack = new Phaser.GameObjects.Image(this.scene, 570, 570, 'btnBACK1');
                    this._btnReplay = new Phaser.GameObjects.Image(this.scene, 720, 570, 'btnReplay1');
                    this._btnNext = new Phaser.GameObjects.Image(this.scene, 870, 570, 'btnNEXT1');
                    let _CollectedPoints = new Phaser.GameObjects.Image(this.scene, 620, 440, 'Collected Points');
                    this.totalScoreTxt = this.scene.add.text(845, 352, "", {
                        "fontFamily": "Kids Rock Demo",
                        "fontSize": 35,
                        "color": "#F49F1C",
                        "align": 'center',
                        'stroke': '#70451A',
                        'strokeThickness': 6
                    });
                    this.totalScoreTxt.setOrigin(0.5, 0.5);
                    let grd = this.totalScoreTxt.context.createLinearGradient(0, 0, 0, this.totalScoreTxt.height);
                    grd.addColorStop(0, '#FFFF00');
                    grd.addColorStop(1, '#C17316');
                    this.totalScoreTxt.setFill(grd);
                    this.starScoreTxt = this.scene.add.text(648, 433, "", {
                        "fontFamily": "Kids Rock Demo",
                        "fontSize": 24,
                        "color": "#FFFFFF",
                        "align": 'center'
                    });
                    this.starScoreTxt.setOrigin(0.5, 0.5);
                    this.add([
                        this._bgComplete,
                        _CollectedPoints,
                        this._cup,
                        this._btnBack,
                        this._btnReplay,
                        this._btnNext,
                        this.totalScoreTxt,
                        this.starScoreTxt
                    ]);
                    this._btnBack.setInteractive({ cursor: 'pointer' });
                    this._btnBack.on('pointerup', () => {
                        onBack(this._btnBack);
                        angularComponentRef.backToMenu();
                        // if (this.music) {
                        //     this.music.stop();
                        // }
                    });
                    setupButtonTextureBased(this._btnBack, 'btnBACK1', 'btnBACK2');
                    this._btnReplay.setInteractive({ cursor: 'pointer' });
                    this._btnReplay.once('pointerup', () => {
                        onReplay(this._btnReplay);
                        if (this.music) {
                            this.music.stop();
                        }
                    });
                    setupButtonTextureBased(this._btnReplay, 'btnReplay1', 'btnReplay2');
                    this._btnNext.setInteractive({ cursor: 'pointer' });
                    this._btnNext.on('pointerup', () => {
                        onNext(this._btnNext);
                        window.angularComponentRef.nextActivity()
                    });
                    setupButtonTextureBased(this._btnNext, 'btnNEXT1', 'btnNEXT2');
                }
                show(score, starScore) {
                    window.angularComponentRef.gameFinish({ score, starScore })
                    this._cup.scale = 1.25;
                    this.scene.tweens.add({
                        targets: this._cup,
                        "scale": 1,
                        duration: 500,
                        ease: Phaser.Math.Easing.Back.Out
                    });
                    this.totalScoreTxt.text = String(window.angularComponentRef.component.userInfo.totalScore);
                    this.starScoreTxt.text = String(starScore);
                    // let music = this.scene.sound.add("viktory");
                    this.music = this.scene.sound.add("Activity completion fantastic");
                    this.music.play();
                }
            }
            screen.CompleteWindow = CompleteWindow;
        })(screen = sh.screen || (sh.screen = {}));
    })(sh || (sh = {}));

    (function (sh) {
        var screen;
        (function (screen) {
            class GameplayScreen extends Phaser.GameObjects.Container {
                constructor(scene, gameplay) {
                    super(scene);
                    let _dy = 640;
                    this.wordRectPositions = [{ x: 81 + 85, y: 393 + 45 }, { x: 303 + 85, y: 393 + 45 }, { x: 511 + 85, y: 393 + 45 }, { x: 725 + 85, y: 393 + 45 }];
                    this.bgMusic = null;
                    this.correctAudio = null;
                    this.wfsnd = null;
                    this.showCompleteWindow = (score, starScore) => {
                        let completeWindow = new screen.CompleteWindow(this.scene, (target) => {
                            this.playBtnClickAnim(target);
                        }, (target) => {
                            this.playBtnClickAnim(target);
                            this.destroyGameplay();
                            this.remove(completeWindow);
                            this.showInstructionPage();
                        }, (target) => {
                            this.playBtnClickAnim(target);
                        });
                        this.setLettersInputEnabled(false);
                        delayedCall(6000, () => {
                            setPageBackground("bg-blue");
                            this.add(completeWindow);
                            completeWindow.show(score, starScore);
                            if (this.bgMusic) this.bgMusic.stop();
                        });
                    };
                    this.showLoseWindow = (score, starScore) => {
                        let tryAgainWindow = new screen.TryAgainWindow(this.scene, (target) => {
                            this.playBtnClickAnim(target);
                        }, (target) => {
                            this.playBtnClickAnim(target);
                            this.destroyGameplay();
                            this.remove(tryAgainWindow);
                            this.showInstructionPage();
                        });
                        this.setLettersInputEnabled(false);
                        delayedCall(2500, () => {
                            setPageBackground("bg-blue");
                            this.add(tryAgainWindow);
                            tryAgainWindow.show(score, starScore);
                            if (this.bgMusic) this.bgMusic.stop();
                        });
                    };
                    this.gameplay = gameplay;
                    window["t"] = this;
                }
                createHexagons() {
                    if (this.contentContainer && this.contentContainer.parentContainer) {
                        this.contentContainer.parentContainer.remove(this.contentContainer);
                    }

                    this.contentContainer = this.scene.add.container(0, 0);
                    this.gameplayContainer.addAt(this.contentContainer, 1);
                    window['this.contentContainer'] = this.contentContainer;

                    window['this.gameplayContainer'] = this.gameplayContainer;

                    this.shipContainer = this.scene.add.container(480 - 1150, 405);
                    this.shipContainer['defY'] = this.shipContainer.y;

                    this.airCraft = this.scene.add.sprite(25, 150, "airBaloon");
                    window['this.airCraft'] = this.airCraft;
                    this.airCraft.setOrigin(0.5, 1);
                    this.shipContainer.add(this.airCraft);
                    sh.scene.Preloader.playAnim('idle', this.airCraft);
                    this.airCraft.setScale(0.9/0.8);

                    this.targetRect1 = this.scene.add.sprite(-180, -199, "targetRect");
                    this.targetRect1.scaleX = 0.98;
                    window['this.targetRect1'] = this.targetRect1;
                    this.shipContainer.add(this.targetRect1);
                    this.targetRect2 = this.scene.add.sprite(1, -199, "targetRect");
                    this.targetRect2.scaleX = 0.98;
                    window['this.targetRect2'] = this.targetRect2;
                    this.shipContainer.add(this.targetRect2);

                    this.bigCorrectRect = this.scene.add.sprite(-90, -199, "bigCorrectRect");
                    window['this.bigCorrectRect'] = this.bigCorrectRect;
                    this.shipContainer.add(this.bigCorrectRect);
                    this.bigCorrectRect.alpha = 0;

                    this.targetRect1.alpha = 0;
                    this.targetRect2.alpha = 0;

                    /* this.scene.tweens.add({
                        targets: this.targetRect1,
                        "y": -199,
                        yoyo: true,
                        duration: 600,
                        delay:600,
                        ease: Phaser.Math.Easing.Quadratic.InOut,
                        repeat: -1
                    });
                    this.scene.tweens.add({
                        targets: this.targetRect2,
                        "y": -199,
                        yoyo: true,
                        duration: 600,
                        ease: Phaser.Math.Easing.Quadratic.InOut,
                        repeat: -1
                    }); */

                    this.shipContainer["defY"] = this.shipContainer.y;
                    this.contentContainer.add(this.shipContainer);
                    window['this.ship'] = this.shipContainer;

                    this.wordsRects = [];
                    let _i = 0;
                    for (let p of this.wordRectPositions) {
                        let hc = this.scene.add.container(this.wordRectPositions[_i]['x'], this.wordRectPositions[_i]['y']);
                        let hcIdleMotionContainer = this.scene.add.container();
                        hc.add(hcIdleMotionContainer);
                        let h = this.scene.add.sprite(0, 0, 'selectRect');
                        h.scaleX = 1.02;
                        hc["-image-"] = h;
                        hc['-draggable-'] = true;
                        hc.add(h);
                        hc["bg"] = h;
                        window['hc_' + _i] = hc;
                        window['h_' + _i] = h;
                        hcIdleMotionContainer.add(h);
                        hc['hcIdleMotionContainer'] = hcIdleMotionContainer;

                        let doIdleMove = () => {
                            if (!this.scene) return;
                            hcIdleMotionContainer['idleTween'] = this.scene.tweens.add({
                                targets: hcIdleMotionContainer,
                                "x": (0 + Math.random() * 6) * (Math.random() > 0.5 ? 1 : -1),
                                "y": (0 + Math.random() * 6) * (Math.random() > 0.5 ? 1 : -1),
                                yoyo: true,
                                delay: Math.random() > 0.5 ? 0 : Math.random() * 500,
                                duration: 1500,
                                ease: Phaser.Math.Easing.Quadratic.InOut,
                                onComplete: doIdleMove,
                                onUpdate: () => {
                                    /* for (let i = 0; i < this.targetBlocks.length; i++) {
                                        let targetBlock = this.targetBlocks[i];
                                        if (!targetBlock['alreadyFilled']) continue;
                                        if  (targetBlock["-block-"] != hc) continue;
                                        hcIdleMotionContainer['idleTween'].paused = true;
                                        hcIdleMotionContainer.x = 0;
                                        hcIdleMotionContainer.y = 0;

                                        let targetRect = i == 0 ? this.targetRect1 : this.targetRect2;
                                        hc.x = targetRect.x;
                                        if (targetBlock.parentContainer) hc.x += targetBlock.parentContainer.x;
                                        hc.x -= 75;
                                        hc.x *= -1;
                                        hc.y = targetRect.y;
                                        if (targetBlock.parentContainer) hc.y += targetBlock.parentContainer.y;
                                        hc.y *= -1;
                                    } */
                                }
                            });
                        };
                        // doIdleMove();

                        hc['startPosition'] = { x: hc.x, y: hc.y };

                        hc["defX"] = p['x'];
                        hc["defY"] = p['y'];
                        this.wordsRects.push(hc);
                        this.contentContainer.add(hc);
                        hc['index'] = _i;
                        _i++;
                    }
                    window['this.cars'] = this.wordsRects;
                }
                createLetters() {
                    /* if (this.letters) {
                        for (let l of this.letters) {
                            l.parentContainer["letter"] = null;
                            l["hexagon"] = null;
                            l.parentContainer.remove(l);
                        }
                    } */
                    this.letters = [];
                    let _letters = this.gameplay.getCurrentRoundLetters();
                    for (let n of _letters) {
                        let wordText;
                        if (this.gameplay.useImages) {
                            wordText = new Phaser.GameObjects.Image(this.scene, 0, 0, n);
                        } else {
                            wordText = this.scene.add.text(0, 0, n, {
                                "fontFamily": "QuranEraV3",
                                "fontSize": 40,
                                "color": "#333333",
                                "align": 'center'
                            });
                            this.add(wordText);
                            wordText.setOrigin(0.5, 0.5);
                            wordText.setPadding({ left: 0, right: 0, top: 65, bottom: 65 });

                            if (wordText.width > 140) wordText.setScale(140 / wordText.width);
                        }
                        wordText["key"] = n;
                        this.letters.push(wordText);
                        window['wordText'] = wordText;
                    }
                }
                showGameplay() {
                    this.isLost = false;
                    setPageBackground("bg-australia");
                    this.bgMusic = this.scene.sound.add("bg_sound");
                    this.bgMusic.play();
                    this.bgMusic.loop = true;
                    this.gameplay.reset();
                    this._gameStage = new Phaser.GameObjects.Image(this.scene, game.scale.width / 2, game.scale.height / 2, 'bg');
                    this._gameStage.setOrigin(0.5, 0.5);
                    this._gameStage.setInteractive();
                    this._btnSound = new Phaser.GameObjects.Image(this.scene, 160 - 105, 100 - 50, 'Sound');
                    this._btnSound.setInteractive({ cursor: 'pointer' });
                    this._btnSound["defScale"] = this._btnSound.scale;
                    setupButtonTextureBased(this._btnSound, 'Sound', 'Sound HOVER EFFECT');
                    this._btnClose = new Phaser.GameObjects.Image(this.scene, 1025 - 105, 100 - 50, 'x Button');
                    this._btnClose.setInteractive({ cursor: 'pointer' });
                    this._btnClose["defScale"] = this._btnClose.scale;
                    setupButtonTextureBased(this._btnClose, 'x Button', 'x Button HOVER EFFECT');
                    this.gameplayContainer = new Phaser.GameObjects.Container(this.scene);
                    this.addAt(this.gameplayContainer, 0);
                    this.gameplayContainer.add([
                        this._gameStage,
                        this._btnSound,
                        this._btnClose
                    ]);

                    this.fgContainer = this.scene.add.container(0, 600);
                    this.fg1 = this.scene.add.sprite(0, 0, "fg");
                    this.fg1.setOrigin(0, 1);
                    this.fgContainer.add(this.fg1);
                    this.fg2 = this.scene.add.sprite(this.fg1.width, 0, "fg");
                    this.fg2.scaleX = -1;
                    this.fg2.setOrigin(1, 1);
                    this.fgContainer.add(this.fg2);
                    this.gameplayContainer.add(this.fgContainer);
                    window['this.fgContainer'] = this.fgContainer;

                    this.createBeehives();
                    this.shuffleOutHexagons();
                    this.gameplay.setupCallbacks(this.showCompleteWindow, this.showLoseWindow);
                }
                createBeehives() {
                    let startX = 142;
                    let startY = 48;
                    let dx = 44;
                    let _BeehiveEmptyArrayPositions = [];
                    for (let i = 0; i < this.gameplay.getMaxTotalAnswersCount(); i++) {
                        _BeehiveEmptyArrayPositions.push({ x: startX + i * dx, y: startY });
                    }
                    this._BeehiveEmptyArray = []; window["_BeehiveEmptyArray"] = this._BeehiveEmptyArray;
                    this._Beehive = [];
                    for (let p of _BeehiveEmptyArrayPositions) {
                        let _BeehiveEmpty = new Phaser.GameObjects.Image(this.scene, p['x'], p['y'], 'Beehive 1 Empty');
                        this._BeehiveEmptyArray.push(_BeehiveEmpty);
                        _BeehiveEmpty.visible = false;
                        let _Beehive = new Phaser.GameObjects.Image(this.scene, p['x'], p['y'], 'Beehive 1');
                        this._Beehive.push(_Beehive);
                    }
                    for (let bea of this._BeehiveEmptyArray) {
                        this.gameplayContainer.add(bea);
                    }
                    for (let bee of this._Beehive) {
                        this.gameplayContainer.add(bee);
                        bee.visible = false;
                    }
                }
                randomizeLettersPosition() {
                    let hx = this.wordsRects.slice();
                    for (let l of this.letters) {
                        if (hx.length == 0)
                            return;
                        let hc = Phaser.Utils.Array.RemoveRandomElement(hx);
                        hc['hcIdleMotionContainer'].add(l);
                        hc["letter"] = l;
                        hc["-letter-text"] = l.key ?? l.text;
                        l["hexagon"] = hc;
                    }
                }
                updateHexagonsArrange() {
                    for (let hc of this.wordsRects) {
                        if (!hc["letter"]) {
                            hc.parentContainer.bringToTop(hc);
                            break;
                        }
                    }
                }
                shuffleOutHexagons() {
                    this.createHexagons();

                    let setLettersInputEnabled = () => {
                        if (this.correctAudio && this.correctAudio.isPlaying) {
                            delayedCall((this.correctAudio.duration * 0.75) * 1000, () => {
                                this.setLettersInputEnabled(true);
                            });
                        }
                        else {
                            this.setLettersInputEnabled(true);
                        }
                    };

                    if (!this.gameplay.isRoundsComplete()) {
                        delayedCall(2900, () => {
                            if (this.gameplay.isCorrectLetterAudioQuestionEnable()) {
                                this.playCorrectAudio();
                            }
                            setLettersInputEnabled();
                        });
                    }

                    if (this.sfxCarAccelerating) {
                        this.sfxCarAccelerating.stop();
                        this.sfxCarAccelerating = null;
                    }
                    if (this.sfxWrong) {
                        this.sfxWrong.stop();
                        this.sfxWrong = null;
                    }

                    if (!this.gameplay.isRoundsComplete()) {
                        this.createLetters();
                        this.randomizeLettersPosition();
                    }

                    this.setLettersInputEnabled(false);
                    this.updateHexagonsArrange();
                    // Phaser.Utils.Array.Shuffle(this.carsPositions);
                    let cars = this.wordsRects;
                    for (let i = 0; i < cars.length; i++) {
                        let hc = cars[i];
                        hc["defX"] = this.wordRectPositions[i]["x"];
                        hc["defY"] = this.wordRectPositions[i]["y"];
                        this.scene.tweens.killTweensOf(hc);

                        hc.alpha = 0;
                        delayedCall(2400, () => {
                            this.scene.tweens.add({
                                targets: hc,
                                alpha: 1,
                                duration: 300,
                                ease: Phaser.Math.Easing.Cubic.Out,
                                delay: 200 + i * 125
                            });
                            this.scene.sound.add('Blocks appearing in each round').play();
                        });
                    }

                    this.scene.tweens.add({
                        targets: this.targetRect1,
                        "alpha": 1,
                        duration: 900,
                        delay: 1700
                    });
                    this.scene.tweens.add({
                        targets: this.targetRect2,
                        "alpha": 1,
                        duration: 900,
                        delay: 2100
                    });

                    var snd = this.scene.sound.add('Plane coming in');
                    snd.setRate(1.15);
                    snd.play();
                    this.scene.tweens.add({
                        targets: this.shipContainer,
                        "x": 480,
                        duration: 2250,
                        // ease: Phaser.Math.Easing.Cubic.Out,
                        onComplete: () => {
                            //ship idle movement

                            /* this.scene.tweens.add({
                                targets: this.shipContainer,
                                "y": this.shipContainer.y - 7,
                                duration: 1500-500,
                                repeat: -1,
                                yoyo: true
                            }); */
                        }
                    });

                    for (let c of cars) this.gameplayContainer.remove(c);
                    this.wordsRects.sort((c1, c2) => c1.y - c2.y);
                    // for (let c of cars) this.gameplayContainer.add(c);

                    this.createInput();
                }
                shuffleInHexagons() {
                    this.updateHexagonsArrange();
                    let positions = this.wordRectPositions.slice();
                    this.setLettersInputEnabled(false);

                    // delayedCall(600, () => {
                    // this.scene.sound.add('move to centre').play();
                    this.shuffleOutHexagons();
                    // });
                }
                onCorrectDragsMade() {
                    let completed = this.onCorrectAnswer();

                    // this.scene.sound.add('First meteor explostion').play();

                    this.setLettersInputEnabled(false);

                    // this.scene.sound.add('Snail giggling sound - correct selection').play();

                    delayedCall(1700 + 1500, () => {
                        for (let _hc of this.wordsRects) {
                            this.scene.tweens.add({
                                targets: _hc,
                                "x": _hc.x + 900,
                                ease: Phaser.Math.Easing.Cubic.In,
                                duration: 1750,
                                delay: 0
                            });
                        }
                        this.scene.tweens.killTweensOf(this.shipContainer);

                        this.scene.sound.add('Plane leaving sound').play();
                        this.scene.tweens.add({
                            targets: this.shipContainer,
                            "x": this.shipContainer.x + 900,
                            ease: Phaser.Math.Easing.Cubic.In,
                            duration: 1750,
                            delay: 0
                        });
                    });

                    // delayedCall(650, () => this.scene.sound.add('Going up sound').play())
                    // if (completed) delayedCall(1750, () => this.scene.sound.add('Final celebration sound').play())

                    delayedCall(completed ? 3700 : 3950 + 1500, () => {
                        if (!completed) {
                            this.shuffleInHexagons();
                        } else {
                            this.scene.sound.add("Success 2").play();
                        }
                    });

                    // this.playBtnClickAnim(hc);
                    /* let lost = false;
                    if (correctAnswer) {
                    } else {
                        sh.scene.Preloader.playAnim('wrong', this.airCraft, () => {
                            sh.scene.Preloader.playAnim('idle', this.airCraft);
                            this.airCraft.setScale(1 / 0.9);
                        });
                        this.airCraft.setScale(1);

                        this.scene.tweens.add({
                            targets: hc,
                            alpha: 0,
                            duration: 500
                        });

                        delayedCall(700, () => { hc.visible = false; });
                        lost = this.onWrongAnswer();
                        if (!lost) {
                            // this.shuffleInHexagons();
                        } else {
                            this.isLost = true;
                        }
                    } */
                }
                createInput() {
                    this.scene.input.on('drag', (pointer, block, dragX, dragY) => {
                        if (!block['-draggable-'])
                            return;
                        block.x = dragX;
                        block.y = dragY;
                        block.parentContainer.bringToTop(block);
                    });
                    console.log("CORRECT WORDS: ", this.gameplay.getCorrectLetters());
                    this.targetBlocks = [];
                    let bb = [{ x: 300, y: 206 }, { x: 481, y: 206 }];
                    for (let i = 0; i < 2; i++) {
                        let b = bb[i];
                        b['alreadyFilled'] = false;
                        this.targetBlocks.push(b);
                        b["-letter-text"] = this.gameplay.getCorrectLetters()[i];
                        b["-index-"] = i;
                        b["-block-"] = null;
                    }
                    window['this.targetBlocks'] = this.targetBlocks;

                    for (let a of this.wordsRects) {
                        // let correctAnswer = hc["letter"] && hc["letter"]["key"] == this.gameplay.getCorrectLetters(1);
                        //hc["letter"].alpha = correctAnswer ? 1 : 0.5;

                        a.setSize(a["-image-"].width, a["-image-"].height);
                        a.setInteractive({ cursor: 'pointer' });
                        this.scene.input.setDraggable(a, true);
                        a.on('pointerdown', () => {
                            if (a["-placedAtBlock-"]) {
                                a["-placedAtBlock-"]['alreadyFilled'] = false;
                                a["-placedAtBlock-"] = null;
                            }

                            a['-pointerdown-'] = true;
                            if (a['-draggable-']) this.scene.sound.add("Move the block from its spot").play();
                        });
                        a.on('pointerup', () => {
                            a['-pointerdown-'] = false;
                            if (!a['-draggable-'])
                                return;

                            let wasTargetBlockFound = false;
                            let targetBlock;
                            for (targetBlock of this.targetBlocks) {
                                if (targetBlock['alreadyFilled'])
                                    continue;
                                if (Phaser.Math.Distance.Between(a.x, a.y, targetBlock.x, targetBlock.y) < 50) {
                                    //as
                                    // a['-draggable-'] = false;
                                    targetBlock['alreadyFilled'] = true;
                                    this.scene.tweens.add({
                                        targets: a,
                                        x: targetBlock.x,
                                        y: targetBlock.y,
                                        duration: 300,
                                        ease: Phaser.Math.Easing.Back.Out
                                    });
                                    targetBlock["-block-"] = a;
                                    a["-placedAtBlock-"] = targetBlock;
                                    this.scene.sound.add("Dopping the block").play();

                                    wasTargetBlockFound = true;
                                    break;
                                }
                            }

                            let allTargetsFilled = true;
                            for (targetBlock of this.targetBlocks) {
                                if (!targetBlock['alreadyFilled']) {
                                    allTargetsFilled = false;
                                    break;
                                }
                            }
                            if (allTargetsFilled) this.checkTargetBlockLetters();

                            if (!wasTargetBlockFound) {
                                this.moveBridgeBackToStartPosition(a, null, true);
                            }
                        });
                        a.on('pointerout', () => {
                            if (!a['-draggable-'])
                                return;
                            if (!a['-pointerdown-'])
                                return;
                            this.moveBridgeBackToStartPosition(a, null, true);
                        });
                    }
                    this._btnSound.on('pointerup', () => {
                        this.playBtnClickAnim(this._btnSound);
                        this.onSoundClick();
                    });
                    this._btnClose.on('pointerup', () => {
                        this.playBtnClickAnim(this._btnClose);
                        this.onCloseClick();
                    });
                }
                hideAllUnusedBeehives() {
                    let i = this.gameplay.getCurrentTotalAnswersCount();
                    for (let k = i + 1; k < this._BeehiveEmptyArray.length; k++) {
                        this._BeehiveEmptyArray[k].visible = false;
                        this._Beehive[k].visible = false;
                    }
                }
                checkTargetBlockLetters() {
                    let sameLettersNum = 0;
                    for (let targetBlock of this.targetBlocks) {
                        if (!targetBlock['-block-']) {
                            // this.scene.sound.add("Placing block above").play();
                            return;
                        }
                        if (targetBlock["-letter-text"] == targetBlock['-block-']["-letter-text"]) {
                            sameLettersNum++;
                        }
                    }
                    this.setLettersInputEnabled(false);
                    if (sameLettersNum == this.targetBlocks.length) {
                        delayedCall(3000, () => {
                            for (let _hc of this.wordsRects) {
                                if (_hc['-placedAtBlock-']) continue;
                                this.scene.tweens.add({
                                    targets: _hc,
                                    "alpha": 0,
                                    ease: Phaser.Math.Easing.Cubic.In,
                                    duration: 900,
                                    delay: 0
                                });
                            }
                            this.scene.sound.add("Blocks disappearing").play();
                        });

                        delayedCall(1000, () => {
                            let _letter1, _letter2;
                            for (let _hc of this.wordsRects) {
                                if (!_hc['-placedAtBlock-']) continue;

                                if (_hc['-placedAtBlock-']["-index-"] === 0) {
                                    _letter1 = _hc['letter'];
                                } else {
                                    _letter2 = _hc['letter'];
                                }
                            }
                            let mid = (_letter1.width + _letter2.width) / 2;
                            let _dx = mid - _letter1.width;

                            for (let _hc of this.wordsRects) {
                                if (!_hc['-placedAtBlock-']) continue;
                                let dx = this.gameplay.useImages ? 90 : 75;
                                if (_hc['-placedAtBlock-']["-index-"] === 0) {
                                    this.scene.tweens.add({
                                        targets: _hc['letter'],
                                        "x": _hc['letter'].x + dx - _dx,
                                        "originX": 1,
                                        delay: 0,
                                        duration: 300,
                                        onUpdate: () => {
                                            _hc['letter'].setOrigin(_hc['letter'].originX, _hc['letter'].originY);
                                        }
                                    });
                                } else {
                                    this.scene.tweens.add({
                                        targets: _hc['letter'],
                                        "x": _hc['letter'].x - dx - _dx,
                                        "originX": 0,
                                        delay: 0,
                                        duration: 300,
                                        onUpdate: () => {
                                            _hc['letter'].setOrigin(_hc['letter'].originX, _hc['letter'].originY);
                                        }
                                    });
                                }

                                this.scene.tweens.add({
                                    targets: _hc["bg"],
                                    "alpha": 0,
                                    duration: 300
                                });
                            }
                            this.scene.tweens.add({
                                targets: this.bigCorrectRect,
                                "alpha": 1,
                                duration: 300
                            });

                            this.onCorrectDragsMade();
                        });
                    } else {
                        // for (let targetBlock of this.targetBlocks) targetBlock['-block-']["-image-"].setTexture('DoughWrong');

                        // delayedCall(500, () => { this.scene.sound.add("wrong drop").play(); });
                        delayedCall(1800, () => {
                            for (let targetBlock of this.targetBlocks) {
                                // for (let targetBlock of this.targetBlocks) targetBlock['-block-']["-image-"].setTexture('DoughNormal');
                                this.moveBridgeBackToStartPosition(targetBlock['-block-'], null, false);
                            }
                            for (let targetBlock of this.targetBlocks) {
                                targetBlock['alreadyFilled'] = false;
                                targetBlock['-block-']['-draggable-'] = true;
                                targetBlock['-block-']["-placedAtBlock-"] = null;
                                targetBlock["-block-"] = null;
                            }
                            // delayedCall(100, ()=>this.scene.sound.add("all three blocks move back after wrong").play());
                        });
                        delayedCall(1500, () => this.scene.sound.add("Two blocks move back after a wrong placement").play());
                        delayedCall(150, () => {
                            let lost = this.onWrongAnswer();

                            delayedCall(650, () => { this.scene.sound.add('No snail animation sound').play(); });
                            delayedCall(750, () => {
                                sh.scene.Preloader.playAnim('wrong', this.airCraft, () => {
                                    sh.scene.Preloader.playAnim('idle', this.airCraft);                                    
                                    this.airCraft.setScale(0.9/0.8);
                                });
                                this.airCraft.setScale(1);
                            });

                            // delayedCall(500, () => {
                            if (!lost) this.setLettersInputEnabled(true);
                            // });
                        });
                    }
                }
                moveBridgeBackToStartPosition(block, onComplete, playSound) {
                    this.scene.tweens.add({
                        targets: block,
                        x: block['startPosition'].x,
                        y: block['startPosition'].y,
                        duration: 350,
                        ease: Phaser.Math.Easing.Sine.Out,
                        onComplete: () => {
                            if (onComplete)
                                onComplete();
                        }
                    });
                    if (Phaser.Math.Distance.Between(block['startPosition'].x, block['startPosition'].y, block.x, block.y) > 20) {
                        delayedCall(100, () => {
                            if (playSound) {
                                this.scene.sound.add("Block goes back when released").play();
                            }
                        });
                    }
                }
                onCorrectAnswer() {
                    let correctAudioKey = this.gameplay.getCorrectAudioKey();
                    delayedCall(600, () => {
                        this.scene.sound.add(correctAudioKey).play();
                    });

                    let i = this.gameplay.getCurrentTotalAnswersCount();
                    if (i < this._BeehiveEmptyArray.length) {
                        this._BeehiveEmptyArray[i].visible = false;
                        this._Beehive[i].visible = true;
                    }
                    this.hideAllUnusedBeehives();
                    let completed = this.gameplay.onCorrectAnswer();
                    this.scene.sound.add('Correct sfx').play();

                    // this.sfxCarAccelerating = this.scene.sound.add('Car accelerating');
                    // this.sfxCarAccelerating.play();

                    return completed;
                }
                onWrongAnswer(forceNewRound) {
                    let i = this.gameplay.getCurrentTotalAnswersCount();
                    if (i < this._BeehiveEmptyArray.length) {
                        this._BeehiveEmptyArray[i].visible = true;
                        this._Beehive[i].visible = false;
                    }
                    this.hideAllUnusedBeehives();
                    let lost = this.gameplay.onWrongAnswer(forceNewRound);
                    this.sfxWrong = this.scene.sound.add("wrong sfx");
                    this.sfxWrong.play();
                    return lost;
                }
                playCorrectAudio() {
                    if (this.correctAudio) {
                        this.correctAudio.stop();
                    }
                    this.correctAudio = this.scene.sound.add("englQuestion");
                    this.correctAudio.once('complete', () => {
                        this.correctAudio = this.scene.sound.add(this.gameplay.getCorrectAudioKey());
                        this.correctAudio.play();
                    });
                    this.correctAudio.play();
                    if (this.areYouSureWindow && this.areYouSureWindow.parentContainer == this) {
                        this.correctAudio.pause();
                    }
                }
                onSoundClick() {
                    this.playCorrectAudio();
                }
                onCloseClick() {
                    this.showAreYouSurePage();
                    this.scene.sound.add('warning page pop up sfx').play();
                }
                playBtnClickAnim(target) {
                    let sc = target["defScale"] ? target["defScale"] : 1;
                    target.scaleX = target.scaleY = sc;
                    this.scene.tweens.add({
                        targets: target,
                        "scaleX": 0.9 * sc,
                        "scaleY": 0.9 * sc,
                        duration: 100,
                        yoyo: true
                    });
                }
                showInstructionPage() {
                    setPageBackground("bg-blue");
                    this.instructionPage = new screen.InstructionPage(this.scene, (target) => {
                        this.playBtnClickAnim(target);
                        this.remove(this.instructionPage);
                        this.showGameplay();
                        if (this.wfsnd) {
                            this.wfsnd.stop();
                        }
                    }, (target) => {
                        this.playBtnClickAnim(target);
                        if (this.wfsnd) {
                            this.wfsnd.stop();
                        }
                        if (!game.cache.json.get('gameplay')['welcomeAudioDisabled']) {
                            this.wfsnd = this.scene.sound.add("Welcome Find the sound");
                            this.wfsnd.play();
                        }
                    });
                    this.add(this.instructionPage);
                    try {
                        if (!game.cache.json.get('gameplay')['welcomeAudioDisabled']) {
                            this.wfsnd = this.scene.sound.add("Welcome Find the sound");
                            this.wfsnd.play();
                        }
                    }
                    catch (e) {
                        console.error(e);
                    }
                }
                showAreYouSurePage() {
                    /* for (let j = 0; j < this.cars.length; j++) {
                        if (this.cars[j]["raceTween"]) {
                            this.cars[j]["raceTween"].pause();
                        }
                    } */

                    pauseAllDelayedCalls();
                    setPageBackground("bg-blue");
                    this.pauseSounds();
                    if (!this.areYouSureWindow) this.areYouSureWindow = new screen.AreYouSureWindow(this.scene, () => {
                        this.remove(this.areYouSureWindow);
                        this.destroyGameplay();
                        this.showInstructionPage();
                    }, () => {
                        this.remove(this.areYouSureWindow);
                        this.unpauseSounds();
                        resumeAllDelayedCalls();

                        /* for (let j = 0; j < this.cars.length; j++) {
                            if (this.cars[j]["raceTween"]) {
                                this.cars[j]["raceTween"].resume();
                            }
                        } */

                        setPageBackground("bg-australia");
                    });
                    this.add(this.areYouSureWindow);
                }
                setLettersInputEnabled(enabled) {
                    if (enabled) {
                        for (let hc of this.wordsRects) {
                            hc.setInteractive({ cursor: 'pointer' });
                            this.scene.input.setDraggable(hc, true);
                            // hc["bg"].setInteractive(new Phaser.Geom.Rectangle(80, 100, 240, 200), Phaser.Geom.Rectangle.Contains);
                        }
                    }
                    else {
                        for (let hc of this.wordsRects) {
                            try { this.scene.input.setDraggable(hc, false); } catch (e) { }
                            hc.disableInteractive();
                        }
                    }
                }
                pauseSounds() {
                    if (this.correctAudio)
                        this.correctAudio.pause();
                    if (this.bgMusic)
                        this.bgMusic.pause();
                }
                unpauseSounds() {
                    if (this.correctAudio)
                        this.correctAudio.resume();
                    if (this.bgMusic)
                        this.bgMusic.resume();
                }
                destroyGameplay() {
                    this.setLettersInputEnabled(false);
                    this.remove(this.gameplayContainer);
                    this.scene.sound.stopAll();
                    destroyAllDelayedCalls();
                }
            }
            screen.GameplayScreen = GameplayScreen;
        })(screen = sh.screen || (sh.screen = {}));
    })(sh || (sh = {}));
    (function (sh) {
        var screen;
        (function (screen) {
            class InstructionPage extends Phaser.GameObjects.Container {
                constructor(scene, onPlayClick, onSndClick) {
                    super(scene);
                    this._instructionPage = new Phaser.GameObjects.Image(this.scene, 0 - 105, 0 - 48, 'Instructions page  ALL ACTIVITY  TITLEs');
                    this._instructionPage.setOrigin(0, 0);
                    this._instructionPage.setInteractive();

                    this._instructionPageTitle = this.scene.add.text(475, 110, sh.App.json.instructionTitle ?? 'Aeroplane', {
                        "fontFamily": "Kids Rock DEMO",
                        "fontSize": 45,
                        "color": "#43425D",
                        "align": 'center'
                    });
                    this._instructionPageTitle.setOrigin(0.5, 0.5);
                    window['this._instructionPageTitle'] = this._instructionPageTitle;
                    this._instructionPageTitle.setStroke('#ffffff', 5);
                    //  Apply the gradient fill.
                    const gradient = this._instructionPageTitle.context.createLinearGradient(0, 0, 0, this._instructionPageTitle.height);
                    gradient.addColorStop(0, '#eeef67');
                    gradient.addColorStop(0.55, '#e98c2c');
                    gradient.addColorStop(1, '#91301a');
                    this._instructionPageTitle.setFill(gradient);

                    this._btnPlay = new Phaser.GameObjects.Image(this.scene, game.scale.width / 2, 480 - 50, 'btnPLAY1');
                    this._btnPlay.setInteractive({ cursor: 'pointer' });
                    this._btnPlay.once('pointerup', onPlayClick);
                    setupButtonTextureBased(this._btnPlay, 'btnPLAY1', 'btnPLAY2');
                    this.instrTxt = this.scene.add.text(game.scale.width / 2, game.scale.height / 2, "Use the words to make the verse on\n the flag.", {
                        "fontFamily": "Kids Rock DEMO",
                        "fontSize": 30,
                        "lineSpacing": 15,
                        "color": "#43425D",
                        "align": 'center'
                    });
                    this.instrTxt.setOrigin(0.5, 0.5);
                    this._btnSoundInstruction = new Phaser.GameObjects.Image(this.scene, 800 - 105, 156 - 50, 'Sound');
                    this._btnSoundInstruction.setInteractive({ cursor: 'pointer' });
                    this._btnSoundInstruction.on('pointerup', onSndClick);
                    this._btnSoundInstruction["defScale"] = this._btnSoundInstruction.scale;
                    setupButtonTextureBased(this._btnSoundInstruction, 'Sound', 'Sound HOVER EFFECT');
                    this.add(this._instructionPage);
                    this.add(this._instructionPageTitle);
                    this.add(this.instrTxt);
                    this.add(this._btnPlay);
                    this.add(this._btnSoundInstruction);
                }
            }
            screen.InstructionPage = InstructionPage;
        })(screen = sh.screen || (sh.screen = {}));
    })(sh || (sh = {}));
    (function (sh) {
        var screen;
        (function (screen) {
            class TryAgainWindow extends Phaser.GameObjects.Container {
                constructor(scene, onBack, onReplay) {
                    super(scene);
                    this.music = null;
                    this.setPosition(-106, -48);
                    this._bg = new Phaser.GameObjects.Image(this.scene, 0, 0, 'Try again page');
                    this._bg.setOrigin(0, 0);
                    this._bg.setInteractive();
                    this._star = new Phaser.GameObjects.Image(this.scene, 400, 415, 'Break Star');
                    this._btnBack = new Phaser.GameObjects.Image(this.scene, 600, 580, 'btnBACK1');
                    this._btnReplay = new Phaser.GameObjects.Image(this.scene, 765, 580, 'btnReplay1');
                    this.totalScoreTxt = this.scene.add.text(850, 355, "", {
                        "fontFamily": "Kids Rock Demo",
                        "fontSize": 35,
                        "color": "#F49F1C",
                        "align": 'center',
                        'stroke': '#70451A',
                        'strokeThickness': 6
                    });
                    this.totalScoreTxt.setOrigin(0.5, 0.5);
                    let grd = this.totalScoreTxt.context.createLinearGradient(0, 0, 0, this.totalScoreTxt.height);
                    grd.addColorStop(0, '#FFFF00');
                    grd.addColorStop(1, '#C17316');
                    this.totalScoreTxt.setFill(grd);
                    this.starScoreTxt = this.scene.add.text(635, 431, "", {
                        "fontFamily": "Kids Rock Demo",
                        "fontSize": 24,
                        "color": "#FFFFFF",
                        "align": 'center'
                    });
                    this.starScoreTxt.setOrigin(0.5, 0.5);
                    this.add([
                        this._bg,
                        this._star,
                        this._btnBack,
                        this._btnReplay,
                        this.totalScoreTxt,
                        this.starScoreTxt
                    ]);
                    this._btnBack.setInteractive({ cursor: 'pointer' });
                    this._btnBack.on('pointerup', () => {
                        onBack(this._btnBack);
                        angularComponentRef.backToMenu();
                        // if (this.music) {
                        //     this.music.stop();
                        // }
                    });
                    setupButtonTextureBased(this._btnBack, 'btnBACK1', 'btnBACK2');
                    this._btnReplay.setInteractive({ cursor: 'pointer' });
                    this._btnReplay.once('pointerup', () => {
                        onReplay(this._btnReplay);
                        if (this.music) {
                            this.music.stop();
                        }
                    });
                    setupButtonTextureBased(this._btnReplay, 'btnReplay1', 'btnReplay2');
                }
                show(score, starScore) {
                    this._star.scale = 1.25;
                    this.scene.tweens.add({
                        targets: this._star,
                        "scale": 1,
                        duration: 500,
                        ease: Phaser.Math.Easing.Back.Out
                    });
                    this.totalScoreTxt.text = String(window.angularComponentRef.component.userInfo.totalScore);
                    this.starScoreTxt.text = String(0);
                    this.music = this.scene.sound.add("Fail - close one");
                    this.music.play();
                }
            }
            screen.TryAgainWindow = TryAgainWindow;
        })(screen = sh.screen || (sh.screen = {}));
    })(sh || (sh = {}));
}

function destroyAeroPlane() {
    if (game) {
        game.destroy();
    }
}
