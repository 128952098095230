import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription, Observable } from 'rxjs';
import { AngularFireStorage } from '@angular/fire/storage';
import { LoginActionStatusService } from 'src/app/shared/actionStatus.service';
import { AuthService } from '../../shared/auth.service';

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html'
})
export class ProfileComponent implements OnInit, OnDestroy, AfterViewInit {
    userSubscription: Subscription;
    schoolName = '';

    id: string;
    activityId: string;

    gameStatus: string;
    data: any;
    totalScore: number;

    titles: any = {};
    userInfo: any;

    currentValue;

    photoArray;
    loading: boolean = true;

    constructor(
        private router: Router,
        public storage: AngularFireStorage,
        private actionStatus: LoginActionStatusService,
        private auth: AuthService
    ) { }

    ngOnInit() {
        this.userSubscription = this.auth.onUserUpdate.subscribe(userData => {
            if (userData != null && userData) this.loadChildData(userData);
        }, error => {
            console.log('error', error);
        });

        let fromPath = localStorage.getItem('fromPath');
        let inUrls = new Array('/game/my-progress', '/game/my-awards', '/game/my-gallery', '/game/set-avatar', '/game/profile');
        if (fromPath && !inUrls.find(item => item == fromPath)) {
            localStorage.setItem('fromToProfile', fromPath);
        }

        let user = JSON.parse(localStorage.getItem('currentUser'));
        let userUid = user.uid;
        let adminUserData = JSON.parse(localStorage.getItem('quranera_admin_userData'));
        if (adminUserData) {
            userUid = user.email;
        }
    }

    async loadChildData(userData) {
        let user = JSON.parse(localStorage.getItem('currentUser'));

        var school = await this.auth.getStudentSchoolData({ collectionID: "userData" }).toPromise();

        this.schoolName = school.result;

        this.userInfo = userData;

        if (!this.userInfo.name) {
            this.userInfo.name = user.displayName;
        }

        if (!this.userInfo.school) {
            this.userInfo.school = 'unset';
        }

        if (!this.userInfo.age) {
        }

        if (!this.userInfo.avatar) {
            this.userInfo.avatar = 'g1';
        }

        let dob = new Date(this.userInfo.birthday.year, parseInt(this.userInfo.birthday.month) - 1, this.userInfo.birthday.day);
        let age = this.calculateAge(dob);
        this.userInfo.age = age || '-';
        this.setbuttonEvents();
    }

    ngAfterViewInit(): void {
        this.setbuttonEvents();
        setTimeout(() => {
            this.instructionLoaded();
        }, 3000);
    }

    setbuttonEvents() {
        setTimeout(() => {
            var buttons = document.querySelectorAll('button');
            for (var i = 0; i < buttons.length; i++) {
                buttons[i].addEventListener('click', function (event) {
                    let audio = <HTMLAudioElement>document.getElementById('buttonClick');
                    if (audio) audio.play();
                });
                buttons[i].addEventListener('mouseover', function (event) {
                    let audio = <HTMLAudioElement>document.getElementById('buttonHover');
                    if (audio) audio.play();
                });
            }
        }, 300)
    }

    backToMenu() {
        this.previousState();
    }

    ngOnDestroy(): void {
        this.userSubscription.unsubscribe();
    }

    previousState() {
        let backUrl = localStorage.getItem('fromToProfile');
        if (backUrl) {
            this.router.navigate(['/game']);
        }
        else {
            this.router.navigate(['/game']);
        }
    }

    instructionLoaded() {
        if (this.loading) {
            this.loading = false;
        }
    }

    private calculateAge(birthday: Date) { // birthday is a date
        var ageDifMs = Date.now() - birthday.getTime();
        var ageDate = new Date(ageDifMs); // miliseconds from epoch
        return Math.abs(ageDate.getUTCFullYear() - 1970);
    }

    openLogin() {
        this.actionStatus.action.next(true);
    }
}
