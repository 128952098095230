import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class StorageService {
    private storageType = 'localStorage';
    private storageTypes = ['localStorage', 'sessionStorage'];
    private storagePrefix = 'quranera_admin_';
    private storagePrefixS = 'superAdmin_';

    constructor() { }

    private keyName(name, withPrefix = true) {
        return withPrefix ? (this.storagePrefix + name) : name;
    }

    private keyNameSuper(name, withPrefix = true) {
        return withPrefix ? (this.storagePrefixS + name) : name;
    }

    private storage() {
        return window[this.storageType];
    }

    public setType(type: string) {
        if (this.storageTypes.indexOf(type) > -1) {
            this.storageType = type;
        }
    }

    public setPrefix(prefix: string) {
        this.storagePrefix = prefix;
    }

    public get(name: string, withPrefix = true) {
        const val = this.storage().getItem(this.keyName(name, withPrefix));

        if (
            val &&
            (
                (val.startsWith('{') && val.endsWith('}')) ||
                (val.startsWith('[') && val.endsWith(']'))
            )
        ) {
            return JSON.parse(val);
        }

        return val;
    }

    public getSuper(name: string, withPrefix = true) {
        const val = this.storage().getItem(this.keyNameSuper(name, withPrefix));

        if (
            val &&
            (
                (val.startsWith('{') && val.endsWith('}')) ||
                (val.startsWith('[') && val.endsWith(']'))
            )
        ) {
            return JSON.parse(val);
        }

        return val;
    }

    public set(name: string, val: any) {
        if (val === null) {
            this.remove(name);
        } else {
            if (typeof val === 'object') {
                val = JSON.stringify(val);
            }

            this.storage().setItem(this.keyName(name), val);
        }
    }

    public remove(name: string) {
        this.storage().removeItem(this.keyName(name));
    }

    public clear() {
        const listOfRemovingKeys = [];
        for (let i = 0; i < this.storage().length; i++) {
            const storageKey = this.storage().key(i);

            if (storageKey.startsWith(this.storagePrefix)) {
                listOfRemovingKeys.push(storageKey);
            }
        }

        for (let i = 0; i < listOfRemovingKeys.length; i++) {
            this.storage().removeItem(listOfRemovingKeys[i]);
        }
    }
}
