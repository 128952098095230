var game;
function soundSearch(json) {
    var currentQuestionIndex = 0;

    function setupButton(btn, frame_pointerdown, frame_pointerover, frame_pointerout, frame_pointerup, textureBased) {
        if (typeof frame_pointerout === "undefined") frame_pointerout = frame_pointerdown;
        if (typeof frame_pointerup === "undefined") frame_pointerup = frame_pointerdown;

        btn['frame_pointerdown'] = frame_pointerdown;
        btn['frame_pointerover'] = frame_pointerover;
        btn['frame_pointerout'] = frame_pointerout;
        btn['frame_pointerup'] = frame_pointerup;

        if (!btn["alreadySetupEvents"]) {
            btn["alreadySetupEvents"] = true;
            let fn = textureBased ? "setTexture" : "setFrame";
            btn.on('pointerdown', () => { btn[fn](btn['frame_pointerdown']); });
            btn.on('pointerover', () => { btn[fn](btn['frame_pointerover']); });
            btn.on('pointerout', () => { btn[fn](btn['frame_pointerout']); });
            btn.on('pointerup', () => { btn[fn](btn['frame_pointerup']); });
        }
    };

    //boot
    var boot = function (game) {
        console.log('%cBoot', 'color:white; background:red');
    };

    boot.prototype = {
        init: function () {
            this.apiData = json;

            //fix input
            this.game.scale.transformX = (pageX) => {
                let offsetLeft = 0;
                let parentElement = this.game.canvas.parentElement;
                while (parentElement) {
                    if (parentElement.offsetLeft) {
                        offsetLeft = parentElement.offsetLeft;
                        break;
                    }
                    parentElement = parentElement.parentElement;
                }
                return (pageX - offsetLeft) * this.game.scale.displayScale.x;
            };
            this.game.scale.transformY = (pageY) => {
                let offsetTop = 0;
                let parentElement = this.game.canvas.parentElement;
                while (parentElement) {
                    if (parentElement.offsetTop) {
                        offsetTop = parentElement.offsetTop;
                        break;
                    }
                    parentElement = parentElement.parentElement;
                }
                return (pageY - offsetTop) * this.game.scale.displayScale.y;
            };
        },
        preload: function () {
            this.load.atlas('assets', 'assets/games/sound-search/assets.png', 'assets/games/sound-search/assets.json');
            this.load.atlas('soundBtn', 'assets/qa/sound-icon/sound-btn-spritesheet.png', 'assets/qa/sound-icon/sound-btn-spritesheet.json');
            if (this.apiData.bgImage) {
                this.load.image('bg-image', this.apiData.bgImage);
            }
            else {
                this.load.image('bg-image', 'assets/games/sound-search/img/background.jpg');
            }

            this.load.image('Normal Sound Button', 'assets/games/sound-search/img/Normal Sound Button.png');
            this.load.image('Hover Sound Button', 'assets/games/sound-search/img/Hover Sound Button.png');

            this.load.image('red-arrow-image', 'assets/images/red-arrow.png');
            this.load.spritesheet('idle_default', 'assets/games/sound-search/spritesheets/Idle_sheet.png', { frameWidth: 2160 / 8, frameHeight: 1440 / 4, endFrame: 32 });
            this.load.spritesheet('idle_no', 'assets/games/sound-search/spritesheets/No_sheet.png', { frameWidth: 2160 / 8, frameHeight: 1440 / 4, endFrame: 32 });
            // this.load.spritesheet('idle_yes', 'assets/games/sound-search/spritesheets/Yes_sheet.png', { frameWidth: 400, frameHeight: 400, endFrame: 90 });
            this.load.atlas('idle_yes_atlas', 'assets/games/sound-search/spritesheets/Yes_atlas.png', 'assets/games/sound-search/spritesheets/Yes_atlas.json');


            this.cache.binary.add('questions', this.apiData.questions);
            this.cache.binary.add('instruction', this.apiData.instruction);
            // this.load.json('complete', location.origin + '/data/complete.json');
            this.cache.binary.add('totalPoints', '0');
            this.cache.binary.add('qAnsPoints', {
                correctAnsPoint: 2,
                secTimeCorrectAnsPoint: 0,
                wrongAnsPoint: 0
            });

            this.load.image('instruction-bg', 'assets/games/sound-search/img/modal-bg-1.png');
            this.load.image('completion-bg', 'assets/games/sound-search/img/complete-page-bg.jpg');
            this.load.image('try-again-bg', 'assets/games/sound-search/img/Try-again-bg.jpg');
            this.load.image('try-again-trophy', 'assets/games/sound-search/img/Try-again-trophy-w-bg.png');
            this.load.image('complete-trophy', 'assets/games/sound-search/img/complete-trophy.png');

            this.load.image('modal-overlay', 'assets/games/sound-search/img/modal-overlay.png');

            // this.load.audio('yes', 'assets/games/sound-search/sounds/yes.wav');
            this.load.audio('yes', 'assets/games/sound-search/sounds/yes_2.mp3');
            this.load.audio('noCorrectAnswerThis', 'assets/games/sound-search/sounds/no.mp3');
            this.load.audio('Next round starts', 'assets/games/sound-search/sounds/Next round starts.mp3');

            if (this.apiData.complete && this.apiData.complete.audios && this.apiData.complete.audios.length > 0) {
                var index = Math.floor(Math.random() * Math.floor(this.apiData.complete.audios.length));
                this.load.audio('completionSound', this.apiData.complete.audios[index]);
            }
            else {
                this.load.audio('completionSound', 'assets/games/sound-search/sounds/viktory.wav');
            }

            this.load.audio('tryAgainSound', 'assets/games/sound-search/sounds/fail.mp3');

            if (this.apiData.bgAudio) {
                this.load.audio('bg-music', this.apiData.bgAudio);
            }

            this.load.audio('selectionSound', 'assets/sounds/selection.mp3');
            this.load.audio('hoverSound', 'assets/sounds/hover.mp3');
            this.load.audio('exitSound', 'assets/sounds/warning-pop-up.mp3'); console.log('musics loaded');
        },
        create: function () {
            this.isMobile = !this.game.device.desktop;
            // this.scale.scaleMode = Phaser.ScaleManager.USER_SCALE;
            // if (this.isMobile) {
            //     this.scaleGame();
            //     this.scale.setResizeCallback(this.scaleGame);
            // }
            //Local storing
            if (window.localStorage.getItem('currentState')) {
                // this.game.state.start('ResumeGame', true, false, window.localStorage.getItem('currentState'));
                window.localStorage.removeItem('currentState');
            }
            this.scene.start("StartGame");//this.game.state.start('StartGame', true, false);
        },

        scaleGame: function () {
            const padding = this.isMobile ? 0 : 0; // include padding around the canvas frame for desktop
            const parentWidth = document.getElementById('game-container').offsetWidth;
            const xScale = (parentWidth - padding) / this.game.width;
            const scalePoint = Math.min(xScale, 1);
            // if (this.scale) {
            //     this.scale.setUserScale(scalePoint, scalePoint);
            // }
        }
    };

    //startGame
    var startgame = function (game) {
        console.log('startgame');
        console.log('%cStartGame', 'color:white; background:blue');
    };

    startgame.prototype = {
        init: function () {
            this.goUp = false;
            var storage = {
                questions: [],
                currentPoints: 0
            };
            window.localStorage.setItem('payload', JSON.stringify(storage));
        },
        preload: function () {
            this.fData = this.cache.binary.get('instruction');
            if (this.fData && this.fData.audio) {
                this.load.audio('startSound', this.fData.audio);
            }

            //sounds
            var self = this;
            this.questionData = { questions: this.cache.binary.get('questions') };
            for (var i = 0; i < this.questionData.questions.length; i++) {
                this.questionData.questions[i].answers.forEach(function (answer, _i) {
                    self.load.image('answerImage_' + i + '_' + _i, "assets/games/sound-search/img/answers/" + answer.imageName + ".png");
                    self.load.audio('answerSound_' + i + '_' + _i, "assets/games/sound-search/sounds/answers/" + answer.sound + ".mp3");
                });
                var soundName = this.questionData.questions[i].questionAudioName;
                if (soundName) {
                    this.load.audio('questionSound_' + i, "assets/games/sound-search/sounds/questions/" + soundName + ".mp3");
                }
            }
        },
        update: function () {
            if (this.redArrowImage) {
                if (this.goUp) {
                    this.redArrowImage.y -= 1;
                    if (this.redArrowImage.y <= -4) {
                        this.goUp = false
                    }
                } else {
                    this.redArrowImage.y += 1;
                    if (this.redArrowImage.y >= 18) {
                        this.goUp = true;
                    }
                }
            }
        },
        create: function () {
            window.angularComponentRef.loadingFinished();
            this.setupState();

            this.addSounds();
            this.playQuestionSound();

            var modalTextStyle = {
                backgroundColor: 'transparent',
                stroke: '#ffffff',
                strokeThickness: 2,
                fontFamily: 'Kids Rock DEMO',
                fontSize: 35,
                color: '#43425d',
                wordWrap: true,
                align: 'center'
            };

            var modalHeaderStyle = {
                backgroundColor: 'transparent',
                stroke: '#ffffff',
                strokeThickness: 16,
                fontFamily: 'Kids Rock DEMO',
                fontSize: 50,
                wordWrap: false,
                align: 'center'
            };

            this.add.sprite(0, 0, 'instruction-bg').setOrigin(0, 0);

            this.modal = this.add.container();

            var modalBox = this.add.sprite(this.game.scale.width / 2, this.game.scale.height / 2, 'assets', 'activity-Instruction-board.png');
            modalBox.setOrigin(0.5, 0.5);

            this.modalHeaderGroup = this.add.container();
            var modalBoxHeader = this.add.sprite(0, 0, 'assets', 'activity-Instruction-board-header.png');
            modalBoxHeader.setOrigin(0, 0);

            var modalHeaderText = this.add.text(0, 0, this.fData.title, modalHeaderStyle);
            if (!(this.game.device.mobileSafari || this.game.device.safari)) {
                modalHeaderText.setShadow(3, 3, '#43425d', 0, false, true);
            }

            var grd = modalHeaderText.context.createLinearGradient(0, 0, 0, modalHeaderText.height);
            grd.addColorStop(0, '#edee62');
            grd.addColorStop(0.2, '#edee62');
            grd.addColorStop(0.7, '#dd7b33');
            modalHeaderText.setFill(grd);

            var bgSoundButton = this.add.sprite(0, 0, 'soundBtn', 'introduction-sound_02.png');
            bgSoundButton.setOrigin(0, 0);
            bgSoundButton.on('pointerup', function () {
                if (this.redArrowImage) {
                    this.redArrowImage.alpha = 0;
                }
                this.playQuestionSound();
            }, this);

            setupButton(bgSoundButton, 'introduction-sound_02.png', 'introduction-sound_01.png');
            bgSoundButton.setInteractive({ cursor: 'pointer' });

            bgSoundButton.on('pointerover', this.playHoverSound, this);
            bgSoundButton.on('pointerdown', this.playSelectionSound, this);

            this.modalHeaderGroup.add(modalBoxHeader);
            this.modalHeaderGroup.add(modalHeaderText);
            this.modalHeaderGroup.add(bgSoundButton);
            this.modalHeaderGroup.setPosition((modalBox.width - modalBoxHeader.width) / 2 + modalBox.getBounds().left, modalBox.getBounds().top - 21);

            if (this.game.device.os.iOS) {
                this.redArrowImage = this.add.sprite(0, 0, 'red-arrow-image');
                this.redArrowImage.setOrigin(0, 0)
                this.modalHeaderGroup.add(this.redArrowImage);
            }

            modalHeaderText.setPosition(modalBoxHeader.getCenter().x, modalBoxHeader.getCenter().y);
            modalHeaderText.setOrigin(0.5, 0.5);
            modalBoxHeader.setPosition(this.modalHeaderGroup.getBounds().left, this.modalHeaderGroup.getBounds().top);
            bgSoundButton.setPosition(modalBoxHeader.getCenter().x + 30, modalBoxHeader.getCenter().y - 40);

            if (this.game.device.os.iOS) {
                this.redArrowImage.setPosition(modalBoxHeader.getCenter().x + 34, modalBoxHeader.getCenter().y - 110);
            }

            var modalText = this.add.text(this.game.scale.width / 2, this.game.scale.height / 2, this.fData.content.replaceAll('{br}', "\n"), modalTextStyle);
            modalText.setWordWrapWidth(modalBox.width - 110);//modalText.wordWrapWidth = modalBox.width - 110;
            modalText.setOrigin(0.5, 0.5);

            console.log('startButton');
            var startButton = this.add.sprite(this.game.scale.width / 2, modalBox.getBounds().bottom - 103, 'assets', 'activity-Instruction-play-button.png');
            startButton.setOrigin(0, 0)
            startButton.on('pointerup', function () {
                console.log('startButton clicked');
                // if (!this.game.device.desktop) {
                //     this.game.scale.startFullScreen(false);
                // }
                this.fsound.stop();
                currentQuestionIndex = 0;
                this.scene.start("Questions");//this.game.state.start('Questions', true, false, 0);
                console.log('startButton clicked22');
            }, this);

            setupButton(startButton, 'activity-Instruction-play-button.png', 'activity-Instruction-play-button-hover.png');
            startButton.setInteractive({ cursor: 'pointer' });

            startButton.on('pointerover', this.playHoverSound, this);
            startButton.on('pointerdown', this.playSelectionSound, this);

            startButton.input.pixelPerfectOver = true;
            startButton.input.pixelPerfectClick = true;
            startButton.setOrigin(0.5, 0.5);

            var leavesRightSprite = this.add.sprite(0, 0, 'assets', 'leaves.png');
            leavesRightSprite.setOrigin(0, 0)
            leavesRightSprite.setPosition(modalBox.getBounds().right - 145, modalBox.getBounds().top + 15);

            var leavesLeftSprite = this.add.sprite(0, 0, 'assets', 'leaves.png');
            leavesLeftSprite.setOrigin(0.5, 0.5);
            leavesLeftSprite.scaleX *= -1;
            leavesLeftSprite.setPosition(modalBox.getBounds().left + 34, modalBox.getBounds().bottom - 140);

            this.modal.add(leavesLeftSprite);
            this.modal.add(leavesRightSprite);
            this.modal.add(modalBox);
            this.modal.add(modalBoxHeader);
            this.modal.add(modalText);
            this.modal.add(startButton);
        },

        setupState: function () {
            // this.game.scale.fullScreenScaleMode = Phaser.ScaleManager.SHOW_ALL;

            // this.game.stage.disableVisibilityChange = true; // keep animation run on focus out
            // this.scale.pageAlignHorizontally = true;

            // this.scale.pageAlignVertically = true;
        },

        addSounds: function () {
            // console.warn("sound!");
            this.fsound = this.sound.add('startSound');
            this.hoverSound = this.sound.add('hoverSound');
            this.selectionSound = this.sound.add('selectionSound');

            // this.time.addEvent({ delay: 0, callback: this.playQuestionSound, callbackScope: this, loop: false });//this.game.time.events.add(0, this.playQuestionSound, this);
        },

        playHoverSound: function () {
            console.log('playHoverSound');
            this.hoverSound.play();
        },
        playSelectionSound: function () {
            this.selectionSound.play();
        },
        playQuestionSound: function () {
            this.fsound.play();
        }
    };

    //endGame
    var endgame = function (game) {
        console.log("%cEndGame", "color:white; background:blue");
    };

    endgame.prototype = {
        init: function () {
            this.saveActivityAns();
        },
        preload: function () {
            this.totalPoints = window.angularComponentRef.getTotalScore();
            // this.fData = this.cache.binary.get('complete')
        },
        create: function () {
            if (!this.completionSound) {
                this.completionSound = this.sound.add('completionSound');
            }
            this.completionSound.play();
            this.hoverSound = this.sound.add('hoverSound');
            this.selectionSound = this.sound.add('selectionSound');

            window.localStorage.removeItem('currentState');
            window.localStorage.removeItem('wrongButtonKey');

            var storageJson = window.localStorage.getItem('payload');
            var storage = JSON.parse(storageJson);
            var currentPoints = 0;
            if (storage) {
                currentPoints = storage.currentPoints || 0;
            }
            // this.scale.pageAlignHorizontally = true;
            // this.scale.pageAlignVertically = true;

            this.add.sprite(0, 0, 'completion-bg').setOrigin(0, 0);

            // this.game.stage.disableVisibilityChange = true; // keep animation run on focus out

            var totalScoreTextStyle = {
                backgroundColor: 'transparent',
                stroke: '#91301a',
                strokeThickness: 6,
                fontFamily: 'Kids Rock DEMO',
                fontSize: 39,
                wordWrap: false,
                align: 'left'
            };
            var totalScoreTextStyleOutline = {
                backgroundColor: 'transparent',
                stroke: '#fff',
                strokeThickness: 12,
                fontFamily: 'Kids Rock DEMO',
                fontSize: 39,
                wordWrap: false,
                align: 'left'
            };
            var collectedPointsTextStyle = {
                backgroundColor: 'transparent',
                stroke: '#91301a',
                strokeThickness: 2,
                fontFamily: 'Kids Rock DEMO',
                fontSize: 25,
                wordWrap: false,
                align: 'left',
                color: '#ffffff'
            };

            var modalBox = this.add.sprite(this.game.scale.width / 2, this.game.scale.height / 2, 'assets', 'activity-Instruction-board.png');
            modalBox.setOrigin(0.5, 0.5);
            var completeRibbon = this.add.sprite(modalBox.getCenter().x, modalBox.getBounds().top + 222, 'assets', 'complete-ribbon-full.png');
            completeRibbon.setOrigin(0.5, 0.5);

            var totalScoreOutline = this.add.text(modalBox.getBounds().left + 340, modalBox.getBounds().bottom - 302, "Total Score: " + " " + this.totalPoints, totalScoreTextStyleOutline);
            var totalScore = this.add.text(modalBox.getBounds().left + 342, modalBox.getBounds().bottom - 300, "Total Score: " + " " + this.totalPoints, totalScoreTextStyle);

            var grd = totalScore.context.createLinearGradient(0, 0, 0, totalScore.height);
            grd.addColorStop(0, '#f4fa38');
            grd.addColorStop(0.3, '#edee62');
            grd.addColorStop(0.7, '#dd7b33');
            totalScore.setFill(grd);

            var collectedPointsMedal = this.add.sprite(modalBox.getBounds().left + 324, modalBox.getBounds().bottom - 244, 'assets', 'collected-poins-medal.png');
            collectedPointsMedal.setOrigin(0, 0)
            var collectedPoints = this.add.text(modalBox.getBounds().left + 422, modalBox.getBounds().bottom - 204, "+" + currentPoints, collectedPointsTextStyle);

            // var trophy = this.game.add.sprite(modalBox.getBounds().left+66, modalBox.bottom-355, 'trophy');
            var trophy = this.add.sprite(modalBox.getBounds().left + 210, modalBox.getBounds().bottom - 215, 'complete-trophy');
            trophy.setScale(0.5, 0.5);
            trophy.setOrigin(0.5, 0.5);
            trophy.alpha = 0;
            this.tweens.add({
                targets: trophy,
                scaleX: 1,
                scaleY: 1,
                duration: 400,
                ease: Phaser.Math.Easing.Linear
            });
            this.tweens.add({
                targets: trophy,
                alpha: 1,
                duration: 400,
                ease: Phaser.Math.Easing.Linear
            });
            // game.add.tween(trophy.scale).to({ x: 1, y: 1 }, 400, Phaser.Easing.Linear.None, true);
            // game.add.tween(trophy).to({ alpha: 1 }, 400, Phaser.Easing.Linear.None, true);

            var backToMenuButton = this.add.sprite(modalBox.getBounds().left + 332, modalBox.getBounds().bottom - 89, 'assets', 'back-to-menu-button.png');
            backToMenuButton.setOrigin(0, 0)
            setupButton(backToMenuButton, 'back-to-menu-button.png', 'back-to-menu-button-hover.png');
            backToMenuButton.setInteractive({ cursor: 'pointer' });
            backToMenuButton.on('pointerup', this.backToMenu, this);

            var playAgainButton = this.add.sprite(modalBox.getBounds().left + 479, modalBox.getBounds().bottom - 89, 'assets', 'play-again-button.png');
            playAgainButton.setOrigin(0, 0)
            setupButton(playAgainButton, 'play-again-button.png', 'play-again-button-hover.png');
            playAgainButton.setInteractive({ cursor: 'pointer' });
            playAgainButton.on('pointerup', this.playAgain, this);

            var nextActivityButton = this.add.sprite(modalBox.getBounds().left + 626, modalBox.getBounds().bottom - 89, 'assets', 'next-activity-button.png');
            nextActivityButton.setOrigin(0, 0)
            setupButton(nextActivityButton, 'next-activity-button.png', 'next-activity-button-hover.png');
            nextActivityButton.setInteractive({ cursor: 'pointer' });
            nextActivityButton.on('pointerup', this.nextActivity, this);

            backToMenuButton.on('pointerover', this.playHoverSound, this);
            backToMenuButton.on('pointerdown', this.playSelectionSound, this);

            playAgainButton.on('pointerover', this.playHoverSound, this);
            playAgainButton.on('pointerdown', this.playSelectionSound, this);

            nextActivityButton.on('pointerover', this.playHoverSound, this);
            nextActivityButton.on('pointerdown', this.playSelectionSound, this);
        },

        backToMenu: function () {
            window.angularComponentRef.backToMenu();
            this.completionSound.stop()
        },
        playAgain: function () {
            console.log('play again button clicked');
            this.completionSound.stop()
            this.scene.start("StartGame");//this.game.state.start('StartGame', true, false);
        },
        nextActivity: function () {
            window.angularComponentRef.nextActivity();
            // this.game.state.start('StartGame', true, false);
        },

        formatTime: function (s) {
            var minutes = "0" + Math.floor(s / 60);
            var seconds = "0" + (s - minutes * 60);
            return minutes.substr(-2) + ":" + seconds.substr(-2);
        },

        playHoverSound: function () {
            this.hoverSound.play();
        },
        playSelectionSound: function () {
            this.selectionSound.play();
        },

        saveActivityAns: function () {
            var storageJson = window.localStorage.getItem('payload');
            var storage = JSON.parse(storageJson);
            var _obj = {
                activity_id: 1,
                country_id: 1,
                zone_id: 1,
                continent_id: 1,
                totalScore: 0,
                questions: []
            }
            if (storage) {
                for (var i = 0; i < storage.questions.length; i++) {
                    var questionKey = storage.questions[i].questionKey;
                    var answerKey = storage.questions[i].answerKey;
                    var attempts = storage.questions[i].attempts;
                    var points = storage.questions[i].points;
                    var question = {
                        [questionKey]: {
                            [answerKey]: {
                                try_count: attempts,
                                score: points
                            }
                        }
                    }
                    _obj.questions.push(question);
                    _obj.totalScore += points
                }
                window.angularComponentRef.gameFinish(_obj);
            }
        }
    };

    //startGame
    var resumegame = function (game) {
        console.log("%cStartGame", "color:white; background:blue");
    };

    resumegame.prototype = {
        init: function (/*currentQuestionIndex*/) {
            // this.currentQuestionIndex = currentQuestionIndex;
            this.fData = { questions: this.cache.binary.get('questions') };
        },
        create: function () {
            // this.scale.pageAlignHorizontally = true;
            // this.scale.pageAlignVertically = true;

            this.hoverSound = this.sound.add('hoverSound');
            this.selectionSound = this.sound.add('selectionSound');

            this.add.sprite(0, 0, 'bg-image').setOrigin(0, 0);

            // this.game.stage.disableVisibilityChange = true; // keep animation run on focus out

            var startButton = this.add.sprite(this.game.scale.width / 2, this.game.scale.height / 2, 'assets', 'next-button.png');
            startButton.setOrigin(0, 0)
            setupButton(startButton, 'next-button.png', 'next-button-hover.png');
            startButton.setInteractive({ cursor: 'pointer' });

            startButton.on('pointerup', function () {
                if (currentQuestionIndex < this.fData.questions.length) {
                    this.scene.start("Questions");//this.game.state.start("Questions", true, false, this.currentQuestionIndex);
                } else {
                    this.scene.start("EndGame");//this.game.state.start("EndGame", true, false);
                }
            }, this);

            startButton.on('pointerover', this.playHoverSound, this);
            startButton.on('pointerdown', this.playSelectionSound, this);
            startButton.setOrigin(0.5, 0.5);

            var style = {
                backgroundColor: 'transparent',
                stroke: '#074a83',
                strokeThickness: 6,
                fontFamily: "Kids Rock DEMO",
                fontSize: 40,
                color: "#ffffff",
                wordWrap: false,
                align: "left"
            };
            var startButtonText = this.add.text(this.game.scale.width / 2, this.game.scale.height / 2 - 100, 'Resume quiz', style);
            startButtonText.setOrigin(0.5, 0.5);
        },
        playHoverSound: function () {
            this.hoverSound.play();
        },
        playSelectionSound: function () {
            this.selectionSound.play();
        }
    };
    //questions
    var questions = function (game) {
        console.log("%cQuestions", "color:white; background:blue");
    };

    questions.prototype = {
        init: function (/*currentQuestionIndex*/) {
            // this.currentQuestionIndex = currentQuestionIndex || 0;
            this.wrongAnswersCount = 0;
            this.maxWrongAnswers = 1;
            window.localStorage.setItem('currentState', currentQuestionIndex);
        },
        preload: function () {
            this.fData = { questions: this.cache.binary.get('questions').filter(question => question.key != 5 && question.key != 10) };
            this.qAnsPoints = this.cache.binary.get('qAnsPoints');
        },
        create: function () {
            if (!this.bgMusic) {
                this.bgMusic = this.sound.add('bg-music', { volume: 1, loop: true });
                this.bgMusic.play();
            }
            else {
                if (!this.bgMusic.isPlaying) {
                    this.bgMusic.play();
                }
            }

            this.questionItem = this.fData.questions[currentQuestionIndex];

            this.drawBg();

            this.drawPuppet();

            this.drawStaticScene(this.questionItem.answers, this.questionItem.answer);

            this.drawStarPoints();

            this.addSounds();

            this.addEvents();

            this.addPlayQuestionButton();

            this.exitButton();

            this.exitModal();
        },
        drawStaticScene: function (answers, rightAnswerIndex) {
            console.log('drawStaticScene');
            let optionBoardMarginTop = 160;
            this.optionBoardA = this.add.sprite(50, optionBoardMarginTop, 'assets', 'option-board.png');
            this.optionBoardA.setOrigin(0, 0)
            this.optionBoardB = this.add.sprite(this.game.scale.width - 343, 160, 'assets', 'option-board.png');
            this.optionBoardB.setOrigin(0, 0)

            var isRightAnswer = (0 === rightAnswerIndex);
            this.optionPaperBtnA = this.add.sprite(this.optionBoardA.getBounds().left + 24, optionBoardMarginTop + 12, 'assets', 'option-paper.png');
            this.optionPaperBtnA.setOrigin(0, 0)
            setupButton(this.optionPaperBtnA, 'option-paper-click.png', 'option-paper-hover.png', 'option-paper.png');
            // this.optionPaperBtnA.setInteractive({ cursor: 'pointer' });
            this.optionPaperBtnA.fData = { isRight: isRightAnswer };
            this.optionPaperBtnA["context"] = this;
            this.optionPaperBtnA["isRightAnswer"] = isRightAnswer;
            this.optionPaperBtnA.on('pointerup', this.onClickAnswerButton, this.optionPaperBtnA);
            this.optionPaperBtnA.on('pointerover', this.playHoverSound, this);
            this.optionPaperBtnA.on('pointerdown', this.playSelectionSound, this);
            // var isClicked = false;
            // if (item.key === window.localStorage.getItem('wrongButtonKey')) {
            //     isClicked = true;
            // }
            isRightAnswer = (1 === rightAnswerIndex);
            this.optionPaperBtnB = this.add.sprite(this.optionBoardB.getBounds().left + 24, optionBoardMarginTop + 12, 'assets', 'option-paper.png');
            this.optionPaperBtnB.setOrigin(0, 0)
            setupButton(this.optionPaperBtnB, 'option-paper-click.png', 'option-paper-hover.png', 'option-paper.png');
            // this.optionPaperBtnB.setInteractive({ cursor: 'pointer' });
            this.optionPaperBtnB.fData = { isRight: isRightAnswer };
            this.optionPaperBtnB["context"] = this;
            this.optionPaperBtnB["isRightAnswer"] = isRightAnswer;
            this.optionPaperBtnB.on('pointerup', this.onClickAnswerButton, this.optionPaperBtnB);
            this.optionPaperBtnB.on('pointerover', this.playHoverSound, this);
            this.optionPaperBtnB.on('pointerdown', this.playSelectionSound, this);

            this.answerCorrectImage = 'option-paper-correct.png';
            this.answerWrongImage = 'option-paper-wrong.png'; // wrong button image need to change

            this.firstAnswerImage = this.add.sprite(this.optionPaperBtnA.getBounds().left, this.optionPaperBtnA.getBounds().top, 'answerImage_' + currentQuestionIndex + '_0');
            this.firstAnswerImage.setOrigin(0, 0)
            this.secondAnswerImage = this.add.sprite(this.optionPaperBtnB.getBounds().left, this.optionPaperBtnA.getBounds().top, 'answerImage_' + currentQuestionIndex + '_1');
            this.secondAnswerImage.setOrigin(0, 0)

            // let bush_1 = this.add.sprite(0, this.game.world.bottom - 183, 'assets', 'bush-1.png');
            // let bush_2 = this.add.sprite(this.game.world.width - 527, this.game.world.bottom - 157, 'assets', 'bush-2.png');

            this.optionBoardA_soundBtn = this.add.sprite(this.optionBoardA.getBounds().left + 118 + 30, optionBoardMarginTop + 224 + 40, 'Normal Sound Button');
            this.optionBoardA_soundBtn.setScale(0.5);
            this.optionBoardA_soundBtn.setOrigin(0.5, 0.5)
            setupButton(this.optionBoardA_soundBtn, 'Normal Sound Button', 'Hover Sound Button', undefined, undefined, true);
            this.optionBoardA_soundBtn.setInteractive({ cursor: 'pointer' });
            this.optionBoardA_soundBtn["context"] = this;
            this.optionBoardA_soundBtn["answerIndex"] = 0;
            this.optionBoardA_soundBtn.on('pointerup', this.playAnswerSound, this.optionBoardA_soundBtn);

            this.optionBoardB_soundBtn = this.add.sprite(this.optionBoardB.getBounds().left + 118 + 30, optionBoardMarginTop + 224 + 40, 'Normal Sound Button');
            this.optionBoardB_soundBtn.setScale(0.5);
            this.optionBoardB_soundBtn.setOrigin(0.5, 0.5)
            setupButton(this.optionBoardB_soundBtn, 'Normal Sound Button', 'Hover Sound Button', undefined, undefined, true);
            this.optionBoardB_soundBtn.setInteractive({ cursor: 'pointer' });
            this.optionBoardB_soundBtn["context"] = this;
            this.optionBoardB_soundBtn["answerIndex"] = 1;
            this.optionBoardB_soundBtn.on('pointerup', this.playAnswerSound, this.optionBoardB_soundBtn);

            if (this.optionPaperBtnA.game) {
                this.optionPaperBtnA.disableInteractive();
                this.optionPaperBtnB.disableInteractive();
            }
        },
        drawBg: function () {
            this.bg = this.add.sprite(0, 0, 'bg-image')
            this.bg.setOrigin(0, 0)
        },
        drawStarPoints: function () {
            var starMargin = 12
            var starWidth = 33

            var bgHead = this.add.sprite(56, 525, 'assets', 'stars-bg-head.png');
            bgHead.setOrigin(0, 0)
            //background body reapeat
            var bgBodyGroup = this.add.container();
            var k = bgHead.getBounds().right;
            var allStarWidth = (this.fData.questions.length * (starWidth + starMargin)) / 3 // 3 - the width of a chunk for background repeat
            for (var i = 0; i < allStarWidth; i++) {
                var bgBodyChunk = this.add.sprite(k, bgHead.getBounds().top, 'assets', 'stars-bg-body.png')
                bgBodyChunk.setOrigin(0, 0)
                bgBodyGroup.add(bgBodyChunk)
                k += 3;
            }

            var bgTail = this.add.sprite(bgBodyGroup.getBounds().right + bgHead.getBounds().width / 2, bgHead.getBounds().top, 'assets', 'stars-bg-head.png');
            // flip head to get the tail part
            bgTail.setOrigin(.5, 0);
            bgTail.scaleX *= -1;

            this.starDefaultGroup = this.add.container()
            this.starActiveGroup = this.add.container()
            this.starWrongGroup = this.add.container()
            var starPosX = bgHead.getBounds().right

            var storageJson = window.localStorage.getItem('payload');
            var storage = JSON.parse(storageJson);

            for (var i = 0; i < this.fData.questions.length; i++) {
                var star = this.add.sprite(starPosX, bgHead.getBounds().top + 6, 'assets', 'star.png')
                star.setOrigin(0, 0)
                var starActive = this.add.sprite(starPosX, bgHead.getBounds().top + 6, 'assets', 'star-active.png')
                starActive.setOrigin(0, 0)
                starActive.alpha = 0;
                var starWrong = this.add.sprite(starPosX, bgHead.getBounds().top + 6, 'assets', 'star-wrong.png')
                starWrong.setOrigin(0, 0)
                starWrong.alpha = 0;
                starPosX += starWidth + starMargin
                this.starDefaultGroup.add(star)
                this.starActiveGroup.add(starActive)
                this.starWrongGroup.add(starWrong)
                if (storage.questions[i] && storage.questions[i].correctAnswer) {
                    this.setStar(i, 1)
                } else if (storage.questions[i] && !storage.questions[i].correctAnswer) {
                    this.setStarWrong(i)
                }
            }
        },
        setStar: function (index, alpha) {
            this.starActiveGroup.getAt(index).alpha = alpha
        },
        setStarWrong: function (index) {
            this.starWrongGroup.getAt(index).alpha = 1;
            this.starDefaultGroup.getAt(index).alpha = 0;
        },
        playAnswerSound: function () {
            this.context.answerSounds['answerSound_' + currentQuestionIndex + '_' + this.answerIndex].play();
        },
        onClickAnswerButton: function () {
            var button = this;
            var context = this.context;
            // console.warn("context.puppetIdle.animations.stop(null, true);")
            context.puppetIdle.anims.stop();
            // context.questionSoundEvent.remove(false);//context.game.time.events.remove(context.questionSoundEvent);

            if (context.sound && context.sound.isPlaying) {
                context.sound.stop();
            }
            var storage = window.localStorage.getItem('payload');
            storage = JSON.parse(storage);

            var questionIdx;
            if (storage) {
                var question;
                for (var i = 0; i < storage.questions.length; i++) {
                    var item = storage.questions[i];
                    if (context.questionItem.key === item.questionKey) {
                        question = item;
                        questionIdx = i;
                        break;
                    }
                }
                if (question) {
                    question.attempts = question.attempts + 1;
                    question.correctAnswer = this.isRightAnswer;
                    storage.questions[questionIdx] = question;
                } else {
                    question = {
                        questionKey: context.questionItem.key,
                        correctAnswer: this.isRightAnswer,
                        attempts: 1
                    };
                    storage.questions.push(question)
                }
            }
            var points = 0;

            context.optionPaperBtnA.disableInteractive();
            context.optionPaperBtnB.disableInteractive();

            var isEndGame = false;
            if (this.isRightAnswer) {
                if (context.wrongAnswersCount === 0) {
                    points = +context.qAnsPoints.correctAnsPoint;
                } else {
                    points = +context.qAnsPoints.secTimeCorrectAnsPoint;
                }
                isEndGame = true;

                // this.text.fill = '#6afaff';
                // this.text.stroke = '#1a472b';
                button.setFrame(context.answerCorrectImage);
                setupButton(button, context.answerCorrectImage, context.answerCorrectImage);//button.setFrames(context.answerCorrectImage, context.answerCorrectImage);

                context.puppetNo.alpha = 0;
                context.puppetIdle.alpha = 0;
                context.puppetYes.alpha = 1;

                context.puppetNo.anims.stop();
                context.anims.create({
                    key: 'yesAnimate',
                    frames: context.anims.generateFrameNames('idle_yes_atlas', {
                        prefix: 'Kangoro New',
                        suffix: '.png',
                        start: 6,
                        end: 90,
                        zeroPad: 4
                    }),
                    frameRate: 45,
                    repeat: 0
                });
                context.puppetYes.play('yesAnimate');
                setTimeout(function () {
                    context.yesSound.play();
                }, 250);

                context.addNewDefaultLoop();
                window.localStorage.setItem('currentState', +(currentQuestionIndex) + 1);
                context.setStar(currentQuestionIndex, 1)
            } else {
                points = +context.qAnsPoints.secTimeCorrectAnsPoint;

                button.disableInteractive();

                context.puppetIdle.alpha = 0;
                context.puppetYes.alpha = 0;

                if (!this.clicked) {
                    this.clicked = true;
                    context.wrongAnswersCount++;
                }
                isEndGame = context.wrongAnswersCount === context.maxWrongAnswers;

                points = +context.qAnsPoints.wrongAnsPoint;

                context.puppetNo.alpha = 1;

                context.anims.create({
                    key: 'noAnimate',
                    frames: context.anims.generateFrameNumbers('idle_no', { start: 0, end: 31, first: 0 }),
                    frameRate: 20,
                    repeat: 0
                });
                context.puppetNo.play('noAnimate');

                context.noCorrectAnswerThis.play();
                if (isEndGame) {
                    // if (context.optionPaperBtnA.data.isRight) {
                    //     context.optionPaperBtnA.setFrames(context.answerCorrectImage, context.answerCorrectImage, context.answerCorrectImage);
                    // } else {
                    //     context.optionPaperBtnB.setFrames(context.answerCorrectImage, context.answerCorrectImage, context.answerCorrectImage);
                    // }

                    window.localStorage.setItem('currentState', +(currentQuestionIndex) + 1);
                    // window.localStorage.removeItem('wrongButtonKey');
                } else {
                    // window.localStorage.setItem('wrongButtonKey', this.key);
                }
                button.setFrame(context.answerWrongImage);
                setupButton(button, context.answerWrongImage, context.answerWrongImage);//button.setFrames(context.answerWrongImage, context.answerWrongImage);
                context.setStarWrong(currentQuestionIndex);
            }
            if (storage) {
                if (isEndGame) {
                    storage.currentPoints = storage.currentPoints + points;
                    if (questionIdx) {
                        storage.questions[questionIdx].points = points;
                        storage.questions[questionIdx].answerKey = this.key;
                    } else {
                        storage.questions[currentQuestionIndex].points = points;
                        storage.questions[currentQuestionIndex].answerKey = this.key;
                    }
                }
                window.localStorage.setItem('payload', JSON.stringify(storage));
            }
        },
        drawPuppet: function () {
            var frameWidth = 361;
            var frameHeight = 448;
            var framePosX = 500;
            var framePosY = 350;

            this.puppetIdle = this.add.sprite(framePosX, framePosY, 'idle_default');
            this.puppetIdle.setOrigin(0.5, 0.5)
            this.puppetIdle.height = frameHeight;
            this.puppetIdle.width = frameWidth;

            // puppet no
            this.puppetNo = this.add.sprite(framePosX, framePosY, 'idle_no');
            this.puppetNo.setOrigin(0.5, 0.5)
            this.puppetNo.height = frameHeight;
            this.puppetNo.width = frameWidth;
            this.puppetNo.alpha = 0;

            // puppet yes
            this.puppetYes = this.add.sprite(framePosX - 2, framePosY + 7, 'idle_yes_atlas');
            this.puppetYes.setOrigin(0.5, 0.5)
            this.puppetYes.height = frameHeight;
            this.puppetYes.width = frameWidth;
            this.puppetYes.alpha = 0;

            var self = this;

            this.anims.create({
                key: 'idleAnimate',
                frames: this.anims.generateFrameNumbers('idle_default', { start: 0, end: 31, first: 0 }),
                frameRate: 20,
                repeat: 0
            });
            var a = this.puppetIdle.play('idleAnimate');
            this.puppetIdle.on('animationcomplete', function () {
                // self.puppetIdle.frame = 0;
            });


            this.anims.create({
                key: 'noAnimate',
                frames: this.anims.generateFrameNumbers('idle_no', { start: 0, end: 31, first: 0 }),
                frameRate: 20,
                repeat: 0
            });
            // this.puppetNoAnim = this.puppetNo.play('noAnimate');
            this.puppetNo.on('animationcomplete', function () {
                // self.puppetNo.frame = 0;
                setTimeout(function () {
                    self.onClickNextButton()
                }, 1000)
            });

            /*this.puppetNoAnim = this.puppetNo.anims.add('noAnimate');
            this.puppetNo.on('animationcomplete', function () {
                self.puppetNo.frame = 0;
                setTimeout(function () {
                    self.onClickNextButton()
                }, 1000)
            });*/


            this.anims.create({
                key: 'yesAnimate',
                frames: this.anims.generateFrameNames('idle_yes_atlas', {
                    prefix: 'Kangoro New',
                    suffix: '.png',
                    start: 6,
                    end: 90,
                    zeroPad: 4
                }),
                frameRate: 45,
                repeat: 0
            });

            // this.puppetYesAnim = this.puppetYes.play('yesAnimate');
            this.puppetYes.on('animationcomplete', function () {
                // self.puppetYes.frame = 0;
                setTimeout(function () {
                    self.onClickNextButton()
                }, 1000)
            });

            /*this.puppetYesAnim = this.puppetYes.anims.add('yesAnimate');
            this.puppetYes.on('animationcomplete', function () {
                self.puppetYes.frame = 0;
                this.setTimeout(function () {
                    self.onClickNextButton()
                }, 1000)
            });*/
        },
        addNewDefaultLoop: function () {
            if (this.defaultLoop) {
                this.time.clearPendingEvents();//this.game.time.events.clearPendingEvents();
                this.defaultLoop = this.time.addEvent({ delay: 1000 * 5, callback: this.payPuppet, callbackScope: this, loop: true });//this.defaultLoop = self.game.time.events.loop(Phaser.Timer.SECOND * 5, this.payPuppet, this);
            } else if (!this.defaultLoop) {
                this.defaultLoop = this.time.addEvent({ delay: 1000 * 5, callback: this.payPuppet, callbackScope: this, loop: true });//this.defaultLoop = self.game.time.events.loop(Phaser.Timer.SECOND * 5, this.payPuppet, this);
            }
        },
        playQuestionSound: function () {
            if (!this.fsound) {
                return
            }
            this.fsound.play();
            var self = this;
            this.fsound.on('complete', function () {
                // if (self.optionPaperBtnA.game) {
                self.optionPaperBtnA.setInteractive({ cursor: 'pointer' });
                self.optionPaperBtnB.setInteractive({ cursor: 'pointer' });
                // self.questionSoundEvent.remove(false);// self.game.time.events.remove(self.questionSoundEvent);
                // self.questionSoundEvent = self.time.addEvent({ delay: 1000 * 7, callback: function () {
                //         this.fsound.play();
                //     }, callbackScope: this, loop: true });
                /*self.questionSoundEvent = self.game.time.events.loop(Phaser.Timer.SECOND * 7, function () {
                    this.sound.play()
                }, self);*/
                // }
            });
        },
        payPuppet: function () {
            console.warn('this.puppetNoAnim.isPlaying');
            // if (this.puppetNoAnim.isPlaying
            //     || this.puppetYesAnim.isPlaying) {
            //     return
            // }
            if (this.puppetNo.anims.isPlaying
                || this.puppetYes.anims.isPlaying) {
                return
            }
            this.puppetNo.alpha = 0;
            this.puppetYes.alpha = 0;
            this.puppetIdle.alpha = 1;

            this.puppetIdle.anims.play('idleAnimate');
        },
        addSounds: function () {
            this.answerSounds = {};
            var self = this;
            this.fData.questions[currentQuestionIndex].answers.forEach(function (answer, i) {
                self.answerSounds['answerSound_' + currentQuestionIndex + '_' + i] = self.sound.add('answerSound_' + currentQuestionIndex + '_' + i);
            });

            this.fsound = null;
            if (this.fData.questions[currentQuestionIndex].questionAudioName) {
                this.fsound = this.sound.add('questionSound_' + currentQuestionIndex);
            }
            this.yesSound = this.sound.add("yes");
            this.noCorrectAnswerThis = this.sound.add("noCorrectAnswerThis");

            this.hoverSound = this.sound.add('hoverSound');
            this.selectionSound = this.sound.add('selectionSound');
            this.exitSound = this.sound.add('exitSound');
            var self = this;
            /*this.game.onPause.add(function () {
                self.game.sound.unsetMute();
                self.sound.stop();
                self.bgMusic.pause();
            })*/
        },
        addEvents: function () {
            if (currentQuestionIndex % 4 == 0) {
                this.playQuestionSound();
            } else {
                this.optionPaperBtnA.setInteractive({ cursor: 'pointer' });
                this.optionPaperBtnB.setInteractive({ cursor: 'pointer' });
            }
            // this.questionSoundEvent = this.time.addEvent({ delay: 1000, callback: this.playQuestionSound, callbackScope: this, loop: false });//this.questionSoundEvent = this.game.time.events.add(Phaser.Timer.SECOND, this.playQuestionSound, this);
            this.addNewDefaultLoop();
        },
        addPlayQuestionButton: function () {
            this.playQuestionBtn = this.add.sprite(100, 16, 'assets', 'audio-icon.png');
            this.playQuestionBtn.setOrigin(1, 0)
            setupButton(this.playQuestionBtn, 'audio-icon.png', 'audio-icon-hover.png');

            this.playQuestionBtn.setInteractive({ cursor: 'pointer' });
            this.playQuestionBtn.on('pointerup', this.playQuestionSound, this);
            this.playQuestionBtn.on('pointerover', this.playHoverSound, this);
            this.playQuestionBtn.on('pointerdown', this.playSelectionSound, this);
        },
        exitButton: function () {
            this.extiBtn = this.add.sprite(this.game.scale.width - 100, 0 + 16, 'assets', 'exit-button.png');
            this.extiBtn.setOrigin(0, 0)
            setupButton(this.extiBtn, 'exit-button.png', 'exit-button-hover.png');
            this.extiBtn.setInteractive({ cursor: 'pointer' });
            this.extiBtn.on('pointerup', this.showExitModal, this);
            this.extiBtn.on('pointerover', this.playHoverSound, this);
            this.extiBtn.on('pointerdown', this.playSelectionSound, this);
        },

        exitModal: function () {
            var modalBg = this.add.sprite(0, 0, 'modal-overlay');
            modalBg.setInteractive();
            modalBg.setOrigin(0, 0)
            this.modal = this.add.container();
            var modalBox = this.add.sprite(this.game.scale.width / 2, this.game.scale.height / 2, 'assets', 'activity-Instruction-board.png');
            modalBox.setOrigin(0.5, 0.5);

            var leavesRightSprite = this.add.sprite(0, 0, 'assets', 'leaves.png');
            leavesRightSprite.setOrigin(0, 0)
            leavesRightSprite.setPosition(modalBox.getBounds().right - 145, modalBox.getBounds().top + 15);

            var leavesLeftSprite = this.add.sprite(0, 0, 'assets', 'leaves.png');
            leavesLeftSprite.setOrigin(0.5, 0.5);
            leavesLeftSprite.scaleX *= -1;
            leavesLeftSprite.setPosition(modalBox.getBounds().left + 34, modalBox.getBounds().bottom - 140);

            var warningIcon = this.add.sprite(modalBox.getCenter().x, modalBox.getBounds().top + 74, 'assets', 'warning-icon.png');
            warningIcon.setOrigin(0.5, 0);


            var modalTextStyle = {
                backgroundColor: 'transparent',
                stroke: '#ffffff',
                strokeThickness: 2,
                fontFamily: 'Kids Rock DEMO',
                fontSize: 27,
                color: '#43425d',
                wordWrap: true,
                align: 'center'
            };
            var modalText = this.add.text(this.game.scale.width / 2, this.game.scale.height / 2 + 10, "Your progress in this activity will not be saved. \n Are you sure you want to exit? ", modalTextStyle);
            modalText.setWordWrapWidth(modalBox.width - 110);//modalText.wordWrapWidth = modalBox.width - 110;
            modalText.setOrigin(0.5, 0.5);

            var modalYesBtn = this.add.sprite(modalBox.getBounds().left + 217, modalBox.getBounds().bottom - 179, 'assets', 'button-yes.png');
            modalYesBtn.setOrigin(0, 0)
            setupButton(modalYesBtn, 'button-yes.png', 'button-yes-hover.png');
            modalYesBtn.setInteractive({ cursor: 'pointer' });
            modalYesBtn.on('pointerup', this.exitGame, this);
            var modalNoBtn = this.add.sprite(modalYesBtn.getBounds().right - 43, modalBox.getBounds().bottom - 179, 'assets', 'button-no.png');
            modalNoBtn.setOrigin(0, 0)
            setupButton(modalNoBtn, 'button-no.png', 'button-no-hover.png');
            modalNoBtn.setInteractive({ cursor: 'pointer' });
            modalNoBtn.on('pointerup', this.exitGameCancel, this);

            modalYesBtn.on('pointerover', this.playHoverSound, this);
            modalYesBtn.on('pointerdown', this.playSelectionSound, this);

            modalNoBtn.on('pointerover', this.playHoverSound, this);
            modalNoBtn.on('pointerdown', this.playSelectionSound, this);

            this.modal.add(modalBg);
            this.modal.add(leavesLeftSprite);
            this.modal.add(leavesRightSprite);
            this.modal.add(modalBox);
            this.modal.add(warningIcon);
            this.modal.add(modalText);
            this.modal.add(modalYesBtn);
            this.modal.add(modalNoBtn);

            this.modal.visible = false;
            this.modal.alpha = 0;
        },

        showExitModal: function () {
            this.extiBtn.visible = false;
            this.modal.visible = true;
            // game.add.tween(this.modal).to( { alpha: 1 }, 200, Phaser.Easing.Linear.None, true);
            this.modal.alpha = 1;
            this.time.paused = true;
            this.sound.pauseAll();// this.scene.pause();//this.game.paused = true;
            this.exitSound.play();
        },

        exitGame: function () {
            this.time.paused = false;
            this.sound.resumeAll();// this.scene.resume();//this.game.paused = false;
            window.localStorage.removeItem('currentState');

            if (this.bgMusic) {
                this.bgMusic.stop();
            }
            // window.localStorage.removeItem('wrongButtonKey');
            // this.game.state.start('StartGame', true, false);
            window.angularComponentRef.backToMenu();
        },
        exitGameCancel: function () {
            this.extiBtn.visible = true;
            this.modal.alpha = 0;
            this.modal.visible = false;
            this.time.paused = false;
            this.sound.resumeAll();// this.scene.resume();//this.game.paused = false;
            this.bgMusic.resume();
        },
        onClickNextButton: function () {
            // this.game.sound.stopAll();
            if (this.fsound) {
                this.fsound.stop();
            }
            Object.values(this.answerSounds).forEach(function (item) {
                item.stop();
            });
            if (this.yesSound) {
                this.yesSound.stop();
            }
            if (this.noCorrectAnswerThis) {
                this.noCorrectAnswerThis.stop();
            }
            currentQuestionIndex++;
            var storage = window.localStorage.getItem('payload');
            storage = JSON.parse(storage);
            if (storage && storage.questions) {
                var incorrectArr = [];
                for (var i = 0; i < storage.questions.length; i++) {
                    if (!storage.questions[i].correctAnswer) {
                        incorrectArr[incorrectArr.length] = storage.questions[i]
                    }
                }
                if (incorrectArr.length >= 3) {
                    if (this.bgMusic) {
                        this.bgMusic.stop();
                    }
                    this.scene.start("TryGame");//this.game.state.start("TryGame", true, false);
                } else if (currentQuestionIndex < this.fData.questions.length) {
                    // this.scene.switch("Questions")
                    this.scene.start("Questions");//this.game.state.start("Questions", true, false, this.currentQuestionIndex);

                    this.sound.add("Next round starts").play();
                } else {
                    if (this.bgMusic) {
                        this.bgMusic.stop();
                    }
                    this.scene.start("EndGame");//this.game.state.start("EndGame", true, false);
                }
            } else {
                if (this.bgMusic) {
                    this.bgMusic.stop();
                }
                this.scene.start("EndGame");//this.game.state.start("EndGame", true, false);
            }
        },
        playHoverSound: function () {
            console.log('playHoverSound');
            this.hoverSound.play();
        },
        playSelectionSound: function () {
            this.selectionSound.play();
        }
    };

    //endGame
    var trygame = function (game) {
        console.log("%cTryGame", "color:white; background:blue");
    };

    trygame.prototype = {
        preload: function () {
            this.totalPoints = window.angularComponentRef.getTotalScore();
            // this.fData = this.cache.binary.get('complete')
        },
        create: function () {
            console.log('trygame create');
            if (!this.tryAgainSound) {
                this.tryAgainSound = this.sound.add('tryAgainSound');
            }
            this.tryAgainSound.play();

            this.hoverSound = this.sound.add('hoverSound');
            this.selectionSound = this.sound.add('selectionSound');

            window.localStorage.removeItem('currentState');
            window.localStorage.removeItem('wrongButtonKey');

            var storageJson = window.localStorage.getItem('payload');
            var storage = JSON.parse(storageJson);
            var currentPoints = 0;
            if (storage) {
                // currentPoints = storage.currentPoints || 0;
            }
            // this.scale.pageAlignHorizontally = true;
            // this.scale.pageAlignVertically = true;

            this.add.sprite(0, 0, 'try-again-bg').setOrigin(0, 0);

            // this.game.stage.disableVisibilityChange = true; // keep animation run on focus out

            var totalScoreTextStyle = {
                backgroundColor: 'transparent',
                stroke: '#91301a',
                strokeThickness: 6,
                fontFamily: 'Kids Rock DEMO',
                fontSize: 39,
                wordWrap: false,
                align: 'left'
            };
            var totalScoreTextStyleOutline = {
                backgroundColor: 'transparent',
                stroke: '#fff',
                strokeThickness: 12,
                fontFamily: 'Kids Rock DEMO',
                fontSize: 39,
                wordWrap: false,
                align: 'left'
            };
            var collectedPointsTextStyle = {
                backgroundColor: 'transparent',
                stroke: '#91301a',
                strokeThickness: 2,
                fontFamily: 'Kids Rock DEMO',
                fontSize: 25,
                wordWrap: false,
                align: 'left',
                color: '#ffffff'
            };

            var modalBox = this.add.sprite(this.game.scale.width / 2, this.game.scale.height / 2, 'assets', 'activity-Instruction-board.png');
            modalBox.setOrigin(0.5, 0.5);
            var completeRibbon = this.add.sprite(modalBox.getCenter().x, modalBox.getBounds().top + 222, 'assets', 'try-again-ribbon.png');
            completeRibbon.setOrigin(0.5, 0.5);

            var totalScoreOutline = this.add.text(modalBox.getBounds().left + 340, modalBox.getBounds().bottom - 302, "Total Score: " + " " + this.totalPoints, totalScoreTextStyleOutline);
            var totalScore = this.add.text(modalBox.getBounds().left + 342, modalBox.getBounds().bottom - 300, "Total Score: " + " " + this.totalPoints, totalScoreTextStyle);

            var grd = totalScore.context.createLinearGradient(0, 0, 0, totalScore.height);
            grd.addColorStop(0, '#edee62');
            grd.addColorStop(0.2, '#edee62');
            grd.addColorStop(0.7, '#dd7b33');
            totalScore.setFill(grd);

            var collectedPointsMedal = this.add.sprite(modalBox.getBounds().left + 324, modalBox.getBounds().bottom - 244, 'assets', 'collected-poins-medal.png');
            collectedPointsMedal.setOrigin(0, 0)

            var collectedPoints = this.add.text(modalBox.getBounds().left + 422, modalBox.getBounds().bottom - 204, currentPoints, collectedPointsTextStyle);

            // var trophy = this.game.add.sprite(modalBox.getBounds().left+66, modalBox.bottom-355, 'trophy');
            var trophy = this.add.sprite(modalBox.getBounds().left + 210, modalBox.getBounds().bottom - 215, 'try-again-trophy');
            trophy.setScale(0.5, 0.5);
            trophy.setOrigin(0.5, 0.5);
            trophy.alpha = 0;
            this.tweens.add({
                targets: trophy,
                scaleX: 1,
                scaleY: 1,
                duration: 400,
                ease: Phaser.Math.Easing.Linear
            });
            this.tweens.add({
                targets: trophy,
                alpha: 1,
                duration: 400,
                ease: Phaser.Math.Easing.Linear
            });
            // game.add.tween(trophy.scale).to({ x: 1, y: 1 }, 400, Phaser.Easing.Linear.None, true);
            // game.add.tween(trophy).to({ alpha: 1 }, 400, Phaser.Easing.Linear.None, true);

            var backToMenuButton = this.add.sprite(modalBox.getBounds().left + 332, modalBox.getBounds().bottom - 89, 'assets', 'back-to-menu-button.png');
            backToMenuButton.setOrigin(0, 0)
            setupButton(backToMenuButton, 'back-to-menu-button.png', 'back-to-menu-button-hover.png');
            backToMenuButton.setInteractive({ cursor: 'pointer' });
            backToMenuButton.on('pointerup', this.backToMenu, this);
            var playAgainButton = this.add.sprite(modalBox.getBounds().left + 479, modalBox.getBounds().bottom - 89, 'assets', 'play-again-button.png');
            playAgainButton.setOrigin(0, 0)
            setupButton(playAgainButton, 'play-again-button.png', 'play-again-button-hover.png');
            playAgainButton.setInteractive({ cursor: 'pointer' });
            playAgainButton.on('pointerup', this.playAgain, this);

            backToMenuButton.on('pointerover', this.playHoverSound, this);
            backToMenuButton.on('pointerdown', this.playSelectionSound, this);

            playAgainButton.on('pointerover', this.playHoverSound, this);
            playAgainButton.on('pointerdown', this.playSelectionSound, this);
        },

        backToMenu: function () {
            console.log('back to menu button clicked');
            this.tryAgainSound.stop();
            window.angularComponentRef.backToMenu();
        },
        playAgain: function () {
            console.log('play again button clicked');
            this.tryAgainSound.stop()
            this.scene.start("StartGame");//this.game.state.start('StartGame', true, false);
        },
        playHoverSound: function () {
            this.hoverSound.play();
        },
        playSelectionSound: function () {
            this.selectionSound.play();
        }
    };

    //main
    game = new Phaser.Game({
        type: Phaser.AUTO,
        width: 980,
        height: 600,
        parent: 'game-container',
        dom: {
            createContainer: false
        },
        scale: {
            mode: Phaser.Scale.NONE,
            // autoCenter: Phaser.Scale.CENTER_BOTH
        },
        transparent: true,
        scene: {
            create: () => {
                game.scene.add("Boot", boot, false);//game.state.add("Boot", boot);
                game.scene.add("StartGame", startgame);//game.state.add("StartGame", startgame);
                game.scene.add("ResumeGame", resumegame);//game.state.add("ResumeGame", resumegame);
                game.scene.add("Questions", questions);//game.state.add("Questions", questions);
                game.scene.add("EndGame", endgame);//game.state.add("EndGame", endgame);
                game.scene.add("TryGame", trygame);//game.state.add("TryGame", trygame);

                game.scene.start("Boot");//game.state.start("Boot");
            }
        }
    });
}
function destroySoundSearch() {
    if (game)
        game.destroy();
}
