import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { MatSort, MatTableDataSource, Sort } from '@angular/material';
import { Router } from '@angular/router';
import { AuthService } from '../../shared/auth.service';
import { AssignmentService } from '../../shared/assignment.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-assignment',
    templateUrl: './assignment.component.html',
    styleUrls: ['./assignment.component.scss']
})
export class AssignmentComponent implements OnInit, OnDestroy, AfterViewInit {
    userSubscription: Subscription;
    dataSource = new MatTableDataSource([]);
    displayedColumns: string[] = ['title', 'startDate', 'dueDate', 'status'];

    userData: any;

    assignmentItems: any;
    list = [];

    idList = [];
    filter = 1;

    // pagination
    itemsPerPage = 4;
    itemsCount = 0;
    pageIndex = 0;

    loading: boolean = true;

    @ViewChild(MatSort, { static: false }) sort: MatSort;

    constructor(
        private router: Router,
        private auth: AuthService,
        public storage: AngularFireStorage,
        public assignmentService: AssignmentService,
    ) { }

    ngOnInit() {
        this.userSubscription = this.auth.onUserUpdate.subscribe(userData => {
            if (userData != null && userData) this.loadChildData(userData);
        }, error => {
            console.log('error', error);
        });
        this.forceLoadAssignments();
    }

    forceLoadAssignments() {
        if (!this.assignmentItems) {
            if (!this.assignmentService.assignmentsItemsALL && !this.assignmentService.assignmentsALL) {
                setTimeout(() => {
                    return this.forceLoadAssignments();
                }, 100);
            }
            else {
                this.loadAssignments();
            }
        }
    }

    loadAssignments() {
        let lastweek = new Date();
        lastweek.setDate(lastweek.getDate() - 7);

        this.assignmentItems = this.assignmentService.assignmentsItemsALL;

        for (var i = 0; i < this.assignmentItems.length; i++) {
            var assignmentItem = this.assignmentItems[i];

            var assignmentData = this.assignmentService.assignmentsALL.filter(x => x.id == assignmentItem.assignmentId)[0];

            if (this.idList.indexOf(assignmentData.title) < 0) {
                this.idList.push(assignmentData.title);

                var countryAssigns = this.assignmentItems.filter(w => w.assignmentId == assignmentData.id);

                var doneAssign = countryAssigns.filter(x => x.done === true);

                this.list.push({ ...assignmentData, status: countryAssigns.length == doneAssign.length ? "Complete" : "Incomplete" });
            }
        }

        this.updateDataSource(this.list);
        this.loading = false;
    }

    loadChildData(userData) {
        this.userData = userData;
    }

    ngOnDestroy(): void {
    }

    back2Profile() {
        this.router.navigate(['/game/profile']);
    }

    createArray(num) {
        return Array(Math.ceil(num / this.itemsPerPage)).fill(1);
    }
    pageChanged(e) {
        if (e.pageIndex >= 0 && e.pageIndex < Math.ceil(this.itemsCount / this.itemsPerPage)) {
            this.pageIndex = e.pageIndex;
        }

        this.updateDataSource(this.list);
    }

    ngAfterViewInit() {
        this.setbuttonEvents()
    }
    setbuttonEvents() {
        setTimeout(() => {
            var buttons = document.querySelectorAll('button');
            for (var i = 0; i < buttons.length; i++) {
                buttons[i].addEventListener('click', function (event) {
                    let audio = <HTMLAudioElement>document.getElementById('buttonClick');
                    if (audio) audio.play();
                });
                buttons[i].addEventListener('mouseover', function (event) {
                    let audio = <HTMLAudioElement>document.getElementById('buttonHover');
                    if (audio) audio.play();
                });
            }
        }, 300)
    }

    updateDataSource(data) {
        const c = this.pageIndex * this.itemsPerPage;

        const nowTime = (new Date()).getTime() / 1000;

        data = data.filter(item => {
            if (this.filter == 1) {
                return item.endDate.seconds > nowTime;
            } else {
                return item.endDate.seconds < nowTime;
            }
        });

        this.itemsCount = data.length;
        data = data.slice(c, c + this.itemsPerPage);

        this.dataSource = new MatTableDataSource(data.map(item => {
            return {
                title: item.title,
                startDate: (new Date(item.startDate.seconds * 1000)).toDateString(),
                dueDate: (new Date(item.endDate.seconds * 1000)).toDateString(),
                status: item.status
            };
        }));
    }

    changeFilter(filter: number) {
        this.filter = filter;

        this.updateDataSource(this.list);
    }

    sortData(sort: Sort) {
        const data = this.list.slice();
        if (!sort.active || sort.direction === '') {
            this.list = data;
            return;
        }

        this.list = data.sort((a, b) => {
            const isAsc = sort.direction === 'asc';
            switch (sort.active) {
                case 'title': return compare(a.title, b.title, isAsc);
                case 'startDate': return compare(a.startDate, b.startDate, isAsc);
                case 'dueDate': return compare(a.dueDate, b.dueDate, isAsc);
                case 'status': return compare(a.status, b.status, isAsc);
                default: return 0;
            }
        });
        this.updateDataSource(this.list);
    }
}
function compare(a, b, isAsc) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
