var game;
var apiData;
function questionAndAnswers(json) {

    function setupButton(btn, frame_pointerdown, frame_pointerover, frame_pointerout, frame_pointerup, autoUpdate) {
        if ( typeof frame_pointerout === "undefined" ) frame_pointerout = frame_pointerdown;
        if ( typeof frame_pointerup === "undefined" ) frame_pointerup = frame_pointerdown;

        btn['frame_pointerdown'] = frame_pointerdown;
        btn['frame_pointerover'] = frame_pointerover;
        btn['frame_pointerout'] = frame_pointerout;
        btn['frame_pointerup'] = frame_pointerup;

        if (!btn["alreadySetupEvents"]) {
            btn["alreadySetupEvents"] = true;
            btn.buttonState = 'out';
            btn.on('pointerdown', () => { btn.buttonState = 'down'; btn.setFrame(btn['frame_pointerdown']); });
            btn.on('pointerover', () => { btn.buttonState = 'over'; btn.setFrame(btn['frame_pointerover']); });
            btn.on('pointerout', () => { btn.buttonState = 'out'; btn.setFrame(btn['frame_pointerout']); });
            btn.on('pointerup', () => { btn.buttonState = 'up'; btn.setFrame(btn['frame_pointerup']); });
            btn.setFrame(btn['frame_pointerout']);

            if(autoUpdate) {
                var initTimeout = setTimeout(() => btn.setFrame(btn['frame_pointer' + btn.buttonState]), 0);
                var updateInterval = setInterval(() => btn.setFrame(btn['frame_pointer' + btn.buttonState]), 100);

                btn.on('destroy', function() {
                    clearTimeout(initTimeout);
                    clearInterval(updateInterval);
                })
            }
        }
    }

    //boot
    var boot = function (game) {
        console.log('%cBoot', 'color:white; background:red');
    };

    boot.prototype = {
        init: function () {
            this.apiData = json.data;

            //fix input
            this.game.scale.transformX = (pageX) => {
                let offsetLeft = 0;
                let parentElement = this.game.canvas.parentElement;
                while (parentElement) {
                    if (parentElement.offsetLeft) {
                        offsetLeft = parentElement.offsetLeft;
                        break;
                    }
                    parentElement = parentElement.parentElement;
                }
                return (pageX - offsetLeft) * this.game.scale.displayScale.x;
            };
            this.game.scale.transformY = (pageY) => {
                let offsetTop = 0;
                let parentElement = this.game.canvas.parentElement;
                while (parentElement) {
                    if (parentElement.offsetTop) {
                        offsetTop = parentElement.offsetTop;
                        break;
                    }
                    parentElement = parentElement.parentElement;
                }
                return (pageY - offsetTop) * this.game.scale.displayScale.y;
            };
        },
        preload: function () {
            var modalTextStyle = {
                backgroundColor: 'transparent',
                fontFamily: 'Kids Rock DEMO',
                fontSize: 1
            };
            var modalText = this.add.text(0, 0, ".", modalTextStyle);
            modalText.setPosition(-1000, -1000);
            modalText.alpha = 0;

            this.load.image('red-arrow-image', 'assets/images/red-arrow.png');
            this.load.atlas('answer-buttons', 'assets/qa/animation/picture/answer-buttons.png', 'assets/qa/animation/picture/answer-buttons.json');
            this.load.atlas('assets', 'assets/qa/assets.png', 'assets/qa/assets.json');
            this.load.atlas('assetsGen', 'assets/qa/assetsGen.png', 'assets/qa/assetsGen.json');
            this.load.atlas('soundBtn', 'assets/qa/sound-icon/sound-btn-spritesheet.png', 'assets/qa/sound-icon/sound-btn-spritesheet.json');

            this.load.atlas('idle_atlas', 'assets/qa/animation/picture/Idle_atlas.png', 'assets/qa/animation/picture/Idle_atlas.json');
            this.load.atlas('yes_atlas', 'assets/qa/animation/picture/Yes_atlas.png', 'assets/qa/animation/picture/Yes_atlas.json');
            this.load.atlas('no_atlas', 'assets/qa/animation/picture/No_atlas.png', 'assets/qa/animation/picture/No_atlas.json');
            this.load.atlas('noCorrectAnswerThis_atlas', 'assets/qa/animation/picture/No_its_this_one_atlas.png', 'assets/qa/animation/picture/No_its_this_one_atlas.json');

            this.load.audio('yes1', 'assets/qa/animation/sound/snail_yes_1.mp3');
            this.load.audio('yes2', 'assets/qa/animation/sound/snail_yes_2.mp3');
            this.load.audio('noTryAgain', 'assets/qa/animation/sound/snail_no_short.mp3');
            this.load.audio('noCorrectAnswerThis', 'assets/qa/animation/sound/snail_no_long.mp3');
            this.load.audio('tryAgain', 'assets/qa/animation/sound/Question time - idle_state.mp3');

            this.cache.binary.add('complete', this.apiData.complete);
            this.cache.binary.add('instruction', this.apiData.instruction);
            this.cache.binary.add('questions', this.apiData.content.questions);
            if (this.apiData.content.totalScore) {
                this.cache.binary.add('totalPoints', this.apiData.content.totalScore);
            } else {
                this.cache.binary.add('totalPoints', '0');
            }
            this.cache.binary.add('qAnsPoints', this.apiData.content.qAnsPoints);

            this.load.image('modal-overlay', 'assets/qa/modal-overlay.png'); // API placeholder
            console.warn("LOCAL BG LOADING FORCED!");
            // if (this.apiData.bgImage) {
            //     this.load.image('bg-image', this.apiData.bgImage);
            // } else {
                this.load.image('bg-image', 'assets/qa/new/background question board.jpg');
                this.load.image('bg-image-1', 'assets/qa/new/Group 13 copy.jpg');
            // }
            if (this.apiData.instruction && this.apiData.instruction.b_image) {
                this.load.image('instruction-bg', 'assets/qa/instruction/' + this.apiData.instruction.b_image);
            } else {
                this.load.image('instruction-bg', 'assets/qa/modal-bg-1.png');
            }
            // if (this.apiData.complete && this.apiData.complete.b_image) {
            //   this.load.image('completion-bg', 'assets/qa/completion/' + this.apiData.complete.b_image);
            // } else {
            //   this.load.image('completion-bg', 'assets/qa/modal-bg-1.png');
            // }
            if (this.apiData.complete && this.apiData.complete.award_image) {
                this.load.image('trophy', 'assets/qa/completion/' + this.apiData.complete.award_image);
            }
            this.load.image('completion-bg', 'assets/qa/complete-page-bg.jpg');
            if (this.apiData.complete && this.apiData.complete.audios && this.apiData.complete.audios.length > 0) {
                var index = Math.floor(Math.random() * Math.floor(this.apiData.complete.audios.length));
                this.load.audio('completionSound', this.apiData.complete.audios[index]);
            }
            else {
                this.load.audio('completionSound', 'assets/sounds/viktory.wav');
            }

            this.load.audio('tryAgainSound', 'assets/sounds/fail.wav');

            this.load.audio('bg-music', 'assets/sounds/qa_bg_sound.mp3');

            this.load.audio('selectionSound', 'assets/sounds/selection.mp3');
            this.load.audio('hoverSound', 'assets/sounds/q&a-hover.mp3');
            this.load.audio('tapAnswer', 'assets/sounds/q&a-tap.mp3');
            this.load.audio('exitSound', 'assets/sounds/warning-pop-up.mp3');

            this.fData = this.cache.binary.get('questions');
            for (var j = 0; j < this.fData.length; j++) {
                var questionName = 'questionSound' + j;
                this.load.audio(questionName, this.fData[j].audio);
            }
        },
        create: function () {

            this.isMobile = !this.game.device.desktop;
            //Local storing
            if (window.localStorage.getItem('currentState')) {
                window.localStorage.removeItem('currentState');
            }
            apiData = this.apiData;
            this.scene.start("StartGame", {apiData: this.apiData});
        },

        scaleGame: function () {
            const padding = this.isMobile ? 0 : 0; // include padding around the canvas frame for desktop
            const parentWidth = document.getElementById('game-container').offsetWidth;
            const xScale = (parentWidth - padding) / this.game.width;
            const scalePoint = Math.min(xScale, 1);
        }
    };

    //startGame
    var startgame = function (game) {
        console.log('%cStartGame', 'color:white; background:blue');
    };

    startgame.prototype = {
        init: function () {
            this.goUp = false;
            var storage = {
                questions: [],
                currentPoints: 0
            };
            window.localStorage.setItem('payload', JSON.stringify(storage));
        },
        preload: function () {
            this.fData = this.cache.binary.get('instruction');
            if (this.fData && this.fData.audio) {
                this.load.audio('startSound', this.fData.audio);
            }
        },
        update: function () {
            if (this.redArrowImage) {
              if (this.goUp) {
                this.redArrowImage.y -= 1;
                if (this.redArrowImage.y <= -4) {
                  this.goUp = false
                }
              } else {
                this.redArrowImage.y += 1;
                if (this.redArrowImage.y >= 18) {
                  this.goUp = true;
                }
              }
            }
          },
        create: function () {
            window.angularComponentRef.loadingFinished();
            this.setupState();

            this.addSounds();

            var modalTextStyle = {
                backgroundColor: 'transparent',
                stroke: '#ffffff',
                strokeThickness: 2,
                lineSpacing: 15,
                fontFamily: 'Kids Rock DEMO',
                fontSize: 30,
                color: '#43425d',
                wordWrap: true,
                align: 'center'
            };

            var modalHeaderStyle = {
                backgroundColor: 'transparent',
                stroke: '#ffffff',
                strokeThickness: 16,
                fontFamily: 'Kids Rock DEMO',
                fontSize: 50,
                wordWrap: false,
                align: 'center'
            };

            this.add.sprite(this.game.scale.width/2, this.game.scale.height/2, 'instruction-bg');

            this.modal = this.add.container();

            var modalBox = this.add.sprite(this.game.scale.width/2, this.game.scale.height/2, 'assets', 'activity-Instruction-board.png');
            modalBox.setOrigin(0.5, 0.5);

            this.modalHeaderGroup = this.add.container();
            var modalBoxHeader = this.add.sprite(0, 0, 'assets', 'activity-Instruction-board-header.png');
            modalBoxHeader.setOrigin(0, 0);

            var modalHeaderText = this.add.text(0, 0, this.fData.title, modalHeaderStyle);
            if (!(this.game.device.mobileSafari || this.game.device.safari)) {
                modalHeaderText.setShadow(3, 3, '#43425d', 0, false, true);
            }
            var grd = modalHeaderText.context.createLinearGradient(0, 0, 0, modalHeaderText.height);
            grd.addColorStop(0, '#edee62');
            grd.addColorStop(0.2, '#edee62');
            grd.addColorStop(0.7, '#dd7b33');
            modalHeaderText.setFill(grd);

            var bgSoundButton = this.add.sprite(0, 0, 'soundBtn', 'introduction-sound_02.png');
            bgSoundButton.setOrigin(0, 0);
            bgSoundButton.on('pointerup', function () {
                if (this.redArrowImage) {
                  this.redArrowImage.alpha = 0;
                }
                this.playQuestionSound()
              }, this);

            setupButton(bgSoundButton, 'introduction-sound_02.png', 'introduction-sound_01.png');
            bgSoundButton.setInteractive({ cursor: 'pointer' });
            bgSoundButton.on('pointerover', this.playHoverSound, this);
            bgSoundButton.on('pointerdown', this.playSelectionSound, this );

            this.modalHeaderGroup.add(modalBoxHeader);
            this.modalHeaderGroup.add(modalHeaderText);
            this.modalHeaderGroup.add(bgSoundButton);
            this.modalHeaderGroup.setPosition((modalBox.width - modalBoxHeader.width) / 2 + modalBox.getBounds().left, modalBox.getBounds().top - 21);

            if (this.game.device.os.iOS) {
                this.redArrowImage = this.add.sprite(0, 0, 'red-arrow-image');
                this.redArrowImage.setOrigin(0, 0);
                this.modalHeaderGroup.add(this.redArrowImage);
              }

            modalHeaderText.setPosition(modalBoxHeader.getCenter().x, modalBoxHeader.getCenter().y);
            modalHeaderText.setOrigin(0.5, 0.5);
            modalBoxHeader.setPosition(this.modalHeaderGroup.getBounds().left, this.modalHeaderGroup.getBounds().top);
            bgSoundButton.setPosition(modalBoxHeader.getCenter().x + 30, modalBoxHeader.getCenter().y - 40);

            if (this.game.device.os.iOS) {
                this.redArrowImage.setPosition(modalBoxHeader.getCenter().x + 34, modalBoxHeader.getCenter().y - 110);
            }

            var modalText = this.add.text(this.game.scale.width/2, this.game.scale.height/2, this.fData.content.replaceAll('\\n', '\n'), modalTextStyle);
            console.log(this.fData.content)
            modalText.setWordWrapWidth(modalBox.width - 110);
            modalText.setOrigin(0.5, 0.5);

            var startButton = this.add.sprite(this.game.scale.width/2, modalBox.getBounds().bottom - 103, 'assets', 'activity-Instruction-play-button.png');
            startButton.setOrigin(0, 0)
            startButton.on('pointerup', function () {
                this.fsound.stop();
                currentQuestionIndex = 0;
                this.scene.start("Questions", {currentQuestionIndex: 0});
            }, this);

            setupButton(startButton, 'activity-Instruction-play-button.png', 'activity-Instruction-play-button-hover.png');
            startButton.setInteractive({ cursor: 'pointer' });

            startButton.input.pixelPerfectOver = true;
            startButton.input.pixelPerfectClick = true;
            startButton.setOrigin(0.5, 0.5);

            var leavesRightSprite = this.add.sprite(0, 0, 'assets', 'leaves.png');
            leavesRightSprite.setOrigin(0, 0)
            leavesRightSprite.setPosition(modalBox.getBounds().right - 145, modalBox.getBounds().top + 15);

            var leavesLeftSprite = this.add.sprite(0, 0, 'assets', 'leaves.png');
            leavesLeftSprite.setOrigin(0.5, 0.5);
            leavesLeftSprite.scaleX *= -1;
            leavesLeftSprite.setPosition(modalBox.getBounds().left + 34, modalBox.getBounds().bottom - 140);


            this.modal.add(leavesLeftSprite);
            this.modal.add(leavesRightSprite);
            this.modal.add(modalBox);
            this.modal.add(modalBoxHeader);
            this.modal.add(modalText);
            this.modal.add(startButton);

            this.playQuestionSound();
        },

        setupState: function () {

        },

        addSounds: function () {
            this.fsound = this.sound.add('startSound');
            this.hoverSound = this.sound.add('hoverSound');
            this.selectionSound = this.sound.add('selectionSound');
            this.time.addEvent({ delay: 0, callback: this.playQuestionSound, callbackScope: this, loop: false });
        },

        playHoverSound: function () {
            this.hoverSound.play();
        },
        playSelectionSound: function () {
            this.selectionSound.play();
        },
        playQuestionSound: function () {
            this.fsound.play();
        }
    };
    //endGame
    var endgame = function (game) {
        console.log("%cEndGame", "color:white; background:blue");
    };

    endgame.prototype = {
        init: function () {

        },
        preload: function () {
            this.totalPoints = window.angularComponentRef.getTotalScore();
            this.fData = this.cache.binary.get('complete')
        },
        create: function () {
            if (!this.completionSound) {
                this.completionSound = this.sound.add('completionSound');
            }
            this.completionSound.play()

            window.localStorage.removeItem('currentState');
            window.localStorage.removeItem('wrongButtonKey');

            var storageJson = window.localStorage.getItem('payload');
            var storage = JSON.parse(storageJson);
            var currentPoints = 0;
            if (storage) {
                currentPoints = storage.currentPoints || 0;
            }

            this.add.sprite(0, 0, 'completion-bg').setOrigin(0, 0);

            var totalScoreTextStyle = {
                backgroundColor: 'transparent',
                stroke: '#91301a',
                strokeThickness: 6,
                fontFamily: 'Kids Rock DEMO',
                fontSize: 39,
                wordWrap: false,
                align: 'left'
            };
            var totalScoreTextStyleOutline = {
                backgroundColor: 'transparent',
                stroke: '#fff',
                strokeThickness: 12,
                fontFamily: 'Kids Rock DEMO',
                fontSize: 39,
                wordWrap: false,
                align: 'left'
            };
            var collectedPointsTextStyle = {
                backgroundColor: 'transparent',
                stroke: '#91301a',
                strokeThickness: 2,
                fontFamily: 'Kids Rock DEMO',
                fontSize: 25,
                wordWrap: false,
                align: 'left',
                color: '#ffffff'
            };

            var modalBox = this.add.sprite(this.game.scale.width/2, this.game.scale.height/2, 'assets', 'activity-Instruction-board.png');
            modalBox.setOrigin(0.5, 0.5);
            var completeRibbon = this.add.sprite(modalBox.getCenter().x, modalBox.getBounds().top + 222, 'assets', 'complete-ribbon-full.png');
            completeRibbon.setOrigin(0.5, 0.5);

            var totalScoreOutline = this.add.text(modalBox.getBounds().left + 340, modalBox.getBounds().bottom - 302, "Total Score: " + " " + this.totalPoints, totalScoreTextStyleOutline);
            var totalScore = this.add.text(modalBox.getBounds().left + 342, modalBox.getBounds().bottom - 300, "Total Score: " + " " + this.totalPoints, totalScoreTextStyle);

            var grd = totalScore.context.createLinearGradient(0, 0, 0, totalScore.height);
            grd.addColorStop(0, '#f4fa38');
            grd.addColorStop(0.3, '#edee62');
            grd.addColorStop(0.7, '#dd7b33');
            totalScore.setFill(grd);

            var collectedPointsMedal = this.add.sprite(modalBox.getBounds().left + 324, modalBox.getBounds().bottom - 244, 'assets', 'collected-poins-medal.png');
            collectedPointsMedal.setOrigin(0, 0)
            var collectedPoints = this.add.text(modalBox.getBounds().left + 422, modalBox.getBounds().bottom - 204, "+" + currentPoints, collectedPointsTextStyle);

            var trophy = this.add.sprite(modalBox.getBounds().left + 210, modalBox.getBounds().bottom - 215, 'trophy');
            trophy.setScale(0.5, 0.5);
            trophy.setOrigin(0.5, 0.5);
            trophy.alpha = 0;
            this.tweens.add({
                targets: trophy,
                scaleX: 1,
                scaleY: 1,
                duration: 400,
                ease: Phaser.Math.Easing.Linear
            });
            this.tweens.add({
                targets: trophy,
                alpha: 1,
                duration: 400,
                ease: Phaser.Math.Easing.Linear
            });

            var backToMenuButton = this.add.sprite(modalBox.getBounds().left + 332, modalBox.getBounds().bottom - 89, 'assets', 'back-to-menu-button.png');
            backToMenuButton.setOrigin(0, 0)
            setupButton(backToMenuButton, 'back-to-menu-button.png', 'back-to-menu-button-hover.png');
            backToMenuButton.setInteractive({ cursor: 'pointer' });
            backToMenuButton.on('pointerup', this.backToMenu, this );

            var playAgainButton = this.add.sprite(modalBox.getBounds().left + 479, modalBox.getBounds().bottom - 89, 'assets', 'play-again-button.png');
            playAgainButton.setOrigin(0, 0)
            setupButton(playAgainButton, 'play-again-button.png', 'play-again-button-hover.png');
            playAgainButton.setInteractive({ cursor: 'pointer' });
            playAgainButton.on('pointerup', this.playAgain, this );

            var nextActivityButton = this.add.sprite(modalBox.getBounds().left + 626, modalBox.getBounds().bottom - 89, 'assets', 'next-activity-button.png');
            nextActivityButton.setOrigin(0, 0)
            setupButton(nextActivityButton, 'next-activity-button.png', 'next-activity-button-hover.png');
            nextActivityButton.setInteractive({ cursor: 'pointer' });
            nextActivityButton.on('pointerup', this.nextActivity, this );

            backToMenuButton.on('pointerover', this.playHoverSound, this);
            backToMenuButton.on('pointerdown', this.playSelectionSound, this );

            playAgainButton.on('pointerover', this.playHoverSound, this);
            playAgainButton.on('pointerdown', this.playSelectionSound, this );

            nextActivityButton.on('pointerover', this.playHoverSound, this);
            nextActivityButton.on('pointerdown', this.playSelectionSound, this );

            this.saveActivityAns();

            this.hoverSound = this.sound.add('hoverSound');
            this.selectionSound = this.sound.add('selectionSound');

        },

        backToMenu: function () {
            window.angularComponentRef.backToMenu();
            this.completionSound.stop();
        },
        playAgain: function () {
            this.completionSound.stop();
            this.scene.start("StartGame");
        },
        nextActivity: function () {
            console.log('nextActivity');
            window.angularComponentRef.nextActivity();
        },

        formatTime: function (s) {
            var minutes = "0" + Math.floor(s / 60);
            var seconds = "0" + (s - minutes * 60);
            return minutes.substr(-2) + ":" + seconds.substr(-2);
        },

        saveActivityAns: function () {
            var storageJson = window.localStorage.getItem('payload');
            var storage = JSON.parse(storageJson);
            var _obj = {
                activity_id: 1,
                country_id: 1,
                zone_id: 1,
                continent_id: 1,
                totalScore: 0,
                questions: []
            }
            if (storage) {
                for (var i = 0; i < storage.questions.length; i++) {
                    var questionKey = storage.questions[i].questionKey;
                    var answerKey = storage.questions[i].answerKey;
                    var attempts = storage.questions[i].attempts;
                    var points = storage.questions[i].points;
                    var question = {
                        [questionKey]: {
                            [answerKey]: {
                                try_count: attempts,
                                score: points
                            }
                        }
                    }
                    _obj.questions.push(question);
                    _obj.totalScore += points
                }
                window.angularComponentRef.gameFinish(_obj);
            }
        },

        playHoverSound: function () {
            this.hoverSound.play();
        },
        playSelectionSound: function () {
            this.selectionSound.play();
        }
    };

    //startGame
    var resumegame = function (game) {
        console.log("%cStartGame", "color:white; background:blue");
    };

    resumegame.prototype = {
        init: function (data) {
            this.currentQuestionIndex = data.currentQuestionIndex;
            this.fData = this.cache.binary.get('questions');
        },
        preload: function () {

        },
        create: function () {

            this.add.sprite(0, 0, 'bg-image').setOrigin(0, 0);

            this.hoverSound = this.sound.add('hoverSound');
            this.selectionSound = this.sound.add('selectionSound');

            var startButton = this.add.sprite(this.game.scale.width/2, this.game.scale.height/2, 'assets', 'next-button.png');
            startButton.setOrigin(0, 0)
            setupButton(startButton, 'next-button.png', 'next-button-hover.png');
            startButton.setInteractive({ cursor: 'pointer' });

            startButton.on('pointerup', function () {
                if (currentQuestionIndex < this.fData.questions.length) {
                    this.scene.start("Questions", {currentQuestionIndex: this.currentQuestionIndex});
                } else {
                    this.scene.start("EndGame");
                }
            }, this);

            startButton.on('pointerover', this.playHoverSound, this);
            startButton.on('pointerdown', this.playSelectionSound, this );
            startButton.setOrigin(0.5, 0.5);

            var style = {
                backgroundColor: 'transparent',
                stroke: '#074a83',
                strokeThickness: 6,
                fontFamily: "Kids Rock DEMO",
                fontSize: 40,
                color: "#ffffff",
                wordWrap: false,
                align: "left"
            };
            var startButtonText = this.add.text(this.game.scale.width/2, this.game.scale.height/2 - 100, 'Resume quiz', style);
            startButtonText.setOrigin(0.5, 0.5);
        },
        playHoverSound: function () {
            this.hoverSound.play();
        },
        playSelectionSound: function () {
            this.selectionSound.play();
        }

    };

    //questions
    var questions = function (game) {
        console.log("%cQuestions", "color:white; background:blue");
        // this.bgIndex = 1;
    };

    questions.prototype = {
        init: function (data) {
            var currentQuestionIndex = data.currentQuestionIndex;
            this.currentQuestionIndex = currentQuestionIndex;
            this.wrongAnswersCount = 0;
            this.maxWrongAnswers = 2;
            window.localStorage.setItem('currentState', currentQuestionIndex);
            this.answerTextMaxWidth = 0;
            // this.bgIndex++;
            // this.bgIndex %= 2;
        },
        preload: function () {
            this.fData = this.cache.binary.get('questions');
            this.qAnsPoints = this.cache.binary.get('qAnsPoints');
        },
        create: function () {
            this.defaultLoop = null;

            if (!this.bgMusic) {
                this.bgMusic = this.sound.add('bg-music', {volume: 1, loop: true});
                this.bgMusic.play();
            }
            else {
                if (!this.bgMusic.isPlaying) {
                    this.bgMusic.play();
                }
            }

            this.drawBackground();

            // this.fData = this.game.cache.getJSON('questions');
            this.questionItem = this.fData[this.currentQuestionIndex];
            this.drawQuestionBox(this.questionItem.title);

            this.drawButtonGroup(this.questionItem.answers, this.questionItem.correctAnsKey);

            this.drawPuppet();

            this.addSounds();

            this.addEvents();

            this.exitButton();

            this.exitModal();


        },
        update: function () {

        },
        drawBackground: function () {
            // var key = this.bgIndex == 0 ? 'bg-image' : 'bg-image-1';
            // if (game.textures.get(key).key == "__MISSING") key = 'bg-image';
            this.add.sprite(0, 0, 'bg-image').setOrigin(0, 0);
        },
        drawButtonGroup: function (answers, rightAnswerKey) {
            this.answerTextMaxWidth = 0;
            this.answerNormalImage = 'answer-button-default.png';
            this.answerHoverImage = 'answer-button-default-hover.png';
            this.answerCorrectImage = 'answer-button-correct.png';
            this.answerWrongImage = 'answer-button-wrong.png';

            this.groupButtons = this.add.container();
            var previousGroup;
            for (var index = 0; index < answers.length; index++) {
                var isRightAnswer = (answers[index].key === rightAnswerKey);
                var group = this.drawAnswerButton(answers[index], isRightAnswer);
                if (previousGroup) {
                    group.setPosition(previousGroup.x, previousGroup.getBounds().bottom + 15);
                }
                previousGroup = group;
                this.groupButtons.add(group);
            }
            this.groupButtons.setPosition(66, this.questionBox.getBounds().bottom + 32);

            var groupButtonsChildren = this.groupButtons.getAll();
            for (var i = 0; i < groupButtonsChildren.length; i++) {
                var subChildren = groupButtonsChildren[i].getAll();
                for (var j = 0; j < subChildren.length; j++) {
                    var button = subChildren[j];
                    if (!button.getData("isAnswerButton")) {
                        continue;
                    }
                    setupButton(button, this.answerNormalImage, this.answerHoverImage, undefined, undefined, true);

                    if (button.getData("key") === window.localStorage.getItem('wrongButtonKey')) {
                        setupButton(button, this.answerWrongImage, this.answerWrongImage, undefined, undefined, true);
                        button.disableInteractive();
                        this.wrongAnswersCount++;
                    } else {
                        setupButton(button, this.answerNormalImage, this.answerHoverImage, undefined, undefined, true);
                    }
                }
            }
        },
        drawAnswerButton: function (item, isRightAnswer) {
            var style = {
                backgroundColor: 'transparent',
                stroke: '#681e00',
                strokeThickness: 4,
                fontFamily: "Kids Rock DEMO",
                fontSize: 24,
                color: "#ffffff",
                wordWrap: false,
                align: "left"
            };
            var text = this.add.text(90, 30, item.title, style);
            if (this.answerTextMaxWidth < text.width) {
                this.answerTextMaxWidth = text.width;
            }
            var isClicked = false;
            if (item.key === window.localStorage.getItem('wrongButtonKey')) {
                isClicked = true;
            }

            var button = this.add.sprite(0, 0, 'answer-buttons', this.answerNormalImage);
            button.setOrigin(0, 0);
            setupButton(button, this.answerNormalImage, this.answerHoverImage, undefined, undefined, true);
            button.setInteractive({ cursor: 'pointer' });
            var callbackContext = this;
            button.on('pointerup', function() {
                this.buttonOnClick(button, {
                    context: callbackContext,
                    text: text,
                    clicked: isClicked,
                    isRightAnswer: isRightAnswer,
                    key: item.key
                });
            }.bind(this));
            button.on('pointerover', this.playHoverSound, this);
            button.on('pointerdown', this.playSelectionSound, this );

            button.setData("isRight", isRightAnswer);
            button.setData("key", item.key);
            button.setData("isAnswerButton", true);

            var group = this.add.container();
            group.add(button);
            group.add(text);
            return group;
        },
        drawQuestionBox: function (textContent) {
            var style = {
                backgroundColor: 'transparent',
                stroke: '#681e00', //'#074a83',
                strokeThickness: 5,
                fontFamily: "Kids Rock DEMO",
                fontSize: 32,
                color: "#f5d839",
                wordWrap: true,
                align: "left"
            };
            textContent = 'Question ' + this.questionItem.order + '/' + this.fData.length; //'Q' + this.questionItem.order + '.\xa0' + textContent;

            //question box
            this.questionBox = this.add.container();
            var questionBoxPaddingLeft = 32;
            var questionBoxPaddingRight = 100;
            var questionBoxPaddingY = 15;
            var questionBoxLeft = 0;


            var questionBoxText = this.add.text(questionBoxLeft + questionBoxPaddingLeft, questionBoxPaddingY + 40, textContent, style);
            questionBoxText.setWordWrapWidth(750 - (questionBoxPaddingLeft + questionBoxPaddingRight));
            questionBoxText.setOrigin(0, 0.5);
            questionBoxText.y = 100;

            var questionSoundButton = this.add.sprite(-15, -22, 'assets', 'audio-icon.png');
            questionSoundButton.setScale(1.1, 1.1);
            questionSoundButton.setOrigin(0, 0);
            setupButton(questionSoundButton, 'audio-icon.png', 'audio-icon-hover.png');
            questionSoundButton.setInteractive({ cursor: 'pointer' });
            questionSoundButton.on('pointerup', this.playQuestionSound, this );
            questionSoundButton.on('pointerover', this.playHoverSound, this);
            questionSoundButton.on('pointerdown', this.playSelectionSound, this );

            this.questionBox.add(questionBoxText);
            this.questionBox.add(questionSoundButton);
            this.questionBox.setPosition(55, 34);
        },
        drawPuppet: function () {
            var x = 575;
            var y = 165;

            //puppet
            this.puppetSprite = this.add.sprite(x, y, 'idle_atlas');
            this.puppetSprite.setOrigin(0.0, 0.0);

            var self = this;

            this.anims.create({
                key: 'idleAnimate',
                frames: this.anims.generateFrameNames('idle_atlas', {
                    prefix: 'iddle',
                    suffix: '.png',
                    start: 0,
                    end: 89,
                    zeroPad: 2
                }),
                frameRate: 33,
                repeat: -1
            });


            this.anims.create({
                key: 'noAnimate',
                frames: this.anims.generateFrameNames('no_atlas', {
                    prefix: 'No',
                    suffix: '.png',
                    start: 0,
                    end: 89,
                    zeroPad: 2
                }),
                frameRate: 33,
                repeat: 0
            });

            this.anims.create({
                key: 'yesAnimate',
                frames: this.anims.generateFrameNames('yes_atlas', {
                    prefix: 'YES',
                    suffix: '.png',
                    start: 6,
                    end: 89,
                    zeroPad: 2
                }),
                frameRate: 33,
                repeat: 0
            });

            this.anims.create({
                key: 'noCorrectAnswerThisAnimate',
                frames: this.anims.generateFrameNames('noCorrectAnswerThis_atlas', {
                    prefix: 'No its this one',
                    suffix: '.png',
                    start: 18,
                    end: 89,
                    zeroPad: 2
                }),
                frameRate: 33,
                repeat: 0
            });

            this.puppetSprite.on('animationcomplete', function (animation, frame) {
                if(animation.key !== 'idleAnimate'){
                    self.puppetSprite.anims.play("idleAnimate");
                }
            });
            this.puppetSprite.play('idleAnimate');
        },
        addSounds: function () {
            //sound
            this.fsound = this.sound.add('questionSound' + this.currentQuestionIndex);
            this.trayAgainLoop = null;
            this.fsound.on('complete', function () {
                this.addTrayAgainLoop();
            }, this);

            this.yesSound1 = this.sound.add('yes1');
            this.yesSound2 = this.sound.add('yes2');
            this.noTryAgain = this.sound.add('noTryAgain');
            this.tryAgain = this.sound.add('tryAgain');
            this.noCorrectAnswerThis = this.sound.add('noCorrectAnswerThis');

            this.hoverSound = this.sound.add('hoverSound');
            this.selectionSound = this.sound.add('selectionSound');
            this.iddleDefaultSound = this.sound.add('tapAnswer');
            this.exitSound = this.sound.add('exitSound');
            var self = this;
        },
        addEvents: function () {
            this.questionSoundEvent = this.time.addEvent({ delay: 1000, callback: this.playQuestionSound, callbackScope: this, loop: false });
            this.addNewDefaultLoop();
        },
        addNewDefaultLoop: function () {
            if (this.defaultLoop) {
                this.time.clearPendingEvents();
                this.defaultLoop = this.time.addEvent({ delay: 1000 * 5, callback: this.payPuppet, callbackScope: this, loop: true });
            } else if (!this.defaultLoop) {
                this.defaultLoop = this.time.addEvent({ delay: 1000 * 5, callback: this.payPuppet, callbackScope: this, loop: true });
                // this.puppetSprite.anims.play('idleAnimate');
            }
        },
        buttonOnClick: function (button, data) {
            var context = data.context;
            context.questionSoundEvent.remove(false);

            if (context.fsound && context.fsound.isPlaying) {
                context.fsound.stop();
            }
            var storage = window.localStorage.getItem('payload');
            storage = JSON.parse(storage);

            var questionIdx;
            if (storage) {
                var question;
                for (var i = 0; i < storage.questions.length; i++) {
                    var item = storage.questions[i];
                    if (context.questionItem.key === item.questionKey) {
                        question = item;
                        questionIdx = i;
                        break;
                    }
                }
                if (question) {
                    question.attempts = question.attempts + 1;
                    question.correctAnswer = data.isRightAnswer;
                    storage.questions[questionIdx] = question;
                } else {
                    question = {
                        questionKey: context.questionItem.key,
                        correctAnswer: data.isRightAnswer,
                        attempts: 1
                    };
                    storage.questions.push(question)
                }
            }
            var points = 0;

            var isEndGame = false;
            if (data.isRightAnswer) {
                if (context.wrongAnswersCount === 0) {
                    points = +context.qAnsPoints.correctAnsPoint;

                } else {
                    points = +context.qAnsPoints.secTimeCorrectAnsPoint;
                }
                isEndGame = true;

                context.tryAgain.stop();
                context.noTryAgain.stop();

                data.text.fill = '#6afaff';
                data.text.stroke = '#1a472b';
                context.groupButtons.each(function (item) {
                    item.each(x => x.disableInteractive());
                });
                setupButton(button, context.answerCorrectImage, context.answerCorrectImage, undefined, undefined, true);

                context.puppetSprite.anims.play('yesAnimate');
                context.yesSoundIndex = (context.yesSoundIndex || 0) + 1;
                var yesSound = context['yesSound' + (context.yesSoundIndex % 2 + 1)];
                // yesSound.play();
                setTimeout(function(){yesSound.play()}, 500)
                // context.nextButton.setInteractive({cursor: "pointer"});
                // context.nextButton.alpha = 1;
                setTimeout(context.proceedToTheNextQuestion.bind(context), yesSound.duration * 1000 + 500 + 1500);

                context.addNewDefaultLoop();
                context.addTrayAgainLoop(true);
                window.localStorage.setItem('currentState', +(context.currentQuestionIndex) + 1);
                window.localStorage.removeItem('wrongButtonKey');
            } else {
                points = +context.qAnsPoints.secTimeCorrectAnsPoint;

                button.disableInteractive();

                if (!data.clicked) {
                    data.clicked = true;
                    context.wrongAnswersCount++;
                }
                isEndGame = context.wrongAnswersCount === context.maxWrongAnswers;
                if (isEndGame) {
                    points = +context.qAnsPoints.wrongAnsPoint;
                    context.puppetSprite.anims.play('noCorrectAnswerThisAnimate');
                    context.noCorrectAnswerThis.play();
                } else {
                    context.puppetSprite.anims.play('noAnimate');
                    context.noTryAgain.play();
                }
                if (isEndGame) {
                    context.groupButtons.each(function (item) {
                        item.each(x => x.disableInteractive());
                        if (item.getAt(0).getData("isRight")) {
                            setupButton(item.getAt(0), context.answerCorrectImage, context.answerCorrectImage, undefined, undefined, true);
                        }
                    });
                    setTimeout(context.proceedToTheNextQuestion.bind(context), context.noCorrectAnswerThis.duration * 1000 + 200 + 1500);

                    window.localStorage.setItem('currentState', +(context.currentQuestionIndex) + 1);
                    window.localStorage.removeItem('wrongButtonKey');
                } else {
                    window.localStorage.setItem('wrongButtonKey', data.key);
                }
                setupButton(button, context.answerWrongImage, context.answerWrongImage, undefined, undefined, true);

                context.addNewDefaultLoop();
                context.addTrayAgainLoop(isEndGame);
            }
            if (storage) {
                if (isEndGame) {
                    storage.currentPoints = storage.currentPoints + points;
                    if (questionIdx) {
                        storage.questions[questionIdx].points = points;
                        storage.questions[questionIdx].answerKey = data.key;
                    } else {
                        storage.questions[context.currentQuestionIndex].points = points;
                        storage.questions[context.currentQuestionIndex].answerKey = data.key;
                    }
                }
                window.localStorage.setItem('payload', JSON.stringify(storage));
            }
        },
        addTrayAgainLoop: function (isEndGame) {
            if (this.trayAgainLoop) {
                this.trayAgainLoop.remove(false);
            }

            if (isEndGame) {
                return;
            }

            console.log('Try again loop');
            // this.trayAgainLoop = this.time.addEvent({ delay: 1000 * 10, callback: function() {
            //     this.tryAgain.play();
            // }, callbackScope: this, loop: true });
        },
        playQuestionSound: function () {
            this.tryAgain.stop();
            this.fsound.play();
        },
        payPuppet: function () {
            this.tryAgain.stop();
        },
        proceedToTheNextQuestion: function (delay) {
            if (this.fsound) {
                this.fsound.stop();
            }
            if (this.noTryAgain) {
                this.noTryAgain.stop();
            }
            if (this.iddleDefaultSound) {
                this.iddleDefaultSound.stop();
            }
            if (this.yesSound1) {
                this.yesSound1.stop();
            }
            if (this.yesSound2) {
                this.yesSound2.stop();
            }
            if (this.noCorrectAnswerThis) {
                this.noCorrectAnswerThis.stop();
            }
            this.currentQuestionIndex++;
            if (this.currentQuestionIndex < this.fData.length) {
                this.defaultLoop = null;
                this.scene.start("Questions", {currentQuestionIndex: this.currentQuestionIndex});
            } else {
                if (this.bgMusic) {
                    this.bgMusic.stop();
                }
                this.scene.start("EndGame");
            }
        },

        exitButton: function () {
            this.extiBtn = this.add.sprite(this.game.scale.width - 100, 16, 'assets', 'exit-button.png');
            this.extiBtn.setOrigin(0, 0)
            setupButton(this.extiBtn, 'exit-button.png', 'exit-button-hover.png');
            this.extiBtn.setInteractive({ cursor: 'pointer' });
            this.extiBtn.on('pointerup', this.showExitModal, this);
            this.extiBtn.on('pointerover', this.playHoverSound, this);
            this.extiBtn.on('pointerdown', this.playSelectionSound, this );
        },

        exitModal: function () {
            var modalBg = this.add.sprite(0, 0, 'modal-overlay');
            modalBg.setInteractive();
            modalBg.setOrigin(0, 0)
            this.modal = this.add.container();
            var modalBox = this.add.sprite(this.game.scale.width/2, this.game.scale.height/2, 'assets', 'activity-Instruction-board.png');
            modalBox.setOrigin(0.5, 0.5);

            var leavesRightSprite = this.add.sprite(0, 0, 'assets', 'leaves.png');
            leavesRightSprite.setOrigin(0, 0)
            leavesRightSprite.setPosition(modalBox.getBounds().right - 145, modalBox.getBounds().top + 15);

            var leavesLeftSprite = this.add.sprite(0, 0, 'assets', 'leaves.png');
            leavesLeftSprite.setOrigin(0.5, 0.5);
            leavesLeftSprite.scaleX *= -1;
            leavesLeftSprite.setPosition(modalBox.getBounds().left + 34, modalBox.getBounds().bottom - 140);

            var warningIcon = this.add.sprite(modalBox.getCenter().x, modalBox.getBounds().top + 74, 'assets', 'warning-icon.png');
            warningIcon.setOrigin(0.5, 0);


            var modalTextStyle = {
                backgroundColor: 'transparent',
                stroke: '#ffffff',
                strokeThickness: 2,
                fontFamily: 'Kids Rock DEMO',
                fontSize: 27,
                color: '#43425d',
                wordWrap: true,
                align: 'center'
            };
            var modalText = this.add.text(this.game.scale.width/2, this.game.scale.height/2 + 10, "Your progress in this activity will not be saved. \n \n Are you sure you want to exit? ", modalTextStyle);
            modalText.setWordWrapWidth(modalBox.width - 110);
            modalText.setOrigin(0.5, 0.5);
            var modalYesBtn = this.add.sprite(modalBox.getBounds().left + 217, modalBox.getBounds().bottom - 179, 'assets', 'button-yes.png');
            modalYesBtn.setOrigin(0, 0)
            setupButton(modalYesBtn, 'button-yes.png','button-yes-hover.png');
            modalYesBtn.setInteractive({ cursor: 'pointer' });
            modalYesBtn.on('pointerup', this.exitGame, this);
            var modalNoBtn = this.add.sprite(modalYesBtn.getBounds().right - 43, modalBox.getBounds().bottom - 179, 'assets', 'button-no.png');
            modalNoBtn.setOrigin(0, 0)
            setupButton(modalNoBtn, 'button-no.png','button-no-hover.png');
            modalNoBtn.setInteractive({ cursor: 'pointer' });
            modalNoBtn.on('pointerup', this.exitGameCancel, this);

            modalYesBtn.on('pointerover', this.playHoverSound, this);
            modalYesBtn.on('pointerdown', this.playSelectionSound, this );

            modalNoBtn.on('pointerover', this.playHoverSound, this);
            modalNoBtn.on('pointerdown', this.playSelectionSound, this );

            this.modal.add(modalBg);
            this.modal.add(leavesLeftSprite);
            this.modal.add(leavesRightSprite);
            this.modal.add(modalBox);
            this.modal.add(warningIcon);
            this.modal.add(modalText);
            this.modal.add(modalYesBtn);
            this.modal.add(modalNoBtn);

            this.modal.visible = false;
            this.modal.alpha = 0;
        },

        showExitModal: function () {
            this.extiBtn.visible = false;
            this.modal.visible = true;
            this.modal.alpha = 1;
            this.time.paused = true;
            this.sound.pauseAll();
            this.exitSound.play();
        },

        exitGame: function () {
            this.time.paused = false;
            this.sound.resumeAll();
            window.localStorage.removeItem('currentState');
            window.localStorage.removeItem('wrongButtonKey');
            if (this.bgMusic) {
                this.bgMusic.stop();
            }
            window.angularComponentRef.backToMenu();
        },
        exitGameCancel: function () {
            this.extiBtn.visible = true;
            this.modal.alpha = 0;
            this.modal.visible = false;
            this.time.paused = false;
            this.sound.resumeAll();
            this.bgMusic.resume();
        },
        playHoverSound: function () {
            this.hoverSound.play();
        },
        playIddleDefaultSound: function () {
            this.iddleDefaultSound.play()
        },
        playSelectionSound: function () {
            this.selectionSound.play();
        }
    };

    //main
    game = new Phaser.Game({
        type: Phaser.AUTO,
        width: 980,
        height: 600,
        parent: 'game-container',
        dom: {
            createContainer: false
        },
        scale: {
            mode: Phaser.Scale.NONE,
            // autoCenter: Phaser.Scale.CENTER_BOTH
        },
        transparent: true,
        scene: {
            create: () => {
                game.scene.add("Boot", boot, false);
                game.scene.add("StartGame", startgame);
                game.scene.add("ResumeGame", resumegame);
                game.scene.add("Questions", questions);
                game.scene.add("EndGame", endgame);

                game.scene.start("Boot");
            }
        }
    });


}
function destroyQuestionAndAnswers() {
    if (game)
        game.destroy();
}
