import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { Subscription, Observable } from 'rxjs';
import { AngularFireStorage } from '@angular/fire/storage';
import { AuthService } from '../../shared/auth.service';

@Component({
    selector: 'app-set-avatar',
    templateUrl: './set-avatar.component.html'
})
export class SetAvatarComponent implements OnInit, OnDestroy, AfterViewInit {
    protected subscription: Subscription;

    activities: Observable<any>;
    selectedAvatar: string;
    userInfo: any;
    loading: boolean = true;

    constructor(
        public storage: AngularFireStorage,
        private auth: AuthService
    ) { }

    ngOnInit() {
        this.userSubscription = this.auth.onUserUpdate.subscribe(userData => {
            if (userData != null && userData) this.loadChildData(userData);
        }, error => {
            console.log('error', error);
        });
    }
    userSubscription: Subscription;

    ngOnDestroy() {
        if (this.subscription) this.subscription.unsubscribe();
        if (this.userSubscription) this.userSubscription.unsubscribe();
    }

    loadChildData(userData) {
        this.userInfo = userData;
        if (!this.userInfo.avatar) {
            this.selectedAvatar = 'g1';
            this.userInfo.sex = 'female';
        }
        else {
            this.selectedAvatar = this.userInfo.avatar;
        }
        this.setHoverClickEvents();
    }

    ngAfterViewInit(): void {
        this.setHoverClickEvents();
        setTimeout(() => {
            this.instructionLoaded();
        }, 3000);
    }

    setHoverClickEvents() {
        setTimeout(() => {
            var buttons = document.querySelectorAll('button');
            for (var i = 0; i < buttons.length; i++) {
                buttons[i].addEventListener('click', function (event) {
                    let audio = <HTMLAudioElement>document.getElementById('buttonClick');
                    if (audio) audio.play();
                });
                buttons[i].addEventListener('mouseover', function (event) {
                    let audio = <HTMLAudioElement>document.getElementById('buttonHover');
                    if (audio) audio.play();
                });
            }
        }, 100)
    }



    previousState() {
        window.history.back();
    }

    done() {
        this.userInfo.avatar = this.selectedAvatar;

        this.auth.checkToken(async x => {
            await this.auth.updateSelfData({ collectionID: "userData", data: { avatar: this.selectedAvatar } }).toPromise();
        });
    }

    setGender(type: string) {
        this.userInfo.sex = type;
        this.setHoverClickEvents();
    }

    instructionLoaded() {
        if (this.loading) {
            this.loading = false;
        }
    }
}
