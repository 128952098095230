import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { Observable, Observer, Subscription } from 'rxjs';
import { AngularFireStorage } from '@angular/fire/storage';
import { AuthService } from '../../shared/auth.service';
import { HttpClient } from '@angular/common/http';

@Component({
    selector: 'app-gallery',
    templateUrl: './gallery.component.html'
})
export class GalleryComponent implements OnInit, OnDestroy, AfterViewInit {
    data: any;
    totalScore: number;

    activity: any;
    activities: Observable<any>;
    base64Image: any;

    titles: any = {};
    userInfo: any;
    activeWindow: number = 1;

    currentValue;

    photoArray;

    tabCount: number;
    tabs = [];

    galleryImage: string;
    showPopup: boolean = false;

    loading: boolean = true;

    popUpData: any;

    constructor(
        private auth: AuthService,
        private http: HttpClient,
        public storage: AngularFireStorage) { }

    ngOnInit() {
        this.userSubscription = this.auth.onUserUpdate.subscribe(userData => {
            if (userData != null && userData) this.loadChildData(userData);
        }, error => {
            console.log('error', error);
        });
    }

    userSubscription: Subscription;

    loadChildData(userData) {
        this.tabs = [];
        this.userInfo = userData;
        this.tabCount = Math.ceil(this.userInfo.gallery.length / 12);
        for (let i = 0; i < this.tabCount; i++) {
            let inarray = [];
            for (let j = (12 * i); j < (12 * i) + 12; j++) {
                if (this.userInfo.gallery[j]) {
                    inarray.push(this.userInfo.gallery[j]);
                }
            }
            this.tabs.push(inarray)
        }
        this.setHoverClickEvents();
    }

    ngAfterViewInit(): void {
        this.setHoverClickEvents();
        setTimeout(() => {
            this.instructionLoaded();
        }, 3000);
    }

    setHoverClickEvents() {
        setTimeout(() => {
            var buttons = document.querySelectorAll('button');
            for (var i = 0; i < buttons.length; i++) {
                buttons[i].addEventListener('click', function (event) {
                    let audio = <HTMLAudioElement>document.getElementById('buttonClick');
                    if (audio) audio.play();
                });
                buttons[i].addEventListener('mouseover', function (event) {
                    let audio = <HTMLAudioElement>document.getElementById('buttonHover');
                    if (audio) audio.play();
                });
            }
            buttons = document.querySelectorAll('.gallery-item');
            for (var i = 0; i < buttons.length; i++) {
                buttons[i].addEventListener('click', function (event) {
                    let audio = <HTMLAudioElement>document.getElementById('buttonClick');
                    if (audio) audio.play();
                });
                buttons[i].addEventListener('mouseover', function (event) {
                    let audio = <HTMLAudioElement>document.getElementById('buttonHover');
                    if (audio) audio.play();
                });
            }
            buttons = document.querySelectorAll('.download-button');
            for (var i = 0; i < buttons.length; i++) {
                buttons[i].addEventListener('click', function (event) {
                    let audio = <HTMLAudioElement>document.getElementById('buttonClick');
                    if (audio) audio.play();
                });
                buttons[i].addEventListener('mouseover', function (event) {
                    let audio = <HTMLAudioElement>document.getElementById('buttonHover');
                    if (audio) audio.play();
                });
            }
        }, 100)
    }

    backToMenu() {
        this.previousState();
    }

    ngOnDestroy(): void {
        this.userSubscription.unsubscribe();
    }

    previousState() {
        window.history.back();
    }

    previous() {
        if (this.activeWindow > 1)
            this.activeWindow = this.activeWindow - 1
    }
    next() {
        if (this.activeWindow < this.tabCount)
            this.activeWindow = this.activeWindow + 1
    }

    openPopup(item: any, photoUrl: string) {
        this.popUpData = item;
        this.galleryImage = photoUrl;
        this.showPopup = true;
        this.setHoverClickEvents();
    }

    instructionLoaded() {
        if (this.loading) {
            this.loading = false;
        }
    }

    isMedium(character: string) {
        var middle = ['ب', 'پ'];

        return middle.indexOf(character) > -1;
    }

    isHigh(character: string) {
        var bottom = ['ج', 'چ', 'ح', 'خ', 'ر', 'ز', 'ع', 'غ', 'م', 'ن', 'و', 'ی', 'ي', 'س', 'س', 'ش', 'ص', 'ض'];

        return bottom.indexOf(character) > -1;
    }

    downloadImage(imageUrl) {
        this.getBase64ImageFromURL(imageUrl).subscribe((base64data) => {
            this.base64Image = 'data:image/jpg;base64,' + base64data;
            // save image to disk
            var link = document.createElement('a');

            document.body.appendChild(link); // for Firefox

            link.setAttribute('href', this.base64Image);
            link.setAttribute('download', (this.popUpData.countryTitle + ", " + this.popUpData.id + ".jpg") || "MyGallery.jpg");
            link.click();
        });
    }

    getBase64ImageFromURL(url: string) {
        return Observable.create((observer: Observer<string>) => {
            const img: HTMLImageElement = new Image();
            img.crossOrigin = 'anonymous';
            img.src = url;
            if (!img.complete) {
                img.onload = () => {
                    observer.next(this.getBase64Image(img));
                    observer.complete();
                };
                img.onerror = (err) => {
                    observer.error(err);
                };
            } else {
                observer.next(this.getBase64Image(img));
                observer.complete();
            }
        });
    }

    getBase64Image(img: HTMLImageElement) {
        const canvas: HTMLCanvasElement = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx: CanvasRenderingContext2D = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);
        const dataURL: string = canvas.toDataURL('image/png');

        return dataURL.replace(/^data:image\/(png|jpg);base64,/, '');
    }
}
