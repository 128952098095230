import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoggerService {

  constructor() { }

  log(...args) {
    this.apply('log', args);
  }

  info(...args) {
    this.apply('info', args);
  }

  warn(...args) {
    this.apply('warn', args);
  }

  private apply(type, args) {
    if(!environment.production) {
      window.console[type].apply(null, args);
    }
  }
}
