var trainGame;
var game;

var loadTrain = (json) => {
    (function (trainGame) {
        class App extends Phaser.Game {
            constructor(json) {
                App.json = json;
                let config = {
                    type: Phaser.AUTO,
                    width: App.CANVAS_WIDTH,
                    height: App.CANVAS_HEIGHT,
                    parent: 'game-container',
                    dom: {
                        createContainer: false
                    },
                    scale: {
                        mode: FitScaleManager.detectBestScaleMode(),
                        autoCenter: Phaser.Scale.CENTER_BOTH
                    },
                    transparent: true,
                    scene: {
                        create: () => {
                            this.scene.add('Boot', trainGame.scene.Boot, true);
                        }
                    }
                };
                super(config);
            }
        }

        App.CANVAS_WIDTH = 980;
        App.CANVAS_HEIGHT = 600;
        trainGame.App = App;
    })(trainGame || (trainGame = {}));

    var delayedCalls = [];

    function delayedCall(delay, callback, args, callbackScope) {
        let scene = game.scene.getAt(0);
        if (scene) {
            let dc = scene.time.delayedCall(delay, callback, args, callbackScope);
            delayedCalls.push(dc);
            return dc;
        }
        let t = setTimeout(callback, delay);
        delayedCalls.push(t);
        return t;
    }

    function pauseAllDelayedCalls() {
        for (let dc of delayedCalls) {
            if (dc instanceof Phaser.Time.TimerEvent) {
                dc.paused = true;
            }
        }
    }

    function resumeAllDelayedCalls() {
        for (let dc of delayedCalls) {
            if (dc instanceof Phaser.Time.TimerEvent) {
                dc.paused = false;
            }
        }
    }

    function destroyAllDelayedCalls() {
        for (let dc of delayedCalls) {
            if (dc instanceof Phaser.Time.TimerEvent) {
                dc.remove(false);
            } else {
                clearTimeout(dc);
            }
        }
        delayedCalls = [];
    }

    function setPageBackground(bg) {
        document.querySelector("html").style.backgroundImage = "url(assets/games/load-train/imgs/" + bg + ".jpg)";
    }

    function setupButton(btn, frame) {
        btn.on('pointerdown', () => {
            btn.setFrame(frame + '_hover' + '0000');
        });
        btn.on('pointerover', () => {
            btn.setFrame(frame + '_hover' + '0000');
            game.scene.getAt(0).sound.add("button hover").play();
        });
        btn.on('pointerout', () => {
            btn.setFrame(frame + '0000');
        });
        btn.on('pointerup', () => {
            btn.setFrame(frame + '0000');
            game.scene.getAt(0).sound.add('activity selection - button selection').play();
        });
    }

    function setupButtonTextureBased(btn, texture, hoverTexture) {
        btn.on('pointerdown', () => {
            btn.setTexture(hoverTexture);
        });
        btn.on('pointerover', () => {
            btn.setTexture(hoverTexture);
            game.scene.getAt(0).sound.add("button hover").play();
        });
        btn.on('pointerout', () => {
            btn.setTexture(texture);
        });
        btn.on('pointerup', () => {
            btn.setTexture(texture);
            game.scene.getAt(0).sound.add('activity selection - button selection').play();
        });
    }

    function playBtnClickAnim(target) {
        let sc = target.hasOwnProperty("defScale") ? target["defScale"] : 1;
        target.scaleX = target.scaleY = sc;
        let scene = game.scene.getAt(0);
        scene.tweens.add({
            targets: target,
            "scaleX": 0.9 * sc,
            "scaleY": 0.9 * sc,
            duration: 100,
            yoyo: true
        });
    }

    /**
     * @author Roman Parada
     * This class is created to fix overlapping of bottom part of canvas by navigation bar in iOS.
     * It make a delayed resize of the canvas (like Phaser-3 FIT methods does) and it overrides Phaser-3 input window to Phaser-3 core transform methods
     *
     * How to use:
     * Just call the code line below in Boot scene's init() method of your project:
     * new FitScaleManager(this.game).setup();
     */
    class FitScaleManager {
        constructor(game) {
            this.doResize = () => {
                let scale = this.calculateScale();
                let newCanvasWidth = this.phaserScaleManager.width * scale;
                let newCanvasHeight = this.phaserScaleManager.height * scale;
                this.canvasStyle.width = newCanvasWidth + 'px';
                this.canvasStyle.height = newCanvasHeight + 'px';
                this.canvasStyle.marginLeft = (window.innerWidth - newCanvasWidth) / 2 + 'px';
                this.canvasStyle.marginTop = (window.innerHeight - newCanvasHeight) / 2 + 'px';
            };
            this.game = game;
            this.canvasStyle = this.game.canvas.style;
            this.phaserScaleManager = this.game.scale;
        }

        static detectBestScaleMode() {
            let iOS = /iPad|iPhone|iPod/.test(navigator.platform || "");
            let isAndroid = window.navigator.userAgent.toLowerCase().indexOf("android") > -1;
            return iOS || isAndroid ? Phaser.Scale.FIT : Phaser.Scale.NONE;
        }
        ;

        /**
         * Just call this method once in Boot scene's init() method
         */
        setup() {
            this.phaserScaleManager.addListener(Phaser.Scale.Events.RESIZE, this.onResize, this);
            this.overridePhaserTransformMethods();
            this.onResize();
        }

        calculateScale() {
            if (game.scale.scaleMode == Phaser.Scale.NONE)
                return 1;
            return Math.min(window.innerWidth / this.phaserScaleManager.width, window.innerHeight / this.phaserScaleManager.height);
        }

        overridePhaserTransformMethods() {
            this.game.scale.transformX = (pageX) => {
                return (pageX - parseInt(this.canvasStyle.marginLeft.split("px")[0])) / this.calculateScale();
            };
            this.game.scale.transformY = (pageY) => {
                return (pageY - parseInt(this.canvasStyle.marginTop.split("px")[0])) / this.calculateScale();
            };
        }

        onResize() {
            setTimeout(this.doResize, FitScaleManager.RESIZE_DELAY);
        }
    }

    FitScaleManager.RESIZE_DELAY = 500;

    /* Gameplay */
    (function (trainGame) {
        var core;
        (function (core) {
            class Gameplay {
                constructor() {
                    this.allLettersNames = null;
                    this.failsNumToLose = 3;
                    this.currentRound = 0;
                    this.correctAnswersCount = 0;
                    this.wrongAnswersCount = 0;
                    this.correctAnswersCountThisRound = 0;
                    this.wrongAnswersCountThisRound = 0;
                    this.onNewRound = null;
                    this.nextLetterDelay = 0;
                    this.failsNumToLose = Number(game.cache.json.get('gameplay')["failsNumToLose"]);
                    this.useImages = Boolean(game.cache.json.get('gameplay')["useImages"]);
                }

                setupCallbacks(onComplete, onLose, onNewRound) {
                    this.onComplete = onComplete;
                    this.onLose = onLose;
                    this.onNewRound = onNewRound;
                }

                onLetterChosen() {
                    this.currentRound++;
                    if (this.correctAnswersCount >= this.totalRoundsNum) {
                        this.onComplete(this.correctAnswersCount, this.correctAnswersCount);
                        return true;
                    } else {
                        this.nextLetter();
                    }
                    return false;
                }

                nextLetter() {
                    let fn = () => {
                        this.randomize();
                        this.correctAnswersCountThisRound = 0;
                        this.wrongAnswersCountThisRound = 0;
                        if (this.onNewRound)
                            this.onNewRound();
                    };
                    if (this.nextLetterDelay == 0) {
                        fn();
                    } else {
                        delayedCall(this.nextLetterDelay, fn);
                    }
                }

                readWord(word) {
                    let d = 1400;
                    delayedCall(d, () => {
                        game.scene.getScene('ScreenMain').sound.add(word).play();
                    });
                    return 6500;
                }

                getCorrectLetterName(index) {
                    return this.roundLetterNames[index];
                }

                getCorrectAudioKey(index) {
                    return this.lettersAudioKeys[index];
                }

                onCorrectAnswer() {
                    this.correctAnswersCount++;
                    this.correctAnswersCountThisRound++;
                    this.nextLetterDelay = this.readWord(this.correctWord);
                    return this.onLetterChosen();
                }

                onWrongAnswer() {
                    this.wrongAnswersCount++;
                    this.wrongAnswersCountThisRound++;
                    this.nextLetterDelay = 1000;
                    if (this.wrongAnswersCount >= this.failsNumToLose) {
                        this.onLose(0, 0);
                        return true;
                    } else {
                        console.log('Wrong letter chosen');
                        // this.onLetterChosen();
                    }
                    return false;
                }

                getCurrentTotalAnswersCount() {
                    return this.correctAnswersCount + this.wrongAnswersCount;
                }

                getCurrentTotalAnswersCountThisRound() {
                    return this.correctAnswersCountThisRound + this.wrongAnswersCountThisRound;
                }

                isNewRound() {
                    return this.getCurrentTotalAnswersCountThisRound() == 0;
                }

                isRoundsComplete() {
                    return this.currentRound >= this.totalRoundsNum;
                }

                randomize() {
                    let round = this.rounds[this.currentRound];
                    this.correctWord = round["correctWord"];
                    if (this.wordsLetters === undefined) {
                        this.roundLetterNames = this.correctWord.split("");
                    } else {
                        this.roundLetterNames = this.wordsLetters[this.correctWord];
                    }
                    this.roundLetterNames = this.roundLetterNames.reverse();
                    this.lettersAudioKeys = [];
                    for (let i = 0; i < this.roundLetterNames.length; i++) {
                        this.lettersAudioKeys.push(this.getAudioKeyOfChar(this.roundLetterNames[i]));
                    }
                    this.roundWords = Phaser.Utils.Array.Shuffle([this.correctWord, round["wrongWord1"], round["wrongWord2"]]);
                }

                getAudioKeyOfChar(letter) {
                    let json = game.cache.json.get('gameplay');
                    let letters = json["letters"];
                    for (let d of letters) {
                        if (letter == d['letterName']) {
                            return d['audioKey'];
                        }
                    }
                    return null;
                }

                reset() {
                    this.setupCallbacks(null, null, null);
                    this.correctWord = null;
                    let json = JSON.parse(JSON.stringify(game.cache.json.get('gameplay')));

                    this.wordsLetters = json["wordsLetters"];

                    this.letters = json["letters"].slice();
                    this.allLettersNames = [];
                    for (let d of this.letters)
                        this.allLettersNames.push(d["letterName"]);
                    this.rounds = JSON.parse(JSON.stringify(json["rounds"]));
                    this.totalRoundsNum = this.rounds.length;
                    this.currentRound = 0;
                    this.correctAnswersCount = 0;
                    this.wrongAnswersCount = 0;
                    this.correctAnswersCountThisRound = 0;
                    this.wrongAnswersCountThisRound = 0;
                    this.nextLetterDelay = 0;
                    this.nextLetter();
                }
            }

            core.Gameplay = Gameplay;
        })(core = trainGame.core || (trainGame.core = {}));
    })(trainGame || (trainGame = {}));

    /* Boot */
    (function (trainGame) {
        var scene;
        (function (scene) {
            class Boot extends Phaser.Scene {
                init() {
                    this.game.scale.transformX = (pageX) => {
                        let offsetLeft = 0;
                        let parentElement = game.canvas.parentElement;
                        while (parentElement) {
                            if (parentElement.offsetLeft) {
                                offsetLeft = parentElement.offsetLeft;
                                break;
                            }
                            parentElement = parentElement.parentElement;
                        }
                        return (pageX - offsetLeft) * this.game.scale.displayScale.x;
                    };
                    this.game.scale.transformY = (pageY) => {
                        let offsetTop = 0;
                        let parentElement = game.canvas.parentElement;
                        while (parentElement) {
                            if (parentElement.offsetTop) {
                                offsetTop = parentElement.offsetTop;
                                break;
                            }
                            parentElement = parentElement.parentElement;
                        }
                        return (pageY - offsetTop) * this.game.scale.displayScale.y;
                    };
                }

                create() {
                    game.scene.remove('Boot');
                    game.scene.add('Preloader', trainGame.scene.Preloader, true);
                }
            }

            scene.Boot = Boot;
        })(scene = trainGame.scene || (trainGame.scene = {}));
    })(trainGame || (trainGame = {}));

    /* Preloader */
    (function (trainGame) {
        var scene;
        (function (scene) {
            class Preloader extends Phaser.Scene {
                preload() {
                    if (typeof trainGame.App.json == "string") this.load.json('gameplay', trainGame.App.json); else this.game.cache.json.add('gameplay', trainGame.App.json);
                }

                create() {
                    let json = game.cache.json.get('gameplay');
                    let words = json["words"];
                    if (json["useImages"]) {
                        let rounds = JSON.parse(JSON.stringify(json["rounds"]));
                        for (let i = 0; i < rounds.length; i++) {
                            let w = rounds[i];
                            this.load.image(w.correctWord, "assets/games/load-train/imgs/words/" + w.correctWord + ".png");
                            this.load.image(w.wrongWord1, "assets/games/load-train/imgs/words/" + w.wrongWord1 + ".png");
                            this.load.image(w.wrongWord2, "assets/games/load-train/imgs/words/" + w.wrongWord2 + ".png");
                        }
                        for (let l of json["letters"]) {
                            this.load.image(l["letterName"], "assets/games/load-train/imgs/letters/" + l["letterName"] + ".png");
                        }
                    }
                    for (let l of json["letters"]) {
                        this.load.audio(l["audioKey"], "assets/games/load-train/sound/mp3/letters/" + l["audioKey"] + ".mp3");
                    }
                    for (let w of words) {
                        this.load.audio(w, "assets/games/load-train/sound/mp3/words/" + w + ".mp3");
                    }
                    this.load.pack('preloader', 'assets/games/load-train/pack.json');
                    let progressTxt = this.add.text(game.scale.width / 2, game.scale.height / 2 + 50, "", {
                        "fontFamily": "QuranErafont",
                        "fontSize": 25,
                        "color": "#000000",
                        "align": 'center'
                    });
                    this.load.on('progress', (value) => {
                        progressTxt.text = Math.ceil(value * 100) + "%";
                    }, this);
                    this.load.on('complete', () => {
                        this.nextScene();
                    });
                    this.load.start();
                }

                static playAnim(animKey, sprite, onComplete = null) {
                    let mainScene = game.scene.getScene('ScreenMain');
                    if (!mainScene.anims.exists(animKey)) {
                        let data = Preloader.ANIMS_DATA[animKey];
                        mainScene.anims.create({
                            key: animKey,
                            frames: mainScene.anims.generateFrameNames(data['atlas'], {
                                start: data['start'], end: data['end'], zeroPad: data['padNum'],
                                prefix: data['prefix'], suffix: data['suffix'] || ''
                            }),
                            frameRate: data['frameRate'],
                            repeat: data['repeat']
                        });
                    }
                    if (sprite.anims.currentAnim) {
                        sprite.anims.currentAnim.off('complete');
                    }
                    sprite.anims.stop();
                    sprite.play(animKey);
                    sprite.anims.currentAnim.once('complete', () => {
                        if (onComplete)
                            onComplete();
                    });
                    return sprite;
                }

                nextScene() {
                    game.scene.remove('Preloader');
                    game.scene.add('ScreenMain', trainGame.scene.MainScene, true);
                }
            }

            Preloader.ANIMS_DATA = {
                'horse-iddle': {
                    'start': 1,
                    'end': 48,
                    'padNum': 4,
                    'prefix': 'iddle',
                    'suffix': '.png',
                    'repeat': -1,
                    'frameRate': 24,
                    'atlas': 'horse-iddle-atlas'
                },
                'horse-yes': {
                    'start': 1,
                    'end': 72,
                    'padNum': 4,
                    'prefix': 'Yes v4 ',
                    'suffix': '.png',
                    'repeat': 0,
                    'frameRate': 24,
                    'atlas': 'horse-yes-atlas'
                },

                'train-stop': {
                    'start': 1,
                    'end': 1,
                    'padNum': 4,
                    'prefix': 'Train looping ',
                    'suffix': '.png',
                    'repeat': -1,
                    'frameRate': 24,
                    'atlas': 'train-atlas'
                },

                'train-moving': {
                    'start': 1,
                    'end': 16,
                    'padNum': 4,
                    'prefix': 'Train looping ',
                    'suffix': '.png',
                    'repeat': -1,
                    'frameRate': 24,
                    'atlas': 'train-atlas'
                },

                'smoke-off': {
                    'start': 1,
                    'end': 16,
                    'padNum': 4,
                    'prefix': 'Smoke off ',
                    'suffix': '.png',
                    'repeat': 0,
                    'frameRate': 24,
                    'atlas': 'smoke-off-atlas'
                },

                'smoke': {
                    'start': 1,
                    'end': 36,
                    'padNum': 4,
                    'prefix': 'Smoke looping on the way ',
                    'suffix': '.png',
                    'repeat': -1,
                    'frameRate': 24,
                    'atlas': 'smoke-atlas'
                },
            };
            scene.Preloader = Preloader;
        })(scene = trainGame.scene || (trainGame.scene = {}));
    })(trainGame || (trainGame = {}));

    /* MainScene */
    (function (trainGame) {
        var scene;
        (function (scene) {
            var Gameplay = trainGame.core.Gameplay;

            class MainScene extends Phaser.Scene {
                create() {
                    this.gameplay = new Gameplay();
                    this.gameplayScreen = new trainGame.screen.GameplayScreen(this, this.gameplay);
                    this.children.add(this.gameplayScreen);
                    this.gameplayScreen.showInstructionPage();
                }
            }

            scene.MainScene = MainScene;
        })(scene = trainGame.scene || (trainGame.scene = {}));
    })(trainGame || (trainGame = {}));

    /* Small Claw */
    (function (trainGame) {
        var screen;
        (function (screen) {
            class SmallClaw extends Phaser.GameObjects.Container {
                constructor(scene, gameplayScreen, buttonIndex) {
                    super(scene);
                    this.buttonIndex = buttonIndex;
                    this.gameplayScreen = gameplayScreen;
                    this.boxDropped = false;

                    this.clawClosed = new Phaser.GameObjects.Image(this.scene, 0, 0, 'claw-closed');
                    this.add(this.clawClosed);

                    this.clawOpen = new Phaser.GameObjects.Image(this.scene, 0, 5, 'claw-open');
                    this.add(this.clawOpen);

                    this.smallBox = new screen.SmallBox(scene, gameplayScreen, buttonIndex);
                    this.smallBox.setPosition(0, 110);
                    this.add(this.smallBox);

                    this.dropButton = new Phaser.GameObjects.Image(this.scene, -1.5, 23, 'drop-button');
                    this.dropButton.disableInteractive();
                    this.dropButton["defScale"] = this.dropButton.scale;
                    setupButtonTextureBased(this.dropButton, 'drop-button', 'drop-button-hover');
                    this.dropButton.on('pointerup', () => {
                        this.dropBox();
                    });
                    this.add(this.dropButton);

                    this._HandIcon = this.scene.add.image(10, 67, 'Hand Icon');
                    this._HandIcon.setOrigin(0, 1);
                    this._HandIcon.scale = 0.5;
                    this._HandIcon.visible = true;
                    this.add(this._HandIcon);
                }

                dropBox() {
                    this.reset();

                    delayedCall(500, () => this.smallBox.playAudio());
                    let _dy = (this.smallBox.box.scaleY-1)/2*this.smallBox.box.height;
                    this.scene.tweens.add({
                        targets: this.smallBox,
                        y: 349 - _dy,
                        duration: 900,
                        ease: Phaser.Math.Easing.Bounce.Out,
                        onComplete: () => {
                            this.boxDropped = true;
                            this.gameplayScreen.trainContainer.add(this.smallBox);
                            this.smallBox.setPosition(this.x, this.y + 349 - _dy);
                            this.gameplayScreen.onSmallBoxDropped(this.smallBox.boxIndex);
                            this.smallBox._setInteractive();
                        }
                    });

                    delayedCall(300, () => {
                        this.clawClosed.visible = true;
                        this.clawOpen.visible = false;
                        this.scene.sound.add("Box falling").play();
                    });
                }

                reset() {
                    this.boxDropped = false;
                    this.add(this.smallBox);
                    let _dy = (this.smallBox.box.scaleY-1)/2*this.smallBox.box.height;
                    this.smallBox.setPosition(0, 110 + _dy);
                    this.clawClosed.visible = false;
                    this.clawOpen.visible = true;
                    this.smallBox.reset();
                    this.hideHandIcon();
                }

                showHandIconAnim() {
                    this.dropButton.setInteractive({ cursor: 'pointer', pixelPerfect: true });
                    this._HandIcon.visible = true;
                    this._HandIcon.angle = 0;
                    this._t = this.scene.tweens.add({
                        targets: this._HandIcon,
                        "angle": 10,
                        yoyo: true,
                        repeat: -1,
                        duration: 500
                    });
                }

                hideHandIcon() {
                    this.dropButton.disableInteractive();
                    this._HandIcon.visible = false;
                    if (this._t) this.scene.tweens.remove(this._t);
                    this._t = null;
                }
            }

            screen.SmallClaw = SmallClaw;
        })(screen = trainGame.screen || (trainGame.screen = {}));
    })(trainGame || (trainGame = {}));

    /* Small Box */
    (function (trainGame) {
        var screen;
        (function (screen) {
            class SmallBox extends Phaser.GameObjects.Container {
                constructor(scene, gameplayScreen, boxIndex) {
                    super(scene);
                    this.gameplayScreen = gameplayScreen;
                    this.boxIndex = boxIndex;

                    this.box = new Phaser.GameObjects.Image(this.scene, 0, 0, 'small-box');
                    this.box.setInteractive({ cursor: 'pointer' });
                    this.box.scaleY =  game.cache.json.get('gameplay')["smallBoxScaleY"] ?? 1.25;
                    this.box["defScale"] = this.box.scale;
                    setupButtonTextureBased(this.box, 'small-box', 'small-box-hover');
                    this.box.on('pointerup', () => {
                        this.playAudio();
                    });
                    this.add(this.box);

                    if (this.gameplayScreen.gameplay.useImages) {
                        this.wordText = new Phaser.GameObjects.Image(this.scene, 0, 0, null);
                        this.add(this.wordText);
                    } else {
                        this.wordText = this.scene.add.text(0, 0, "", {
                            "fontFamily": "QuranErafont",
                            "fontSize": 100,
                            "color": "#000000",
                            "align": 'center',
                            'stroke': '#FFFFFF',
                            'strokeThickness': 7
                        });
                        this.wordText.setOrigin(0.5, 0.5);
                        this.wordText.style.fixedHeight = 150;
                        this.add(this.wordText);
                    }
                }

                playAudio() {
                    this.gameplayScreen.playCorrectAudio(this.boxIndex);
                }

                setup(letter) {
                    this.letter = letter;
                    if (this.gameplayScreen.gameplay.useImages) {
                        this.wordText.setTexture(this.letter);
                        this.wordText.setScale(0.8);
                    } else {
                        this.wordText.setText(this.letter);
                    }
                    this._disableInteractive();
                }

                _setInteractive() {
                    this.box.setInteractive({ cursor: 'pointer' });
                }

                _disableInteractive() {
                    this.box.disableInteractive();
                }

                reset() {
                }
            }

            screen.SmallBox = SmallBox;
        })(screen = trainGame.screen || (trainGame.screen = {}));
    })(trainGame || (trainGame = {}));

    /* Big Claw */
    (function (trainGame) {
        var screen;
        (function (screen) {
            class BigClaw extends Phaser.GameObjects.Container {
                constructor(scene, gameplayScreen, clawIndex) {
                    super(scene);
                    this.clawIndex = clawIndex;
                    this.gameplayScreen = gameplayScreen;

                    this.claw = new Phaser.GameObjects.Image(this.scene, 0, 0, 'big-claw');
                    this.add(this.claw);

                    this.bigBox = new screen.BigBox(scene, gameplayScreen, clawIndex);
                    this.bigBox.setPosition(0, 80);
                    this.add(this.bigBox);
                }

                setInitialPosition(x, y) {
                    this.initialPosition = { x: x, y: y };
                    this.setPosition(x, y);
                }

                dropBox(onCompleteCallback) {
                    this.add(this.bigBox);
                    this.bigBox.setPosition(0, 80);

                    this.scene.tweens.add({
                        targets: this.bigBox,
                        y: 298,
                        duration: 950,
                        ease: Phaser.Math.Easing.Bounce.Out,
                        onComplete: () => {
                            this.gameplayScreen.trainContainer.add(this.bigBox);
                            this.bigBox.setPosition(125, 382);
                            if (onCompleteCallback) onCompleteCallback();
                        }
                    });
                    delayedCall(400, () => {
                        this.scene.sound.add("Box falling").play();
                    });
                }

                reset() {
                    this.setPosition(this.initialPosition.x, this.initialPosition.y);
                    this.add(this.bigBox);
                    this.bigBox.setPosition(0, 80);
                    this.bigBox.reset();
                }
            }

            screen.BigClaw = BigClaw;
        })(screen = trainGame.screen || (trainGame.screen = {}));
    })(trainGame || (trainGame = {}));

    /* Big Box */
    (function (trainGame) {
        var screen;
        (function (screen) {
            class BigBox extends Phaser.GameObjects.Container {
                constructor(scene, gameplayScreen, boxIndex) {
                    super(scene);
                    this.gameplayScreen = gameplayScreen;
                    this.boxIndex = boxIndex;

                    this.box = new Phaser.GameObjects.Image(this.scene, 0, 0, 'big-box');
                    this.box.setInteractive({ cursor: 'pointer' });
                    this.box["defScale"] = this.box.scale;
                    setupButtonTextureBased(this.box, 'big-box', 'big-box');
                    this.box.on('pointerup', () => {
                        this.gameplayScreen.onBigBoxClicked(this.boxIndex);
                    });
                    this.add(this.box);

                    this.boxOverlayGreen = new Phaser.GameObjects.Image(this.scene, 0, 0, 'big-box-green');
                    this.boxOverlayGreen.visible = false;
                    this.add(this.boxOverlayGreen);

                    this.boxOverlayRed = new Phaser.GameObjects.Image(this.scene, 0, 0, 'big-box-red');
                    this.boxOverlayRed.visible = false;
                    this.add(this.boxOverlayRed);

                    if (this.gameplayScreen.gameplay.useImages) {
                        this.wordText = new Phaser.GameObjects.Image(this.scene, 0, 0, null);
                        this.add(this.wordText);
                    } else {
                        this.wordText = this.scene.add.text(0, 0, "", {
                            "fontFamily": "QuranErafont",
                            "fontSize": 100,
                            "color": "#000000",
                            "align": 'center',
                            'stroke': '#FFFFFF',
                            'strokeThickness': 7
                        });
                        this.wordText.setOrigin(0.5, 0.5);
                        this.wordText.style.fixedHeight = 150;
                        this.add(this.wordText);
                    }
                }

                setup(word, isCorrect) {
                    this.word = word;
                    this.isCorrect = isCorrect;
                    if (this.gameplayScreen.gameplay.useImages) {
                        this.wordText.setTexture(word);
                        this.wordText.setScale(1.0);
                    } else {
                        this.wordText.setText(this.word);
                    }

                    this.reset();
                }

                turnGreen() {
                    this.boxOverlayGreen.visible = true;
                    this.boxOverlayRed.visible = false;
                    this._disableInteractive();
                }

                turnRed() {
                    this.boxOverlayGreen.visible = false;
                    this.boxOverlayRed.visible = true;
                    this._disableInteractive();
                }

                _setInteractive() {
                    this.box.setInteractive({ cursor: 'pointer' });
                }

                _disableInteractive() {
                    this.box.disableInteractive();
                }

                reset() {
                    this.boxOverlayGreen.visible = false;
                    this.boxOverlayRed.visible = false;
                    this._setInteractive();
                }
            }

            screen.BigBox = BigBox;
        })(screen = trainGame.screen || (trainGame.screen = {}));
    })(trainGame || (trainGame = {}));

    /* Gameplay Screen */
    (function (trainGame) {
        var screen;
        var Preloader = trainGame.scene.Preloader;
        (function (screen) {
            class GameplayScreen extends Phaser.GameObjects.Container {
                constructor(scene, gameplay) {
                    super(scene);
                    this.bgMusic = null;
                    this.firstTallyDefPos = { x: 60, y: 565 };
                    this.soundGooseYes = null;
                    this.soundGooseNo = null;
                    this.correctAudio = null;
                    this.wfsnd = null;
                    this.showCompleteWindow = (score, starScore) => {
                        let completeWindow = new screen.CompleteWindow(this.scene, (target) => {
                            playBtnClickAnim(target);
                        }, (target) => {
                            playBtnClickAnim(target);
                            this.destroyGameplay();
                            this.remove(completeWindow);
                            this.showInstructionPage();
                        }, (target) => {
                            playBtnClickAnim(target);
                        });
                        delayedCall(10000, () => {
                            setPageBackground("bg-blue");
                            this.add(completeWindow);
                            completeWindow.show(score, starScore);
                            this.bgMusic.stop();
                        });
                    };
                    this.showLoseWindow = (score, starScore) => {
                        let tryAgainWindow = new screen.TryAgainWindow(this.scene, (target) => {
                            playBtnClickAnim(target);
                        }, (target) => {
                            playBtnClickAnim(target);
                            this.destroyGameplay();
                            this.remove(tryAgainWindow);
                            this.showInstructionPage();
                        });
                        delayedCall(750, () => {
                            setPageBackground("bg-blue");
                            this.add(tryAgainWindow);
                            tryAgainWindow.show(score, starScore);
                            this.bgMusic.stop();
                        });
                    };
                    this.gameplay = gameplay;
                    window["gs"] = this;
                }

                showGameplay() {
                    setPageBackground("bg-australia");
                    this.bgMusic = this.scene.sound.add("Bachground ambience");
                    this.bgMusic.play();
                    this.bgMusic.loop = true;

                    this.gameplayContainer = new Phaser.GameObjects.Container(this.scene);
                    this.add(this.gameplayContainer);

                    this._gameStage = new Phaser.GameObjects.Image(this.scene, game.scale.width / 2, game.scale.height / 2, 'BG');
                    this._gameStage.setOrigin(0.5, 0.5);
                    this._gameStage.setInteractive();
                    this.gameplayContainer.add(this._gameStage);

                    /* init gameplay */
                    this.gameplay.reset();

                    /* train and horse */
                    this.trainContainer = new Phaser.GameObjects.Container(this.scene);
                    this.gameplayContainer.add(this.trainContainer);

                    this.smoke = this.scene.add.sprite(0, 0, 'smoke');
                    this.smoke.setOrigin(0, 0);
                    this.trainContainer.add(this.smoke);
                    Preloader.playAnim('smoke', this.smoke);

                    this.train = this.scene.add.sprite(0, 0, 'train-atlas', 'Train looping 0001.png');
                    this.train.setOrigin(0, 0);
                    this.trainContainer.add(this.train);
                    Preloader.playAnim('train-stop', this.train);

                    this.horse = this.scene.add.sprite(0, 0, 'horse-iddle-atlas', 'iddle0001.png');
                    this.horse.setOrigin(0, 0);
                    this.trainContainer.add(this.horse);
                    Preloader.playAnim('horse-iddle', this.horse);

                    /* small claws */
                    this.smallClawsContainer = new Phaser.GameObjects.Container(this.scene);
                    this.gameplayContainer.add(this.smallClawsContainer);

                    this.smallClaws = [];
                    for (let i = 0; i < 3; i++) {
                        const claw = new screen.SmallClaw(this.scene, this, i);
                        claw.setPosition(313 + i * 137, 80);
                        this.smallClawsContainer.add(claw);
                        this.smallClaws.push(claw);
                        claw.reset();
                    }

                    /* Big Claws */

                    this.bigClawsContainer = new Phaser.GameObjects.Container(this.scene);
                    this.gameplayContainer.add(this.bigClawsContainer);

                    this.bigClaws = [];
                    for (let i = 0; i < 3; i++) {
                        const claw = new screen.BigClaw(this.scene, this, i);
                        claw.setInitialPosition(200 + i * 250, 85);
                        this.bigClawsContainer.add(claw);
                        this.bigClaws.push(claw);
                        claw.reset();
                    }

                    /* UI buttons */
                    this._btnClose = new Phaser.GameObjects.Image(this.scene, 932, 47, 'x Button');
                    this._btnClose.setInteractive({ cursor: 'pointer' });
                    this._btnClose["defScale"] = this._btnClose.scale;
                    setupButtonTextureBased(this._btnClose, 'x Button', 'x Button HOVER EFFECT');
                    this.gameplayContainer.add(this._btnClose);

                    this._btnSound = new Phaser.GameObjects.Image(this.scene, 52, 47, 'Sound');
                    this._btnSound.setInteractive({ cursor: 'pointer' });
                    this._btnSound["defScale"] = this._btnSound.scale;
                    setupButtonTextureBased(this._btnSound, 'Sound', 'Sound HOVER EFFECT');
                    this.gameplayContainer.add(this._btnSound);

                    this._btnClose.on('pointerup', () => {
                        playBtnClickAnim(this._btnClose);
                        this.onCloseClick();
                    });
                    this._btnSound.on('pointerup', () => {
                        playBtnClickAnim(this._btnSound);
                        this.scene.sound.play("Load the Train - Question Audio");
                    });

                    this.gameplay.setupCallbacks(this.showCompleteWindow, this.showLoseWindow, () => {
                        this.onNewRound(true);
                    });

                    this.tallies = [];
                    this.talliesContainer = new Phaser.GameObjects.Container(this.scene);
                    this.talliesContainer.setPosition(30, 565);
                    this.add(this.talliesContainer);

                    this.tallyBar = this.scene.add.image(0, 0, 'tally-bar');
                    this.tallyBar.setOrigin(0, 0.5);
                    this.tallyBar.scaleX = 1.36;
                    this.talliesContainer.add(this.tallyBar);

                    for(let i = 0; i < this.gameplay.totalRoundsNum+this.gameplay.failsNumToLose; i++) {
                        const tally = this.scene.add.image(25 + i * 40, 0, 'tally-correct');
                        this.tallies.push(tally);
                        this.talliesContainer.add(tally);
                    }

                    /* launch round */
                    this.startRound();
                }

                updateTalliesVisibility() {
                    this.tallies.forEach(tally => tally.visible = false);
                    for (let i = 0; i < this.gameplay.getCurrentTotalAnswersCount(); i++) {
                        this.tallies[i].visible = true;
                    }
                }

                startRound() {
                    if (this.gameplay.isRoundsComplete()) {
                        return;
                    }
                    this.resetAll();
                    this.smallClaws.forEach(claw => claw.smallBox.setup(this.gameplay.roundLetterNames[claw.smallBox.boxIndex]));
                    this.bigClaws.forEach(claw => claw.bigBox.setup(this.gameplay.roundWords[claw.bigBox.boxIndex], this.gameplay.roundWords[claw.bigBox.boxIndex] == this.gameplay.correctWord));

                    this.updateTalliesVisibility();

                    this.playTrainArrivingAnim(() => {
                        this.playSmallClawsAppearingAnim(() => {
                            this.smallClaws[2].showHandIconAnim();
                        });
                    });
                }

                resetAll() {
                    this.smallClawsContainer.visible = false;
                    this.bigClawsContainer.visible = false;
                    this.trainContainer.x = -1000;
                    this.smallClaws.forEach(claw => claw.reset());
                    this.bigClaws.forEach(claw => claw.reset());
                }

                onBigBoxClicked(boxIndex) {
                    const clickedBox = this.bigClaws[boxIndex].bigBox;
                    if (clickedBox.isCorrect) {
                        clickedBox.turnGreen();
                        this.onCorrectAnswer();
                        this.bigClaws.forEach(claw => claw.bigBox._disableInteractive());
                        this.playBigClawsCorrectSelectionAnim(boxIndex, () => {
                            delayedCall(1000, () => {
                                this.playHorseYesAnim();
                                delayedCall(3000, () => {
                                    this.playTrainDepartureAnim(() => {
                                    });
                                });
                            });
                        });

                    } else {
                        clickedBox.turnRed();
                        this.onWrongAnswer();
                    }
                }

                onSmallBoxDropped(boxIndex) {
                    const firstDroppedClawIndex = this.smallClaws.findIndex(claw => claw.boxDropped);
                    if (firstDroppedClawIndex > 0) {
                        const nextClawToDrop = this.smallClaws[firstDroppedClawIndex - 1];
                        nextClawToDrop.showHandIconAnim();
                    }
                    if (this.smallClaws.every(claw => claw.boxDropped)) {
                        this.playSmallClawsDisappearingAnim(() => {
                            this.playBigClawsAppearingAnim(() => {
                                this.scene.sound.play("Load the Train - Question Audio");
                            })
                        })
                    }
                }

                playHorseYesAnim() {
                    Preloader.playAnim('horse-yes', this.horse, () => {
                        Preloader.playAnim('horse-iddle', this.horse);
                    });
                    this.soundGooseYes = this.scene.sound.add("Horse neigh and Yeah");//this.soundGooseYes = this.scene.sound.add("yes");
                    this.soundGooseYes.play();
                }

                playTrainArrivingAnim(onCompleteCallback) {
                    Preloader.playAnim('train-moving', this.train);
                    this.smoke.alpha = 1;
                    Preloader.playAnim('smoke', this.smoke);
                    this.trainContainer.x = -1000;
                    this.trainArrivingTween = this.scene.tweens.add({
                        targets: this.trainContainer,
                        x: 0,
                        duration: 4000,
                        ease: Phaser.Math.Easing.Sine.Out,
                        onComplete: () => {
                            Preloader.playAnim('train-stop', this.train);
                            this.scene.tweens.add({
                                targets: this.smoke,
                                alpha: 0,
                                duration: 200,
                                ease: Phaser.Math.Easing.Linear
                            });
                            if (onCompleteCallback) onCompleteCallback();
                        }
                    });
                    this.scene.sound.add("Train coming").play();
                }

                playTrainDepartureAnim(onCompleteCallback) {
                    /* tween smoke appearing */
                    this.smoke.alpha = 0;
                    Preloader.playAnim('smoke', this.smoke);
                    Preloader.playAnim('train-moving', this.train);

                    this.scene.tweens.add({
                        targets: this.smoke,
                        alpha: 1,
                        duration: 250,
                        ease: Phaser.Math.Easing.Linear
                    });
                    /* departure tween */
                    this.trainDepartureTween = this.scene.tweens.add({
                        targets: this.trainContainer,
                        x: 1000,
                        duration: 4000,
                        ease: Phaser.Math.Easing.Sine.In,
                        onComplete: () => {
                            if (onCompleteCallback) onCompleteCallback();
                        }
                    });

                    this.scene.sound.add("Train moving and leaving").play();
                }

                playSmallClawsAppearingAnim(onCompleteCallback) {
                    this.smallClawsContainer.visible = true;
                    this.bigClawsContainer.visible = false;
                    this.smallClaws.forEach((claw, index) => {
                        claw.y = -180;
                        this.scene.tweens.add({
                            targets: claw,
                            y: 80,
                            duration: 400,
                            delay: (2 - index) * 200,
                            ease: Phaser.Math.Easing.Cubic.Out,
                            onComplete: () => {
                                if (onCompleteCallback) onCompleteCallback();
                            }
                        });
                        // delayedCall((2 - index) * 200, () => {
                        this.scene.sound.add("Claws hanging").play();
                        // });
                    });
                }

                playSmallClawsDisappearingAnim(onCompleteCallback) {
                    this.smallClaws.forEach((claw, index) => {
                        this.scene.tweens.add({
                            targets: claw,
                            y: -180,
                            duration: 300,
                            delay: (2 - index) * 150,
                            ease: Phaser.Math.Easing.Cubic.In,
                            onComplete: () => {
                                if (index === 0 && onCompleteCallback) onCompleteCallback();
                            }
                        });
                    });
                }

                playBigClawsAppearingAnim(onCompleteCallback) {
                    this.smallClawsContainer.visible = false;
                    this.bigClawsContainer.visible = true;
                    this.bigClaws.forEach((claw, index) => {
                        claw.reset();
                        claw.y = -250;
                        this.scene.tweens.add({
                            targets: claw,
                            y: 85,
                            duration: 400,
                            delay: (2 - index) * 175,
                            ease: Phaser.Math.Easing.Sine.Out,
                            onComplete: () => {
                                if (index === 0 && onCompleteCallback) onCompleteCallback();
                            }
                        });
                        // delayedCall((2 - index) * 150, () => {
                        this.scene.sound.add("Claws going up").play();
                        // });
                    });
                }

                playBigClawsCorrectSelectionAnim(selectedClawIndex, onCompleteCallback) {
                    const targetClaw = this.bigClaws[selectedClawIndex];
                    const otherClaws = this.bigClaws.filter(claw => claw != targetClaw);
                    this.bigClawsContainer.visible = true;
                    otherClaws.forEach((claw, index) => {
                        this.scene.tweens.add({
                            targets: claw,
                            y: -250,
                            duration: 450,
                            delay: index * 150,
                            ease: Phaser.Math.Easing.Sine.In,
                            onComplete: () => { }
                        });
                    });

                    this.scene.tweens.add({
                        targets: targetClaw,
                        x: 125,
                        duration: 400 + Math.abs(targetClaw.x - 175) * 1.5,
                        delay: 300,
                        ease: Phaser.Math.Easing.Sine.InOut,
                        onComplete: () => {
                            targetClaw.dropBox(() => {
                                this.scene.tweens.add({
                                    targets: targetClaw,
                                    y: -250,
                                    duration: 450,
                                    delay: 0,
                                    ease: Phaser.Math.Easing.Sine.In,
                                    onComplete: () => { }
                                });
                                if (onCompleteCallback) onCompleteCallback();
                            });
                            this.scene.sound.add("Claws going up").play();
                        }
                    });
                    this.scene.sound.add("Claw moving").play();
                }

                onNewRound(showOut) {
                    if (showOut) {
                        delayedCall(4500, () => {
                            console.log("Starting new round...");
                            this.startRound();
                        });
                    }
                }

                onCorrectAnswer() {
                    let completed = this.gameplay.onCorrectAnswer();
                    this.scene.sound.add("Correct").play();
                    this.tallies[this.gameplay.getCurrentTotalAnswersCount() - 1].setTexture('tally-correct');
                    this.updateTalliesVisibility();
                    return completed;
                }

                onWrongAnswer() {
                    let lost = this.gameplay.onWrongAnswer();
                    this.soundGooseNo = this.scene.sound.add("Wrong attempt");
                    this.soundGooseNo.play();
                    this.tallies[this.gameplay.getCurrentTotalAnswersCount() - 1].setTexture('tally-wrong');
                    this.updateTalliesVisibility();
                    return lost;
                }

                playCorrectAudio(index) {
                    if (this.correctAudio) {
                        this.correctAudio.stop();
                    }
                    this.correctAudio = this.scene.sound.add(this.gameplay.getCorrectAudioKey(index));
                    this.correctAudio.play();
                    if (this.areYouSureWindow && this.areYouSureWindow.parentContainer == this) {
                        this.correctAudio.pause();
                    }
                }

                onCloseClick() {
                    this.showAreYouSurePage();
                    this.scene.sound.add('warning page pop up sfx').play();
                }

                showInstructionPage() {
                    setPageBackground("bg-blue");
                    let playInstructionSound = () => {
                        if (this.wfsnd) {
                            this.wfsnd.stop();
                        }
                        this.wfsnd = this.scene.sound.add("Introduction");
                        this.wfsnd.play();
                    };
                    this.instructionPage = new screen.InstructionPage(this.scene, (target) => {
                        playBtnClickAnim(target);
                        this.remove(this.instructionPage);
                        this.showGameplay();
                        if (this.wfsnd) {
                            this.wfsnd.stop();
                        }
                    }, (target) => {
                        playBtnClickAnim(target);
                        playInstructionSound();
                    });
                    this.add(this.instructionPage);
                    try {
                        playInstructionSound();
                    } catch (e) {
                        console.error(e);
                    }
                }

                showAreYouSurePage() {
                    pauseAllDelayedCalls();
                    setPageBackground("bg-blue");
                    this.pauseSounds();
                    this.areYouSureWindow = new screen.AreYouSureWindow(this.scene, () => {
                        this.remove(this.areYouSureWindow);
                        this.destroyGameplay();
                        this.showInstructionPage();
                    }, () => {
                        this.remove(this.areYouSureWindow);
                        this.unpauseSounds();
                        resumeAllDelayedCalls();
                        setPageBackground("bg-australia");
                    });
                    this.add(this.areYouSureWindow);
                }

                pauseSounds() {
                    this.scene.sound.pauseAll();
                }

                unpauseSounds() {
                    this.scene.sound.resumeAll();
                }

                destroyGameplay() {
                    this.scene.tweens.killAll();
                    this.remove(this.gameplayContainer, true);
                    this.scene.sound.stopAll();
                    destroyAllDelayedCalls();
                }
            }

            screen.GameplayScreen = GameplayScreen;
        })(screen = trainGame.screen || (trainGame.screen = {}));
    })(trainGame || (trainGame = {}));

    /* InstructionPage */
    (function (trainGame) {
        var screen;
        (function (screen) {
            class InstructionPage extends Phaser.GameObjects.Container {
                constructor(scene, onPlayClick, onSndClick) {
                    super(scene);
                    this._instructionPage = new Phaser.GameObjects.Image(this.scene, 0 - 105, 0 - 48, 'Instructions page  ALL ACTIVITY  TITLEs');
                    this._instructionPage.setOrigin(0, 0);
                    this._instructionPage.setInteractive();

                    this._instructionPageTitle = new Phaser.GameObjects.Image(this.scene, 475, 110, 'Load the Train Title');
                    this._instructionPageTitle.setScale(0.8);

                    this._btnPlay = new Phaser.GameObjects.Image(this.scene, game.scale.width / 2, 480 - 50, 'btnPLAY1');
                    this._btnPlay.setInteractive({ cursor: 'pointer' });
                    this._btnPlay.once('pointerup', onPlayClick);
                    setupButtonTextureBased(this._btnPlay, 'btnPLAY1', 'btnPLAY2');
                    this.instrTxt = this.scene.add.text(game.scale.width / 2, game.scale.height / 2, "The boxes make up a word.\n Load the train and read the word.\n Then choose the word that matches.", {
                        "fontFamily": "Kids Rock DEMO",
                        "fontSize": 30,
                        "lineSpacing":15,
                        "color": "#43425D",
                        "align": 'center'
                    });
                    this.instrTxt.setOrigin(0.5, 0.5);
                    this._btnSoundInstruction = new Phaser.GameObjects.Image(this.scene, 800 - 105, 156 - 50, 'Sound');
                    this._btnSoundInstruction.setInteractive({ cursor: 'pointer' });
                    this._btnSoundInstruction.on('pointerup', onSndClick);
                    setupButtonTextureBased(this._btnSoundInstruction, 'Sound', 'Sound HOVER EFFECT');
                    this.add(this._instructionPage);
                    this.add(this._instructionPageTitle);
                    this.add(this.instrTxt);
                    this.add(this._btnPlay);
                    this.add(this._btnSoundInstruction);
                }
            }

            screen.InstructionPage = InstructionPage;
        })(screen = trainGame.screen || (trainGame.screen = {}));
    })(trainGame || (trainGame = {}));

    /* Complete Window */
    (function (trainGame) {
        var screen;
        (function (screen) {
            class CompleteWindow extends Phaser.GameObjects.Container {
                constructor(scene, onBack, onReplay, onNext) {
                    super(scene);
                    this.music = null;
                    this.setPosition(-104.5, -48);
                    this._bgComplete = new Phaser.GameObjects.Image(this.scene, 0, 0, 'Completion page LATEST UPDATED');
                    this._bgComplete.setOrigin(0, 0);
                    this._bgComplete.setInteractive();
                    this._cup = new Phaser.GameObjects.Image(this.scene, 400, 410, 'Trophy');
                    this._btnBack = new Phaser.GameObjects.Image(this.scene, 570, 570, 'btnBACK1');
                    this._btnReplay = new Phaser.GameObjects.Image(this.scene, 720, 570, 'btnReplay1');
                    this._btnNext = new Phaser.GameObjects.Image(this.scene, 870, 570, 'btnNEXT1');
                    let _CollectedPoints = new Phaser.GameObjects.Image(this.scene, 620, 440, 'Collected Points');
                    this.totalScoreTxt = this.scene.add.text(845, 352, "", {
                        "fontFamily": "Kids Rock Demo",
                        "fontSize": 35,
                        "color": "#F49F1C",
                        "align": 'center',
                        'stroke': '#70451A',
                        'strokeThickness': 6
                    });
                    this.totalScoreTxt.setOrigin(0.5, 0.5);
                    let grd = this.totalScoreTxt.context.createLinearGradient(0, 0, 0, this.totalScoreTxt.height);
                    grd.addColorStop(0, '#FFFF00');
                    grd.addColorStop(1, '#C17316');
                    this.totalScoreTxt.setFill(grd);
                    this.starScoreTxt = this.scene.add.text(648, 433, "", {
                        "fontFamily": "Kids Rock Demo",
                        "fontSize": 24,
                        "color": "#FFFFFF",
                        "align": 'center'
                    });
                    this.starScoreTxt.setOrigin(0.5, 0.5);
                    this.add([
                        this._bgComplete,
                        _CollectedPoints,
                        this._cup,
                        this._btnBack,
                        this._btnReplay,
                        this._btnNext,
                        this.totalScoreTxt,
                        this.starScoreTxt
                    ]);
                    this._btnBack.setInteractive({ cursor: 'pointer' });
                    this._btnBack.on('pointerup', () => {
                        onBack(this._btnBack);
                        window.angularComponentRef.backToMenu();
                        // if (this.music) {
                        //     this.music.stop();
                        // }
                    });
                    setupButtonTextureBased(this._btnBack, 'btnBACK1', 'btnBACK2');
                    this._btnReplay.setInteractive({ cursor: 'pointer' });
                    this._btnReplay.once('pointerup', () => {
                        onReplay(this._btnReplay);
                        if (this.music) {
                            this.music.stop();
                        }
                    });
                    setupButtonTextureBased(this._btnReplay, 'btnReplay1', 'btnReplay2');
                    this._btnNext.setInteractive({ cursor: 'pointer' });
                    this._btnNext.on('pointerup', () => {
                        onNext(this._btnNext);
                        window.angularComponentRef.nextActivity();
                        // if (this.music) {
                        //     this.music.stop();
                        // }
                    });
                    setupButtonTextureBased(this._btnNext, 'btnNEXT1', 'btnNEXT2');
                }

                show(score, starScore) {
                    window.angularComponentRef.gameFinish({ score, starScore });
                    this._cup.scale = 1.25;
                    this.scene.tweens.add({
                        targets: this._cup,
                        "scale": 1,
                        duration: 500,
                        ease: Phaser.Math.Easing.Back.Out
                    });
                    this.totalScoreTxt.text = String(window.angularComponentRef.component.userInfo.totalScore);
                    this.starScoreTxt.text = String(starScore);
                    // let music = this.scene.sound.add("viktory");
                    this.music = this.scene.sound.add("Activity completion fantastic");
                    this.music.play();
                }
            }

            screen.CompleteWindow = CompleteWindow;
        })(screen = trainGame.screen || (trainGame.screen = {}));
    })(trainGame || (trainGame = {}));

    /* AreYouSureWindow */
    (function (trainGame) {
        var screen;
        (function (screen) {
            class AreYouSureWindow extends Phaser.GameObjects.Container {
                constructor(scene, onYes, onNo) {
                    super(scene);
                    this._areYouSurePage = new Phaser.GameObjects.Image(this.scene, -105, 0 - 48, 'Exit warning');
                    this._areYouSurePage.setOrigin(0, 0);
                    this._areYouSurePage.setInteractive();
                    this._btnSureYes = new Phaser.GameObjects.Image(this.scene, game.scale.width / 2 - 95, 485 - 50, 'btnYES1');
                    this._btnSureYes.setInteractive({ cursor: 'pointer' });
                    this._btnSureYes.once('pointerup', () => {
                        onYes;
                        window.angularComponentRef.backToMenu();
                    });
                    var modalTextStyle = {
                        backgroundColor: 'transparent',
                        stroke: '#ffffff',
                        strokeThickness: 2,
                        lineSpacing: 15,
                        fontFamily: 'Kids Rock DEMO',
                        fontSize: 27,
                        color: '#43425d',
                        wordWrap: true,
                        align: 'center'
                    };
                    var modalText = this.scene.add.text(game.scale.width / 2 + 12, game.scale.height / 2, "Your progress in this activity will not be saved. \n Are you sure you want to exit? ", modalTextStyle);
                    modalText.setOrigin(0.5, 0.5);

                    setupButtonTextureBased(this._btnSureYes, 'btnYES1', 'btnYES2');
                    this._btnSureNo = new Phaser.GameObjects.Image(this.scene, game.scale.width / 2 + 95, 485 - 50, 'btnNO1');
                    this._btnSureNo.setInteractive({ cursor: 'pointer' });
                    this._btnSureNo.once('pointerup', onNo);
                    setupButtonTextureBased(this._btnSureNo, 'btnNO1', 'btnNO2');
                    this.add(this._areYouSurePage);
                    this.add(this._btnSureYes);
                    this.add(this._btnSureNo);
                    this.add(modalText);
                }
            }

            screen.AreYouSureWindow = AreYouSureWindow;
        })(screen = trainGame.screen || (trainGame.screen = {}));
    })(trainGame || (trainGame = {}));

    /* Try Again Window */
    (function (trainGame) {
        var screen;
        (function (screen) {
            class TryAgainWindow extends Phaser.GameObjects.Container {
                constructor(scene, onBack, onReplay) {
                    super(scene);
                    this.music = null;
                    this.setPosition(-106, -48);
                    this._bg = new Phaser.GameObjects.Image(this.scene, 0, 0, 'Try again page');
                    this._bg.setOrigin(0, 0);
                    this._bg.setInteractive();
                    this._star = new Phaser.GameObjects.Image(this.scene, 400, 415, 'Break Star');
                    this._btnBack = new Phaser.GameObjects.Image(this.scene, 600, 580, 'btnBACK1');
                    this._btnReplay = new Phaser.GameObjects.Image(this.scene, 765, 580, 'btnReplay1');
                    this.totalScoreTxt = this.scene.add.text(830, 355, "", {
                        "fontFamily": "Kids Rock Demo",
                        "fontSize": 35,
                        "color": "#F49F1C",
                        "align": 'center',
                        'stroke': '#70451A',
                        'strokeThickness': 6
                    });
                    this.totalScoreTxt.setOrigin(0.5, 0.5);
                    let grd = this.totalScoreTxt.context.createLinearGradient(0, 0, 0, this.totalScoreTxt.height);
                    grd.addColorStop(0, '#FFFF00');
                    grd.addColorStop(1, '#C17316');
                    this.totalScoreTxt.setFill(grd);
                    this.starScoreTxt = this.scene.add.text(635, 431, "", {
                        "fontFamily": "Kids Rock Demo",
                        "fontSize": 24,
                        "color": "#FFFFFF",
                        "align": 'center'
                    });
                    this.starScoreTxt.setOrigin(0.5, 0.5);
                    this.add([
                        this._bg,
                        this._star,
                        this._btnBack,
                        this._btnReplay,
                        this.totalScoreTxt,
                        this.starScoreTxt
                    ]);
                    this._btnBack.setInteractive({ cursor: 'pointer' });
                    this._btnBack.on('pointerup', () => {
                        onBack(this._btnBack);
                        window.angularComponentRef.backToMenu();
                        // if (this.music) {
                        //     this.music.stop();
                        // }
                    });
                    setupButtonTextureBased(this._btnBack, 'btnBACK1', 'btnBACK2');
                    this._btnReplay.setInteractive({ cursor: 'pointer' });
                    this._btnReplay.once('pointerup', () => {
                        onReplay(this._btnReplay);
                        if (this.music) {
                            this.music.stop();
                        }
                    });
                    setupButtonTextureBased(this._btnReplay, 'btnReplay1', 'btnReplay2');
                }

                show(score, starScore) {
                    this._star.scale = 1.25;
                    this.scene.tweens.add({
                        targets: this._star,
                        "scale": 1,
                        duration: 500,
                        ease: Phaser.Math.Easing.Back.Out
                    });
                    this.totalScoreTxt.text = String(window.angularComponentRef.component.userInfo.totalScore);
                    this.starScoreTxt.text = String(0);
                    this.music = this.scene.sound.add("Fail - close one");
                    this.music.play();
                }
            }

            screen.TryAgainWindow = TryAgainWindow;
        })(screen = trainGame.screen || (trainGame.screen = {}));
    })(trainGame || (trainGame = {}));

    game = new trainGame.App(json);
};

function destroyLoadTrain() {
    if (game) {
        game.destroy();
        game = null;
    }
}
