var game;
var sh;
function stackWood(json) {
    /**
     * @author Roman Parada
     * This class is created to fix overlapping of bottom part of canvas by navigation bar in iOS.
     * It make a delayed resize of the canvas (like Phaser-3 FIT methods does) and it overrides Phaser-3 input window to Phaser-3 core transform methods
     *
     * How to use:
     * Just call the code line below in Boot scene's init() method of your project:
     * new FitScaleManager(this.game).setup();
     */
    class FitScaleManager {
        constructor(game) {
            this.doResize = () => {
                let scale = this.calculateScale();
                let newCanvasWidth = this.phaserScaleManager.width * scale;
                let newCanvasHeight = this.phaserScaleManager.height * scale;
                this.canvasStyle.width = newCanvasWidth + 'px';
                this.canvasStyle.height = newCanvasHeight + 'px';
                this.canvasStyle.marginLeft = (window.innerWidth - newCanvasWidth) / 2 + 'px';
                this.canvasStyle.marginTop = (window.innerHeight - newCanvasHeight) / 2 + 'px';
            };
            this.game = game;
            this.canvasStyle = this.game.canvas.style;
            this.phaserScaleManager = this.game.scale;
        }
        static detectBestScaleMode() {
            let iOS = /iPad|iPhone|iPod/.test(navigator.platform || "");
            let isAndroid = window.navigator.userAgent.toLowerCase().indexOf("android") > -1;
            return iOS || isAndroid ? Phaser.Scale.FIT : Phaser.Scale.NONE;
        }
        ;
        /**
         * Just call this method once in Boot scene's init() method
         */
        setup() {
            this.phaserScaleManager.addListener(Phaser.Scale.Events.RESIZE, this.onResize, this);
            this.overridePhaserTransformMethods();
            this.onResize();
        }
        calculateScale() {
            if (game.scale.scaleMode === Phaser.Scale.NONE)
                return 1;
            return Math.min(window.innerWidth / this.phaserScaleManager.width, window.innerHeight / this.phaserScaleManager.height);
        }
        overridePhaserTransformMethods() {
            this.game.scale.transformX = (pageX) => {
                return (pageX - parseInt(this.canvasStyle.marginLeft.split("px")[0])) / this.calculateScale();
            };
            this.game.scale.transformY = (pageY) => {
                return (pageY - parseInt(this.canvasStyle.marginTop.split("px")[0])) / this.calculateScale();
            };
        }
        onResize() {
            setTimeout(this.doResize, FitScaleManager.RESIZE_DELAY);
        }
    }
    FitScaleManager.RESIZE_DELAY = 500;

    (function (sh) {
        class App extends Phaser.Game {
            constructor(json) {
                App.json = json;
                // console.log(App.json);

                let config = {
                    type: Phaser.AUTO,
                    width: App.CANVAS_WIDTH,
                    height: App.CANVAS_HEIGHT,
                    parent: 'game-container',
                    dom: {
                        createContainer: false
                    },
                    scale: {
                        mode: FitScaleManager.detectBestScaleMode(),
                        autoCenter: Phaser.Scale.CENTER_BOTH
                    },
                    transparent: true,
                    scene: {
                        create: () => {
                            this.scene.add('Boot', sh.scene.Boot, true);
                        }
                    }
                };
                super(config);
            }
        }
        App.CANVAS_WIDTH = 980;
        App.CANVAS_HEIGHT = 600;
        sh.App = App;
    })(sh || (sh = {}));

    game = new sh.App(json);

    var delayedCalls = [];
    function delayedCall(delay, callback, args, callbackScope) {
        let scene = game.scene.getAt(0);
        if (scene) {
            let dc = scene.time.delayedCall(delay, callback, args, callbackScope);
            delayedCalls.push(dc);
            return dc;
        }
        let t = setTimeout(callback, delay);
        delayedCalls.push(t);
        return t;
    }

    function pauseAllDelayedCalls() {
        for (let dc of delayedCalls) {
            if (dc instanceof Phaser.Time.TimerEvent) {
                dc.paused = true;
            }
        }
    }

    function resumeAllDelayedCalls() {
        for (let dc of delayedCalls) {
            if (dc instanceof Phaser.Time.TimerEvent) {
                dc.paused = false;
            }
        }
    }

    function destroyAllDelayedCalls() {
        for (let dc of delayedCalls) {
            if (dc instanceof Phaser.Time.TimerEvent) {
                dc.remove(false);
            }
            else {
                clearTimeout(dc);
            }
        }
        delayedCalls = [];
    }

    function setPageBackground(bg) {
        // document.querySelector("html").style.backgroundImage = "url(assets/games/stack-the-wood/imgs/" + bg + ".jpg)";
    }

    function setupButton(btn, frame) {
        btn.on('pointerdown', () => { btn.setFrame(frame + '_hover' + '0000'); });
        btn.on('pointerover', () => { btn.setFrame(frame + '_hover' + '0000'); game.scene.getAt(0).sound.add("button hover").play(); });
        btn.on('pointerout', () => { btn.setFrame(frame + '0000'); });
        btn.on('pointerup', () => { btn.setFrame(frame + '0000'); game.scene.getAt(0).sound.add('activity selection - button selection').play(); });
    }

    function setupButtonTextureBased(btn, texture, hoverTexture) {
        btn.on('pointerdown', () => { btn.setTexture(hoverTexture); });
        btn.on('pointerover', () => { btn.setTexture(hoverTexture); game.scene.getAt(0).sound.add("button hover").play(); });
        btn.on('pointerout', () => { btn.setTexture(texture); });
        btn.on('pointerup', () => { btn.setTexture(texture); game.scene.getAt(0).sound.add('activity selection - button selection').play(); });
    }

    (function (sh) {
        var core;
        (function (core) {
            class Gameplay {
                constructor() {
                    this.currentRound = 0;
                    this.currentLetter = 0;
                    this.correctAnswersCount = 0;
                    this.wrongAnswersCount = 0;
                    this.correctAnswersCountThisRound = 0;
                    this.wrongAnswersCountThisRound = 0;
                    this.reset();
                    let json = game.cache.json.get('gameplay');
                    this.wrongAnswersToLose = json["wrongAnswersToLose"];
                    this.numberOfQuestionsPerRound = json["numberOfQuestionsPerRound"];
                    this.lettersConfig = JSON.parse(JSON.stringify(json["lettersConfig"]));
                    this.rounds = JSON.parse(JSON.stringify(json["rounds"]));
                    this.numberOfRounds = json["numberOfRounds"] ?? this.rounds.length;
                    this.useImages = Boolean(game.cache.json.get('gameplay')["useImages"]);
                }
                setupCallbacks(onComplete, onLose) {
                    this.onComplete = onComplete;
                    this.onLose = onLose;
                }
                onLetterChosen(forceNewRound) {
                    if (forceNewRound || this.isNewRound()) {
                        return this.nextRound();
                    }
                    return false;
                }
                nextRound() {
                    this.correctAnswersCountThisRound = 0;
                    this.wrongAnswersCountThisRound = 0;
                    this.currentRound++;
                    if (this.isRoundsComplete()) {
                        this.onComplete(this.correctAnswersCount, this.correctAnswersCount);
                        return true;
                    }

                    return false;
                }
                getCorrectLetterName() {
                    return this.rounds[this.currentRound]['correctLetterName'];
                }
                isCorrectLetterAudioQuestionEnable() {
                    return this.rounds[this.currentRound]['audioQuestionEnable'];
                }
                getCorrectAndLetterSoundSfxAudioKey() {
                    return "Q-" + this.lettersConfig[this.getCorrectLetterName()]['Sounds of the letters'];
                }
                onCorrectAnswer() {
                    this.correctAnswersCountThisRound++;
                    this.correctAnswersCount++;
                    return this.onLetterChosen();
                }
                onWrongAnswer(forceNewRound) {
                    this.wrongAnswersCountThisRound++;
                    this.wrongAnswersCount++;
                    if (this.wrongAnswersCount >= this.wrongAnswersToLose) {
                        this.onLose(this.correctAnswersCount, this.correctAnswersCount);
                        return true;
                    }
                    else {
                        this.onLetterChosen(forceNewRound);
                    }
                    return false;
                }

                getMaxTotalAnswersCount() {
                    return this.wrongAnswersToLose + this.numberOfRounds - 1;
                }

                getCurrentTotalAnswersCount() {
                    return this.correctAnswersCount + this.wrongAnswersCount;
                }
                isNewRound() {
                    return this.correctAnswersCountThisRound >= 1 || (this.correctAnswersCountThisRound + this.wrongAnswersCountThisRound) === 4 || (this.correctAnswersCountThisRound + this.wrongAnswersCountThisRound) === 0;
                }
                isRoundsComplete() {
                    return this.currentRound >= this.numberOfRounds && this.wrongAnswersCount < this.wrongAnswersToLose;
                }
                reset() {
                    this.currentRound = 0;
                    this.correctAnswersCount = 0;
                    this.wrongAnswersCount = 0;
                }
                getCurrentRoundLetters() {
                    return this.rounds[this.currentRound]["letters"];
                }
            }
            core.Gameplay = Gameplay;
        })(core = sh.core || (sh.core = {}));
    })(sh || (sh = {}));

    (function (sh) {
        var scene;
        (function (scene) {
            class Boot extends Phaser.Scene {
                init() {
                    this.game.scale.transformX = (pageX) => {
                        let offsetLeft = 0;
                        let parentElement = game.canvas.parentElement;
                        while (parentElement) {
                            if (parentElement.offsetLeft) {
                                offsetLeft = parentElement.offsetLeft;
                                break;
                            }
                            parentElement = parentElement.parentElement;
                        }
                        return (pageX - offsetLeft) * this.game.scale.displayScale.x;
                    };
                    this.game.scale.transformY = (pageY) => {
                        let offsetTop = 0;
                        let parentElement = game.canvas.parentElement;
                        while (parentElement) {
                            if (parentElement.offsetTop) {
                                offsetTop = parentElement.offsetTop;
                                break;
                            }
                            parentElement = parentElement.parentElement;
                        }
                        return (pageY - offsetTop) * this.game.scale.displayScale.y;
                    };
                }
                create() {
                    game.scene.remove('Boot');
                    game.scene.add('Preloader', sh.scene.Preloader, true);
                }
            }
            scene.Boot = Boot;
        })(scene = sh.scene || (sh.scene = {}));
    })(sh || (sh = {}));

    (function (sh) {
        var scene;
        (function (scene) {
            var Gameplay = sh.core.Gameplay;
            class MainScene extends Phaser.Scene {
                create() {
                    this.gameplay = new Gameplay();
                    this.gameplayScreen = new sh.screen.GameplayScreen(this, this.gameplay);
                    this.children.add(this.gameplayScreen);
                    this.gameplayScreen.showInstructionPage();
                }
            }
            scene.MainScene = MainScene;
        })(scene = sh.scene || (sh.scene = {}));
    })(sh || (sh = {}));

    (function (sh) {
        var scene;
        (function (scene) {
            class Preloader extends Phaser.Scene {
                preload() {
                    if (typeof sh.App.json == "string") this.load.json('gameplay', sh.App.json); else this.game.cache.json.add('gameplay', sh.App.json);
                }
                create() {
                    let json = game.cache.json.get('gameplay');

                    let lettersConfig = json["lettersConfig"];
                    this.load.audio("englQuestion", "assets/games/stack-the-wood/sound/mp3/letters/englQuestion.mp3");
                    for (let letterName in lettersConfig) {
                        if (json["useImages"]) this.load.image(letterName, "assets/games/stack-the-wood/imgs/letters/" + letterName + ".png");
                        if (lettersConfig[letterName]) {
                            this.load.audio("Q-" + lettersConfig[letterName]["Sounds of the letters"], "assets/games/stack-the-wood/sound/mp3/letters/Sounds of the letters/" + lettersConfig[letterName]["Sounds of the letters"]);
                        }
                    }
                    let progressTxt = this.add.text(game.scale.width / 2, game.scale.height / 2 + 50, "", {
                        "fontFamily": "QuranErafont",
                        "fontSize": 25,
                        "color": "#333333",
                        "align": 'center'
                    });
                    progressTxt.setOrigin(0.5, 0.5);
                    this.load.pack('preloader', 'assets/games/stack-the-wood/pack.json');
                    this.load.on('progress', (value) => {
                        progressTxt.text = Math.ceil(value * 100) + "%";
                    }, this);
                    this.load.on('complete', () => {
                        this.nextScene();
                    });
                    this.load.start();
                }
                static playAnim(animKey, sprite, onComplete = null) {
                    let mainScene = game.scene.getScene('ScreenMain');
                    let data = Preloader.ANIMS_DATA[animKey];
                    if (!mainScene.anims.exists(animKey)) {
                        mainScene.anims.create({
                            key: animKey,
                            frames: mainScene.anims.generateFrameNames(data['atlas'], {
                                start: data['start'], end: data['end'], zeroPad: data['padNum'],
                                prefix: data['prefix'], suffix: ''
                            }),
                            frameRate: data['frameRate'],
                            repeat: data['repeat']
                        });
                    }
                    if (sprite.anims.currentAnim) {
                        sprite.anims.currentAnim.off('complete');
                    }
                    sprite.anims.stop();
                    // sprite.setTexture(data['atlas']);
                    sprite.play(animKey);
                    sprite.anims.currentAnim.once('complete', () => {
                        if (onComplete)
                            onComplete();
                    });
                    return sprite;
                }
                nextScene() {
                    game.scene.remove('Preloader');
                    game.scene.add('ScreenMain', sh.scene.MainScene, true);
                }
            }
            Preloader.ANIMS_DATA = {
                'idle': {
                    'start': 0,
                    'end': 99,
                    'padNum': 2,
                    'prefix': 'Iddle Snail 0',
                    'repeat': -1,
                    'frameRate': 30,
                    'atlas': 'idle-atlas'
                },
                'wrong': {
                    'start': 0,
                    'end': 99,
                    'padNum': 2,
                    'prefix': 'wrong Answer _20',
                    'repeat': 0,
                    'frameRate': 30,
                    'atlas': 'wrong-atlas'
                },
                'final': {
                    'start': 0,
                    'end': 79,
                    'padNum': 2,
                    'prefix': 'Final celebration Snail ',
                    'repeat': 0,
                    'frameRate': 30,
                    'atlas': 'final-atlas'
                },
                'idle_alone': {
                    'start': 98,
                    'end': 98,
                    'padNum': 2,
                    'prefix': 'Correct Answer _50',
                    'repeat': 0,
                    'frameRate': 30,
                    'atlas': 'correct1-atlas'
                },
                'correct1': {
                    'start': 0,
                    'end': 99,
                    'padNum': 2,
                    'prefix': 'Correct Answer _50',
                    'repeat': 0,
                    'frameRate': 30,
                    'atlas': 'correct1-atlas'
                },
                'correct2': {
                    'start': 0,
                    'end': 99,
                    'padNum': 2,
                    'prefix': 'Correct Answer _60',
                    'repeat': 0,
                    'frameRate': 30,
                    'atlas': 'correct2-atlas'
                },
                'correct3': {
                    'start': 0,
                    'end': 99,
                    'padNum': 2,
                    'prefix': 'Correct Answer _70',
                    'repeat': 0,
                    'frameRate': 30,
                    'atlas': 'correct3-atlas'
                },
            };
            scene.Preloader = Preloader;
        })(scene = sh.scene || (sh.scene = {}));
    })(sh || (sh = {}));
    (function (sh) {
        var screen;
        (function (screen) {
            class AreYouSureWindow extends Phaser.GameObjects.Container {
                constructor(scene, onYes, onNo) {
                    super(scene);
                    this._areYouSurePage = new Phaser.GameObjects.Image(this.scene, -105, 0 - 48, 'Exit warning');
                    this._areYouSurePage.setOrigin(0, 0);
                    this._areYouSurePage.setInteractive();
                    this._btnSureYes = new Phaser.GameObjects.Image(this.scene, game.scale.width / 2 - 95, 485 - 50, 'btnYES1');
                    this._btnSureYes.setInteractive({ cursor: 'pointer' });
                    this._btnSureYes.on('pointerup', () => {
                        onYes;
                        angularComponentRef.backToMenu();
                    });
                    var modalTextStyle = {
                        backgroundColor: 'transparent',
                        stroke: '#ffffff',
                        strokeThickness: 2,
                        lineSpacing: 15,
                        fontFamily: 'Kids Rock DEMO',
                        fontSize: 27,
                        color: '#43425d',
                        wordWrap: true,
                        align: 'center'
                    };
                    var modalText = this.scene.add.text(game.scale.width / 2 + 12, game.scale.height / 2, "Your progress in this activity will not be saved. \n Are you sure you want to exit? ", modalTextStyle);
                    modalText.setOrigin(0.5, 0.5);

                    setupButtonTextureBased(this._btnSureYes, 'btnYES1', 'btnYES2');
                    this._btnSureNo = new Phaser.GameObjects.Image(this.scene, game.scale.width / 2 + 95, 485 - 50, 'btnNO1');
                    this._btnSureNo.setInteractive({ cursor: 'pointer' });
                    this._btnSureNo.on('pointerup', onNo);
                    setupButtonTextureBased(this._btnSureNo, 'btnNO1', 'btnNO2');
                    this.add(this._areYouSurePage);
                    this.add(this._btnSureYes);
                    this.add(this._btnSureNo);
                    this.add(modalText);
                }
            }
            screen.AreYouSureWindow = AreYouSureWindow;
        })(screen = sh.screen || (sh.screen = {}));
    })(sh || (sh = {}));

    (function (sh) {
        var screen;
        (function (screen) {
            class CompleteWindow extends Phaser.GameObjects.Container {
                constructor(scene, onBack, onReplay, onNext) {
                    super(scene);
                    this.music = null;
                    this.setPosition(-104.5, -48);
                    this._bgComplete = new Phaser.GameObjects.Image(this.scene, 0, 0, 'Completion page LATEST UPDATED');
                    this._bgComplete.setOrigin(0, 0);
                    this._bgComplete.setInteractive();
                    this._cup = new Phaser.GameObjects.Image(this.scene, 400, 410, 'Trophy');
                    this._btnBack = new Phaser.GameObjects.Image(this.scene, 570, 570, 'btnBACK1');
                    this._btnReplay = new Phaser.GameObjects.Image(this.scene, 720, 570, 'btnReplay1');
                    this._btnNext = new Phaser.GameObjects.Image(this.scene, 870, 570, 'btnNEXT1');
                    let _CollectedPoints = new Phaser.GameObjects.Image(this.scene, 620, 440, 'Collected Points');
                    this.totalScoreTxt = this.scene.add.text(845, 352, "", {
                        "fontFamily": "Kids Rock Demo",
                        "fontSize": 35,
                        "color": "#F49F1C",
                        "align": 'center',
                        'stroke': '#70451A',
                        'strokeThickness': 6
                    });
                    this.totalScoreTxt.setOrigin(0.5, 0.5);
                    let grd = this.totalScoreTxt.context.createLinearGradient(0, 0, 0, this.totalScoreTxt.height);
                    grd.addColorStop(0, '#FFFF00');
                    grd.addColorStop(1, '#C17316');
                    this.totalScoreTxt.setFill(grd);
                    this.starScoreTxt = this.scene.add.text(648, 433, "", {
                        "fontFamily": "Kids Rock Demo",
                        "fontSize": 24,
                        "color": "#FFFFFF",
                        "align": 'center'
                    });
                    this.starScoreTxt.setOrigin(0.5, 0.5);
                    this.add([
                        this._bgComplete,
                        _CollectedPoints,
                        this._cup,
                        this._btnBack,
                        this._btnReplay,
                        this._btnNext,
                        this.totalScoreTxt,
                        this.starScoreTxt
                    ]);
                    this._btnBack.setInteractive({ cursor: 'pointer' });
                    this._btnBack.on('pointerup', () => {
                        onBack(this._btnBack);
                        angularComponentRef.backToMenu();
                        // if (this.music) {
                        //     this.music.stop();
                        // }
                    });
                    setupButtonTextureBased(this._btnBack, 'btnBACK1', 'btnBACK2');
                    this._btnReplay.setInteractive({ cursor: 'pointer' });
                    this._btnReplay.once('pointerup', () => {
                        onReplay(this._btnReplay);
                        if (this.music) {
                            this.music.stop();
                        }
                    });
                    setupButtonTextureBased(this._btnReplay, 'btnReplay1', 'btnReplay2');
                    this._btnNext.setInteractive({ cursor: 'pointer' });
                    this._btnNext.on('pointerup', () => {
                        onNext(this._btnNext);
                        window.angularComponentRef.nextActivity()
                    });
                    setupButtonTextureBased(this._btnNext, 'btnNEXT1', 'btnNEXT2');
                }
                show(score, starScore) {
                    window.angularComponentRef.gameFinish({ score, starScore })
                    this._cup.scale = 1.25;
                    this.scene.tweens.add({
                        targets: this._cup,
                        "scale": 1,
                        duration: 500,
                        ease: Phaser.Math.Easing.Back.Out
                    });
                    this.totalScoreTxt.text = String(window.angularComponentRef.component.userInfo.totalScore);
                    this.starScoreTxt.text = String(starScore);
                    // let music = this.scene.sound.add("viktory");
                    this.music = this.scene.sound.add("Activity completion fantastic");
                    this.music.play();
                }
            }
            screen.CompleteWindow = CompleteWindow;
        })(screen = sh.screen || (sh.screen = {}));
    })(sh || (sh = {}));

    (function (sh) {
        var screen;
        (function (screen) {
            class GameplayScreen extends Phaser.GameObjects.Container {
                constructor(scene, gameplay) {
                    super(scene);
                    let _dx = 0;
                    let _dy = 640;
                    this.carsPositionsOutScreen = [{ x: 245 - 50 - _dx, y: 514 - _dy }, { x: 485 - _dx, y: 514 - _dy }, { x: 723 + 55 - _dx, y: 514 - _dy }];
                    this.carsPositions = [{ x: 245 - 50, y: 514 }, { x: 485, y: 514 }, { x: 723 + 55, y: 514 }];
                    this.bgMusic = null;
                    this.correctAudio = null;
                    this.wfsnd = null;
                    this.showCompleteWindow = (score, starScore) => {
                        let completeWindow = new screen.CompleteWindow(this.scene, (target) => {
                            this.playBtnClickAnim(target);
                        }, (target) => {
                            this.playBtnClickAnim(target);
                            this.destroyGameplay();
                            this.remove(completeWindow);
                            this.showInstructionPage();
                        }, (target) => {
                            this.playBtnClickAnim(target);
                        });
                        this.setLettersInputEnabled(false);
                        delayedCall(9200, () => {
                            setPageBackground("bg-blue");
                            this.add(completeWindow);
                            completeWindow.show(score, starScore);
                            if (this.bgMusic) this.bgMusic.stop();
                        });
                    };
                    this.showLoseWindow = (score, starScore) => {
                        let tryAgainWindow = new screen.TryAgainWindow(this.scene, (target) => {
                            this.playBtnClickAnim(target);
                        }, (target) => {
                            this.playBtnClickAnim(target);
                            this.destroyGameplay();
                            this.remove(tryAgainWindow);
                            this.showInstructionPage();
                        });
                        this.setLettersInputEnabled(false);
                        delayedCall(2500, () => {
                            setPageBackground("bg-blue");
                            this.add(tryAgainWindow);
                            tryAgainWindow.show(score, starScore);
                            if (this.bgMusic) this.bgMusic.stop();
                        });
                    };
                    this.gameplay = gameplay;
                    window["t"] = this;
                }
                createHexagons() {
                    if (this.contentContainer && this.contentContainer.parentContainer) {
                        this.contentContainer.parentContainer.remove(this.contentContainer);
                    }

                    this.contentContainer = this.scene.add.container(0, 0);
                    this.gameplayContainer.addAt(this.contentContainer, 1);
                    window['this.contentContainer'] = this.contentContainer;

                    if (this.bgWoodsContainer && this.bgWoodsContainer.parentContainer) {
                        this.bgWoodsContainer.parentContainer.remove(this.bgWoodsContainer);
                    } else {
                        this.bgWoodsContainer = this.scene.add.container(330, 165);
                        this.bgWoodsContainer.setScale(0.6);
                        let positions = [
                            { x: 810, y: 416 }, { x: 844, y: 422 }, { x: 875, y: 425 }, { x: 906, y: 430 },
                            { x: 830, y: 364 - 40 }, { x: 857, y: 367 - 40 }, { x: 890, y: 369 - 40 },
                            { x: 847, y: 305 - 80 }, { x: 880, y: 307 - 80 },
                            { x: 867, y: 252 - 125 }
                        ];
                        for (let c = 0; c < 10; c++) {
                            let p = positions.shift();
                            let wood = this.scene.add.sprite(p.x, p.y, 'smalwood');
                            wood.alpha = 0;
                            window['wood'] = wood;
                            wood.setScale(1 / 0.6);
                            this.bgWoodsContainer.add(wood);
                            window['this.bgWoodsContainer'] = this.bgWoodsContainer;
                        }
                    }
                    this.gameplayContainer.addAt(this.bgWoodsContainer, 1);

                    let wasSnail = this.snail ? true : false;
                    let _ind = this.snail ? this.snail['index'] : this.carsPositions.length - 1;
                    this.snail = this.scene.add.sprite(this.snail ? this.snail.x : this.carsPositions[_ind].x + 305, this.snail ? this.snail.y : 604);
                    this.snail['index'] = _ind;
                    this.contentContainer.add(this.snail);
                    this.snail.setOrigin(0.5, 1);
                    window['this.snail'] = this.snail;
                    sh.scene.Preloader.playAnim('idle', this.snail);

                    /* let frontTree = this.scene.add.sprite(895, 185, 'frontTree');
                    window['frontTree'] = frontTree;
                    this.contentContainer.add(frontTree); */

                    let shadowContainer = this.scene.add.container();
                    this.contentContainer.add(shadowContainer);

                    this.cars = [];
                    let _i = 0;
                    for (let _k = 0; _k < this.carsPositions.length; _k++) {
                        let p = this.carsPositions[_k];
                        let hc = this.scene.add.container(p['x'], p['y']);
                        let hcIdleMotionContainer = this.scene.add.container();
                        hc.add(hcIdleMotionContainer);
                        let shadow = this.scene.add.sprite(this.carsPositions[_k]["x"], this.carsPositions[_k]["y"] + 50, 'shadow');
                        if (!wasSnail || _k === _ind) shadow.alpha = 0;
                        hc["shadow"] = shadow;
                        shadowContainer.add(shadow);

                        let h = this.scene.add.sprite(0, 0, 'cloud');
                        h.setOrigin(0.518, 0.5);
                        hc['isUsedAlready'] = false
                        hc.add(h);
                        hc["bg"] = h;
                        window['hc_' + _i] = hc;
                        window['h_' + _i] = h;
                        hcIdleMotionContainer.add(h);
                        hc['hcIdleMotionContainer'] = hcIdleMotionContainer;

                        hc["defX"] = p['x'];
                        hc["defY"] = p['y'];
                        this.cars.push(hc);
                        this.contentContainer.addAt(hc, 2);
                        hc['index'] = _i;
                        _i++;
                    }
                    window['this.cars'] = this.cars;
                }
                createLetters() {
                    /* if (this.letters) {
                        for (let l of this.letters) {
                            l.parentContainer["letter"] = null;
                            l["hexagon"] = null;
                            l.parentContainer.remove(l);
                        }
                    } */
                    this.letters = [];
                    let _letters = this.gameplay.getCurrentRoundLetters();
                    for (let n of _letters) {
                        let wordText;
                        if (this.gameplay.useImages) {
                            wordText = new Phaser.GameObjects.Image(this.scene, 0, 0, n);
                        } else {
                            wordText = this.scene.add.text(0, 0, n, {
                                "fontFamily": "QuranErafont",
                                "fontSize": 50,
                                "color": "#333333",
                                "align": 'center'
                            });
                            this.add(wordText);
                            wordText.setOrigin(0.5, 0.5);
                            wordText.setPadding({ left: 0, right: 0, top: 65, bottom: 65 });

                            if (wordText.width > 160) wordText.setScale(160 / wordText.width);
                        }
                        wordText.setPosition(0, -3);
                        wordText["key"] = n;
                        this.letters.push(wordText);
                        window['wordText'] = wordText;
                    }
                }
                showGameplay() {
                    this.isLost = false;
                    setPageBackground("bg-australia");
                    this.bgMusic = this.scene.sound.add("bg_sound");
                    this.bgMusic.play();
                    this.bgMusic.loop = true;
                    this.gameplay.reset();
                    this._gameStage = new Phaser.GameObjects.Image(this.scene, game.scale.width / 2, game.scale.height / 2, 'bg');
                    this._gameStage.setOrigin(0.5, 0.5);
                    this._gameStage.setInteractive();
                    this._btnSound = new Phaser.GameObjects.Image(this.scene, 160 - 105, 100 - 50, 'Sound');
                    this._btnSound.setInteractive({ cursor: 'pointer' });
                    this._btnSound["defScale"] = this._btnSound.scale;
                    setupButtonTextureBased(this._btnSound, 'Sound', 'Sound HOVER EFFECT');
                    this._btnClose = new Phaser.GameObjects.Image(this.scene, 1025 - 105, 100 - 50, 'x Button');
                    this._btnClose.setInteractive({ cursor: 'pointer' });
                    this._btnClose["defScale"] = this._btnClose.scale;
                    setupButtonTextureBased(this._btnClose, 'x Button', 'x Button HOVER EFFECT');
                    this.gameplayContainer = new Phaser.GameObjects.Container(this.scene);
                    this.addAt(this.gameplayContainer, 0);
                    this.gameplayContainer.add([
                        this._gameStage,
                        this._btnSound,
                        this._btnClose
                    ]);
                    this.createBeehives();
                    this.shuffleOutHexagons();
                    this.gameplay.setupCallbacks(this.showCompleteWindow, this.showLoseWindow);
                }
                createBeehives() {
                    let startX = 140;
                    let startY = 43;
                    let dx = 36;
                    let _BeehiveEmptyArrayPositions = [];
                    for (let i = 0; i < this.gameplay.getMaxTotalAnswersCount(); i++) {
                        _BeehiveEmptyArrayPositions.push({ x: startX + i * dx, y: startY });
                    }
                    this._BeehiveEmptyArray = []; window["_BeehiveEmptyArray"] = this._BeehiveEmptyArray;
                    this._Beehive = [];
                    for (let p of _BeehiveEmptyArrayPositions) {
                        let _BeehiveEmpty = new Phaser.GameObjects.Image(this.scene, p['x'], p['y'], 'Beehive 1 Empty');
                        this._BeehiveEmptyArray.push(_BeehiveEmpty);
                        _BeehiveEmpty.visible = false;
                        let _Beehive = new Phaser.GameObjects.Image(this.scene, p['x'], p['y'], 'Beehive 1');
                        this._Beehive.push(_Beehive);
                    }
                    for (let bea of this._BeehiveEmptyArray) {
                        this.gameplayContainer.add(bea);
                    }
                    for (let bee of this._Beehive) {
                        this.gameplayContainer.add(bee);
                        bee.visible = false;
                    }
                }
                randomizeLettersPosition() {
                    let hx = this.cars.slice();
                    for (let l of this.letters) {
                        if (hx.length == 0)
                            return;
                        let hc = Phaser.Utils.Array.RemoveRandomElement(hx);
                        hc['hcIdleMotionContainer'].add(l);
                        hc["letter"] = l;
                        l["hexagon"] = hc;
                    }
                }
                updateHexagonsArrange() {
                    for (let hc of this.cars) {
                        if (!hc["letter"]) {
                            hc.parentContainer.bringToTop(hc);
                            break;
                        }
                    }
                }
                shuffleOutHexagons() {
                    this.createHexagons();

                    let setLettersInputEnabled = () => {
                        if (this.correctAudio && this.correctAudio.isPlaying) {
                            delayedCall((this.correctAudio.duration) * 1000, () => {
                                this.setLettersInputEnabled(true);
                            });
                        }
                        else {
                            this.setLettersInputEnabled(true);
                        }
                    };

                    if (!this.gameplay.isRoundsComplete()) {
                        if (this.gameplay.isCorrectLetterAudioQuestionEnable()) {
                            delayedCall(2000, () => {
                                this.playCorrectAudio();
                                setLettersInputEnabled();
                            });
                        }
                    }

                    if (this.sfxCarAccelerating) {
                        this.sfxCarAccelerating.stop();
                        this.sfxCarAccelerating = null;
                    }
                    if (this.sfxWrong) {
                        this.sfxWrong.stop();
                        this.sfxWrong = null;
                    }

                    if (!this.gameplay.isRoundsComplete()) {
                        this.createLetters();
                        this.randomizeLettersPosition();
                    }

                    this.setLettersInputEnabled(false);
                    this.updateHexagonsArrange();
                    // Phaser.Utils.Array.Shuffle(this.carsPositions);

                    delayedCall(1325, () => { this.scene.sound.add('All words appear').play(); });
                    if (!this.usedCarsIndexes) delayedCall(300, () => { this.scene.sound.add('Logs falling').play(); });

                    let cars = this.cars;
                    for (let i = 0; i < cars.length; i++) {
                        let hc = cars[i];

                        hc['letter'].alpha = 0;
                        this.scene.tweens.add({
                            targets: hc['letter'],
                            alpha: 1,
                            duration: 200,
                            delay: 1450
                        });

                        hc["defX"] = this.carsPositions[i]["x"];
                        hc["defY"] = this.carsPositions[i]["y"];

                        if (!this.usedCarsIndexes || this.usedCarsIndexes.indexOf(hc['index']) >= 0) {
                            this.scene.tweens.killTweensOf(hc);

                            hc.x = this.carsPositionsOutScreen[i].x;
                            hc.y = this.carsPositionsOutScreen[i].y;

                            // hc.alpha = 0;
                            this.scene.tweens.add({
                                targets: hc,
                                // alpha: 1,
                                "x": hc["defX"],
                                "y": hc["defY"],
                                duration: 300,
                                delay: (cars.length - 1 - i) * 325,
                                ease: Phaser.Math.Easing.Cubic.In,
                                onComplete: () => {
                                    // if (this.snail['index'] === i) sh.scene.Preloader.playAnim('idle', this.snail);
                                }
                            });

                            if (this.usedCarsIndexes) delayedCall((cars.length - 1 - hc['index']) * 450, () => {
                                this.scene.sound.add('new log falls').play();
                            });

                            this.scene.tweens.add({
                                targets: hc["shadow"],
                                alpha: 1,
                                duration: 325,
                                delay: (cars.length - 1 - i) * 375,
                                ease: Phaser.Math.Easing.Cubic.In
                            });
                        }
                    }

                    this.usedCarsIndexes = undefined;
                    this.correctIndex = undefined;

                    for (let c of cars) this.gameplayContainer.remove(c);
                    this.cars.sort((c1, c2) => c1.y - c2.y);
                    // for (let c of cars) this.gameplayContainer.add(c);

                    this.createInput();
                }
                shuffleInHexagons() {
                    this.updateHexagonsArrange();
                    let positions = this.carsPositions.slice();
                    this.setLettersInputEnabled(false);

                    // delayedCall(600, () => {
                    // this.scene.sound.add('move to centre').play();
                    this.shuffleOutHexagons();
                    // });
                }
                createInput() {
                    for (let hc of this.cars) {
                        let correctAnswer = hc["letter"] && hc["letter"]["key"] == this.gameplay.getCorrectLetterName();
                        //hc["letter"].alpha = correctAnswer ? 1 : 0.5;
                        hc["bg"].once('pointerup', () => {
                            if (hc["isUsedAlready"]) return;
                            hc['isUsedAlready'] = true;

                            // this.playBtnClickAnim(hc);
                            let lost = false;
                            let hcIndex = hc['index'] + 1;

                            var snaiPosX = this.carsPositions[hcIndex - 1].x + 305;
                            let d = Math.abs(snaiPosX - this.snail.x);
                            let duration = 650;//Math.min( d / 600 * 1000, 700);
                            // sh.scene.Preloader.playAnim('idle', this.snail);

                            if (correctAnswer) {
                                this.usedCarsIndexes = this.usedCarsIndexes || [];
                                this.usedCarsIndexes.push(hc['index']);
                                this.correctIndex = hc['index'];

                                let completed = this.onCorrectAnswer();

                                if (d > 100) delayedCall(1750, () => { this.scene.sound.add('Snail moving').play(); });

                                this.setLettersInputEnabled(false);
                                this.scene.tweens.add({
                                    targets: this.snail,
                                    x: snaiPosX,
                                    duration: duration,
                                    delay: 1750,
                                    onComplete: () => {
                                        let originX = [0.5, 0.808, 1.102];
                                        this.snail.originX = originX[hcIndex - 1];
                                        this.snail['index'] = hc['index'];

                                        this.scene.tweens.add({
                                            targets: hc["shadow"],
                                            alpha: 0,
                                            duration: 650,
                                            delay: 850
                                        });

                                        delayedCall(1700, () => { this.scene.sound.add('Throwing log bckwards').play(); });
                                        delayedCall(200, () => { this.scene.sound.add('Snail smile - correct animation').play(); });
                                        sh.scene.Preloader.playAnim('correct' + hcIndex, this.snail, () => {
                                            // this.setLettersInputEnabled(true);
                                            hc["bg"].disableInteractive();

                                            for (let c of this.cars) {
                                                this.scene.tweens.add({
                                                    targets: c["letter"],
                                                    alpha: 0,
                                                    duration: 500
                                                });
                                            }

                                            this.snail.originX = 0.5;
                                            if (completed) {
                                                sh.scene.Preloader.playAnim('final', this.snail);
                                                delayedCall(250, () => this.scene.sound.add('Snail laugh - final celebration animation').play());
                                                delayedCall(1800, () => { this.scene.sound.add('Final celebration').play(); });
                                            }
                                            // else sh.scene.Preloader.playAnim('idle', this.snail);
                                        });

                                        delayedCall(2450, () => {
                                            // completed = this.onCorrectAnswer();
                                            // this.scene.sound.add('First meteor explostion').play();
                                            // sh.scene.Preloader.playAnim('correct'+hcIndex, this.snail);
                                            delayedCall(completed ? 2000 : 1500, () => {
                                                if (!completed) {
                                                    this.shuffleInHexagons();
                                                } else {
                                                }
                                            })

                                            //wood
                                            let wood = this.bgWoodsContainer.getAll()[this.gameplay.correctAnswersCount - 1];
                                            this.scene.tweens.add({
                                                targets: wood,
                                                alpha: 1,
                                                duration: 500
                                            });
                                            wood.setScale(0.8 * 1 / 0.6);
                                            this.scene.tweens.add({
                                                targets: wood,
                                                scale: 1 / 0.6,
                                                duration: 200
                                            });
                                            let _y = wood.y;
                                            wood.y = _y - 100;
                                            this.scene.tweens.add({
                                                targets: wood,
                                                y: _y,
                                                duration: 450,
                                                ease: Phaser.Math.Easing.Bounce.Out
                                            });
                                            this.scene.tweens.add({
                                                targets: wood,
                                                angle: -3,
                                                yoyo: true,
                                                repeat: 2,
                                                duration: 100
                                            });
                                        });

                                        this.scene.tweens.add({
                                            targets: hc["bg"],
                                            alpha: 0,
                                            duration: 500,
                                            onComplete: () => {
                                                hc.visible = false;
                                            }
                                        });
                                        this.scene.tweens.add({
                                            targets: hc["letter"],
                                            alpha: 0,
                                            duration: 500
                                        });
                                    }
                                });
                            }
                            else {
                                this.scene.sound.add('Log selection').play();

                                if (d > 100) this.scene.sound.add('Snail moving').play();

                                // this.usedCarsIndexes = this.usedCarsIndexes || [];
                                // this.usedCarsIndexes.push(hc['index'])
                                this.snail['index'] = hc['index'];
                                this.setLettersInputEnabled(false);
                                this.scene.tweens.add({
                                    targets: this.snail,
                                    x: snaiPosX,
                                    duration: duration,
                                    onComplete: () => {
                                        delayedCall(1000, () => {
                                            this.scene.tweens.add({
                                                targets: hc["bg"],
                                                alpha: 1,
                                                duration: 500,
                                                onComplete: () => {
                                                    hc["bg"].disableInteractive();
                                                }
                                            });
                                        });

                                        delayedCall(950, () => this.scene.sound.add('Snail trying hard sound - wrong answer').play());
                                        sh.scene.Preloader.playAnim('wrong', this.snail, () => {
                                            this.snail.originX = 0.5;
                                            sh.scene.Preloader.playAnim('idle', this.snail);

                                            lost = this.onWrongAnswer();
                                            if (!lost) {
                                                // this.shuffleInHexagons();
                                                delayedCall(500, () => {
                                                    this.playCorrectAudio()

                                                    if (this.correctAudio && this.correctAudio.isPlaying) {
                                                        delayedCall((this.correctAudio.duration) * 1000, () => {
                                                            this.setLettersInputEnabled(true);
                                                        });
                                                    }
                                                    else {
                                                        this.setLettersInputEnabled(true);
                                                    }
                                                });
                                            } else {
                                                this.isLost = true;
                                            }
                                        });

                                        this.scene.tweens.add({
                                            targets: hc["bg"],
                                            alpha: 0,
                                            duration: 500
                                        });
                                        this.scene.tweens.add({
                                            targets: hc["letter"],
                                            alpha: 0,
                                            duration: 500
                                        });

                                        // delayedCall(700, ()=>{hc.visible=false;});
                                    }
                                });
                            }
                        });
                    }
                    this._btnSound.on('pointerup', () => {
                        this.playBtnClickAnim(this._btnSound);
                        this.onSoundClick();
                    });
                    this._btnClose.on('pointerup', () => {
                        this.playBtnClickAnim(this._btnClose);
                        this.onCloseClick();
                    });
                }
                hideAllUnusedBeehives() {
                    let i = this.gameplay.getCurrentTotalAnswersCount();
                    for (let k = i + 1; k < this._BeehiveEmptyArray.length; k++) {
                        this._BeehiveEmptyArray[k].visible = false;
                        this._Beehive[k].visible = false;
                    }
                }
                onCorrectAnswer() {
                    let correctAndLetterSoundSfxAudioKey = this.gameplay.getCorrectAndLetterSoundSfxAudioKey();
                    delayedCall(175, () => {
                        this.scene.sound.add(correctAndLetterSoundSfxAudioKey).play();
                    });

                    let i = this.gameplay.getCurrentTotalAnswersCount();
                    if (i < this._BeehiveEmptyArray.length) {
                        this._BeehiveEmptyArray[i].visible = false;
                        this._Beehive[i].visible = true;
                    }
                    this.hideAllUnusedBeehives();
                    let completed = this.gameplay.onCorrectAnswer();
                    delayedCall(0, () => this.scene.sound.add('Correct sfx').play());

                    // this.sfxCarAccelerating = this.scene.sound.add('Car accelerating');
                    // this.sfxCarAccelerating.play();
                    return completed;
                }
                onWrongAnswer(forceNewRound) {
                    let i = this.gameplay.getCurrentTotalAnswersCount();
                    if (i < this._BeehiveEmptyArray.length) {
                        this._BeehiveEmptyArray[i].visible = true;
                        this._Beehive[i].visible = false;
                    }
                    this.hideAllUnusedBeehives();
                    let lost = this.gameplay.onWrongAnswer(forceNewRound);
                    this.sfxWrong = this.scene.sound.add("wrong sfx");
                    this.sfxWrong.play();
                    return lost;
                }
                playCorrectAudio() {
                    if (this.correctAudio) {
                        this.correctAudio.stop();
                    }
                    this.correctAudio = this.scene.sound.add("englQuestion");
                    this.correctAudio.once('complete', () => {
                        this.correctAudio = this.scene.sound.add(this.gameplay.getCorrectAndLetterSoundSfxAudioKey());
                        this.correctAudio.play();
                    });
                    this.correctAudio.play();
                    if (this.areYouSureWindow && this.areYouSureWindow.parentContainer == this) {
                        this.correctAudio.pause();
                    }
                }
                onSoundClick() {
                    this.playCorrectAudio();
                }
                onCloseClick() {
                    this.showAreYouSurePage();
                    this.scene.sound.add('warning page pop up sfx').play();
                }
                playBtnClickAnim(target) {
                    let sc = target["defScale"] ? target["defScale"] : 1;
                    target.scaleX = target.scaleY = sc;
                    this.scene.tweens.add({
                        targets: target,
                        "scaleX": 0.9 * sc,
                        "scaleY": 0.9 * sc,
                        duration: 100,
                        yoyo: true
                    });
                }
                showInstructionPage() {
                    setPageBackground("bg-blue");
                    this.instructionPage = new screen.InstructionPage(this.scene, (target) => {
                        this.playBtnClickAnim(target);
                        this.remove(this.instructionPage);
                        this.showGameplay();
                        if (this.wfsnd) {
                            this.wfsnd.stop();
                        }
                    }, (target) => {
                        this.playBtnClickAnim(target);
                        if (this.wfsnd) {
                            this.wfsnd.stop();
                        }
                        if (!game.cache.json.get('gameplay')['welcomeAudioDisabled']) {
                            this.wfsnd = this.scene.sound.add("Welcome Find the sound");
                            this.wfsnd.play();
                        }
                    });
                    this.add(this.instructionPage);
                    try {
                        if (!game.cache.json.get('gameplay')['welcomeAudioDisabled']) {
                            this.wfsnd = this.scene.sound.add("Welcome Find the sound");
                            this.wfsnd.play();
                        }
                    }
                    catch (e) {
                        console.error(e);
                    }
                }
                showAreYouSurePage() {
                    /* for (let j = 0; j < this.cars.length; j++) {
                        if (this.cars[j]["raceTween"]) {
                            this.cars[j]["raceTween"].pause();
                        }
                    } */

                    pauseAllDelayedCalls();
                    setPageBackground("bg-blue");
                    this.pauseSounds();
                    if (!this.areYouSureWindow) this.areYouSureWindow = new screen.AreYouSureWindow(this.scene, () => {
                        this.remove(this.areYouSureWindow);
                        this.destroyGameplay();
                        this.showInstructionPage();
                    }, () => {
                        this.remove(this.areYouSureWindow);
                        this.unpauseSounds();
                        resumeAllDelayedCalls();

                        /* for (let j = 0; j < this.cars.length; j++) {
                            if (this.cars[j]["raceTween"]) {
                                this.cars[j]["raceTween"].resume();
                            }
                        } */

                        setPageBackground("bg-australia");
                    });
                    this.add(this.areYouSureWindow);
                }
                setLettersInputEnabled(enabled) {
                    if (enabled) {
                        for (let hc of this.cars) {
                            hc["bg"].setInteractive({ cursor: 'pointer' });
                            // hc["bg"].setInteractive(new Phaser.Geom.Rectangle(80, 100, 240, 200), Phaser.Geom.Rectangle.Contains);
                        }
                    }
                    else {
                        for (let hc of this.cars) {
                            hc["bg"].disableInteractive();
                        }
                    }
                }
                pauseSounds() {
                    if (this.correctAudio)
                        this.correctAudio.pause();
                    if (this.bgMusic)
                        this.bgMusic.pause();
                }
                unpauseSounds() {
                    if (this.correctAudio)
                        this.correctAudio.resume();
                    if (this.bgMusic)
                        this.bgMusic.resume();
                }
                destroyGameplay() {
                    this.setLettersInputEnabled(false);
                    this.cars = null;
                    this.snail = null;
                    this.usedCarsIndexes = false;
                    this.bgWoodsContainer.parentContainer.remove(this.bgWoodsContainer);
                    this.remove(this.gameplayContainer);
                    this.scene.sound.stopAll();
                    destroyAllDelayedCalls();
                }
            }
            screen.GameplayScreen = GameplayScreen;
        })(screen = sh.screen || (sh.screen = {}));
    })(sh || (sh = {}));
    (function (sh) {
        var screen;
        (function (screen) {
            class InstructionPage extends Phaser.GameObjects.Container {
                constructor(scene, onPlayClick, onSndClick) {
                    super(scene);
                    this._instructionPage = new Phaser.GameObjects.Image(this.scene, 0 - 105, 0 - 48, 'Instructions page  ALL ACTIVITY  TITLEs');
                    this._instructionPage.setOrigin(0, 0);
                    this._instructionPage.setInteractive();

                    this._instructionPageTitle = this.scene.add.text(475, 110, sh.App.json.instructionTitle ?? 'To the air', {
                        "fontFamily": "Kids Rock DEMO",
                        "fontSize": 40,
                        "color": "#43425D",
                        "align": 'center'
                    });
                    this._instructionPageTitle.setOrigin(0.5, 0.5);
                    window['this._instructionPageTitle'] = this._instructionPageTitle;
                    this._instructionPageTitle.setStroke('#ffffff', 5);
                    //  Apply the gradient fill.
                    const gradient = this._instructionPageTitle.context.createLinearGradient(0, 0, 0, this._instructionPageTitle.height);
                    gradient.addColorStop(0, '#eeef67');
                    gradient.addColorStop(0.55, '#e98c2c');
                    gradient.addColorStop(1, '#91301a');
                    this._instructionPageTitle.setFill(gradient);

                    this._btnPlay = new Phaser.GameObjects.Image(this.scene, game.scale.width / 2, 480 - 50, 'btnPLAY1');
                    this._btnPlay.setInteractive({ cursor: 'pointer' });
                    this._btnPlay.once('pointerup', onPlayClick);
                    setupButtonTextureBased(this._btnPlay, 'btnPLAY1', 'btnPLAY2');
                    this.instrTxt = this.scene.add.text(game.scale.width / 2, game.scale.height / 2, "Help Qari snail collect firewood \nfor the winter.", {
                        "fontFamily": "Kids Rock DEMO",
                        "fontSize": 30,
                        "color": "#43425D",
                        "align": 'center'
                    });
                    this.instrTxt.setLineSpacing(15);
                    this.instrTxt.setOrigin(0.5, 0.5);
                    this._btnSoundInstruction = new Phaser.GameObjects.Image(this.scene, 800 - 105, 156 - 50, 'Sound');
                    this._btnSoundInstruction.setInteractive({ cursor: 'pointer' });
                    this._btnSoundInstruction.on('pointerup', onSndClick);
                    this._btnSoundInstruction["defScale"] = this._btnSoundInstruction.scale;
                    setupButtonTextureBased(this._btnSoundInstruction, 'Sound', 'Sound HOVER EFFECT');
                    this.add(this._instructionPage);
                    this.add(this._instructionPageTitle);
                    this.add(this.instrTxt);
                    this.add(this._btnPlay);
                    this.add(this._btnSoundInstruction);
                }
            }
            screen.InstructionPage = InstructionPage;
        })(screen = sh.screen || (sh.screen = {}));
    })(sh || (sh = {}));
    (function (sh) {
        var screen;
        (function (screen) {
            class TryAgainWindow extends Phaser.GameObjects.Container {
                constructor(scene, onBack, onReplay) {
                    super(scene);
                    this.music = null;
                    this.setPosition(-106, -48);
                    this._bg = new Phaser.GameObjects.Image(this.scene, 0, 0, 'Try again page');
                    this._bg.setOrigin(0, 0);
                    this._bg.setInteractive();
                    this._star = new Phaser.GameObjects.Image(this.scene, 400, 415, 'Break Star');
                    this._btnBack = new Phaser.GameObjects.Image(this.scene, 600, 580, 'btnBACK1');
                    this._btnReplay = new Phaser.GameObjects.Image(this.scene, 765, 580, 'btnReplay1');
                    this.totalScoreTxt = this.scene.add.text(850, 355, "", {
                        "fontFamily": "Kids Rock Demo",
                        "fontSize": 35,
                        "color": "#F49F1C",
                        "align": 'center',
                        'stroke': '#70451A',
                        'strokeThickness': 6
                    });
                    this.totalScoreTxt.setOrigin(0.5, 0.5);
                    let grd = this.totalScoreTxt.context.createLinearGradient(0, 0, 0, this.totalScoreTxt.height);
                    grd.addColorStop(0, '#FFFF00');
                    grd.addColorStop(1, '#C17316');
                    this.totalScoreTxt.setFill(grd);
                    this.starScoreTxt = this.scene.add.text(635, 431, "", {
                        "fontFamily": "Kids Rock Demo",
                        "fontSize": 24,
                        "color": "#FFFFFF",
                        "align": 'center'
                    });
                    this.starScoreTxt.setOrigin(0.5, 0.5);
                    this.add([
                        this._bg,
                        this._star,
                        this._btnBack,
                        this._btnReplay,
                        this.totalScoreTxt,
                        this.starScoreTxt
                    ]);
                    this._btnBack.setInteractive({ cursor: 'pointer' });
                    this._btnBack.on('pointerup', () => {
                        onBack(this._btnBack);
                        angularComponentRef.backToMenu();
                        // if (this.music) {
                        //     this.music.stop();
                        // }
                    });
                    setupButtonTextureBased(this._btnBack, 'btnBACK1', 'btnBACK2');
                    this._btnReplay.setInteractive({ cursor: 'pointer' });
                    this._btnReplay.once('pointerup', () => {
                        onReplay(this._btnReplay);
                        if (this.music) {
                            this.music.stop();
                        }
                    });
                    setupButtonTextureBased(this._btnReplay, 'btnReplay1', 'btnReplay2');
                }
                show(score, starScore) {
                    this._star.scale = 1.25;
                    this.scene.tweens.add({
                        targets: this._star,
                        "scale": 1,
                        duration: 500,
                        ease: Phaser.Math.Easing.Back.Out
                    });
                    this.totalScoreTxt.text = '';//String(window.angularComponentRef.component.userInfo.totalScore);
                    this.starScoreTxt.text = String(0);
                    this.music = this.scene.sound.add("Fail - close one");
                    this.music.play();
                }
            }
            screen.TryAgainWindow = TryAgainWindow;
        })(screen = sh.screen || (sh.screen = {}));
    })(sh || (sh = {}));
}

function destroyStackWood() {
    if (game) {
        game.destroy();
    }
}
