var ctb;
var game;
var doughPress = (json) => {
    (function (ctb) {
        class App extends Phaser.Game {
            constructor(json) {
                App.json = json;
                let config = {
                    type: Phaser.AUTO,
                    width: App.CANVAS_WIDTH,
                    height: App.CANVAS_HEIGHT,
                    parent: 'game-container',
                    dom: {
                        createContainer: false
                    },
                    scale: {
                        mode: FitScaleManager.detectBestScaleMode(),
                        autoCenter: Phaser.Scale.CENTER_BOTH
                    },
                    transparent: true,
                    scene: {
                        create: () => {
                            this.scene.add('Boot', ctb.scene.Boot, true);
                        }
                    }
                };
                super(config);
            }
        }
        App.CANVAS_WIDTH = 980;
        App.CANVAS_HEIGHT = 600;
        ctb.App = App;
    })(ctb || (ctb = {}));
    var delayedCalls = [];
    function delayedCall(delay, callback, args, callbackScope) {
        let scene = game.scene.getAt(0);
        if (scene) {
            let dc = scene.time.delayedCall(delay, callback, args, callbackScope);
            delayedCalls.push(dc);
            return dc;
        }
        let t = setTimeout(callback, delay);
        delayedCalls.push(t);
        return t;
    }
    function pauseAllDelayedCalls() {
        for (let dc of delayedCalls) {
            if (dc instanceof Phaser.Time.TimerEvent) {
                dc.paused = true;
            }
        }
    }
    function resumeAllDelayedCalls() {
        for (let dc of delayedCalls) {
            if (dc instanceof Phaser.Time.TimerEvent) {
                dc.paused = false;
            }
        }
    }
    function destroyAllDelayedCalls() {
        for (let dc of delayedCalls) {
            if (dc instanceof Phaser.Time.TimerEvent) {
                dc.remove(false);
            }
            else {
                clearTimeout(dc);
            }
        }
        delayedCalls = [];
    }
    function destroyDelayedCall(dc) {
        if (dc instanceof Phaser.Time.TimerEvent) {
            dc.remove(false);
        }
        else {
            clearTimeout(dc);
        }
        let ind = delayedCalls.indexOf(dc);
        if (ind >= 0)
            delayedCalls.splice(ind, 1);
    }
    function setPageBackground(bg) {
        document.querySelector("html").style.backgroundImage = "url(assets/games/dough-press/imgs/" + bg + ".jpg)";
    }
    function setupButton(btn, frame) {
        btn.on('pointerdown', () => { btn.setFrame(frame + '_hover' + '0000'); });
        btn.on('pointerover', () => { btn.setFrame(frame + '_hover' + '0000'); game.scene.getAt(0).sound.add("button hover").play(); });
        btn.on('pointerout', () => { btn.setFrame(frame + '0000'); });
        btn.on('pointerup', () => { btn.setFrame(frame + '0000'); game.scene.getAt(0).sound.add('activity selection - button selection').play(); });
    }
    function setupButtonTextureBased(btn, texture, hoverTexture) {
        btn.on('pointerdown', () => { btn.setTexture(hoverTexture); });
        btn.on('pointerover', () => { btn.setTexture(hoverTexture); game.scene.getAt(0).sound.add("button hover").play(); });
        btn.on('pointerout', () => { btn.setTexture(texture); });
        btn.on('pointerup', () => {
            if (btn.parentContainer)
                btn.setTexture(texture); game.scene.getAt(0).sound.add('activity selection - button selection').play();
        });
    }
    function playBtnClickAnim(target) {
        let sc = target.hasOwnProperty("defScale") ? target["defScale"] : 1;
        target.scaleX = target.scaleY = sc;
        let scene = game.scene.getAt(0);
        scene.tweens.add({
            targets: target,
            "scaleX": 0.9 * sc,
            "scaleY": 0.9 * sc,
            duration: 100,
            yoyo: true
        });
    }
    /**
     * @author Roman Parada
     * This class is created to fix overlapping of bottom part of canvas by navigation bar in iOS.
     * It make a delayed resize of the canvas (like Phaser-3 FIT methods does) and it overrides Phaser-3 input window to Phaser-3 core transform methods
     *
     * How to use:
     * Just call the code line below in Boot scene's init() method of your project:
     * new FitScaleManager(this.game).setup();
     */
    class FitScaleManager {
        constructor(game) {
            this.doResize = () => {
                let scale = this.calculateScale();
                let newCanvasWidth = this.phaserScaleManager.width * scale;
                let newCanvasHeight = this.phaserScaleManager.height * scale;
                this.canvasStyle.width = newCanvasWidth + 'px';
                this.canvasStyle.height = newCanvasHeight + 'px';
                this.canvasStyle.marginLeft = (window.innerWidth - newCanvasWidth) / 2 + 'px';
                this.canvasStyle.marginTop = (window.innerHeight - newCanvasHeight) / 2 + 'px';
            };
            this.game = game;
            this.canvasStyle = this.game.canvas.style;
            this.phaserScaleManager = this.game.scale;
        }
        static detectBestScaleMode() {
            let iOS = /iPad|iPhone|iPod/.test(navigator.platform || "");
            let isAndroid = window.navigator.userAgent.toLowerCase().indexOf("android") > -1;
            return iOS || isAndroid ? Phaser.Scale.FIT : Phaser.Scale.NONE;
        }
        ;
        /**
         * Just call this method once in Boot scene's init() method
         */
        setup() {
            this.phaserScaleManager.addListener(Phaser.Scale.Events.RESIZE, this.onResize, this);
            this.overridePhaserTransformMethods();
            this.onResize();
        }
        calculateScale() {
            if (game.scale.scaleMode == Phaser.Scale.NONE)
                return 1;
            return Math.min(window.innerWidth / this.phaserScaleManager.width, window.innerHeight / this.phaserScaleManager.height);
        }
        overridePhaserTransformMethods() {
            this.game.scale.transformX = (pageX) => {
                return (pageX - parseInt(this.canvasStyle.marginLeft.split("px")[0])) / this.calculateScale();
            };
            this.game.scale.transformY = (pageY) => {
                return (pageY - parseInt(this.canvasStyle.marginTop.split("px")[0])) / this.calculateScale();
            };
        }
        onResize() {
            setTimeout(this.doResize, FitScaleManager.RESIZE_DELAY);
        }
    }
    FitScaleManager.RESIZE_DELAY = 500;

    (function (ctb) {
        var core;
        (function (core) {
            class Gameplay {
                constructor() {
                    this.allLettersNames = null;
                    this.currentRound = 0;
                    this.correctAnswersCount = 0;
                    this.wrongAnswersCount = 0;
                    this.correctAnswersCountThisRound = 0;
                    this.wrongAnswersCountThisRound = 0;
                    this.onNewRound = null;
                    this.nextLetterDelay = 0;
                    this.failsNumToLose = Number(game.cache.json.get('gameplay')["failsNumToLose"]);
                    this.useImages = Boolean(game.cache.json.get('gameplay')["useImages"]);
                }
                setupCallbacks(onComplete, onLose, onNewRound) {
                    this.onComplete = onComplete;
                    this.onLose = onLose;
                    this.onNewRound = onNewRound;
                }
                calculateScore() {
                    return this.totalRoundsNum - this.wrongAnswersCount;
                }
                onLettersPlaced() {
                    if (this.correctAnswersCountThisRound == 1) {
                        this.currentRound++;
                        if (this.correctAnswersCount >= this.totalRoundsNum) {
                            let score = this.calculateScore();
                            this.onComplete(score, score);
                            return true;
                        }
                        else {
                            this.nextLetter();
                        }
                    }
                    return false;
                }
                nextLetter() {
                    let fn = () => {
                        let thisRound = this.rounds.shift();
                        this.blockLetters = thisRound["blockLetters"];
                        this.correctWord = thisRound["correctWord"];
                        this.correctAnswersCountThisRound = 0;
                        this.wrongAnswersCountThisRound = 0;
                        if (this.onNewRound)
                            this.onNewRound();
                    };
                    if (this.nextLetterDelay == 0) {
                        fn();
                    }
                    else {
                        delayedCall(this.nextLetterDelay, fn);
                    }
                }
                onCorrectAnswer() {
                    this.correctAnswersCount++;
                    this.correctAnswersCountThisRound++;
                    this.nextLetterDelay = 3100;
                    return this.onLettersPlaced();
                }
                onWrongAnswer() {
                    this.wrongAnswersCount++;
                    this.wrongAnswersCountThisRound++;
                    this.nextLetterDelay = 2000;
                    if (this.wrongAnswersCount >= this.failsNumToLose) {
                        this.onLose(0, 0);
                        return true;
                    }
                    else {
                        this.onLettersPlaced();
                    }
                    return false;
                }
                getCorrectWordCharAt(at) {
                    return this.wordsLetters[this.correctWord][at];
                }
                getCorrectWordLettersNumber() {
                    return this.wordsLetters[this.correctWord].length;
                }
                getCurrentTotalAnswersCount() {
                    return this.correctAnswersCount + this.wrongAnswersCount;
                }
                getCurrentTotalAnswersCountThisRound() {
                    return this.correctAnswersCountThisRound + this.wrongAnswersCountThisRound;
                }
                isNewRound() {
                    return this.getCurrentTotalAnswersCountThisRound() == 0;
                }
                isRoundsComplete() {
                    return this.currentRound >= this.totalRoundsNum;
                }
                getAudioKeyOfChar(letter) {
                    let json = game.cache.json.get('gameplay');
                    let letters = json["letters"];
                    for (let d of letters) {
                        if (letter == d['letterName']) {
                            return d['audioKey'];
                        }
                    }
                    return null;
                }
                reset() {
                    this.nextLetterDelay = 0;
                    this.setupCallbacks(null, null, null);
                    let json = game.cache.json.get('gameplay');
                    this.wordsLetters = json["wordsLetters"];
                    this.rounds = json["rounds"].slice();
                    this.totalRoundsNum = this.rounds.length;
                    this.letters = json["letters"].slice();
                    this.allLettersNames = [];
                    for (let d of this.letters)
                        this.allLettersNames.push(d["letterName"]);
                    this.nextLetter();
                    this.currentRound = 0;
                    this.correctAnswersCount = 0;
                    this.wrongAnswersCount = 0;
                    this.correctAnswersCountThisRound = 0;
                    this.wrongAnswersCountThisRound = 0;
                }
            }
            core.Gameplay = Gameplay;
        })(core = ctb.core || (ctb.core = {}));
    })(ctb || (ctb = {}));

    (function (ctb) {
        var scene;
        (function (scene) {
            class Boot extends Phaser.Scene {
                init() {
                    // new FitScaleManager(this.game).setup();
                    this.game.scale.transformX = (pageX) => {
                        let offsetLeft = 0;
                        let parentElement = game.canvas.parentElement;
                        while (parentElement) {
                            if (parentElement.offsetLeft) {
                                offsetLeft = parentElement.offsetLeft;
                                break;
                            }
                            parentElement = parentElement.parentElement;
                        }
                        return (pageX - offsetLeft) * this.game.scale.displayScale.x;
                    };
                    this.game.scale.transformY = (pageY) => {
                        let offsetTop = 0;
                        let parentElement = game.canvas.parentElement;
                        while (parentElement) {
                            if (parentElement.offsetTop) {
                                offsetTop = parentElement.offsetTop;
                                break;
                            }
                            parentElement = parentElement.parentElement;
                        }
                        return (pageY - offsetTop) * this.game.scale.displayScale.y;
                    };
                }
                create() {
                    game.scene.remove('Boot');
                    game.scene.add('Preloader', ctb.scene.Preloader, true);
                }
            }
            scene.Boot = Boot;
        })(scene = ctb.scene || (ctb.scene = {}));
    })(ctb || (ctb = {}));

    (function (ctb) {
        var scene;
        (function (scene) {
            var Gameplay = ctb.core.Gameplay;
            class MainScene extends Phaser.Scene {
                create() {
                    this.gameplay = new Gameplay();
                    this.gameplayScreen = new ctb.screen.GameplayScreen(this, this.gameplay);
                    this.children.add(this.gameplayScreen);
                    this.gameplayScreen.showInstructionPage();
                }
                update() {
                    this.gameplayScreen._update();
                }
            }
            scene.MainScene = MainScene;
        })(scene = ctb.scene || (ctb.scene = {}));
    })(ctb || (ctb = {}));

    (function (ctb) {
        var scene;
        (function (scene) {
            class Preloader extends Phaser.Scene {
                preload() {
                    if (typeof ctb.App.json == "string") this.load.json('gameplay', ctb.App.json); else this.game.cache.json.add('gameplay', ctb.App.json);
                }
                create() {
                    let json = game.cache.json.get('gameplay');
                    if (json["useImages"]) {
                        for (let l of json["letters"]) {
                            this.load.image(l["letterName"], "assets/games/dough-press/imgs/letters/" + l["letterName"] + ".png");
                        }
                        for (let r of json["rounds"]) {
                            this.load.image(r["correctWord"], "assets/games/dough-press/imgs/words/" + r["correctWord"] + ".png");
                        }
                    }

                    for (let r of json["rounds"]) {
                        this.load.audio(r["correctWord"], "assets/games/dough-press/sound/mp3/words/" + r["correctWord"] + '.mp3');
                    }
                    let progressTxt = this.add.text(game.scale.width / 2, game.scale.height / 2 + 50, "", {
                        "fontFamily": "QuranErafont",
                        "fontSize": 25,
                        "color": "#000000",
                        "align": 'center'
                    });
                    progressTxt.setOrigin(0.5, 0.5);
                    this.load.pack('preloader', 'assets/games/dough-press/pack.json');
                    this.load.on('progress', (value) => {
                        progressTxt.text = Math.ceil(value * 100) + "%";
                    }, this);
                    this.load.on('complete', () => {
                        this.nextScene();
                    });
                    this.load.start();
                }
                static playAnim(animKey, sprite, onComplete = null) {
                    let mainScene = game.scene.getScene('ScreenMain');
                    if (!mainScene.anims.exists(animKey)) {
                        let data = Preloader.ANIMS_DATA[animKey];
                        mainScene.anims.create({
                            key: animKey,
                            frames: mainScene.anims.generateFrameNames(data['atlas'], {
                                start: data['start'], end: data['end'], zeroPad: data['padNum'],
                                prefix: data['prefix'], suffix: ''
                            }),
                            frameRate: data['frameRate'],
                            repeat: data['repeat']
                        });
                    }
                    if (sprite.anims.currentAnim) {
                        sprite.off('animationcomplete');
                    }
                    sprite.anims.stop();
                    sprite.play(animKey);
                    sprite.on('animationcomplete', () => {
                        if (onComplete)
                            onComplete();
                    });
                    return sprite;
                }
                nextScene() {
                    game.scene.remove('Preloader');
                    game.scene.add('ScreenMain', ctb.scene.MainScene, true);
                }
            }
            Preloader.ANIMS_DATA = {};
            scene.Preloader = Preloader;
        })(scene = ctb.scene || (ctb.scene = {}));
    })(ctb || (ctb = {}));

    (function (ctb) {
        var screen;
        (function (screen) {
            class AreYouSureWindow extends Phaser.GameObjects.Container {
                constructor(scene, onYes, onNo) {
                    super(scene);
                    this._areYouSurePage = new Phaser.GameObjects.Image(this.scene, -105, 0 - 48, 'Exit warning');
                    this._areYouSurePage.setOrigin(0, 0);
                    //this._areYouSurePage.setInteractive();
                    this._btnSureYes = new Phaser.GameObjects.Image(this.scene, game.scale.width / 2 - 95, 485 - 50, 'btnYES1');
                    this._btnSureYes.setInteractive({ cursor: 'pointer' });
                    this._btnSureYes.once('pointerup', () => {
                        onYes();
                        window.angularComponentRef.backToMenu();
                    });
                    var modalTextStyle = {
                        backgroundColor: 'transparent',
                        stroke: '#ffffff',
                        strokeThickness: 2,
                        lineSpacing: 20,
                        fontFamily: 'Kids Rock DEMO',
                        fontSize: 27,
                        color: '#43425d',
                        wordWrap: true,
                        align: 'center'
                    };
                    var modalText = this.scene.add.text(game.scale.width / 2 + 12, game.scale.height / 2, "Your progress in this activity will not be saved. \n Are you sure you want to exit? ", modalTextStyle);
                    modalText.setOrigin(0.5, 0.5);

                    setupButtonTextureBased(this._btnSureYes, 'btnYES1', 'btnYES2');
                    this._btnSureNo = new Phaser.GameObjects.Image(this.scene, game.scale.width / 2 + 95, 485 - 50, 'btnNO1');
                    this._btnSureNo.setInteractive({ cursor: 'pointer' });
                    this._btnSureNo.once('pointerup', onNo);
                    setupButtonTextureBased(this._btnSureNo, 'btnNO1', 'btnNO2');
                    this.add(this._areYouSurePage);
                    this.add(this._btnSureYes);
                    this.add(this._btnSureNo);
                    this.add(modalText);

                }
            }
            screen.AreYouSureWindow = AreYouSureWindow;
        })(screen = ctb.screen || (ctb.screen = {}));
    })(ctb || (ctb = {}));

    (function (ctb) {
        var screen;
        (function (screen) {
            class CompleteWindow extends Phaser.GameObjects.Container {
                constructor(scene, onBack, onReplay, onNext) {
                    super(scene);
                    this.music = null;
                    this.setPosition(-104.5, -48);
                    this._bgComplete = new Phaser.GameObjects.Image(this.scene, 0, 0, 'Completion page LATEST UPDATED');
                    this._bgComplete.setOrigin(0, 0);
                    //this._bgComplete.setInteractive();
                    this._cup = new Phaser.GameObjects.Image(this.scene, 400, 410, 'Trophy');
                    this._btnBack = new Phaser.GameObjects.Image(this.scene, 570, 570, 'btnBACK1');
                    this._btnReplay = new Phaser.GameObjects.Image(this.scene, 720, 570, 'btnReplay1');
                    this._btnNext = new Phaser.GameObjects.Image(this.scene, 870, 570, 'btnNEXT1');
                    let _CollectedPoints = new Phaser.GameObjects.Image(this.scene, 620, 440, 'Collected Points');
                    this.totalScoreTxt = this.scene.add.text(845, 352, "", {
                        "fontFamily": "Kids Rock Demo",
                        "fontSize": 35,
                        "color": "#F49F1C",
                        "align": 'center',
                        'stroke': '#70451A',
                        'strokeThickness': 6
                    });
                    this.totalScoreTxt.setOrigin(0.5, 0.5);
                    let grd = this.totalScoreTxt.context.createLinearGradient(0, 0, 0, this.totalScoreTxt.height);
                    grd.addColorStop(0, '#FFFF00');
                    grd.addColorStop(1, '#C17316');
                    this.totalScoreTxt.setFill(grd);
                    this.starScoreTxt = this.scene.add.text(648, 433, "", {
                        "fontFamily": "Kids Rock Demo",
                        "fontSize": 24,
                        "color": "#FFFFFF",
                        "align": 'center'
                    });
                    this.starScoreTxt.setOrigin(0.5, 0.5);
                    this.add([
                        this._bgComplete,
                        _CollectedPoints,
                        this._cup,
                        this._btnBack,
                        this._btnReplay,
                        this._btnNext,
                        this.totalScoreTxt,
                        this.starScoreTxt
                    ]);
                    this._btnBack.setInteractive({ cursor: 'pointer' });
                    this._btnBack.on('pointerup', () => {
                        onBack(this._btnBack);
                        window.angularComponentRef.backToMenu();
                        // if (this.music) {
                        //     this.music.stop();
                        // }
                    });
                    setupButtonTextureBased(this._btnBack, 'btnBACK1', 'btnBACK2');
                    this._btnReplay.setInteractive({ cursor: 'pointer' });
                    this._btnReplay.once('pointerup', () => {
                        onReplay(this._btnReplay);
                        if (this.music) {
                            this.music.stop();
                        }
                    });
                    setupButtonTextureBased(this._btnReplay, 'btnReplay1', 'btnReplay2');
                    this._btnNext.setInteractive({ cursor: 'pointer' });
                    this._btnNext.on('pointerup', () => {
                        onNext(this._btnNext);
                        window.angularComponentRef.nextActivity();
                        // if (this.music) {
                        //     this.music.stop();
                        // }
                    });
                    setupButtonTextureBased(this._btnNext, 'btnNEXT1', 'btnNEXT2');
                }
                show(score, starScore) {
                    window.angularComponentRef.gameFinish({ score, starScore });
                    this._cup.scale = 1.25;
                    this.scene.tweens.add({
                        targets: this._cup,
                        "scale": 1,
                        duration: 500,
                        ease: Phaser.Math.Easing.Back.Out
                    });
                    this.totalScoreTxt.text = String(window.angularComponentRef.component.userInfo.totalScore);
                    this.starScoreTxt.text = String(starScore);
                    // let music = this.scene.sound.add("viktory");
                    this.music = this.scene.sound.add("Activity completion fantastic");
                    this.music.play();
                }
            }
            screen.CompleteWindow = CompleteWindow;
        })(screen = ctb.screen || (ctb.screen = {}));
    })(ctb || (ctb = {}));

    (function (ctb) {
        var screen;
        (function (screen) {
            var Preloader = ctb.scene.Preloader;
            class GameplayScreen extends Phaser.GameObjects.Container {
                constructor(scene, gameplay) {
                    super(scene);
                    this.bgMusic = null;
                    this.correctAudio = null;
                    this.correctAudioWord = null;
                    this.idleDelayedCall = null;
                    this.soundWrongDrop = null;
                    this.wfsnd = null;
                    this.showCompleteWindow = (score, starScore) => {
                        let completeWindow = new screen.CompleteWindow(this.scene, (target) => {
                            playBtnClickAnim(target);
                        }, (target) => {
                            playBtnClickAnim(target);
                            this.destroyGameplay();
                            completeWindow.destroy(true);
                            this.showInstructionPage();
                        }, (target) => {
                            playBtnClickAnim(target);
                        });
                        this.setInputEnabled(false);
                        delayedCall(2000, () => {
                            setPageBackground("bg-blue");
                            this.add(completeWindow);
                            completeWindow.show(score, starScore);
                            // this.bgMusic.stop();
                        });
                    };
                    this.showLoseWindow = (score, starScore) => {
                        let tryAgainWindow = new screen.TryAgainWindow(this.scene, (target) => {
                            playBtnClickAnim(target);
                        }, (target) => {
                            playBtnClickAnim(target);
                            this.destroyGameplay();
                            tryAgainWindow.destroy(true);
                            this.showInstructionPage();
                        });
                        this.setInputEnabled(false);
                        delayedCall(1500, () => {
                            setPageBackground("bg-blue");
                            this.add(tryAgainWindow);
                            tryAgainWindow.show(score, starScore);
                            // this.bgMusic.stop();
                        });
                    };
                    this.gameplay = gameplay;
                    window["gs"] = this;
                    this._gameStage = new Phaser.GameObjects.Image(this.scene, game.scale.width / 2, game.scale.height / 2, 'BG');
                    this._gameStage.setOrigin(0.5, 0.5);
                    this._gameStage.setScale(1.02);
                    //this._gameStage.setInteractive();
                    this.add(this._gameStage);
                    this._btnClose = new Phaser.GameObjects.Image(this.scene, 1025 - 105, 100 - 50, 'x Button');
                    this._btnClose.setInteractive({ cursor: 'pointer' });
                    this._btnClose["defScale"] = this._btnClose.scale;
                    setupButtonTextureBased(this._btnClose, 'x Button', 'x Button HOVER EFFECT');
                    this.add(this._btnClose);
                    this._btnClose.on('pointerup', () => {
                        playBtnClickAnim(this._btnClose);
                        this.onCloseClick();
                    });
                    this._btnSound = new Phaser.GameObjects.Image(this.scene, 160 - 105, 100 - 50, 'Sound');
                    this._btnSound.setInteractive({ cursor: 'pointer' });
                    this._btnSound["defScale"] = this._btnSound.scale;
                    setupButtonTextureBased(this._btnSound, 'Sound', 'Sound HOVER EFFECT');
                    this.add(this._btnSound);
                    this._btnSound.on('pointerup', () => {
                        playBtnClickAnim(this._btnSound);
                        this.onSoundClick();
                    });
                }
                playCorrectAudio() {
                    if (this.correctAudio) {
                        this.correctAudio.stop();
                    }
                    this.correctAudio = this.scene.sound.add(this.gameplay.correctWord);
                    this.correctAudio.play();
                    if (this.areYouSureWindow && this.areYouSureWindow.parentContainer == this) {
                        this.correctAudio.pause();
                    }
                    if (this.correctAudioWord)
                        this.correctAudioWord.stop();
                }
                onSoundClick() {
                    this.playCorrectAudio();
                }
                showGameplay() {
                    setPageBackground("bg-australia");
                    // this.bgMusic = this.scene.sound.add("Winter bg sound");
                    // this.bgMusic.play();
                    // this.bgMusic.loop = true;
                    this.gameplayContainer = new Phaser.GameObjects.Container(this.scene);
                    this.add(this.gameplayContainer);
                    this.gameplay.reset();
                    this.prepareRound();
                    this.gameplay.setupCallbacks(this.showCompleteWindow, this.showLoseWindow, () => {
                        this.onNewRound(true);
                    });
                    this.createTallies();
                }
                prepareRound() {
                    delayedCall(500, () => this.playCorrectAudio());
                    this.gameplayContainer.removeAll();

                    this._RoundedRectangle8 =  new Phaser.GameObjects.Image(this.scene, 60, 323, "Rounded Rectangle 8");
                    this.gameplayContainer.add(this._RoundedRectangle8);
                    this._RoundedRectangle8.scaleY = 1.35;

                    this._DoughTRANSPARANT = new Phaser.GameObjects.Image(this.scene, 495, 222, "Dough TRANSPARANT");
                    this.gameplayContainer.add(this._DoughTRANSPARANT);

                    this._PressButton = new Phaser.GameObjects.Image(this.scene, 495, 360, 'Press button');
                    this._PressButton.setInteractive({ cursor: 'pointer' });
                    this._PressButton.on('pointerup', () => {
                        this.checkTargetBlockLetters();
                    });
                    this._PressButton.on('pointerdown', () => { this._PressButton.setTexture('Press Hover'); });
                    this._PressButton.on('pointerover', () => { game.scene.getAt(0).sound.add("button hover").play(); });
                    this._PressButton.on('pointerout', () => { this._PressButton.setTexture('Press button'); });
                    this._PressButton.on('pointerup', () => {
                        if (this._PressButton.parentContainer) {
                            this._PressButton.setTexture('Press button'); game.scene.getAt(0).sound.add('activity selection - button selection').play();
                        }
                    });
                    this._PressButton["defScale"] = this._PressButton.scale;
                    this.gameplayContainer.add(this._PressButton);

                    this._HandIcon = new Phaser.GameObjects.Image(this.scene, 545, 410, 'Hand Icon');
                    this._HandIcon.setOrigin(0, 1);
                    this._HandIcon.scale = 0.6;
                    this._HandIcon.visible = false;
                    this.gameplayContainer.add(this._HandIcon);

                    this.pressleft = new Phaser.GameObjects.Image(this.scene, 125 - 180, 215, 'pressleft');
                    this.pressleft.setOrigin(0, 0.5);
                    this.gameplayContainer.add(this.pressleft);

                    this.pressRight = new Phaser.GameObjects.Image(this.scene, 545 + 180, 215, 'pressleft');
                    this.pressRight.setOrigin(0, 0.5);
                    this.pressRight.flipX = true;
                    this.gameplayContainer.add(this.pressRight);

                    let shapeL = new Phaser.GameObjects.Graphics(this.scene);
                    shapeL.fillStyle(0xffffff);
                    shapeL.beginPath();
                    shapeL.fillRect(125 + 30, 215 - 120 / 2, 316, 120);
                    shapeL.fillCircle(125 + 30, 215 + 5, 30);
                    shapeL.fillPath();
                    let maskL = shapeL.createGeometryMask();
                    this.pressleft.setMask(maskL);

                    let shapeR = new Phaser.GameObjects.Graphics(this.scene);
                    shapeR.fillStyle(0xffffff);
                    shapeR.beginPath();
                    shapeR.fillRect(545 - 30, 215 - 120 / 2, 316, 120);
                    shapeR.fillCircle(545 + 316 - 30, 215 + 5, 30);
                    shapeR.fillPath();
                    let maskR = shapeR.createGeometryMask();
                    this.pressRight.setMask(maskR);

                    let randomizedLetter = Phaser.Utils.Array.Shuffle(this.gameplay.blockLetters.slice());
                    if (this.gameplay.useImages) {
                        let correctWord = new Phaser.GameObjects.Image(this.scene, 485, 75, this.gameplay.correctWord);
                        this.gameplayContainer.add(correctWord);
                    }
                    else {
                        let correctWord = this.scene.add.text(485, 75, "", {
                            "fontFamily": "QuranErafont",
                            "fontSize": 90,
                            "color": "#000000",
                            "align": 'center'
                        });
                        correctWord.setOrigin(0.5, 0.5);
                        correctWord.style.fixedHeight = 140;
                        correctWord.setText(this.gameplay.correctWord);
                        this.gameplayContainer.add(correctWord);
                    }
                    this.selectableLetters = [];
                    this.targetBlocks = [];
                    let correctWordLettersNumber = this.gameplay.wordsLetters ? this.gameplay.getCorrectWordLettersNumber() : this.gameplay.correctWord.length;
                    for (let i = 0; i < correctWordLettersNumber; i++) {
                        let b = {
                            x: 356 + i * (i == 2 ? 140 : 142), y: 221.5
                        };
                        b['alreadyFilled'] = false;
                        this.targetBlocks.push(b);
                        if (this.gameplay.wordsLetters) {
                            b["-letter-text"] = this.gameplay.getCorrectWordCharAt(correctWordLettersNumber - 1 - i);
                        } else {
                            b["-letter-text"] = this.gameplay.correctWord.charAt(this.gameplay.correctWord.length - 1 - i);
                        }
                        b["-block-"] = null;
                    }
                    for (let i = 0; i < randomizedLetter.length; i++) {
                        let a = new Phaser.GameObjects.Container(this.scene, 196 + i * 152, 487);
                        a.add(a["-image-"] = new Phaser.GameObjects.Image(this.scene, 0, 0, 'DoughNormal'));
                        a["-image-"].setOrigin(0.5, 0.5);
                        this.selectableLetters.push(a);
                        let txt;
                        if (this.gameplay.useImages) {
                            txt = new Phaser.GameObjects.Image(this.scene, 0, 0, randomizedLetter[i]);
                            a.add(txt);
                        }
                        else {
                            txt = this.scene.add.text(0, 0, "", {
                                "fontFamily": "QuranErafont",
                                "fontSize": 55,
                                "color": "#000000",
                                "align": 'center'
                            });
                            txt.setOrigin(0.5, 0.5);
                            txt.style.fixedHeight = 75;
                            txt.setText(randomizedLetter[i]);
                            a.add(txt);
                        }
                        a['startPosition'] = { x: a.x, y: a.y };
                        a["-letter-"] = txt;
                        a["-letter-text"] = randomizedLetter[i];
                        this.gameplayContainer.add(a);
                        a['-draggable-'] = true;
                    }
                    for (let a of this.selectableLetters) {
                        a.setSize(a["-image-"].width, a["-image-"].height);
                        a.setInteractive({ cursor: 'pointer' });
                        this.scene.input.setDraggable(a);
                        a.on('pointerdown', () => {
                            if (a["-placedAtBlock-"]) {
                                a["-placedAtBlock-"]['alreadyFilled'] = false;
                                a["-placedAtBlock-"] = null;
                            }

                            a['-pointerdown-'] = true;
                            if (a['-draggable-'])
                                this.scene.sound.add("drag from its spot").play();
                        });
                        a.on('pointerup', () => {
                            a['-pointerdown-'] = false;
                            if (!a['-draggable-'])
                                return;

                            let targetBlock;
                            for (targetBlock of this.targetBlocks) {
                                if (targetBlock['alreadyFilled'])
                                    continue;
                                if (Phaser.Math.Distance.Between(a.x, a.y, targetBlock.x, targetBlock.y) < 50) {
                                    //as
                                    // a['-draggable-'] = false;
                                    targetBlock['alreadyFilled'] = true;
                                    this.scene.tweens.add({
                                        targets: a,
                                        x: targetBlock.x,
                                        y: targetBlock.y,
                                        duration: 300,
                                        ease: Phaser.Math.Easing.Back.Out
                                    });
                                    targetBlock["-block-"] = a;
                                    a["-placedAtBlock-"] = targetBlock;
                                    this.scene.sound.add("correct drop").play();
                                    this.updateHandIconVisibility();
                                    return;
                                }
                            }
                            this.updateHandIconVisibility();
                            this.moveBridgeBackToStartPosition(a, null, true);
                        });
                        a.on('pointerout', () => {
                            if (!a['-draggable-'])
                                return;
                            if (!a['-pointerdown-'])
                                return;
                            this.moveBridgeBackToStartPosition(a, null, true);
                        });
                    }

                    this.longIce = new Phaser.GameObjects.Image(this.scene, 495, 215, 'BIG DOUGH Normal');
                    this.longIce.setOrigin(0.5, 0.5);
                    this.longIce.alpha = 0;
                    this.gameplayContainer.add(this.longIce);

                    this.createInput();
                    this.setInputEnabled(false);
                    // delayedCall(1200, ()=>{
                    if (this.gameplay.isNewRound()) {
                        if (!this.gameplay.isRoundsComplete()) {
                            // delayedCall(2000, ()=>{
                            this.setInputEnabled(true);
                            // });
                        }
                    }
                    else {
                        this.setInputEnabled(true);
                    }
                    // });
                }

                updateHandIconVisibility() {
                    this.hideHandIcon();
                    if (this.targetBlocks.every(block => !!block['-block-'])) {
                        this.showHandIconAnim();
                    }
                }

                showHandIconAnim() {
                    this._HandIcon.visible = true;
                    this._HandIcon.angle = 0;
                    this._handTween = this.scene.tweens.add({
                        targets: this._HandIcon,
                        "angle": 10,
                        yoyo: true,
                        repeat: -1,
                        duration: 500
                    });
                }

                hideHandIcon() {
                    this._HandIcon.visible = false;
                    if (this._handTween) {
                        this.scene.tweens.remove(this._handTween);
                        this._handTween = null;
                    }
                }

                doCorrectPress() {
                    this.scene.tweens.add({
                        targets: this._DoughTRANSPARANT,
                        alpha: 0,
                        duration: 250,
                        ease: Phaser.Math.Easing.Linear,
                    });

                    this.startPressAnim();
                    // this.scene.sound.add("Placing block above").play();
                    this.longIce.scaleX = 1;
                    this.longIce.alpha = 0;
                    this.scene.tweens.add({
                        targets: this.longIce,
                        alpha: 1,
                        scaleX: 0.4,
                        duration: 160,
                        delay: 20,
                        ease: Phaser.Math.Easing.Circular.Out
                    });
                    this.scene.tweens.add({
                        targets: this.longIce,
                        scaleX: 1,
                        duration: 300,
                        delay: 185,
                        ease: Phaser.Math.Easing.Sine.InOut
                    });
                    let k = 0;
                    for (let targetBlock of this.targetBlocks) {
                        k++;
                        this.scene.tweens.add({
                            targets: targetBlock['-block-']["-image-"],
                            duration: 80,
                            alpha: 0,
                            delay: 30,
                            ease: Phaser.Math.Easing.Sine.Out
                        });
                        if (targetBlock == this.targetBlocks[1])
                            continue;
                        this.scene.tweens.add({
                            targets: targetBlock['-block-'],
                            x: this.targetBlocks[1]['x'],
                            duration: 140,
                            scaleY: 1.15,
                            scaleX: 0.7,
                            ease: Phaser.Math.Easing.Sine.Out,
                            delay: 60
                        });
                        this.scene.tweens.add({
                            targets: targetBlock['-block-'],
                            x: this.targetBlocks[1]['x'],
                            duration: 100,
                            alpha: 0,
                            delay: 50
                        });

                    }
                    delayedCall(400, () => {
                        if (this.gameplay.useImages) {
                            this.targetBlocks[1]['-block-']["-letter-"].setTexture(this.gameplay.correctWord);
                        }
                        else {
                            this.targetBlocks[1]['-block-']["-letter-"].setText(this.gameplay.correctWord);
                        }
                    });
                    //this.scene.sound.add("Letters joining sound").play();
                    delayedCall(900, () => this.scene.sound.add("Correct click").play());
                    //delayedCall(1500, () => this.scene.sound.add("success for corect word").play());
                    this.onCorrectAnswer();
                }

                startPressAnim() {
                    this.scene.tweens.add({
                        targets: this.pressleft,
                        x: 125,
                        duration: 200,
                        ease: Phaser.Math.Easing.Quintic.Out
                    });
                    this.scene.tweens.add({
                        targets: this.pressRight,
                        x: 545,
                        duration: 200,
                        ease: Phaser.Math.Easing.Quintic.Out
                    });

                    delayedCall(250, () => {
                        this.scene.tweens.add({
                            targets: this.pressleft,
                            x: 125 - 180,
                            duration: 300,
                            ease: Phaser.Math.Easing.Sine.Out
                        });
                        this.scene.tweens.add({
                            targets: this.pressRight,
                            x: 545 + 180,
                            duration: 300,
                            ease: Phaser.Math.Easing.Sine.Out
                        });
                    });
                    this.scene.sound.add("Machine squeezes").play();
                }

                startWrongPressAnim() {
                    this.scene.tweens.add({
                        targets: this.pressleft,
                        x: -24,
                        duration: 180,
                        ease: Phaser.Math.Easing.Back.Out
                    });
                    this.scene.tweens.add({
                        targets: this.pressRight,
                        x: 700,
                        duration: 180,
                        ease: Phaser.Math.Easing.Back.Out
                    });

                    delayedCall(200, () => {
                        this.scene.tweens.add({
                            targets: this.pressleft,
                            x: 125 - 180,
                            duration: 300,
                            ease: Phaser.Math.Easing.Sine.Out
                        });
                        this.scene.tweens.add({
                            targets: this.pressRight,
                            x: 545 + 180,
                            duration: 300,
                            ease: Phaser.Math.Easing.Sine.Out
                        });
                    });
                    this.scene.sound.add("Machine getting stuck").play();
                }

                _update() {
                    if (this.selectableLetters) {
                        for (let a of this.selectableLetters) {
                            if (a.x < 0 + a["-image-"].width / 2) {
                                a.x = 0 + a["-image-"].width / 2;
                            }
                            else if (a.x > game.scale.width - a["-image-"].width / 2) {
                                a.x = game.scale.width - a["-image-"].width / 2;
                            }
                            if (a.y < 0 + a["-image-"].height / 2) {
                                a.y = a["-image-"].height / 2;
                            }
                            else if (a.y > game.scale.height - a["-image-"].height / 2) {
                                a.y = game.scale.height - a["-image-"].height / 2;
                            }
                        }
                    }
                }
                placeAppleOverBuckets(a) {
                    if (!a.parentContainer)
                        return;
                    this.gameplayContainer.remove(a);
                    this.gameplayContainer.addAt(a, this.gameplayContainer.length);
                }
                onNewRound(showOut) {
                    this.scene.sound.add("next_round").play();
                    this.setInputEnabled(false);
                    if (showOut) {
                        this.prepareRound();
                    }
                }
                createInput() {
                    this.scene.input.on('drag', (pointer, block, dragX, dragY) => {
                        if (!block['-draggable-'])
                            return;
                        block.x = dragX;
                        block.y = dragY;
                        this.gameplayContainer.bringToTop(block);
                    });
                    this._btnClose.setInteractive({ cursor: 'pointer', pixelPerfect: true });
                }
                checkTargetBlockLetters() {
                    let sameLettersNum = 0;
                    for (let targetBlock of this.targetBlocks) {
                        if (!targetBlock['-block-']) {
                            // this.scene.sound.add("Placing block above").play();
                            return;
                        }
                        if (targetBlock["-letter-text"] == targetBlock['-block-']["-letter-text"]) {
                            sameLettersNum++;
                        }
                    }
                    this.setInputEnabled(false);
                    this.hideHandIcon();
                    if (sameLettersNum == this.targetBlocks.length) {
                        this.doCorrectPress();
                    }
                    else {
                        for (let targetBlock of this.targetBlocks) targetBlock['-block-']["-image-"].setTexture('DoughWrong');

                        this.startWrongPressAnim();
                        delayedCall(500, () => { this.scene.sound.add("wrong drop").play(); });
                        delayedCall(1800, () => {
                            for (let targetBlock of this.targetBlocks) {
                                for (let targetBlock of this.targetBlocks) targetBlock['-block-']["-image-"].setTexture('DoughNormal');
                                this.moveBridgeBackToStartPosition(targetBlock['-block-'], null, true);
                            }
                            // delayedCall(100, ()=>this.scene.sound.add("all three blocks move back after wrong").play());
                        });
                        delayedCall(1900, () => {
                            this.onWrongAnswer();
                            for (let targetBlock of this.targetBlocks) {
                                targetBlock['alreadyFilled'] = false;
                                targetBlock['-block-']['-draggable-'] = true;
                                targetBlock["-block-"] = null;
                            }
                            this.updateHandIconVisibility();
                        });
                        delayedCall(2750, () => {
                            this.setInputEnabled(true);
                        });
                    }
                }
                moveBridgeBackToStartPosition(block, onComplete, playSound) {
                    this.scene.tweens.add({
                        targets: block,
                        x: block['startPosition'].x,
                        y: block['startPosition'].y,
                        duration: 350,
                        ease: Phaser.Math.Easing.Sine.Out,
                        onComplete: () => {
                            if (onComplete)
                                onComplete();
                        }
                    });
                    if (Phaser.Math.Distance.Between(block['startPosition'].x, block['startPosition'].y, block.x, block.y) > 20) {
                        delayedCall(100, () => {
                            if (playSound) {
                                this.scene.sound.add("block goes back when released").play();
                            }
                        });
                    }
                    this.placeAppleOverBuckets(block);
                }
                onCorrectAnswer() {
                    let i = this.gameplay.getCurrentTotalAnswersCount();
                    this.tallyEmptyArray[i].visible = false;
                    this.tally[i].visible = true;
                    let completed = this.gameplay.onCorrectAnswer();
                    return completed;
                }
                onWrongAnswer() {
                    let i = this.gameplay.getCurrentTotalAnswersCount();
                    this.tallyEmptyArray[i].visible = true;
                    this.tally[i].visible = false;
                    let lost = this.gameplay.onWrongAnswer();
                    // this.soundWrongDrop = this.scene.sound.add("wrong drop");
                    // this.soundWrongDrop.play();
                    // this.scene.sound.add("Goose no").play();
                    if (this.idleDelayedCall != null) {
                        destroyDelayedCall(this.idleDelayedCall);
                        this.idleDelayedCall = null;
                    }
                    if (!lost) {
                        delayedCall(650, () => this.playCorrectAudio());
                    }
                    return lost;
                }
                onCloseClick() {
                    this.showAreYouSurePage();
                    this.scene.sound.add('warning page pop up sfx').play();
                }
                createTallies() {
                    if (this.tally) {
                        for (let te of this.tallyEmptyArray) {
                            te.visible = false;
                            this.bringToTop(te);
                        }
                        for (let t of this.tally) {
                            t.visible = false;
                            this.bringToTop(t);
                        }
                        return;
                    }
                    let startX = 37;
                    let startY = 191;
                    let dy = 44;
                    let dx = 45;
                    let tallyEmptyArrayPositions = [];
                    for (let i = 0; i < game.cache.json.get('gameplay')["rounds"].length+this.gameplay.failsNumToLose; i++) {
                        tallyEmptyArrayPositions.push({ x: startX + Math.floor(i / 7) * dx, y: startY + ((i % 7) * dy) });
                    }
                    this.tallyEmptyArray = [];
                    this.tally = [];
                    for (let p of tallyEmptyArrayPositions) {
                        let _BeehiveEmpty = new Phaser.GameObjects.Image(this.scene, p['x'], p['y'], 'tally Empty');
                        this.tallyEmptyArray.push(_BeehiveEmpty);
                        _BeehiveEmpty.visible = false;
                        let _Beehive = new Phaser.GameObjects.Image(this.scene, p['x'], p['y'], 'tally');
                        this.tally.push(_Beehive);
                    }
                    for (let bea of this.tallyEmptyArray) {
                        this.add(bea);
                    }
                    for (let bee of this.tally) {
                        this.add(bee);
                        bee.visible = false;
                    }
                }
                hideAllUnusedTallies() {
                    let i = this.gameplay.getCurrentTotalAnswersCount();
                    for (let k = i + 1; k < this.tallyEmptyArray.length; k++) {
                        this.tallyEmptyArray[k].visible = false;
                        this.tally[k].visible = false;
                    }
                }
                showInstructionPage() {
                    setPageBackground("bg-blue");
                    let playInstructionSound = () => {
                        if (this.wfsnd) {
                            this.wfsnd.stop();
                        }
                        this.wfsnd = this.scene.sound.add("Instruction");
                        this.wfsnd.play();
                    };
                    this.instructionPage = new screen.InstructionPage(this.scene, (target) => {
                        playBtnClickAnim(target);
                        this.instructionPage.destroy(true);
                        this.showGameplay();
                        if (this.wfsnd) {
                            this.wfsnd.stop();
                        }
                    }, (target) => {
                        playBtnClickAnim(target);
                        playInstructionSound();
                    });
                    this.add(this.instructionPage);
                    try {
                        playInstructionSound();
                    }
                    catch (e) {
                        console.error(e);
                    }
                }
                showAreYouSurePage() {
                    pauseAllDelayedCalls();
                    setPageBackground("bg-blue");
                    this.scene.tweens.pauseAll();
                    this.pauseSounds();
                    this.areYouSureWindow = new screen.AreYouSureWindow(this.scene, () => {
                        this.scene.tweens.resumeAll();
                        this.areYouSureWindow.destroy(true);
                        this.destroyGameplay();
                        this.showInstructionPage();
                    }, () => {
                        this.scene.tweens.resumeAll();
                        this.areYouSureWindow.destroy(true);
                        this.unpauseSounds();
                        resumeAllDelayedCalls();
                        setPageBackground("bg-australia");
                    });
                    this.add(this.areYouSureWindow);
                }
                setInputEnabled(enabled) {
                    if (enabled) {
                        for (let a of this.selectableLetters)
                            a.setInteractive({ cursor: 'pointer' });
                        this._PressButton.setInteractive({ cursor: 'pointer' });
                        // this.showHandIconAnim();
                    }
                    else {
                        for (let a of this.selectableLetters)
                            a.disableInteractive();
                        this._PressButton.disableInteractive();
                        this.hideHandIcon();
                    }
                }
                pauseSounds() {
                    this.scene.sound.pauseAll();
                }
                unpauseSounds() {
                    this.scene.sound.resumeAll();
                }
                destroyGameplay() {
                    this.setInputEnabled(false);
                    this.remove(this.gameplayContainer);
                    this.scene.sound.stopAll();
                    destroyAllDelayedCalls();
                }
            }
            screen.GameplayScreen = GameplayScreen;
        })(screen = ctb.screen || (ctb.screen = {}));
    })(ctb || (ctb = {}));

    (function (ctb) {
        var screen;
        (function (screen) {
            class InstructionPage extends Phaser.GameObjects.Container {
                constructor(scene, onPlayClick, onSndClick) {
                    super(scene);
                    this._instructionPage = new Phaser.GameObjects.Image(this.scene, 0 - 105, 0 - 48, 'Instructions page  ALL ACTIVITY  TITLEs');
                    this._instructionPage.setOrigin(0, 0);
                    //this._instructionPage.setInteractive();

                    this._instructionPageTitle = new Phaser.GameObjects.Image(this.scene, 470, 110, 'Logo');
                    this._instructionPageTitle.setScale(0.8);

                    this._btnPlay = new Phaser.GameObjects.Image(this.scene, game.scale.width / 2, 480 - 50, 'btnPLAY1');
                    this._btnPlay.setInteractive({ cursor: 'pointer' });
                    this._btnPlay.once('pointerup', onPlayClick);
                    setupButtonTextureBased(this._btnPlay, 'btnPLAY1', 'btnPLAY2');
                    this.instrTxt = this.scene.add.text(game.scale.width / 2, game.scale.height / 2, "Drag the doughs to make\n\n the right word", {
                        "fontFamily": "Kids Rock DEMO",
                        "fontSize": 30,
                        "lineSpacing": 20,
                        "color": "#43425D",
                        "align": 'center'
                    });
                    this.instrTxt.setOrigin(0.5, 0.5);
                    this.instrTxt.setWordWrapWidth(600);
                    this.instrTxt.setLineSpacing(5);
                    this._btnSoundInstruction = new Phaser.GameObjects.Image(this.scene, 800 - 105, 156 - 50, 'Sound');
                    this._btnSoundInstruction.setInteractive({ cursor: 'pointer' });
                    this._btnSoundInstruction.on('pointerup', onSndClick);
                    this._btnSoundInstruction["defScale"] = this._btnSoundInstruction.scale;
                    setupButtonTextureBased(this._btnSoundInstruction, 'Sound', 'Sound HOVER EFFECT');
                    this.add(this._instructionPage);
                    this.add(this._instructionPageTitle);
                    this.add(this.instrTxt);
                    this.add(this._btnPlay);
                    this.add(this._btnSoundInstruction);
                }
            }
            screen.InstructionPage = InstructionPage;
        })(screen = ctb.screen || (ctb.screen = {}));
    })(ctb || (ctb = {}));

    (function (ctb) {
        var screen;
        (function (screen) {
            class TryAgainWindow extends Phaser.GameObjects.Container {
                constructor(scene, onBack, onReplay) {
                    super(scene);
                    this.music = null;
                    this.setPosition(-106, -48);
                    this._bg = new Phaser.GameObjects.Image(this.scene, 0, 0, 'Try again page');
                    this._bg.setOrigin(0, 0);
                    // this._bg.setInteractive();
                    this._star = new Phaser.GameObjects.Image(this.scene, 400, 415, 'Break Star');
                    this._btnBack = new Phaser.GameObjects.Image(this.scene, 600, 580, 'btnBACK1');
                    this._btnReplay = new Phaser.GameObjects.Image(this.scene, 765, 580, 'btnReplay1');
                    this.totalScoreTxt = this.scene.add.text(830, 355, "", {
                        "fontFamily": "Kids Rock Demo",
                        "fontSize": 35,
                        "color": "#F49F1C",
                        "align": 'center',
                        'stroke': '#70451A',
                        'strokeThickness': 6
                    });
                    this.totalScoreTxt.setOrigin(0.5, 0.5);
                    let grd = this.totalScoreTxt.context.createLinearGradient(0, 0, 0, this.totalScoreTxt.height);
                    grd.addColorStop(0, '#FFFF00');
                    grd.addColorStop(1, '#C17316');
                    this.totalScoreTxt.setFill(grd);
                    this.starScoreTxt = this.scene.add.text(635, 431, "", {
                        "fontFamily": "Kids Rock Demo",
                        "fontSize": 24,
                        "color": "#FFFFFF",
                        "align": 'center'
                    });
                    this.starScoreTxt.setOrigin(0.5, 0.5);
                    this.add([
                        this._bg,
                        this._star,
                        this._btnBack,
                        this._btnReplay,
                        this.totalScoreTxt,
                        this.starScoreTxt
                    ]);
                    this._btnBack.setInteractive({ cursor: 'pointer' });
                    this._btnBack.on('pointerup', () => {
                        onBack(this._btnBack);
                        // if (this.music) {
                        //     this.music.stop();
                        // }
                    });
                    setupButtonTextureBased(this._btnBack, 'btnBACK1', 'btnBACK2');
                    this._btnReplay.setInteractive({ cursor: 'pointer' });
                    this._btnReplay.once('pointerup', () => {
                        onReplay(this._btnReplay);
                        if (this.music) {
                            this.music.stop();
                        }
                    });
                    setupButtonTextureBased(this._btnReplay, 'btnReplay1', 'btnReplay2');
                }
                show(score, starScore) {
                    this._star.scale = 1.25;
                    this.scene.tweens.add({
                        targets: this._star,
                        "scale": 1,
                        duration: 500,
                        ease: Phaser.Math.Easing.Back.Out
                    });
                    this.totalScoreTxt.text = String(window.angularComponentRef.component.userInfo.totalScore);
                    this.starScoreTxt.text = String(starScore);
                    this.music = this.scene.sound.add("Fail - close one");
                    this.music.play();
                }
            }
            screen.TryAgainWindow = TryAgainWindow;
        })(screen = ctb.screen || (ctb.screen = {}));
    })(ctb || (ctb = {}));

    game = new ctb.App(json);
};

function destroyDoughPress() {
    if (game) {
        game.destroy();
    }
}
