var game;
var sh;
function farmGame(json) {
    /**
     * @author Roman Parada
     * This class is created to fix overlapping of bottom part of canvas by navigation bar in iOS.
     * It make a delayed resize of the canvas (like Phaser-3 FIT methods does) and it overrides Phaser-3 input window to Phaser-3 core transform methods
     *
     * How to use:
     * Just call the code line below in Boot scene's init() method of your project:
     * new FitScaleManager(this.game).setup();
     */
    class FitScaleManager {
        constructor(game) {
            this.doResize = () => {
                let scale = this.calculateScale();
                let newCanvasWidth = this.phaserScaleManager.width * scale;
                let newCanvasHeight = this.phaserScaleManager.height * scale;
                this.canvasStyle.width = newCanvasWidth + 'px';
                this.canvasStyle.height = newCanvasHeight + 'px';
                this.canvasStyle.marginLeft = (window.innerWidth - newCanvasWidth) / 2 + 'px';
                this.canvasStyle.marginTop = (window.innerHeight - newCanvasHeight) / 2 + 'px';
            };
            this.game = game;
            this.canvasStyle = this.game.canvas.style;
            this.phaserScaleManager = this.game.scale;
        }
        static detectBestScaleMode() {
            let iOS = /iPad|iPhone|iPod/.test(navigator.platform || "");
            let isAndroid = window.navigator.userAgent.toLowerCase().indexOf("android") > -1;
            return iOS || isAndroid ? Phaser.Scale.FIT : Phaser.Scale.NONE;
        }
        ;
        /**
         * Just call this method once in Boot scene's init() method
         */
        setup() {
            this.phaserScaleManager.addListener(Phaser.Scale.Events.RESIZE, this.onResize, this);
            this.overridePhaserTransformMethods();
            this.onResize();
        }
        calculateScale() {
            if (game.scale.scaleMode === Phaser.Scale.NONE)
                return 1;
            return Math.min(window.innerWidth / this.phaserScaleManager.width, window.innerHeight / this.phaserScaleManager.height);
        }
        overridePhaserTransformMethods() {
            this.game.scale.transformX = (pageX) => {
                return (pageX - parseInt(this.canvasStyle.marginLeft.split("px")[0])) / this.calculateScale();
            };
            this.game.scale.transformY = (pageY) => {
                return (pageY - parseInt(this.canvasStyle.marginTop.split("px")[0])) / this.calculateScale();
            };
        }
        onResize() {
            setTimeout(this.doResize, FitScaleManager.RESIZE_DELAY);
        }
    }
    FitScaleManager.RESIZE_DELAY = 500;

    (function (sh) {
        class App extends Phaser.Game {
            constructor(json) {
                App.json = json;
                // console.log(App.json);

                let config = {
                    type: Phaser.AUTO,
                    width: App.CANVAS_WIDTH,
                    height: App.CANVAS_HEIGHT,
                    parent: 'game-container',
                    dom: {
                        createContainer: false
                    },
                    scale: {
                        mode: FitScaleManager.detectBestScaleMode(),
                        autoCenter: Phaser.Scale.CENTER_BOTH
                    },
                    transparent: true,
                    scene: {
                        create: () => {
                            this.scene.add('Boot', sh.scene.Boot, true);
                        }
                    }
                };
                super(config);
            }
        }
        App.CANVAS_WIDTH = 980;
        App.CANVAS_HEIGHT = 600;
        sh.App = App;
    })(sh || (sh = {}));

    game = new sh.App(json);

    var delayedCalls = [];
    function delayedCall(delay, callback, args, callbackScope) {
        let scene = game.scene.getAt(0);
        if (scene) {
            let dc = scene.time.delayedCall(delay, callback, args, callbackScope);
            delayedCalls.push(dc);
            return dc;
        }
        let t = setTimeout(callback, delay);
        delayedCalls.push(t);
        return t;
    }

    function pauseAllDelayedCalls() {
        for (let dc of delayedCalls) {
            if (dc instanceof Phaser.Time.TimerEvent) {
                dc.paused = true;
            }
        }
    }

    function resumeAllDelayedCalls() {
        for (let dc of delayedCalls) {
            if (dc instanceof Phaser.Time.TimerEvent) {
                dc.paused = false;
            }
        }
    }

    function destroyAllDelayedCalls() {
        for (let dc of delayedCalls) {
            if (dc instanceof Phaser.Time.TimerEvent) {
                dc.remove(false);
            }
            else {
                clearTimeout(dc);
            }
        }
        delayedCalls = [];
    }

    function setPageBackground(bg) {
        // document.querySelector("html").style.backgroundImage = "url(assets/games/farm/imgs/" + bg + ".jpg)";
    }

    function setupButton(btn, frame) {
        btn.on('pointerdown', () => { btn.setFrame(frame + '_hover' + '0000'); });
        btn.on('pointerover', () => { btn.setFrame(frame + '_hover' + '0000'); game.scene.getAt(0).sound.add("button hover").play(); });
        btn.on('pointerout', () => { btn.setFrame(frame + '0000'); });
        btn.on('pointerup', () => { btn.setFrame(frame + '0000'); game.scene.getAt(0).sound.add('activity selection - button selection').play(); });
    }

    function setupButtonTextureBased(btn, texture, hoverTexture) {
        btn.on('pointerdown', () => { btn.setTexture(hoverTexture); });
        btn.on('pointerover', () => { btn.setTexture(hoverTexture); game.scene.getAt(0).sound.add("button hover").play(); });
        btn.on('pointerout', () => { btn.setTexture(texture); });
        btn.on('pointerup', () => { btn.setTexture(texture); game.scene.getAt(0).sound.add('activity selection - button selection').play(); });
    }

    (function (sh) {
        var core;
        (function (core) {
            class Gameplay {
                constructor() {
                    this.currentRound = 0;
                    this.currentLetter = 0;
                    this.correctAnswersCount = 0;
                    this.wrongAnswersCount = 0;
                    this.correctAnswersCountThisRound = 0;
                    this.wrongAnswersCountThisRound = 0;
                    this.reset();
                    let json = game.cache.json.get('gameplay');
                    this.wrongAnswersToLose = json["wrongAnswersToLose"];
                    this.numberOfQuestionsPerRound = json["numberOfQuestionsPerRound"];
                    this.lettersConfig = JSON.parse(JSON.stringify(json["lettersConfig"]));
                    this.rounds = JSON.parse(JSON.stringify(json["rounds"]));
                    this.numberOfRounds = json["numberOfRounds"] ?? this.rounds.length;
                    this.useImages = Boolean(game.cache.json.get('gameplay')["useImages"]);
                }
                setupCallbacks(onComplete, onLose) {
                    this.onComplete = onComplete;
                    this.onLose = onLose;
                }
                onLetterChosen(forceNewRound) {
                    if (forceNewRound || this.isNewRound()) {
                        return this.nextRound();
                    }
                    return false;
                }
                nextRound() {
                    this.correctAnswersCountThisRound = 0;
                    this.wrongAnswersCountThisRound = 0;
                    this.currentRound++;
                    if (this.isRoundsComplete()) {
                        this.onComplete(this.correctAnswersCount, this.correctAnswersCount);
                        return true;
                    }

                    return false;
                }
                getCorrectLetterName() {
                    return this.rounds[this.currentRound]['correctLetterName'];
                }
                isCorrectLetterAudioQuestionEnable() {
                    return this.rounds[this.currentRound]['audioQuestionEnable'];
                }
                getCorrectAndLetterSoundSfxAudioKey() {
                    return "Q-" + this.lettersConfig[this.getCorrectLetterName()]['Sounds of the letters'];
                }
                onCorrectAnswer() {
                    this.correctAnswersCountThisRound++;
                    this.correctAnswersCount++;
                    return this.onLetterChosen();
                }
                onWrongAnswer(forceNewRound) {
                    this.wrongAnswersCountThisRound++;
                    this.wrongAnswersCount++;
                    if (this.wrongAnswersCount >= this.wrongAnswersToLose) {
                        this.onLose(this.correctAnswersCount, this.correctAnswersCount);
                        return true;
                    }
                    else {
                        this.onLetterChosen(forceNewRound);
                    }
                    return false;
                }

                getMaxTotalAnswersCount() {
                    return this.wrongAnswersToLose + this.numberOfRounds - 1;
                }

                getCurrentTotalAnswersCount() {
                    return this.correctAnswersCount + this.wrongAnswersCount;
                }
                isNewRound() {
                    return this.correctAnswersCountThisRound >= 1 || (this.correctAnswersCountThisRound + this.wrongAnswersCountThisRound) === 4 || (this.correctAnswersCountThisRound + this.wrongAnswersCountThisRound) === 0;
                }
                isRoundsComplete() {
                    return this.currentRound >= this.numberOfRounds && this.wrongAnswersCount < this.wrongAnswersToLose;
                }
                reset() {
                    this.currentRound = 0;
                    this.correctAnswersCount = 0;
                    this.wrongAnswersCount = 0;
                }
                getCurrentRoundLetters() {
                    return this.rounds[this.currentRound]["letters"];
                }
            }
            core.Gameplay = Gameplay;
        })(core = sh.core || (sh.core = {}));
    })(sh || (sh = {}));

    (function (sh) {
        var scene;
        (function (scene) {
            class Boot extends Phaser.Scene {
                init() {
                    this.game.scale.transformX = (pageX) => {
                        let offsetLeft = 0;
                        let parentElement = game.canvas.parentElement;
                        while (parentElement) {
                            if (parentElement.offsetLeft) {
                                offsetLeft = parentElement.offsetLeft;
                                break;
                            }
                            parentElement = parentElement.parentElement;
                        }
                        return (pageX - offsetLeft) * this.game.scale.displayScale.x;
                    };
                    this.game.scale.transformY = (pageY) => {
                        let offsetTop = 0;
                        let parentElement = game.canvas.parentElement;
                        while (parentElement) {
                            if (parentElement.offsetTop) {
                                offsetTop = parentElement.offsetTop;
                                break;
                            }
                            parentElement = parentElement.parentElement;
                        }
                        return (pageY - offsetTop) * this.game.scale.displayScale.y;
                    };
                }
                create() {
                    game.scene.remove('Boot');
                    game.scene.add('Preloader', sh.scene.Preloader, true);
                }
            }
            scene.Boot = Boot;
        })(scene = sh.scene || (sh.scene = {}));
    })(sh || (sh = {}));

    (function (sh) {
        var scene;
        (function (scene) {
            var Gameplay = sh.core.Gameplay;
            class MainScene extends Phaser.Scene {
                create() {
                    this.gameplay = new Gameplay();
                    this.gameplayScreen = new sh.screen.GameplayScreen(this, this.gameplay);
                    this.children.add(this.gameplayScreen);
                    this.gameplayScreen.showInstructionPage();
                }
            }
            scene.MainScene = MainScene;
        })(scene = sh.scene || (sh.scene = {}));
    })(sh || (sh = {}));

    (function (sh) {
        var scene;
        (function (scene) {
            class Preloader extends Phaser.Scene {
                preload() {
                    if (typeof sh.App.json == "string") this.load.json('gameplay', sh.App.json); else this.game.cache.json.add('gameplay', sh.App.json);
                }
                create() {
                    let json = game.cache.json.get('gameplay');
                    let lettersConfig = json["lettersConfig"];

                    if (json.instructionAudio) {
                        this.load.audio("instructionAudio", json.instructionAudio);
                    }

                    this.load.audio("englQuestion", "assets/games/farm/sound/mp3/letters/englQuestion.mp3");

                    for (let letterName in lettersConfig) {
                        if (json["useImages"]) this.load.image(letterName, "assets/games/farm/imgs/letters/" + letterName + ".png");
                        if (lettersConfig[letterName]) {
                            this.load.audio("Q-" + lettersConfig[letterName]["Sounds of the letters"], "assets/games/farm/sound/mp3/letters/Sounds of the letters/" + lettersConfig[letterName]["Sounds of the letters"]);
                        }
                    }
                    let progressTxt = this.add.text(game.scale.width / 2, game.scale.height / 2 + 50, "", {
                        "fontFamily": "QuranErafont",
                        "fontSize": 25,
                        "color": "#333333",
                        "align": 'center'
                    });
                    progressTxt.setOrigin(0.5, 0.5);
                    this.load.pack('preloader', 'assets/games/farm/pack.json');
                    this.load.on('progress', (value) => {
                        progressTxt.text = Math.ceil(value * 100) + "%";
                    }, this);
                    this.load.on('complete', () => {
                        this.nextScene();
                    });
                    this.load.start();
                }
                static playAnim(animKey, sprite, onComplete = null) {
                    let mainScene = game.scene.getScene('ScreenMain');
                    let data = Preloader.ANIMS_DATA[animKey];
                    if (!mainScene.anims.exists(animKey)) {
                        mainScene.anims.create({
                            key: animKey,
                            frames: mainScene.anims.generateFrameNames(data['atlas'], {
                                start: data['start'], end: data['end'], zeroPad: data['padNum'],
                                prefix: data['prefix'], suffix: ''
                            }),
                            frameRate: data['frameRate'],
                            repeat: data['repeat']
                        });
                    }
                    if (sprite.anims.currentAnim) {
                        sprite.anims.currentAnim.off('complete');
                    }
                    sprite.anims.stop();
                    // sprite.setTexture(data['atlas']);
                    sprite.play(animKey);
                    sprite.anims.currentAnim.once('complete', () => {
                        if (onComplete)
                            onComplete();
                    });
                    return sprite;
                }
                nextScene() {
                    game.scene.remove('Preloader');
                    game.scene.add('ScreenMain', sh.scene.MainScene, true);
                }
            }
            Preloader.ANIMS_DATA = {
                'idle': {
                    'start': 1,
                    'end': 99,
                    'padNum': 2,
                    'prefix': 'Duck Finish the Melon v3 0',
                    'repeat': -1,
                    'frameRate': 30,
                    'atlas': 'idle-atlas'
                },
                'finalanimation': {
                    'start': 1,
                    'end': 109,
                    'padNum': 3,
                    'prefix': 'Duck Finish the Melon',
                    'repeat': 0,
                    'frameRate': 30,
                    'atlas': 'finalanimation-atlas'
                },
                'correct': {
                    'start': 1,
                    'end': 74,
                    'padNum': 2,
                    'prefix': 'Duck Take a Bite',
                    'repeat': 0,
                    'frameRate': 30,
                    'atlas': 'correct-atlas'
                },
                'wrong': {
                    'start': 1,
                    'end': 53,
                    'padNum': 2,
                    'prefix': 'Duck Take a Bite',
                    'repeat': 0,
                    'frameRate': 30,
                    'atlas': 'wrong-atlas'
                }
            };
            scene.Preloader = Preloader;
        })(scene = sh.scene || (sh.scene = {}));
    })(sh || (sh = {}));
    (function (sh) {
        var screen;
        (function (screen) {
            class AreYouSureWindow extends Phaser.GameObjects.Container {
                constructor(scene, onYes, onNo) {
                    super(scene);
                    this._areYouSurePage = new Phaser.GameObjects.Image(this.scene, -105, 0 - 48, 'Exit warning');
                    this._areYouSurePage.setOrigin(0, 0);
                    this._areYouSurePage.setInteractive();
                    this._btnSureYes = new Phaser.GameObjects.Image(this.scene, game.scale.width / 2 - 95, 485 - 50, 'btnYES1');
                    this._btnSureYes.setInteractive({ cursor: 'pointer' });
                    this._btnSureYes.on('pointerup', () => {
                        onYes;
                        angularComponentRef.backToMenu();
                    });
                    var modalTextStyle = {
                        backgroundColor: 'transparent',
                        stroke: '#ffffff',
                        strokeThickness: 2,
                        lineSpacing: 15,
                        fontFamily: 'Kids Rock DEMO',
                        fontSize: 27,
                        color: '#43425d',
                        wordWrap: true,
                        align: 'center'
                    };
                    var modalText = this.scene.add.text(game.scale.width / 2 + 12, game.scale.height / 2, "Your progress in this activity will not be saved. \n Are you sure you want to exit? ", modalTextStyle);
                    modalText.setOrigin(0.5, 0.5);

                    setupButtonTextureBased(this._btnSureYes, 'btnYES1', 'btnYES2');
                    this._btnSureNo = new Phaser.GameObjects.Image(this.scene, game.scale.width / 2 + 95, 485 - 50, 'btnNO1');
                    this._btnSureNo.setInteractive({ cursor: 'pointer' });
                    this._btnSureNo.on('pointerup', onNo);
                    setupButtonTextureBased(this._btnSureNo, 'btnNO1', 'btnNO2');
                    this.add(this._areYouSurePage);
                    this.add(this._btnSureYes);
                    this.add(this._btnSureNo);
                    this.add(modalText);
                }
            }
            screen.AreYouSureWindow = AreYouSureWindow;
        })(screen = sh.screen || (sh.screen = {}));
    })(sh || (sh = {}));

    (function (sh) {
        var screen;
        (function (screen) {
            class CompleteWindow extends Phaser.GameObjects.Container {
                constructor(scene, onBack, onReplay, onNext) {
                    super(scene);
                    this.music = null;
                    this.setPosition(-104.5, -48);
                    this._bgComplete = new Phaser.GameObjects.Image(this.scene, 0, 0, 'Completion page LATEST UPDATED');
                    this._bgComplete.setOrigin(0, 0);
                    this._bgComplete.setInteractive();
                    this._cup = new Phaser.GameObjects.Image(this.scene, 400, 410, 'Trophy');
                    this._btnBack = new Phaser.GameObjects.Image(this.scene, 570, 570, 'btnBACK1');
                    this._btnReplay = new Phaser.GameObjects.Image(this.scene, 720, 570, 'btnReplay1');
                    this._btnNext = new Phaser.GameObjects.Image(this.scene, 870, 570, 'btnNEXT1');
                    let _CollectedPoints = new Phaser.GameObjects.Image(this.scene, 620, 440, 'Collected Points');
                    this.totalScoreTxt = this.scene.add.text(845, 352, "", {
                        "fontFamily": "Kids Rock Demo",
                        "fontSize": 35,
                        "color": "#F49F1C",
                        "align": 'center',
                        'stroke': '#70451A',
                        'strokeThickness': 6
                    });
                    this.totalScoreTxt.setOrigin(0.5, 0.5);
                    let grd = this.totalScoreTxt.context.createLinearGradient(0, 0, 0, this.totalScoreTxt.height);
                    grd.addColorStop(0, '#FFFF00');
                    grd.addColorStop(1, '#C17316');
                    this.totalScoreTxt.setFill(grd);
                    this.starScoreTxt = this.scene.add.text(648, 433, "", {
                        "fontFamily": "Kids Rock Demo",
                        "fontSize": 24,
                        "color": "#FFFFFF",
                        "align": 'center'
                    });
                    this.starScoreTxt.setOrigin(0.5, 0.5);
                    this.add([
                        this._bgComplete,
                        _CollectedPoints,
                        this._cup,
                        this._btnBack,
                        this._btnReplay,
                        this._btnNext,
                        this.totalScoreTxt,
                        this.starScoreTxt
                    ]);
                    this._btnBack.setInteractive({ cursor: 'pointer' });
                    this._btnBack.on('pointerup', () => {
                        onBack(this._btnBack);
                        angularComponentRef.backToMenu();
                        // if (this.music) {
                        //     this.music.stop();
                        // }
                    });
                    setupButtonTextureBased(this._btnBack, 'btnBACK1', 'btnBACK2');
                    this._btnReplay.setInteractive({ cursor: 'pointer' });
                    this._btnReplay.once('pointerup', () => {
                        onReplay(this._btnReplay);
                        if (this.music) {
                            this.music.stop();
                        }
                    });
                    setupButtonTextureBased(this._btnReplay, 'btnReplay1', 'btnReplay2');
                    this._btnNext.setInteractive({ cursor: 'pointer' });
                    this._btnNext.on('pointerup', () => {
                        onNext(this._btnNext);
                        window.angularComponentRef.nextActivity()
                    });
                    setupButtonTextureBased(this._btnNext, 'btnNEXT1', 'btnNEXT2');
                }
                show(score, starScore) {
                    window.angularComponentRef.gameFinish({ score, starScore })
                    this._cup.scale = 1.25;
                    this.scene.tweens.add({
                        targets: this._cup,
                        "scale": 1,
                        duration: 500,
                        ease: Phaser.Math.Easing.Back.Out
                    });
                    this.totalScoreTxt.text = String(window.angularComponentRef.component.userInfo.totalScore);
                    this.starScoreTxt.text = String(starScore);
                    // let music = this.scene.sound.add("viktory");
                    this.music = this.scene.sound.add("Activity completion fantastic");
                    this.music.play();
                }
            }
            screen.CompleteWindow = CompleteWindow;
        })(screen = sh.screen || (sh.screen = {}));
    })(sh || (sh = {}));

    (function (sh) {
        var screen;
        (function (screen) {
            class GameplayScreen extends Phaser.GameObjects.Container {
                constructor(scene, gameplay) {
                    super(scene);
                    this.carsPositions = [{ x: -153 + 330, y: 277 + 242 }, { x: 53 + 330, y: 277 + 242 }, { x: 273 + 330, y: 277 + 242 }, { x: 501 + 330, y: 277 + 242 }];
                    this.bgMusic = null;
                    this.correctAudio = null;
                    this.wfsnd = null;
                    this.showCompleteWindow = (score, starScore) => {
                        let completeWindow = new screen.CompleteWindow(this.scene, (target) => {
                            this.playBtnClickAnim(target);
                        }, (target) => {
                            this.playBtnClickAnim(target);
                            this.destroyGameplay();
                            this.remove(completeWindow);
                            this.showInstructionPage();
                        }, (target) => {
                            this.playBtnClickAnim(target);
                        });
                        this.setLettersInputEnabled(false);
                        delayedCall(7750, () => {
                            setPageBackground("bg-blue");
                            this.add(completeWindow);
                            completeWindow.show(score, starScore);
                            if (this.bgMusic) this.bgMusic.stop();
                        });
                    };
                    this.showLoseWindow = (score, starScore) => {
                        let tryAgainWindow = new screen.TryAgainWindow(this.scene, (target) => {
                            this.playBtnClickAnim(target);
                        }, (target) => {
                            this.playBtnClickAnim(target);
                            this.destroyGameplay();
                            this.remove(tryAgainWindow);
                            this.showInstructionPage();
                        });
                        this.setLettersInputEnabled(false);
                        delayedCall(2500, () => {
                            setPageBackground("bg-blue");
                            this.add(tryAgainWindow);
                            tryAgainWindow.show(score, starScore);
                            if (this.bgMusic) this.bgMusic.stop();
                        });
                    };
                    this.gameplay = gameplay;
                    window["t"] = this;
                }
                createHexagons() {
                    if (this.contentContainer && this.contentContainer.parentContainer) {
                        this.contentContainer.parentContainer.remove(this.contentContainer);
                    }

                    this.contentContainer = this.scene.add.container(0, 0);
                    this.gameplayContainer.addAt(this.contentContainer, this.gameplayContainer.getAll().length);
                    window['this.contentContainer'] = this.contentContainer;

                    this.cars = [];
                    let _i = 0;
                    for (let p of this.carsPositions) {
                        let hc = this.scene.add.container(this.carsPositions[_i]['x'], this.carsPositions[_i]['y']); window['hc'] = hc;
                        let hcIdleMotionContainer = this.scene.add.container();
                        hc.add(hcIdleMotionContainer);
                        let h = this.scene.add.sprite(-70, -8, 'melon'); window['h'] = h;
                        h.setOrigin(20 / 185, 0.5);
                        hc['isUsedAlready'] = false
                        hc.add(h);
                        hc["bg"] = h;
                        window['hc_' + _i] = hc;
                        window['h_' + _i] = h;
                        hcIdleMotionContainer.add(h);
                        hc['hcIdleMotionContainer'] = hcIdleMotionContainer;

                        hc["defX"] = p['x'];
                        hc["defY"] = p['y'];
                        this.cars.push(hc);
                        this.contentContainer.add(hc);
                        hc['index'] = _i;
                        _i++;
                    }
                    window['this.cars'] = this.cars;
                }
                createLetters() {
                    /* if (this.letters) {
                        for (let l of this.letters) {
                            l.parentContainer["letter"] = null;
                            l["hexagon"] = null;
                            l.parentContainer.remove(l);
                        }
                    } */
                    this.letters = [];
                    let _letters = this.gameplay.getCurrentRoundLetters();
                    for (let n of _letters) {
                        let wordText;
                        if (this.gameplay.useImages) {
                            wordText = new Phaser.GameObjects.Image(this.scene, 0, 0, n);

                            if (wordText.width > 130) wordText.setScale(130 / wordText.width);
                            if (wordText.height > 70) wordText.setScale(wordText.scaleY * 70 / wordText.height);
                        } else {
                            wordText = this.scene.add.text(0, 0, n, {
                                "fontFamily": "QuranErafont",
                                "fontSize": 40,
                                "color": "#333333",
                                "align": 'center'
                            });
                            this.add(wordText);
                            wordText.setOrigin(0.5, 0.5);
                            wordText.setPadding({ left: 0, right: 0, top: 50, bottom: 50 });
                        }

                        wordText.setPosition(11, -5);
                        wordText["key"] = n;
                        this.letters.push(wordText);
                        window['wordText'] = wordText;
                    }
                }
                showGameplay() {
                    this.melonPositions = [
                        { x: 529 + 40 + 10, y: 342 + 25 },
                        { x: 556 + 40 + 15, y: 321 + 35 },
                        { x: 606 + 40 + 10 + 7, y: 315 + 35 },
                        { x: 661 + 40 + 10 + 10, y: 313 + 35 },

                        { x: 547 + 40 + 10, y: 300 + 30 }, { x: 571 + 40 + 10, y: 261 + 30 },
                        { x: 574 + 80 + 10, y: 273 + 30 }, { x: 637 + 40 + 45 + 10, y: 270 + 30 }, { x: 604 + 40 + 10, y: 234 + 30 },
                        { x: 690 + 10, y: 246 + 20 }
                    ];
                    this.isLost = false;
                    setPageBackground("bg-australia");
                    this.bgMusic = this.scene.sound.add("bg_sound");
                    this.bgMusic.play();
                    this.bgMusic.loop = true;
                    this.gameplay.reset();
                    this._gameStage = new Phaser.GameObjects.Image(this.scene, game.scale.width / 2, game.scale.height / 2, 'bg');
                    this._gameStage.setOrigin(0.5, 0.5);
                    this._gameStage.setInteractive();
                    this._btnSound = new Phaser.GameObjects.Image(this.scene, 160 - 105, 100 - 50, 'Sound');
                    this._btnSound.setInteractive({ cursor: 'pointer' });
                    this._btnSound["defScale"] = this._btnSound.scale;
                    setupButtonTextureBased(this._btnSound, 'Sound', 'Sound HOVER EFFECT');
                    this._btnClose = new Phaser.GameObjects.Image(this.scene, 1025 - 105, 100 - 50, 'x Button');
                    this._btnClose.setInteractive({ cursor: 'pointer' });
                    this._btnClose["defScale"] = this._btnClose.scale;
                    setupButtonTextureBased(this._btnClose, 'x Button', 'x Button HOVER EFFECT');
                    this.gameplayContainer = new Phaser.GameObjects.Container(this.scene);
                    this.addAt(this.gameplayContainer, 0);
                    this.gameplayContainer.add([
                        this._gameStage,
                        this._btnSound,
                        this._btnClose
                    ]);

                    this.wagonBack = this.scene.add.sprite(650 + 80, 365 - 10, "wagonBack"); window['this.wagonBack'] = this.wagonBack;
                    this.gameplayContainer.add(this.wagonBack);

                    this.wagonFront = this.scene.add.sprite(650, 365, "wagonFront"); window['this.wagonFront'] = this.wagonFront;
                    this.gameplayContainer.add(this.wagonFront);

                    this.goose = this.scene.add.sprite(490, 600, "idle-atlas");
                    this.gameplayContainer.add(this.goose);
                    this.goose.setOrigin(0.5, 1);
                    window['this.goose'] = this.goose;
                    sh.scene.Preloader.playAnim('idle', this.goose);
                    // sh.scene.Preloader.playAnim('finalanimation', this.goose);

                    this.createBeehives();
                    this.shuffleOutHexagons();
                    this.gameplay.setupCallbacks(this.showCompleteWindow, this.showLoseWindow);

                    this.frontPlant = this.scene.add.sprite(425, 470, "frontPlant"); window['this.frontPlant'] = this.frontPlant;
                    this.gameplayContainer.add(this.frontPlant);
                }
                createBeehives() {
                    let startX = 120;
                    let startY = 40;
                    let dx = 37;
                    let _BeehiveEmptyArrayPositions = [];
                    for (let i = 0; i < this.gameplay.getMaxTotalAnswersCount(); i++) {
                        _BeehiveEmptyArrayPositions.push({ x: startX + i * dx, y: startY });
                    }
                    this._BeehiveEmptyArray = []; window["_BeehiveEmptyArray"] = this._BeehiveEmptyArray;
                    this._Beehive = [];
                    for (let p of _BeehiveEmptyArrayPositions) {
                        let _BeehiveEmpty = new Phaser.GameObjects.Image(this.scene, p['x'], p['y'], 'Beehive 1 Empty');
                        this._BeehiveEmptyArray.push(_BeehiveEmpty);
                        _BeehiveEmpty.visible = false;
                        let _Beehive = new Phaser.GameObjects.Image(this.scene, p['x'], p['y'], 'Beehive 1');
                        this._Beehive.push(_Beehive);
                    }
                    for (let bea of this._BeehiveEmptyArray) {
                        this.gameplayContainer.add(bea);
                    }
                    for (let bee of this._Beehive) {
                        this.gameplayContainer.add(bee);
                        bee.visible = false;
                    }
                }
                randomizeLettersPosition() {
                    let hx = this.cars.slice();
                    for (let l of this.letters) {
                        if (hx.length == 0)
                            return;
                        let hc = Phaser.Utils.Array.RemoveRandomElement(hx);
                        hc['hcIdleMotionContainer'].add(l);
                        hc["letter"] = l;
                        l["hexagon"] = hc;
                    }
                }
                updateHexagonsArrange() {
                    for (let hc of this.cars) {
                        if (!hc["letter"]) {
                            hc.parentContainer.bringToTop(hc);
                            break;
                        }
                    }
                }
                shuffleOutHexagons() {
                    this.createHexagons();

                    let setLettersInputEnabled = () => {
                        if (this.correctAudio && this.correctAudio.isPlaying) {
                            delayedCall((this.correctAudio.duration * 0.75) * 1000, () => {
                                this.setLettersInputEnabled(true);
                            });
                        }
                        else {
                            this.setLettersInputEnabled(true);
                        }
                    };

                    if (json.instructionAudio) {

                        if (this.gameplay.currentRound === 0) {
                            var snd = this.scene.sound.add("instructionAudio");
                            snd.play();
                            if (!this.gameplay.isRoundsComplete()) {
                                delayedCall(4650, () => {
                                    if (this.gameplay.isCorrectLetterAudioQuestionEnable()) {
                                        this.playCorrectAudio();
                                    }
                                    setLettersInputEnabled();
                                })
                            }

                        }else{
                            if (!this.gameplay.isRoundsComplete()) {
                                delayedCall(650, () => {
                                    if (this.gameplay.isCorrectLetterAudioQuestionEnable()) {
                                        this.playCorrectAudio();
                                    }
                                    setLettersInputEnabled();
                                })
                            }
                        }

                    } else {

                        if (!this.gameplay.isRoundsComplete()) {
                            delayedCall(650, () => {
                                if (this.gameplay.isCorrectLetterAudioQuestionEnable()) {
                                    this.playCorrectAudio();
                                }
                                setLettersInputEnabled();
                            })
                        }
                    }

                    if (this.sfxCarAccelerating) {
                        this.sfxCarAccelerating.stop();
                        this.sfxCarAccelerating = null;
                    }
                    if (this.sfxWrong) {
                        this.sfxWrong.stop();
                        this.sfxWrong = null;
                    }

                    if (!this.gameplay.isRoundsComplete()) {
                        this.createLetters();
                        this.randomizeLettersPosition();
                    }

                    this.setLettersInputEnabled(false);
                    this.updateHexagonsArrange();
                    // Phaser.Utils.Array.Shuffle(this.carsPositions);
                    let cars = this.cars;
                    for (let i = 0; i < cars.length; i++) {
                        let hc = cars[i];
                        hc["defX"] = this.carsPositions[i]["x"];
                        hc["defY"] = this.carsPositions[i]["y"];
                        this.scene.tweens.killTweensOf(hc);

                        if (this.gameplay.getCurrentTotalAnswersCount() === 0) {
                            hc["bg"].setScale(0);
                            this.scene.tweens.add({
                                targets: hc["bg"],
                                scaleX: 1,
                                scaleY: 1,
                                duration: 250,
                                delay: 350 + i * 150
                            });

                            delayedCall(350, () => this.scene.sound.add('Blocks appearing in each round').play())

                            hc['letter'].alpha = 0;
                            this.scene.tweens.add({
                                targets: hc['letter'],
                                alpha: 1,
                                duration: 350,
                                delay: 150 + 350 + i * 150
                            });
                        } else {
                            if (this.gameplay.getCurrentTotalAnswersCount() == 0 || i == this.lastCorrectAnswerIndex) {
                                hc["bg"].setScale(0);
                                this.scene.tweens.add({
                                    targets: hc["bg"],
                                    scaleX: 1,
                                    scaleY: 1,
                                    duration: 200,
                                    delay: 200,
                                    ease: Phaser.Math.Easing.Quadratic.Out,
                                    onComplete: () => { }
                                });

                                this.scene.sound.add('New melon appears sfx').play();
                            }

                            // delayedCall(450, ()=>this.scene.sound.add('Movement fx').play())

                            hc['letter'].alpha = 0;
                            this.scene.tweens.add({
                                targets: hc['letter'],
                                alpha: 1,
                                duration: 175,
                                delay: 550
                            });
                        }
                    }

                    this.scene.tweens.add({
                        targets: this.frontPlant,
                        scaleX: 1.013,
                        yoyo: true,
                        duration: 500
                    });

                    for (let c of cars) this.gameplayContainer.remove(c);
                    this.cars.sort((c1, c2) => c1.y - c2.y);
                    // for (let c of cars) this.gameplayContainer.add(c);

                    this.createInput();
                }
                shuffleInHexagons() {
                    this.updateHexagonsArrange();
                    let positions = this.carsPositions.slice();
                    this.setLettersInputEnabled(false);

                    // delayedCall(600, () => {
                    // this.scene.sound.add('move to centre').play();
                    this.shuffleOutHexagons();
                    // });
                }
                createInput() {
                    let j = 0;
                    for (let hc of this.cars) {
                        let correctAnswer = hc["letter"] && hc["letter"]["key"] == this.gameplay.getCorrectLetterName();
                        //hc["letter"].alpha = correctAnswer ? 1 : 0.5;
                        hc["bg"].once('pointerup', () => {
                            if (hc["isUsedAlready"]) return;
                            hc['isUsedAlready'] = true;

                            // this.playBtnClickAnim(hc);
                            let lost = false;
                            if (correctAnswer) {
                                this.setLettersInputEnabled(false);

                                this.lastCorrectAnswerIndex = this.cars.indexOf(hc);
                                this.scene.tweens.add({
                                    targets: hc["bg"],
                                    scaleX: 0.85,
                                    scaleY: 1.15,
                                    yoyo: true,
                                    duration: 100
                                });

                                let completed = this.onCorrectAnswer();

                                this.scene.tweens.add({
                                    targets: hc["letter"],
                                    alpha: 0,
                                    duration: 300,
                                    delay: 200
                                }); +

                                    delayedCall(600, () => {
                                        this.scene.sound.add('Melon being thrown and landing sfx').play()
                                        let p = this.melonPositions.shift();
                                        this.scene.tweens.add({
                                            targets: hc,
                                            x: p.x,
                                            y: p.y - 90,
                                            scaleX: 0.5,
                                            scaleY: 0.5,
                                            ease: Phaser.Math.Easing.Quadratic.Out,
                                            duration: 500,
                                            delay: 200,
                                            onComplete: () => {
                                                hc.parentContainer.remove(hc);
                                                if (this.gameplay.correctAnswersCount > 5) {
                                                    this.gameplayContainer.addAt(hc, 4);
                                                } else {
                                                    this.gameplayContainer.add(hc);
                                                    this.gameplayContainer.swap(hc, this.wagonFront);
                                                }

                                                this.scene.tweens.add({
                                                    targets: hc,
                                                    y: p.y,
                                                    duration: 100,
                                                    onComplete: () => {
                                                        for (let _hc of this.cars) {
                                                            this.scene.tweens.add({
                                                                targets: _hc["letter"],
                                                                alpha: 0,
                                                                duration: 400
                                                            });
                                                        }

                                                        if (completed) {
                                                            sh.scene.Preloader.playAnim('finalanimation', this.goose);
                                                            delayedCall(400, () => this.scene.sound.add('Eating up - final animation sfx').play())
                                                        } else {
                                                            sh.scene.Preloader.playAnim('correct', this.goose, () => sh.scene.Preloader.playAnim('idle', this.goose));
                                                            delayedCall(500, () => this.scene.sound.add('Biting - correct animation sfx').play())
                                                        }
                                                        // this.scene.sound.add('First meteor explostion').play();

                                                        // sh.scene.Preloader.playAnim('snail-giggling', this.goose);
                                                        // this.scene.sound.add('Snail giggling sound - correct selection').play();

                                                        if (completed) delayedCall(3750, () => this.scene.sound.add('Final celebration sound').play())

                                                        delayedCall(completed ? 4200 : 1600, () => {
                                                            if (!completed) {
                                                                this.shuffleInHexagons();
                                                            }
                                                        });
                                                    }
                                                });
                                            }
                                        });
                                    });
                            } else {
                                delayedCall(350, () => {
                                    sh.scene.Preloader.playAnim('wrong', this.goose, () => sh.scene.Preloader.playAnim('idle', this.goose));
                                    this.scene.sound.add('Goose No sfx').play();
                                });

                                this.scene.tweens.add({
                                    targets: hc,
                                    x: hc.x - 1,
                                    yoyo: true,
                                    repeat: 3,
                                    duration: 100
                                });
                                this.scene.tweens.add({
                                    targets: hc["letter"],
                                    alpha: 0,
                                    duration: 500
                                });

                                // delayedCall(700, ()=>{hc.visible=false;});
                                lost = this.onWrongAnswer();
                                if (!lost) {
                                    // this.shuffleInHexagons();
                                } else {
                                    this.isLost = true;
                                }
                            }
                        });
                    }
                    this._btnSound.on('pointerup', () => {
                        this.playBtnClickAnim(this._btnSound);
                        this.onSoundClick();
                    });
                    this._btnClose.on('pointerup', () => {
                        this.playBtnClickAnim(this._btnClose);
                        this.onCloseClick();
                    });
                }
                hideAllUnusedBeehives() {
                    let i = this.gameplay.getCurrentTotalAnswersCount();
                    for (let k = i + 1; k < this._BeehiveEmptyArray.length; k++) {
                        this._BeehiveEmptyArray[k].visible = false;
                        this._Beehive[k].visible = false;
                    }
                }
                onCorrectAnswer() {
                    let i = this.gameplay.getCurrentTotalAnswersCount();
                    if (i < this._BeehiveEmptyArray.length) {
                        this._BeehiveEmptyArray[i].visible = false;
                        this._Beehive[i].visible = true;
                    }
                    this.hideAllUnusedBeehives();
                    let completed = this.gameplay.onCorrectAnswer();
                    this.scene.sound.add('Correct sfx').play();

                    // this.sfxCarAccelerating = this.scene.sound.add('Car accelerating');
                    // this.sfxCarAccelerating.play();
                    // delayedCall(250, () => {
                    //     this.scene.sound.add(this.gameplay.getCorrectAndLetterSoundSfxAudioKey()).play();
                    // });
                    return completed;
                }
                onWrongAnswer(forceNewRound) {
                    let i = this.gameplay.getCurrentTotalAnswersCount();
                    if (i < this._BeehiveEmptyArray.length) {
                        this._BeehiveEmptyArray[i].visible = true;
                        this._Beehive[i].visible = false;
                    }
                    this.hideAllUnusedBeehives();
                    let lost = this.gameplay.onWrongAnswer(forceNewRound);
                    this.sfxWrong = this.scene.sound.add("wrong sfx");
                    this.sfxWrong.play();
                    return lost;
                }
                playCorrectAudio() {
                    if (this.correctAudio) {
                        this.correctAudio.stop();
                    }
                    this.correctAudio = this.scene.sound.add("englQuestion");
                    this.correctAudio.once('complete', () => {
                        this.correctAudio = this.scene.sound.add(this.gameplay.getCorrectAndLetterSoundSfxAudioKey());
                        this.correctAudio.play();
                    });
                    this.correctAudio.play();
                    if (this.areYouSureWindow && this.areYouSureWindow.parentContainer == this) {
                        this.correctAudio.pause();
                    }
                }
                onSoundClick() {
                    this.playCorrectAudio();
                }
                onCloseClick() {
                    this.showAreYouSurePage();
                    this.scene.sound.add('warning page pop up sfx').play();
                }
                playBtnClickAnim(target) {
                    let sc = target["defScale"] ? target["defScale"] : 1;
                    target.scaleX = target.scaleY = sc;
                    this.scene.tweens.add({
                        targets: target,
                        "scaleX": 0.9 * sc,
                        "scaleY": 0.9 * sc,
                        duration: 100,
                        yoyo: true
                    });
                }
                showInstructionPage() {
                    setPageBackground("bg-blue");
                    this.instructionPage = new screen.InstructionPage(this.scene, (target) => {
                        this.playBtnClickAnim(target);
                        this.remove(this.instructionPage);
                        this.showGameplay();
                        if (this.wfsnd) {
                            this.wfsnd.stop();
                        }
                    }, (target) => {
                        this.playBtnClickAnim(target);
                        if (this.wfsnd) {
                            this.wfsnd.stop();
                        }
                        if (!game.cache.json.get('gameplay')['welcomeAudioDisabled']) {
                            this.wfsnd = this.scene.sound.add("Welcome Find the sound");
                            this.wfsnd.play();
                        }
                    });
                    this.add(this.instructionPage);
                    try {
                        if (!game.cache.json.get('gameplay')['welcomeAudioDisabled']) {
                            this.wfsnd = this.scene.sound.add("Welcome Find the sound");
                            this.wfsnd.play();
                        }
                    }
                    catch (e) {
                        console.error(e);
                    }
                }
                showAreYouSurePage() {
                    /* for (let j = 0; j < this.cars.length; j++) {
                        if (this.cars[j]["raceTween"]) {
                            this.cars[j]["raceTween"].pause();
                        }
                    } */

                    pauseAllDelayedCalls();
                    setPageBackground("bg-blue");
                    this.pauseSounds();
                    if (!this.areYouSureWindow) this.areYouSureWindow = new screen.AreYouSureWindow(this.scene, () => {
                        this.remove(this.areYouSureWindow);
                        this.destroyGameplay();
                        this.showInstructionPage();
                    }, () => {
                        this.remove(this.areYouSureWindow);
                        this.unpauseSounds();
                        resumeAllDelayedCalls();

                        /* for (let j = 0; j < this.cars.length; j++) {
                            if (this.cars[j]["raceTween"]) {
                                this.cars[j]["raceTween"].resume();
                            }
                        } */

                        setPageBackground("bg-australia");
                    });
                    this.add(this.areYouSureWindow);
                }
                setLettersInputEnabled(enabled) {
                    if (enabled) {
                        for (let hc of this.cars) {
                            hc["bg"].setInteractive({ cursor: 'pointer' });
                        }
                    }
                    else {
                        for (let hc of this.cars) {
                            hc["bg"].disableInteractive();
                        }
                    }
                }
                pauseSounds() {
                    if (this.correctAudio)
                        this.correctAudio.pause();
                    if (this.bgMusic)
                        this.bgMusic.pause();
                }
                unpauseSounds() {
                    if (this.correctAudio)
                        this.correctAudio.resume();
                    if (this.bgMusic)
                        this.bgMusic.resume();
                }
                destroyGameplay() {
                    this.setLettersInputEnabled(false);
                    this.remove(this.gameplayContainer);
                    this.scene.sound.stopAll();
                    destroyAllDelayedCalls();
                }
            }
            screen.GameplayScreen = GameplayScreen;
        })(screen = sh.screen || (sh.screen = {}));
    })(sh || (sh = {}));
    (function (sh) {
        var screen;
        (function (screen) {
            class InstructionPage extends Phaser.GameObjects.Container {
                constructor(scene, onPlayClick, onSndClick) {
                    super(scene);
                    this._instructionPage = new Phaser.GameObjects.Image(this.scene, 0 - 105, 0 - 48, 'Instructions page  ALL ACTIVITY  TITLEs');
                    this._instructionPage.setOrigin(0, 0);
                    this._instructionPage.setInteractive();

                    this._instructionPageTitle = this.scene.add.text(475, 110, sh.App.json.instructionTitle ?? 'Farm', {
                        "fontFamily": "Kids Rock DEMO",
                        "fontSize": 45,
                        "color": "#43425D",
                        "align": 'center'
                    });
                    this._instructionPageTitle.setOrigin(0.5, 0.5);
                    window['this._instructionPageTitle'] = this._instructionPageTitle;
                    this._instructionPageTitle.setStroke('#ffffff', 5);
                    //  Apply the gradient fill.
                    const gradient = this._instructionPageTitle.context.createLinearGradient(0, 0, 0, this._instructionPageTitle.height);
                    gradient.addColorStop(0, '#eeef67');
                    gradient.addColorStop(0.55, '#e98c2c');
                    gradient.addColorStop(1, '#91301a');
                    this._instructionPageTitle.setFill(gradient);

                    this._btnPlay = new Phaser.GameObjects.Image(this.scene, game.scale.width / 2, 480 - 50, 'btnPLAY1');
                    this._btnPlay.setInteractive({ cursor: 'pointer' });
                    this._btnPlay.once('pointerup', onPlayClick);
                    setupButtonTextureBased(this._btnPlay, 'btnPLAY1', 'btnPLAY2');
                    this.instrTxt = this.scene.add.text(game.scale.width / 2, game.scale.height / 2, "Lazy Goose is too lazy to harvest \n the melons. Help him pick the \n right melon.", {
                        "fontFamily": "Kids Rock DEMO",
                        "fontSize": 30,
                        "lineSpacing": 15,
                        "color": "#43425D",
                        "align": 'center'
                    });
                    this.instrTxt.setOrigin(0.5, 0.5);
                    this._btnSoundInstruction = new Phaser.GameObjects.Image(this.scene, 800 - 105, 156 - 50, 'Sound');
                    this._btnSoundInstruction.setInteractive({ cursor: 'pointer' });
                    this._btnSoundInstruction.on('pointerup', onSndClick);
                    this._btnSoundInstruction["defScale"] = this._btnSoundInstruction.scale;
                    setupButtonTextureBased(this._btnSoundInstruction, 'Sound', 'Sound HOVER EFFECT');
                    this.add(this._instructionPage);
                    this.add(this._instructionPageTitle);
                    this.add(this.instrTxt);
                    this.add(this._btnPlay);
                    this.add(this._btnSoundInstruction);
                }
            }
            screen.InstructionPage = InstructionPage;
        })(screen = sh.screen || (sh.screen = {}));
    })(sh || (sh = {}));
    (function (sh) {
        var screen;
        (function (screen) {
            class TryAgainWindow extends Phaser.GameObjects.Container {
                constructor(scene, onBack, onReplay) {
                    super(scene);
                    this.music = null;
                    this.setPosition(-106, -48);
                    this._bg = new Phaser.GameObjects.Image(this.scene, 0, 0, 'Try again page');
                    this._bg.setOrigin(0, 0);
                    this._bg.setInteractive();
                    this._star = new Phaser.GameObjects.Image(this.scene, 400, 415, 'Break Star');
                    this._btnBack = new Phaser.GameObjects.Image(this.scene, 600, 580, 'btnBACK1');
                    this._btnReplay = new Phaser.GameObjects.Image(this.scene, 765, 580, 'btnReplay1');
                    this.totalScoreTxt = this.scene.add.text(850, 355, "", {
                        "fontFamily": "Kids Rock Demo",
                        "fontSize": 35,
                        "color": "#F49F1C",
                        "align": 'center',
                        'stroke': '#70451A',
                        'strokeThickness': 6
                    });
                    this.totalScoreTxt.setOrigin(0.5, 0.5);
                    let grd = this.totalScoreTxt.context.createLinearGradient(0, 0, 0, this.totalScoreTxt.height);
                    grd.addColorStop(0, '#FFFF00');
                    grd.addColorStop(1, '#C17316');
                    this.totalScoreTxt.setFill(grd);
                    this.starScoreTxt = this.scene.add.text(635, 431, "", {
                        "fontFamily": "Kids Rock Demo",
                        "fontSize": 24,
                        "color": "#FFFFFF",
                        "align": 'center'
                    });
                    this.starScoreTxt.setOrigin(0.5, 0.5);
                    this.add([
                        this._bg,
                        this._star,
                        this._btnBack,
                        this._btnReplay,
                        this.totalScoreTxt,
                        this.starScoreTxt
                    ]);
                    this._btnBack.setInteractive({ cursor: 'pointer' });
                    this._btnBack.on('pointerup', () => {
                        onBack(this._btnBack);
                        angularComponentRef.backToMenu();
                        // if (this.music) {
                        //     this.music.stop();
                        // }
                    });
                    setupButtonTextureBased(this._btnBack, 'btnBACK1', 'btnBACK2');
                    this._btnReplay.setInteractive({ cursor: 'pointer' });
                    this._btnReplay.once('pointerup', () => {
                        onReplay(this._btnReplay);
                        if (this.music) {
                            this.music.stop();
                        }
                    });
                    setupButtonTextureBased(this._btnReplay, 'btnReplay1', 'btnReplay2');
                }
                show(score, starScore) {
                    this._star.scale = 1.25;
                    this.scene.tweens.add({
                        targets: this._star,
                        "scale": 1,
                        duration: 500,
                        ease: Phaser.Math.Easing.Back.Out
                    });
                    this.totalScoreTxt.text = '';//String(window.angularComponentRef.component.userInfo.totalScore);
                    this.starScoreTxt.text = String(0);
                    this.music = this.scene.sound.add("Fail - close one");
                    this.music.play();
                }
            }
            screen.TryAgainWindow = TryAgainWindow;
        })(screen = sh.screen || (sh.screen = {}));
    })(sh || (sh = {}));
}

function destroyFarmGame() {
    if (game) {
        game.destroy();
    }
}
