import { Component, OnInit, OnDestroy, AfterViewInit, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, Observable } from 'rxjs';
import { AngularFireStorage } from '@angular/fire/storage';
import { PointsMechanismService } from 'src/app/shared/point-mechanism.service'
import { ActivityService } from 'src/app/shared/activity.service';

@Component({
    selector: 'app-letter-song',
    templateUrl: './letter-song.component.html'
})
export class LetterSongActivityComponent implements OnInit, OnDestroy, AfterViewInit {
    protected subscription: Subscription;
    userData: any;

    id: string;
    activityId: string;

    gameStatus: string;
    data: any;
    userInfo: any;

    activity: any;
    activities: Observable<any>;

    titles: any = {};

    progressValue: number;
    isPause: boolean;
    timer: string;
    canSlide: boolean = false;
    isSeeked: boolean = false;

    loading: boolean = true;
    ios;
    userTouch = false;

    constructor(
        private activatedRoute: ActivatedRoute,
        public storage: AngularFireStorage,
        private router: Router,
        public activityService: ActivityService,
        private pm: PointsMechanismService) { }

    ngOnInit() {
        this.ios = this.pm.isIOS();
        this.subscription = this.activatedRoute.params.subscribe((data) => {
            if (data && data['id'] && data['activityId']) {
                this.id = data['id'];
                this.activityId = data['activityId'];
                this.pm.start(this.id, this.activityId);

                this.activityService.getActivityData({ zoneId: this.id, activityId: this.activityId }).subscribe(aData => {
                    this.data = aData.activityData;
                    this.changeGameStatus('introduction');
                });
            }
        });

        this.pm.getUserInfo.subscribe(data => {
            this.userInfo = data;
        });
    }

    @HostListener('document:mousemove', ['$event'])
    public documentMouseDown(event: any): void {
        if (this.canSlide && event.target.id != "sliderZone") {
            let vid = <HTMLVideoElement>document.getElementById('dataAudio');

            var slider = document.getElementById("sliderZone");

            var percentage = ((event.clientX - slider.getBoundingClientRect().left) / slider.offsetWidth);
            vid.currentTime = vid.duration * percentage;

            this.progressValue = (vid.currentTime / vid.duration) * 100;
        }
    }

    @HostListener('document:mouseup', ['$event'])
    public documentMouseUp(event: any): void {
        this.canSlide = false;

        this.isSeeked = false;
    }

    mouseDown() {
        this.canSlide = true;
    }

    mouseUp(event, width) {
        this.canSlide = false;

        if (!this.isSeeked) {
            let player = <HTMLVideoElement>document.getElementById('dataAudio');
            let percent = event.offsetX / width;
            player.currentTime = percent * player.duration;

            this.progressValue = (player.currentTime / player.duration) * 100;

            this.isSeeked = false;
        }
    }
    setbuttonEvents() {
        var buttons = document.querySelectorAll('button');
        for (var i = 0; i < buttons.length; i++) {
            buttons[i].addEventListener('click', function (event) {
                let audio = <HTMLAudioElement>document.getElementById('buttonClick');
                if (audio) audio.play();
            });
            buttons[i].addEventListener('mouseover', function (event) {
                let audio = <HTMLAudioElement>document.getElementById('buttonHover');
                if (audio) audio.play();
            });
        }
    }

    changeGameStatus(status: string) {
        if (status == 'leave') {
            let player = <HTMLAudioElement>document.getElementById('dataAudio');
            if (player.paused === false) {
                player.pause();
                this.isPause = false;
            }
            let audio = <HTMLAudioElement>document.getElementById('warningAudio');
            if (audio) audio.play();
        }
        this.gameStatus = status;
        setTimeout(() => {
            this.setbuttonEvents();
        }, 300);
    }

    ngAfterViewInit(): void {
        this.setbuttonEvents();
        setTimeout(() => {
            this.instructionLoaded();
        }, 3000);
    }

    gameStart() {
        let player = <HTMLAudioElement>document.getElementById('introductionAudio');
        if (player.paused === false) {
            player.pause();
        }
        this.changeGameStatus('start');
        this.progressValue = 0;
        this.timer = '00:00';
        this.togglePlay();
    }

    soundEnd() {
        this.gameFinish();
        this.changeGameStatus('complete');
        let audio = <HTMLAudioElement>document.getElementById('completeAudio');
        if (audio) audio.play();
    }

    gameFinish() {
        this.pm.collectPoint(this.data.maxStars, this.data.progressType);
    }

    backToMenu() {
        this.router.navigate(['/game/activity-list', this.id]);
    }

    ngOnDestroy(): void {
        if (this.subscription) this.subscription.unsubscribe();
    }

    previousState() {
        this.pm.backToActivityList();
    }

    nextActivity() {
        this.pm.nextActivity();
    }

    playIntroductionAudio() {
        let audio = <HTMLAudioElement>document.getElementById('introductionAudio');
        if (audio) audio.play();
        this.userTouch = true;
    }

    initProgressBar() {
        let player = <HTMLAudioElement>document.getElementById('dataAudio');
        // calculate current value time
        this.timer = this.calculateCurrentValue(player.currentTime);

        this.progressValue = (player.currentTime / player.duration) * 100;
    };

    public endAudio() {
        this.isPause = false;
        this.soundEnd();
    }

    seek(event, width) {
        if (this.canSlide) {
            this.isSeeked = true;
            let player = <HTMLAudioElement>document.getElementById('dataAudio');
            let percent = event.offsetX / width;
            player.currentTime = percent * player.duration;

            this.progressValue = (player.currentTime / player.duration) * 100;
        }
    }

    togglePlay() {
        let player = <HTMLAudioElement>document.getElementById('dataAudio');
        if (player.paused === false) {
            player.pause();
            this.isPause = false;
        } else {
            player.play();
            this.isPause = true;
        }
    }

    private calculateCurrentValue(currentTime: number) {
        let current_minute = Math.floor((currentTime / 60) % 60),
            current_seconds_long = currentTime % 60,
            current_seconds = Math.floor(current_seconds_long),
            current_time = (current_minute < 10 ? "0" + current_minute : current_minute) + ":" + (current_seconds < 10 ? "0" + current_seconds : current_seconds);

        return current_time;
    }

    instructionLoaded() {
        if (this.loading) {
            this.loading = false;
            let audio = <HTMLAudioElement>document.getElementById('introductionAudio');
            if (audio) audio.play();
        }
    }
}
