import { Routes } from '@angular/router';
import { ContinentsComponent } from './continents/continents.component';
import { GameComponent } from './game.component';
import { Zone1Component } from './zone-1/zone-1.component';
import { ActivityListComponent } from './activity-list/activity-list.component';
import { QuestionAndAnswerComponent } from './activities/q-a/q-a.component';
import { VideoActivityComponent } from './activities/video/video.component';
import { DragDropActivityComponent } from './activities/drag-drop/drag-drop.component';
import { ReadingActivityComponent } from './activities/reading/reading.component';
import { DotToDotActivityComponent } from './activities/dot-to-dot/dot-to-dot.component';
import { FindTheLetterActivityComponent } from './activities/find-the-letter/find-the-letter.component';
import { MatchTheWordActivityComponent } from './activities/match-the-word/match-the-word.component';
import { ProfileComponent } from './profile/profile.component';
import { SetAvatarComponent } from './set-avatar/set-avatar.component';
import { QuizActivityComponent } from './activities/quiz/quiz.component';
import { AwardsComponent } from './awards/awards.component';
// import { ProgressComponent } from './progress/progress.component';
// import { GamesComponent } from './games/games.component';
// import { NewLettersComponent } from './new-letters/new-letters.component';
import { StoriesComponent } from './stories/stories.component';
import { SongsComponent } from './songs/songs.component';
import { BooksComponent } from './books/books.component';
import { WelcomeActivityComponent } from './activities/welcome/welcome.component';
import { GalleryComponent } from './gallery/gallery.component';
import { SoundSearchComponent } from './activities/sound-search/sound-search.component';
import { LetterSongActivityComponent } from './activities/letter-song/letter-song.component';
import { UserRouteAccessService } from '../shared/user-route-access.service';
import { Zone2Component } from './zone-2/zone-2.component';
import { Zone3Component } from './zone-3/zone-3.component';
import { Zone4Component } from './zone-4/zone-4.component';
import { InnerLogInComponent } from './inner-log-in/inner-log-in.component';
import { Zone5Component } from './zone5/zone5.component';
import { SoundHiveComponent } from './activities/sound-hive/sound-hive.component';
import { LetterGridComponent } from './activities/letter-grid/letter-grid.component';
import { LetterCloudComponent } from './activities/letter-cloud/letter-cloud.component';
import { DragNDropComponent } from './activities/drag-n-drop/drag-n-drop.component';
import { Zone6Component } from './zone6/zone6.component';
import { Zone7Component } from './zone7/zone7.component';
import { CrossTheBridgeComponent } from './activities/cross-the-bridge/cross-the-bridge.component';
import { LetterBasketComponent } from "./activities/letter-basket/letter-basket.component";
import { CarRepairComponent } from "./activities/car-repair/car-repair.component";
import { CatchTheCrabComponent } from "./activities/catch-the-crab/catch-the-crab.component";
import { IceBlocksComponent } from "./activities/ice-blocks/ice-blocks.component";
import { ShootingHoopsComponent } from './activities/shooting-hoops/shooting-hoops.component';
import { PopTheBubblesComponent } from './activities/pop-the-bubbles/pop-the-bubbles.component';
import { Africa1Component } from './africa-1/africa-1.component';
import { Africa2Component } from './africa-2/africa-2.component';
import { Africa3Component } from './africa-3/africa-3.component';
import { SoundBallsComponent } from './activities/sound-balls/sound-balls.component';
import { SoundPadsComponent } from './activities/sound-pads/sound-pads.component';
import { LoadTrainComponent } from './activities/load-train/load-train.component';
import { RocketLaunchComponent } from './activities/rocket-launch/rocket-launch.component';
import { ClimbWallComponent } from './activities/climb-wall/climb-wall.component';
import { TruckRaceComponent } from './activities/truck-race/truck-race.component';
import { LetterBubbleComponent } from './activities/letter-bubble/letter-bubble.component';
import { DoughPressComponent } from './activities/dough-press/dough-press.component';
import { DemolishBuildingComponent } from './activities/demolish-building/demolish-building.component';
import { VideosComponent } from './videos/videos.component';
import { IqraTimeActivityComponent } from './activities/iqra-time/iqraTime.component';
import { AssignmentComponent } from './assignment/assignment.component';
import { ReciteVerseComponent } from './activities/reacite-the-verse/recite.verse';
import { SouthAmerica1Component } from './south-america-1/south-america-1.component';
import { SouthAmerica3Component } from './south-america-3/south-america-3.component';
import { SouthAmerica2Component } from './south-america-2/south-america-2.component';
import { NorthAmerica1Component } from './north-america-1/north-america-1.component';
 import { NorthAmerica2Component } from './north-america-2/north-america-2.component';
 import { NorthAmerica3Component } from './north-america-3/north-america-3.component';
import { AeroPlaneComponent } from './activities/aeroplane/aeroplane.component';
import { BirdGameComponent } from './activities/birdGame/bird.game.component';
import { FarmGameComponent } from './activities/farm/farm.component';
import { JumpingKangarooComponent } from './activities/jumping-kangaroo/jumping-kangaroo.component';
import { MeteorsGameComponent } from './activities/meteors-game/meteors-game.component';
import { ToTheAirComponent } from './activities/to-the-air/to-the-air.component';
import { TruckGameComponent } from './activities/truck-game/truck.game.component';
import { FactoryGameComponent } from './activities/factoryGame/factory.game.component';
import { IceBoardComponent } from './activities/ice-board/ice-board.component';
import { StackWoodComponent } from './activities/stack-wood/stack-wood.component';
import { SubmarineGameComponent } from './activities/submarine/submarine.component';
import { CastleGameComponent } from './activities/castle/castle.component';
import { MosqueCVComponent } from './activities/mosque-complete-verse/mosqueCV.component';
import { FindTheWayComponent } from './activities/find-the-way/fint-the-way.component';
import { SlimeSplatComponent } from './activities/slime-splat/slime-splat.component';

export const GameState: Routes = [
    {
        path: '',
        redirectTo: 'game/continents',
        pathMatch: 'full'
    },

    {
        path: 'game',
        data: {
            authorities: ['ROLE_COMPANY']
        },
        component: GameComponent,
        canActivate: [UserRouteAccessService],
        children: [
            {
                path: 'continents',
                data: {
                    authorities: ['ROLE_COMPANY']
                },
                component: ContinentsComponent,
                canActivate: [UserRouteAccessService],
            },
            {
                path: 'zone1',
                data: {
                    authorities: ['ROLE_COMPANY']
                },
                component: Zone1Component,
                canActivate: [UserRouteAccessService],
            },
            {
                path: 'zone2',
                data: {
                    authorities: ['ROLE_COMPANY']
                },
                component: Zone2Component,
                canActivate: [UserRouteAccessService],
            },
            {
                path: 'zone3',
                data: {
                    authorities: ['ROLE_COMPANY']
                },
                component: Zone3Component,
                canActivate: [UserRouteAccessService],
            },
            {
                path: 'zone4',
                data: {
                    authorities: ['ROLE_COMPANY']
                },
                component: Zone4Component,
                canActivate: [UserRouteAccessService],
            },
            {
                path: 'zone5',
                data: {
                    authorities: ['ROLE_COMPANY']
                },
                component: Zone5Component,
                canActivate: [UserRouteAccessService],
            },
            {
                path: 'zone6',
                data: {
                    authorities: ['ROLE_COMPANY']
                },
                component: Zone6Component,
                canActivate: [UserRouteAccessService],
            },
            {
                path: 'zone7',
                data: {
                    authorities: ['ROLE_COMPANY']
                },
                component: Zone7Component,
                canActivate: [UserRouteAccessService],
            },
            {
                path: 'africa-1',
                data: {
                    authorities: ['ROLE_COMPANY']
                },
                component: Africa1Component,
                canActivate: [UserRouteAccessService],
            },
            {
                path: 'africa-2',
                data: {
                    authorities: ['ROLE_COMPANY']
                },
                component: Africa2Component,
                canActivate: [UserRouteAccessService],
            },
            {
                path: 'africa-3',
                data: {
                    authorities: ['ROLE_COMPANY']
                },
                component: Africa3Component,
                canActivate: [UserRouteAccessService],
            },
            {
                path: 'south-america-1',
                data: {
                    authorities: ['ROLE_COMPANY']
                },
                component: SouthAmerica1Component,
                canActivate: [UserRouteAccessService],
            },
            {
                path: 'south-america-2',
                data: {
                    authorities: ['ROLE_COMPANY']
                },
                component: SouthAmerica2Component,
                canActivate: [UserRouteAccessService],
            },
            {
                path: 'south-america-3',
                data: {
                    authorities: ['ROLE_COMPANY']
                },
                component: SouthAmerica3Component,
                canActivate: [UserRouteAccessService],
            },
            {
                path: 'north-america-1',
                data: {
                    authorities: ['ROLE_COMPANY']
                },
                component: NorthAmerica1Component,
                canActivate: [UserRouteAccessService],
            },
            {
                path: 'north-america-2',
                data: {
                    authorities: ['ROLE_COMPANY']
                },
                component: NorthAmerica2Component,
                canActivate: [UserRouteAccessService],
            },
            {
                path: 'north-america-3',
                data: {
                    authorities: ['ROLE_COMPANY']
                },
                component: NorthAmerica3Component,
                canActivate: [UserRouteAccessService],
            },
            {
                path: 'activity-list/:id',
                data: {
                    authorities: ['ROLE_COMPANY']
                },
                component: ActivityListComponent,
                canActivate: [UserRouteAccessService],
            },
            {
                path: 'activity-questions/:id/:activityId',
                data: {
                    authorities: ['ROLE_COMPANY']
                },
                component: QuestionAndAnswerComponent,
                canActivate: [UserRouteAccessService],
            },
            {
                path: 'activity-video/:id/:activityId',
                data: {
                    authorities: ['ROLE_COMPANY']
                },
                component: VideoActivityComponent,
                canActivate: [UserRouteAccessService],
            },
            {
                path: 'activity-drag-drop/:id/:activityId',
                data: {
                    authorities: ['ROLE_COMPANY']
                },
                component: DragDropActivityComponent,
                canActivate: [UserRouteAccessService],
            },
            {
                path: 'activity-reading/:id/:activityId',
                data: {
                    authorities: ['ROLE_COMPANY']
                },
                component: ReadingActivityComponent,
                canActivate: [UserRouteAccessService],
            },
            {
                path: 'activity-recite-verse/:id/:activityId',
                data: {
                    authorities: ['ROLE_COMPANY']
                },
                component: ReciteVerseComponent,
                canActivate: [UserRouteAccessService],
            },
            {
                path: 'activity-dot-to-dot/:id/:activityId',
                data: {
                    authorities: ['ROLE_COMPANY']
                },
                component: DotToDotActivityComponent,
                canActivate: [UserRouteAccessService],
            },
            {
                path: 'activity-find-the-letter/:id/:activityId',
                data: {
                    authorities: ['ROLE_COMPANY']
                },
                component: FindTheLetterActivityComponent,
                canActivate: [UserRouteAccessService],
            },
            {
                path: 'activity-match-the-codes/:id/:activityId',
                data: {
                    authorities: ['ROLE_COMPANY']
                },
                component: MatchTheWordActivityComponent,
                canActivate: [UserRouteAccessService],
            },
            {
                path: 'activity-sound-search/:id/:activityId',
                data: {
                    authorities: ['ROLE_COMPANY']
                },
                component: SoundSearchComponent,
                canActivate: [UserRouteAccessService],
            },
            {
                path: 'activity-letter-song/:id/:activityId',
                data: {
                    authorities: ['ROLE_COMPANY']
                },
                component: LetterSongActivityComponent,
                canActivate: [UserRouteAccessService],
            },
            {
                path: 'activity-cross-the-bridge/:id/:activityId',
                data: {
                    authorities: ['ROLE_COMPANY']
                },
                component: CrossTheBridgeComponent,
                canActivate: [UserRouteAccessService],
            },
            {
                path: 'activity-letter-basket/:id/:activityId',
                data: {
                    authorities: ['ROLE_COMPANY']
                },
                component: LetterBasketComponent,
                canActivate: [UserRouteAccessService],
            },
            {
                path: 'activity-car-repair/:id/:activityId',
                data: {
                    authorities: ['ROLE_COMPANY']
                },
                component: CarRepairComponent,
                canActivate: [UserRouteAccessService],
            },
            {
                path: 'activity-catch-the-crab/:id/:activityId',
                data: {
                    authorities: ['ROLE_COMPANY']
                },
                component: CatchTheCrabComponent,
                canActivate: [UserRouteAccessService],
            },
            {
                path: 'activity-rocket-launch/:id/:activityId',
                data: {
                    authorities: ['ROLE_COMPANY']
                },
                component: RocketLaunchComponent,
                canActivate: [UserRouteAccessService],
            },
            {
                path: 'activity-ice-blocks/:id/:activityId',
                data: {
                    authorities: ['ROLE_COMPANY']
                },
                component: IceBlocksComponent,
                canActivate: [UserRouteAccessService],
            },
            {
                path: 'activity-shooting-hoops/:id/:activityId',
                data: {
                    authorities: ['ROLE_COMPANY']
                },
                component: ShootingHoopsComponent,
                canActivate: [UserRouteAccessService],
            },
            {
                path: 'activity-sound-pads/:id/:activityId',
                data: {
                    authorities: ['ROLE_COMPANY']
                },
                component: SoundPadsComponent,
                canActivate: [UserRouteAccessService],
            },
            {
                path: 'activity-sound-balls/:id/:activityId',
                data: {
                    authorities: ['ROLE_COMPANY']
                },
                component: SoundBallsComponent,
                canActivate: [UserRouteAccessService],
            },
            {
                path: 'activity-pop-the-bubbles/:id/:activityId',
                data: {
                    authorities: ['ROLE_COMPANY']
                },
                component: PopTheBubblesComponent,
                canActivate: [UserRouteAccessService],
            },
            {
                path: 'quiz-activity/:id',
                data: {
                    authorities: ['ROLE_COMPANY']
                },
                component: QuizActivityComponent,
                canActivate: [UserRouteAccessService],
            },
            {
                path: 'activity-welcome/:id/:activityId',
                data: {
                    authorities: ['ROLE_COMPANY']
                },
                component: WelcomeActivityComponent,
                canActivate: [UserRouteAccessService],
            },
            {
                path: 'activity-sound-hive/:id/:activityId',
                data: {
                    authorities: ['ROLE_COMPANY']
                },
                canActivate: [UserRouteAccessService],
                component: SoundHiveComponent
            },
            {
                path: 'activity-letter-grid/:id/:activityId',
                data: {
                    authorities: ['ROLE_COMPANY']
                },
                canActivate: [UserRouteAccessService],
                component: LetterGridComponent
            },
            {
                path: 'activity-climb-wall/:id/:activityId',
                data: {
                    authorities: ['ROLE_COMPANY']
                },
                canActivate: [UserRouteAccessService],
                component: ClimbWallComponent
            },
            {
                path: 'activity-letter-bubble/:id/:activityId',
                data: {
                    authorities: ['ROLE_COMPANY']
                },
                canActivate: [UserRouteAccessService],
                component: LetterBubbleComponent
            },
            {
                path: 'activity-letter-cloud/:id/:activityId',
                data: {
                    authorities: ['ROLE_COMPANY']
                },
                canActivate: [UserRouteAccessService],
                component: LetterCloudComponent
            },
            {
                path: 'activity-drag-n-drop/:id/:activityId',
                data: {
                    authorities: ['ROLE_COMPANY']
                },
                canActivate: [UserRouteAccessService],
                component: DragNDropComponent
            },
            {
                path: 'profile',
                data: {
                    authorities: ['ROLE_COMPANY']
                },
                component: ProfileComponent,
                canActivate: [UserRouteAccessService],
            },
            {
                path: 'set-avatar',
                data: {
                    authorities: ['ROLE_COMPANY']
                },
                component: SetAvatarComponent,
                canActivate: [UserRouteAccessService],
            },
            {
                path: 'my-awards',
                data: {
                    authorities: ['ROLE_COMPANY']
                },
                component: AwardsComponent,
                canActivate: [UserRouteAccessService],
            },
            {
                path: 'stories',
                data: {
                    authorities: ['ROLE_COMPANY']
                },
                component: StoriesComponent,
                canActivate: [UserRouteAccessService],
            },
            {
                path: 'videos',
                data: {
                    authorities: ['ROLE_COMPANY']
                },
                component: VideosComponent,
                canActivate: [UserRouteAccessService],
            },
            {
                path: 'songs',
                data: {
                    authorities: ['ROLE_COMPANY']
                },
                component: SongsComponent,
                canActivate: [UserRouteAccessService],
            },
            {
                path: 'books',
                data: {
                    authorities: ['ROLE_COMPANY']
                },
                component: BooksComponent,
                canActivate: [UserRouteAccessService],
            },
            {
                path: 'my-gallery',
                data: {
                    authorities: ['ROLE_COMPANY']
                },
                component: GalleryComponent,
                canActivate: [UserRouteAccessService],
            },
            {
                path: 'inner-login',
                data: {
                    authorities: ['ROLE_COMPANY']
                },
                component: InnerLogInComponent,
                canActivate: [UserRouteAccessService],
            },
            {
                path: 'activity-truck-race/:id/:activityId',
                data: {
                    authorities: ['ROLE_COMPANY']
                },
                canActivate: [UserRouteAccessService],
                component: TruckRaceComponent
            },
            {
                path: 'activity-load-train/:id/:activityId',
                data: {
                    authorities: ['ROLE_COMPANY']
                },
                component: LoadTrainComponent,
                canActivate: [UserRouteAccessService],
            },

            {
                path: 'activity-dough-press/:id/:activityId',
                data: {
                    authorities: ['ROLE_COMPANY']
                },
                component: DoughPressComponent,
                canActivate: [UserRouteAccessService],
            },
            {
                path: 'activity-demolish-building/:id/:activityId',
                data: {
                    authorities: ['ROLE_COMPANY']
                },
                component: DemolishBuildingComponent,
                canActivate: [UserRouteAccessService],
            },
            {
                path: 'activity-iqra-time/:id/:activityId',
                data: {
                    authorities: ['ROLE_COMPANY']
                },
                component: IqraTimeActivityComponent,
                canActivate: [UserRouteAccessService],
            },
            {
                path: 'activity-aeroplane/:id/:activityId',
                data: {
                    authorities: ['ROLE_COMPANY']
                },
                component: AeroPlaneComponent,
                canActivate: [UserRouteAccessService],
            },
            {
                path: 'activity-bird-game/:id/:activityId',
                data: {
                    authorities: ['ROLE_COMPANY']
                },
                component: BirdGameComponent,
                canActivate: [UserRouteAccessService],
            },
            {
                path: 'activity-farm-game/:id/:activityId',
                data: {
                    authorities: ['ROLE_COMPANY']
                },
                component: FarmGameComponent,
                canActivate: [UserRouteAccessService],
            },
            {
                path: 'activity-jumping-kangaroo/:id/:activityId',
                data: {
                    authorities: ['ROLE_COMPANY']
                },
                component: JumpingKangarooComponent,
                canActivate: [UserRouteAccessService],
            },
            {
                path: 'activity-meteors-game/:id/:activityId',
                data: {
                    authorities: ['ROLE_COMPANY']
                },
                component: MeteorsGameComponent,
                canActivate: [UserRouteAccessService],
            },
            {
                path: 'activity-to-the-air/:id/:activityId',
                data: {
                    authorities: ['ROLE_COMPANY']
                },
                component: ToTheAirComponent,
                canActivate: [UserRouteAccessService],
            },
            {
                path: 'activity-truck-game/:id/:activityId',
                data: {
                    authorities: ['ROLE_COMPANY']
                },
                component: TruckGameComponent,
                canActivate: [UserRouteAccessService],
            },
            {
                path: 'activity-factory-game/:id/:activityId',
                data: {
                    authorities: ['ROLE_COMPANY']
                },
                component: FactoryGameComponent,
                canActivate: [UserRouteAccessService],
            },
            {
                path: 'activity-ice-board/:id/:activityId',
                data: {
                    authorities: ['ROLE_COMPANY']
                },
                component: IceBoardComponent,
                canActivate: [UserRouteAccessService],
            },
            {
                path: 'activity-stack-wood/:id/:activityId',
                data: {
                    authorities: ['ROLE_COMPANY']
                },
                component: StackWoodComponent,
                canActivate: [UserRouteAccessService],
            },
            {
                path: 'activity-submarine-game/:id/:activityId',
                data: {
                    authorities: ['ROLE_COMPANY']
                },
                component: SubmarineGameComponent,
                canActivate: [UserRouteAccessService],
            },
            {
                path: 'activity-castle-game/:id/:activityId',
                data: {
                    authorities: ['ROLE_COMPANY']
                },
                component: CastleGameComponent,
                canActivate: [UserRouteAccessService],
            },
            {
                path: 'activity-mosque-complete-verse/:id/:activityId',
                data: {
                    authorities: ['ROLE_COMPANY']
                },
                component: MosqueCVComponent,
                canActivate: [UserRouteAccessService],
            },
            {
                path: 'activity-find-the-way/:id/:activityId',
                data: {
                    authorities: ['ROLE_COMPANY']
                },
                component: FindTheWayComponent,
                canActivate: [UserRouteAccessService],
            },
            {
                path: 'activity-slime-splat/:id/:activityId',
                data: {
                    authorities: ['ROLE_COMPANY']
                },
                component: SlimeSplatComponent,
                canActivate: [UserRouteAccessService],
            },
            {
                path: 'my-assignments',
                data: {
                    authorities: ['ROLE_COMPANY']
                },
                component: AssignmentComponent,
                canActivate: [UserRouteAccessService],
            }

        ]
    }

];
