import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AngularFirestore } from '@angular/fire/firestore';
import { StorageService } from '../../storage/storage.service';
import { HttpService } from '../../http/http.service';

@Injectable({
    providedIn: 'root'
})
export class TeacherService {
    constructor(
        private afs: AngularFirestore,
        private storageService: StorageService,
        private http: HttpService
    ) { }

    create(data): Observable<any> {
        return this.http.postRequest('adminCreateTeacher', data);
    }

    update(data): Observable<any> {
        return this.http.postRequest('adminUpdateTeacher', data);
    }

    get(): Promise<object> {
        //return this.http.postRequest('adminGetTeacher', {});
        return new Promise<object>((resolve, reject) => {
            const userData = this.storageService.get('userData');
            this.afs.collection('adminUserData',
                ref => ref.where('school', '==', userData.school).where('role', 'in', ['teacher', 'admin_teacher'])
            ).get().subscribe((teachers: any) => {
                if (teachers && teachers.size) {
                    const userIds = [];
                    teachers = teachers.docs.map((doc) => {
                        userIds.push(doc.id);
                        return { id: doc.id, ...doc.data() }
                    });

                    const userIdsList = [];
                    var lim = 10;
                    let stepK = 0;

                    for (var i = 0; Math.ceil(userIds.length / lim) > i; i++) {
                        userIdsList.push(userIds.slice(i * lim, i * lim + lim));
                    }

                    for (var i = 0; i < userIdsList.length; i++) {
                        this.afs.collection('adminClass',
                            ref => ref.where('userId', 'in', userIdsList[i])
                        ).get().subscribe(classes => {
                            const classList = {};
                            const classIds = {};
                            classes.docs.forEach((doc) => {
                                if (!classList[doc.data().userId]) {
                                    classList[doc.data().userId] = [];
                                    classIds[doc.data().userId] = [];
                                }
                                classList[doc.data().userId].push(doc.data().name);
                                classIds[doc.data().userId].push({
                                    id: doc.id,
                                    name: doc.data().name
                                });
                            });

                            stepK++;
                            if (stepK == userIdsList.length) {
                                resolve(teachers.map(item => {
                                    if (classList[item.id]) {
                                        return { ...item, 'class': classList[item.id] ? classList[item.id].join(', ') : '', 'classIds': (classIds[item.id] ? classIds[item.id] : []) }
                                    }

                                    return item;
                                }));
                            } else {
                                teachers = teachers.map(item => {
                                    if (classList[item.id]) {
                                        return { ...item, 'class': classList[item.id] ? classList[item.id].join(', ') : '', 'classIds': (classIds[item.id] ? classIds[item.id] : []) }
                                    }

                                    return item;
                                });
                            }
                        })
                    }
                } else {
                    resolve([]);
                }
            }, error => {
                resolve([]);
            })
        });
    }

    deleteByIds(data): Observable<any> {
        return this.http.postRequest('adminDeleteTeacher', data);
    }

    getItem(id): Promise<object> {
        //return this.http.postRequest('adminGetTeacherItem', {id: id});
        return new Promise<object>((resolve, reject) => {
            this.afs.collection('adminUserData').doc(id).get().subscribe(student => {
                resolve(student.data());
            }, error => {
                resolve({});
            })
        });
    }
}
