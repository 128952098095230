export const environment = {
    production: false,
    name: 'Quranera.com development',
    firebaseFunctionUrl: 'https://us-central1-quranera-dev.cloudfunctions.net/',
    firebase: {
        apiKey: "AIzaSyDzXii2mJSS7wC-CX0oXVRAwNuMB527nzQ",
        authDomain: "quranera-dev.firebaseapp.com",
        databaseURL: "https://quranera-dev.firebaseio.com",
        projectId: "quranera-dev",
        storageBucket: "quranera-dev.appspot.com",
        messagingSenderId: "18412727511",
        appId: "1:18412727511:web:788ac8eec70bd661799492"
    },
    stripe: {
        token: "pk_test_51QczbR03NdFG2zOsS6weYbb8ps3QCdTSfiAn6IBqSGspeWwMkdKovwxaqEdd8Fb1EMAnaokLtXIKD9tX054TS90b00TStzaW85"
    }
};
