import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { PointsMechanismService } from '../../../shared/point-mechanism.service';
import { FrameShowService } from '../../../shared/FrameShowService';
import { ActivityService } from 'src/app/shared/activity.service';

declare function crossTheBridge(data: any);

declare function destroyCrossTheBridge();

@Component({
    selector: 'app-cross-the-bridge',
    templateUrl: './cross-the-bridge.component.html',
    styleUrls: ['./cross-the-bridge.component.scss']
})
export class CrossTheBridgeComponent implements OnInit, OnDestroy {
    protected subscription: Subscription;
    userInfo: any;
    loading: boolean;
    data: any;
    id: any;

    constructor(private activatedRoute: ActivatedRoute,
        private ngZone: NgZone,
        private frame: FrameShowService,
        private pm: PointsMechanismService,
        public activityService: ActivityService) {
        window['angularComponentRef'] = {
            component: this,
            zone: this.ngZone,
            gameFinish: (value) => this.gameFinish(value),
            backToMenu: () => this.backToMenu(),
            nextActivity: () => this.nextActivity(),
            getTotalScore: () => this.getTotalScore(),
            loadingFinished: () => this.loadingFinished()
        };
    }

    ngOnInit() {
        this.frame.useV3();
        this.frame.callAfterDone(() => {
            this.subscription = this.activatedRoute.params.subscribe((data) => {
                this.id = data['id'];

                this.pm.getUserInfo.subscribe(userInfo => {
                    this.userInfo = userInfo;
                });

                this.pm.start(this.id, data['activityId']);

                this.activityService.getActivityData({ zoneId: this.id, activityId: data.activityId }).subscribe(aData => {
                    setTimeout(() => {
                        crossTheBridge(aData.activityData['data']);
                    }, 1000);
                    this.data = aData.activityData;
                });
            });
        });
    }

    gameFinish(value: any) {
        this.pm.collectPoint(value.score, this.data.progressType);
    }

    backToMenu() {
        this.pm.backToActivityList();
    }

    nextActivity() {
        this.pm.nextActivity();
    }

    getTotalScore() {
        return this.userInfo.totalScore;
    }

    loadingFinished() {
        this.loading = false;
    }

    loadScript() {
        let isFound = false;
        const scripts = document.getElementsByTagName('script');
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < scripts.length; ++i) {
            if (scripts[i].getAttribute('src') != null && scripts[i].getAttribute('src').includes('loader')) {
                isFound = true;
            }
        }

        if (!isFound) {
            const dynamicScripts = ['assets/games/soundhive/js/phaser.min.js', 'assets/games/cross-the-bridge/js/main.js'];
            const loadedScripts = document.createElement('div');
            loadedScripts.id = 'dynamic-scripts';
            // tslint:disable-next-line:prefer-for-of
            for (let i = 0; i < dynamicScripts.length; i++) {
                const node = document.createElement('script');
                node.src = dynamicScripts[i];
                node.type = 'text/javascript';
                node.async = false;
                node.charset = 'utf-8';
                document.getElementsByTagName('head')[0].appendChild(loadedScripts).appendChild(node);
            }
        }
    }

    ngOnDestroy(): void {
        destroyCrossTheBridge();
        window['angularComponentRef'] = null;
        if(this.subscription) this.subscription.unsubscribe();
    }
}
