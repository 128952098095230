import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../shared/auth.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-zone-2',
    templateUrl: './zone-2.component.html'
})
export class Zone2Component implements OnInit, OnDestroy, AfterViewInit {
    user: UserData;

    zones = [
        {
            hover: false,
            id: '1-2-1',
            locked: false,
            active: false
        },
        {
            hover: false,
            id: '1-2-2',
            locked: false,
            active: false
        },
        {
            hover: false,
            id: '1-2-3',
            locked: false,
            active: false
        },
        {
            hover: false,
            id: '1-2-4',
            locked: false,
            active: false
        }
    ];

    plane: any = {
        position: 1
    }
    itemsStyle: any;
    tick2Quiz: boolean = true;
    activeZone: number = 1;
    openZones: number = 1;
    adminUserData;
    loading: boolean = true;
    quizLevel: number = 0;
    activeContinent: number;

    constructor(private router: Router, private auth: AuthService) { }

    userSubscription: Subscription;

    ngOnDestroy() {
        this.userSubscription.unsubscribe();
    }

    ngOnInit() {
        this.tick2Quiz = true;
        this.userSubscription = this.auth.onUserUpdate.subscribe(userData => {
            if (userData != null && userData) this.loadChildData(userData);
        }, error => {
            console.log('error', error);
        });
    }

    loadChildData(userData) {
        this.zones = [
            {
                hover: false,
                id: '1-2-1',
                locked: false,
                active: false
            },
            {
                hover: false,
                id: '1-2-2',
                locked: false,
                active: false
            },
            {
                hover: false,
                id: '1-2-3',
                locked: false,
                active: false
            },
            {
                hover: false,
                id: '1-2-4',
                locked: false,
                active: false
            }
        ];

        this.user = userData;
        if (this.user.activities["1-3-1"]) {
            this.tick2Quiz = false;
        }
        // this.openZones = userData.zone2Lvl;
        this.adminUserData = JSON.parse(localStorage.getItem('quranera_admin_userData'));
        if (!this.user) {
            if (this.adminUserData) {
                userData = this.adminUserData;
                userData.activeLevel = '9-9-9';
            }
        }
        this.plane.position = this.openZones;
        this.activeZone = this.adminUserData ? 99 : parseInt(userData.activeLevel.split("-")[1]);
        this.activeContinent = parseInt(userData.activeLevel.split('-')[0], 10);
        if (this.activeZone > 2) {
            this.openZones = 5;
        } else {
            this.openZones = parseInt(userData.activeLevel.split('-')[2], 10);
        }

        if (userData.quizLevel) {
            this.quizLevel = parseInt(userData.quizLevel, 10);
        }
        if (this.adminUserData) {
            this.quizLevel = 100;
        }

        this.zones.map((zone: Zone, i) => {
            this.zones[i].locked = this.adminUserData ? false : !(this.user.activities[zone.id]);
            this.zones[i].active = this.adminUserData ? false : (this.user.activeLevel === zone.id && this.user.activities[zone.id]);
        });
    }

    ngAfterViewInit() {
        setTimeout(() => {
            if (this.loading) {
                this.instructionLoaded();
            }
        }, 3000);
    }

    mouseEnter(index: number) {
        this.zones[index].hover = true;
        const audio = document.getElementById('continentHover') as HTMLAudioElement;
        audio.pause();
        audio.currentTime = 0;
        if (audio) audio.play();
    }

    mouseLeave(index: number) {
        this.zones[index].hover = false;
    }

    poiClick(zone: Zone) {
        if (!zone.locked) {
            const audio = document.getElementById('continentClick') as HTMLAudioElement;
            if (audio) audio.play();
            setTimeout(() => {
                this.router.navigate(['/game/activity-list/' + zone.id]);
            }, 1000);
        }
    }

    playBgAudio() {
        const audio = document.getElementById('bgAudio') as HTMLAudioElement;
        if (audio) audio.play();
    }
    playIntroductionAudio() {
        let audio = <HTMLAudioElement>document.getElementById('introductionAudio');
        if (audio) audio.play();
    }

    instructionLoaded() {
        if (this.loading) {
            this.loading = false;
            let audio = <HTMLAudioElement>document.getElementById('introductionAudio');
            if (audio) audio.play();
        }
    }

    haveAccess(activityLevel) {
        if (this.adminUserData) return true;
        if (this.user == undefined) return false;
        if (!this.user['activities'][activityLevel + '-1']
            && !this.user['activities'][activityLevel + '-2']
            && !this.user['activities'][activityLevel + '-3']
            && !this.user['activities'][activityLevel + '-4']
        ) return false;

        for (var a = 1; a <= 4; a++) {
            for (var i = 1; i <= 15; i++) {
                if (this.user["activities"][activityLevel + '-' + a.toString()]) {
                    if (this.user["activities"][activityLevel + '-' + a.toString()][i.toString()]) return true;
                }
            }
        }

        return false;
    }
}
