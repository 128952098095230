import { Injectable } from '@angular/core';
import { Router } from "@angular/router";
import { AngularFireAuth } from "@angular/fire/auth";
import { User } from 'firebase';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

export enum AccessType {
    Child,
    Parent,
    Teacher,
    Admin,
    SuperAdmin
}

@Injectable({
    providedIn: 'root'
})
export class ActivityService {
    user: User;
    constructor(
        public afAuth: AngularFireAuth,
        private http: HttpClient,
        public router: Router
    ) { }

    getDataFDB(data): Observable<any> {
        var token = localStorage.getItem('quranera_admin_accessToken');

        const superUserData = localStorage.getItem('superAdmin_userData');

        if (superUserData) token = localStorage.getItem('superAdmin_accessToken');

        if (data.token) token = data.token;

        delete data.token;

        return this.http.post<any>(environment.firebaseFunctionUrl + 'getDataFDB', data, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'authorization': 'Bearer ' + token
            })
        });
    }

    createSelfData(data): Observable<any> {
        var token = localStorage.getItem('quranera_admin_accessToken');

        const superUserData = localStorage.getItem('superAdmin_userData');

        if (superUserData) token = localStorage.getItem('superAdmin_accessToken');

        if (data.token) token = data.token;

        delete data.token;

        return this.http.post<any>(environment.firebaseFunctionUrl + 'createSelfData', data, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'authorization': 'Bearer ' + token
            })
        });
    }

    getSelfData(data): Observable<any> {
        var token = localStorage.getItem('quranera_admin_accessToken');

        const superUserData = localStorage.getItem('superAdmin_userData');

        if (superUserData) token = localStorage.getItem('superAdmin_accessToken');
        else {
            if (data.token) token = data.token;
        }

        delete data.token;

        return this.http.post<any>(environment.firebaseFunctionUrl + 'getSelfData', data, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'authorization': 'Bearer ' + token
            })
        });
    }

    generateAID(data): Observable<any> {
        var token = localStorage.getItem('quranera_admin_accessToken');

        const superUserData = localStorage.getItem('superAdmin_userData');

        if (superUserData) token = localStorage.getItem('superAdmin_accessToken');

        if (data.token) token = data.token;

        delete data.token;

        return this.http.post<any>(environment.firebaseFunctionUrl + 'generateAID', data, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'authorization': 'Bearer ' + token
            })
        });
    }

    setSuperAdminAT(data): Observable<any> {
        var token = localStorage.getItem('superAdmin_accessToken');

        return this.http.post<any>(environment.firebaseFunctionUrl + 'setSuperAdminAT', data, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'authorization': 'Bearer ' + token
            })
        });
    }

    handleAffiliate(data): Observable<any> {
        return this.http.post<any>(environment.firebaseFunctionUrl + 'handleAffiliate', data, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            })
        });
    }

    getActivityData(data): Observable<any> {
        return this.http.post<any>(environment.firebaseFunctionUrl + 'getActivityData', data, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            })
        });
    }

    updateSessionDurations(data): Observable<any> {
        var token = localStorage.getItem('accessToken');

        if (data.token) token = data.token;

        delete data.token;

        return this.http.post<any>(environment.firebaseFunctionUrl + 'updateSessionDurations', data, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'authorization': 'Bearer ' + token
            })
        });
    }

    getQuizData(data): Observable<any> {
        return this.http.post<any>(environment.firebaseFunctionUrl + 'getQuizData', data, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            })
        });
    }

    getAssignmentData(): Observable<any> {
        var token = localStorage.getItem('accessToken');

        return this.http.post<any>(environment.firebaseFunctionUrl + 'getAssignmentData', null, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'authorization': 'Bearer ' + token
            })
        });
    }

    updateAssignmentItem(data): Observable<any> {
        var token = localStorage.getItem('accessToken');

        return this.http.post<any>(environment.firebaseFunctionUrl + 'updateAssignmentItem', data, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'authorization': 'Bearer ' + token
            })
        });
    }

    checkToken(callback) {
        const expirationTime = parseInt(localStorage.getItem('expirationTime') || "0");
        const nowTime = (new Date()).getTime();

        if (nowTime > expirationTime) {
            this.afAuth.auth.onAuthStateChanged(user => {
                this.afAuth.auth.currentUser.getIdToken(true).then(response => {
                    localStorage.setItem('accessToken', response);

                    localStorage.setItem('expirationTime', (nowTime + 3590000).toString());
                    callback();
                }).catch(error => {
                    console.log('error', error);
                })
            });
        } else {
            callback();
        }
    }
}
