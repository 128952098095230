var garage;
var game;

var carRepair = (json) => {
    (function (garage) {
        class App extends Phaser.Game {
            constructor(json) {
                App.json = json;
                let config = {
                    type: Phaser.AUTO,
                    width: App.CANVAS_WIDTH,
                    height: App.CANVAS_HEIGHT,
                    parent: 'game-container',
                    dom: {
                        createContainer: false
                    },
                    scale: {
                        mode: FitScaleManager.detectBestScaleMode(),
                        autoCenter: Phaser.Scale.CENTER_BOTH
                    },
                    transparent: true,
                    scene: {
                        create: () => {
                            this.scene.add('Boot', garage.scene.Boot, true);
                        }
                    }
                };
                super(config);
            }
        }

        App.CANVAS_WIDTH = 980;
        App.CANVAS_HEIGHT = 600;
        garage.App = App;
    })(garage || (garage = {}));

    var delayedCalls = [];

    function delayedCall(delay, callback, args, callbackScope) {
        let scene = game.scene.getAt(0);
        if (scene) {
            let dc = scene.time.delayedCall(delay, callback, args, callbackScope);
            delayedCalls.push(dc);
            return dc;
        }
        let t = setTimeout(callback, delay);
        delayedCalls.push(t);
        return t;
    }

    function pauseAllDelayedCalls() {
        for (let dc of delayedCalls) {
            if (dc instanceof Phaser.Time.TimerEvent) {
                dc.paused = true;
            }
        }
    }

    function resumeAllDelayedCalls() {
        for (let dc of delayedCalls) {
            if (dc instanceof Phaser.Time.TimerEvent) {
                dc.paused = false;
            }
        }
    }

    function destroyAllDelayedCalls() {
        for (let dc of delayedCalls) {
            if (dc instanceof Phaser.Time.TimerEvent) {
                dc.remove(false);
            } else {
                clearTimeout(dc);
            }
        }
        delayedCalls = [];
    }

    function setPageBackground(bg) {
        document.querySelector("html").style.backgroundImage = "url(assets/games/car-repair/imgs/" + bg + ".jpg)";
    }

    function setupButton(btn, frame) {
        btn.on('pointerdown', () => {
            btn.setFrame(frame + '_hover' + '0000');
        });
        btn.on('pointerover', () => {
            btn.setFrame(frame + '_hover' + '0000');
            game.scene.getAt(0).sound.add("button hover").play();
        });
        btn.on('pointerout', () => {
            btn.setFrame(frame + '0000');
        });
        btn.on('pointerup', () => {
            btn.setFrame(frame + '0000');
            game.scene.getAt(0).sound.add('activity selection - button selection').play();
        });
    }

    function setupButtonTextureBased(btn, texture, hoverTexture) {
        btn.on('pointerdown', () => {
            btn.setTexture(hoverTexture);
        });
        btn.on('pointerover', () => {
            btn.setTexture(hoverTexture);
            game.scene.getAt(0).sound.add("button hover").play();
        });
        btn.on('pointerout', () => {
            btn.setTexture(texture);
        });
        btn.on('pointerup', () => {
            btn.setTexture(texture);
            game.scene.getAt(0).sound.add('activity selection - button selection').play();
        });
    }

    function playBtnClickAnim(target) {
        let sc = target.hasOwnProperty("defScale") ? target["defScale"] : 1;
        target.scaleX = target.scaleY = sc;
        let scene = game.scene.getAt(0);
        scene.tweens.add({
            targets: target,
            "scaleX": 0.9 * sc,
            "scaleY": 0.9 * sc,
            duration: 100,
            yoyo: true
        });
    }

    /**
     * @author Roman Parada
     * This class is created to fix overlapping of bottom part of canvas by navigation bar in iOS.
     * It make a delayed resize of the canvas (like Phaser-3 FIT methods does) and it overrides Phaser-3 input window to Phaser-3 core transform methods
     *
     * How to use:
     * Just call the code line below in Boot scene's init() method of your project:
     * new FitScaleManager(this.game).setup();
     */
    class FitScaleManager {
        constructor(game) {
            this.doResize = () => {
                let scale = this.calculateScale();
                let newCanvasWidth = this.phaserScaleManager.width * scale;
                let newCanvasHeight = this.phaserScaleManager.height * scale;
                this.canvasStyle.width = newCanvasWidth + 'px';
                this.canvasStyle.height = newCanvasHeight + 'px';
                this.canvasStyle.marginLeft = (window.innerWidth - newCanvasWidth) / 2 + 'px';
                this.canvasStyle.marginTop = (window.innerHeight - newCanvasHeight) / 2 + 'px';
            };
            this.game = game;
            this.canvasStyle = this.game.canvas.style;
            this.phaserScaleManager = this.game.scale;
        }

        static detectBestScaleMode() {
            let iOS = /iPad|iPhone|iPod/.test(navigator.platform || "");
            let isAndroid = window.navigator.userAgent.toLowerCase().indexOf("android") > -1;
            return iOS || isAndroid ? Phaser.Scale.FIT : Phaser.Scale.NONE;
        }
        ;

        /**
         * Just call this method once in Boot scene's init() method
         */
        setup() {
            this.phaserScaleManager.addListener(Phaser.Scale.Events.RESIZE, this.onResize, this);
            this.overridePhaserTransformMethods();
            this.onResize();
        }

        calculateScale() {
            if (game.scale.scaleMode == Phaser.Scale.NONE)
                return 1;
            return Math.min(window.innerWidth / this.phaserScaleManager.width, window.innerHeight / this.phaserScaleManager.height);
        }

        overridePhaserTransformMethods() {
            this.game.scale.transformX = (pageX) => {
                return (pageX - parseInt(this.canvasStyle.marginLeft.split("px")[0])) / this.calculateScale();
            };
            this.game.scale.transformY = (pageY) => {
                return (pageY - parseInt(this.canvasStyle.marginTop.split("px")[0])) / this.calculateScale();
            };
        }

        onResize() {
            setTimeout(this.doResize, FitScaleManager.RESIZE_DELAY);
        }
    }

    FitScaleManager.RESIZE_DELAY = 500;

    (function (garage) {
        var core;
        (function (core) {
            class Gameplay {
                constructor() {
                    this.allLettersNames = null;
                    this.garagesNum = 3;
                    this.failsNumToLose = 3;
                    this.currentRound = 0;
                    this.correctAnswersCount = 0;
                    this.wrongAnswersCount = 0;
                    this.correctAnswersCountThisRound = 0;
                    this.wrongAnswersCountThisRound = 0;
                    this.onNewRound = null;
                    this.nextLetterDelay = 0;
                    this.failsNumToLose = Number(game.cache.json.get('gameplay')["failsNumToLose"]);
                    this.useImages = Boolean(game.cache.json.get('gameplay')["useImages"]);
                }

                setupCallbacks(onComplete, onLose, onNewRound) {
                    this.onComplete = onComplete;
                    this.onLose = onLose;
                    this.onNewRound = onNewRound;
                }

                onLetterChosen() {
                    this.currentRound++;
                    if (this.currentRound >= this.totalRoundsNum) {
                        this.onComplete(this.correctAnswersCount, this.correctAnswersCount);
                        return true;
                    } else {
                        this.nextLetter();
                    }
                    return false;
                }

                nextLetter() {
                    let fn = () => {
                        this.randomize();
                        this.correctAnswersCountThisRound = 0;
                        this.wrongAnswersCountThisRound = 0;
                        if (this.onNewRound)
                            this.onNewRound();
                    };
                    if (this.nextLetterDelay == 0) {
                        fn();
                    } else {
                        delayedCall(this.nextLetterDelay, fn);
                    }
                }

                readWord(word) {
                    let d = 2000;
                    delayedCall(d, () => {
                        game.scene.getScene('ScreenMain').sound.add(word).play();
                    });
                    return d + 750;
                }

                getCorrectLetterName(index) {
                    return this.garageLettersNames[index];
                }

                getCorrectAudioKey(index) {
                    return this.garageLettersAudioKey[index];
                }

                onCorrectAnswer() {
                    this.correctAnswersCount++;
                    this.correctAnswersCountThisRound++;
                    this.nextLetterDelay = this.readWord(this.correctWord);
                    return this.onLetterChosen();
                }

                onWrongAnswer() {
                    this.wrongAnswersCount++;
                    this.wrongAnswersCountThisRound++;
                    this.nextLetterDelay = 1000;
                    if (this.wrongAnswersCount >= this.failsNumToLose) {
                        this.onLose(0, 0);
                        return true;
                    } else {
                        this.onLetterChosen();
                    }
                    return false;
                }

                getCurrentTotalAnswersCount() {
                    return this.correctAnswersCount + this.wrongAnswersCount;
                }

                getCurrentTotalAnswersCountThisRound() {
                    return this.correctAnswersCountThisRound + this.wrongAnswersCountThisRound;
                }

                isNewRound() {
                    return this.getCurrentTotalAnswersCountThisRound() == 0;
                }

                isRoundsComplete() {
                    return this.currentRound >= this.totalRoundsNum;
                }

                randomize() {
                    let round = this.rounds[this.currentRound];
                    this.correctWord = round["correctWord"];
                    if (this.wordsLetters === undefined) {

                        this.garageLettersNames = this.correctWord.split("");
                    }
                    else {
                        this.garageLettersNames = this.wordsLetters[this.correctWord];
                    }
                    this.garageLettersNames = this.garageLettersNames.reverse();
                    this.garageLettersAudioKey = [];
                    for (let i = 0; i < this.garageLettersNames.length; i++) {
                        this.garageLettersAudioKey.push(this.getAudioKeyOfChar(this.garageLettersNames[i]));
                    }
                    this.carWords = Phaser.Utils.Array.Shuffle([this.correctWord, round["wrongWord1"], round["wrongWord2"]]);
                }

                getAudioKeyOfChar(letter) {
                    let json = game.cache.json.get('gameplay');
                    let letters = json["letters"];
                    for (let d of letters) {
                        if (letter == d['letterName']) {
                            return d['audioKey'];
                        }
                    }
                    return null;
                }

                reset() {
                    this.setupCallbacks(null, null, null);
                    this.correctWord = null;
                    let json = JSON.parse(JSON.stringify(game.cache.json.get('gameplay')));

                    this.wordsLetters = json["wordsLetters"];

                    this.letters = json["letters"].slice();
                    this.allLettersNames = [];
                    for (let d of this.letters)
                        this.allLettersNames.push(d["letterName"]);
                    this.rounds = JSON.parse(JSON.stringify(json["rounds"]));
                    this.totalRoundsNum = this.rounds.length;
                    this.currentRound = 0;
                    this.correctAnswersCount = 0;
                    this.wrongAnswersCount = 0;
                    this.correctAnswersCountThisRound = 0;
                    this.wrongAnswersCountThisRound = 0;
                    this.nextLetterDelay = 0;
                    this.nextLetter();
                }
            }

            core.Gameplay = Gameplay;
        })(core = garage.core || (garage.core = {}));
    })(garage || (garage = {}));

    (function (garage) {
        var scene;
        (function (scene) {
            class Boot extends Phaser.Scene {
                init() {
                    this.game.scale.transformX = (pageX) => {
                        let offsetLeft = 0;
                        let parentElement = game.canvas.parentElement;
                        while (parentElement) {
                            if (parentElement.offsetLeft) {
                                offsetLeft = parentElement.offsetLeft;
                                break;
                            }
                            parentElement = parentElement.parentElement;
                        }
                        return (pageX - offsetLeft) * this.game.scale.displayScale.x;
                    };
                    this.game.scale.transformY = (pageY) => {
                        let offsetTop = 0;
                        let parentElement = game.canvas.parentElement;
                        while (parentElement) {
                            if (parentElement.offsetTop) {
                                offsetTop = parentElement.offsetTop;
                                break;
                            }
                            parentElement = parentElement.parentElement;
                        }
                        return (pageY - offsetTop) * this.game.scale.displayScale.y;
                    };
                }

                create() {
                    game.scene.remove('Boot');
                    game.scene.add('Preloader', garage.scene.Preloader, true);
                }
            }

            scene.Boot = Boot;
        })(scene = garage.scene || (garage.scene = {}));
    })(garage || (garage = {}));

    (function (garage) {
        var scene;
        (function (scene) {
            var Gameplay = garage.core.Gameplay;

            class MainScene extends Phaser.Scene {
                create() {
                    this.gameplay = new Gameplay();
                    this.gameplayScreen = new garage.screen.GameplayScreen(this, this.gameplay);
                    this.children.add(this.gameplayScreen);
                    this.gameplayScreen.showInstructionPage();
                }
            }

            scene.MainScene = MainScene;
        })(scene = garage.scene || (garage.scene = {}));
    })(garage || (garage = {}));

    (function (garage) {
        var scene;
        (function (scene) {
            class Preloader extends Phaser.Scene {
                preload() {
                    if (typeof garage.App.json == "string") this.load.json('gameplay', garage.App.json); else this.game.cache.json.add('gameplay', garage.App.json);
                }

                create() {
                    let json = game.cache.json.get('gameplay');
                    let words = json["words"];
                    if (json["useImages"]) {
                        let rounds = JSON.parse(JSON.stringify(json["rounds"]));
                        for (let i = 0; i < rounds.length; i++) {
                            let w = rounds[i];
                            this.load.image(w.correctWord, "assets/games/car-repair/imgs/letters/" + w.correctWord + ".png");
                            this.load.image(w.wrongWord1, "assets/games/car-repair/imgs/letters/" + w.wrongWord1 + ".png");
                            this.load.image(w.wrongWord2, "assets/games/car-repair/imgs/letters/" + w.wrongWord2 + ".png");
                        }
                        for (let l of json["letters"]) {
                            this.load.image(l["letterName"], "assets/games/car-repair/imgs/letters/" + l["letterName"] + ".png");
                        }
                    }
                    for (let l of json["letters"]) {
                        this.load.audio(l["audioKey"], "assets/games/car-repair/sound/mp3/letters/" + l["audioKey"] + ".mp3");
                    }
                    for (let w of words) {
                        this.load.audio(w, "assets/games/car-repair/sound/mp3/words/" + w + ".mp3");
                    }
                    this.load.pack('preloader', 'assets/games/car-repair/pack.json');
                    this.load.on('progress', (value) => {
                    }, this);
                    this.load.on('complete', () => {
                        this.nextScene();
                    });
                    this.load.start();
                }

                nextScene() {
                    game.scene.remove('Preloader');
                    game.scene.add('ScreenMain', garage.scene.MainScene, true);
                }
            }

            scene.Preloader = Preloader;
        })(scene = garage.scene || (garage.scene = {}));
    })(garage || (garage = {}));

    (function (garage) {
        var screen;
        (function (screen) {
            class AreYouSureWindow extends Phaser.GameObjects.Container {
                constructor(scene, onYes, onNo) {
                    super(scene);
                    this._areYouSurePage = new Phaser.GameObjects.Image(this.scene, -105, 0 - 48, 'Exit warning');
                    this._areYouSurePage.setOrigin(0, 0);
                    this._areYouSurePage.setInteractive();
                    var modalTextStyle = {
                        backgroundColor: 'transparent',
                        stroke: '#ffffff',
                        strokeThickness: 2,
                        lineSpacing: 15,
                        fontFamily: 'Kids Rock DEMO',
                        fontSize: 27,
                        color: '#43425d',
                        wordWrap: true,
                        align: 'center'
                    };
                    var modalText = this.scene.add.text(game.scale.width / 2 + 12, game.scale.height / 2, "Your progress in this activity will not be saved. \n Are you sure you want to exit? ", modalTextStyle);
                    modalText.setOrigin(0.5, 0.5);
                    this._btnSureYes = new Phaser.GameObjects.Image(this.scene, game.scale.width / 2 - 95, 485 - 50, 'btnYES1');
                    this._btnSureYes.setInteractive({ cursor: 'pointer' });
                    this._btnSureYes.once('pointerup', () => {
                        onYes;
                        window.angularComponentRef.backToMenu();
                    });
                    setupButtonTextureBased(this._btnSureYes, 'btnYES1', 'btnYES2');
                    this._btnSureNo = new Phaser.GameObjects.Image(this.scene, game.scale.width / 2 + 95, 485 - 50, 'btnNO1');
                    this._btnSureNo.setInteractive({ cursor: 'pointer' });
                    this._btnSureNo.once('pointerup', onNo);
                    setupButtonTextureBased(this._btnSureNo, 'btnNO1', 'btnNO2');
                    this.add(this._areYouSurePage);
                    this.add(this._btnSureYes);
                    this.add(this._btnSureNo);
                    this.add(modalText);
                }
            }

            screen.AreYouSureWindow = AreYouSureWindow;
        })(screen = garage.screen || (garage.screen = {}));
    })(garage || (garage = {}));

    (function (garage_1) {
        var screen;
        (function (screen) {
            class Car extends Phaser.GameObjects.Container {
                constructor(scene, gameplayScreen, carBG) {
                    super(scene);
                    this.gameplayScreen = gameplayScreen;
                    this._carBG = new Phaser.GameObjects.Image(this.scene, 0, 0, carBG);
                    this.add(this._carBG);
                    this._WIDE_SCREEN = new Phaser.GameObjects.Image(this.scene, 0, -86, 'WIDE SCREEN');
                    this.add(this._WIDE_SCREEN);
                    this.ligthR = new Phaser.GameObjects.Image(this.scene, 160, 65, 'ligth');
                    this.add(this.ligthR);
                    this.ligthL = new Phaser.GameObjects.Image(this.scene, -160, 65, 'ligth');
                    this.add(this.ligthL);
                    this.setLigthVisible(false);
                    if (this.gameplayScreen.gameplay.useImages) {
                        this.wordText = new Phaser.GameObjects.Image(this.scene, 0, -80, null);
                        this.add(this.wordText);
                    } else {
                        this.wordText = this.scene.add.text(0, -90, "", {
                            "fontFamily": "QuranErafont",
                            "fontSize": 100,
                            "color": "#000000",
                            "align": 'center',
                            'stroke': '#FFFFFF',
                            'strokeThickness': 7
                        });
                        this.wordText.setOrigin(0.5, 0.5);
                        this.wordText.style.fixedHeight = 150;
                        this.add(this.wordText);
                    }
                }

                setGarage(garage) {
                    this.garage = garage;
                }

                setup(word, isCorrect) {
                    this.word = word;
                    this.isCorrect = isCorrect;
                    if (this.gameplayScreen.gameplay.useImages) {
                        this.wordText.setTexture(word);
                        this.wordText.setScale(1.5);
                    } else {
                        this.wordText.setText(this.word);
                    }
                }

                createInput() {
                    this._carBG.on('pointerup', () => {
                        // playBtnClickAnim(letterImage);
                        if (this.isCorrect) {
                            this._WIDE_SCREEN.setTexture('WIDE SCREEN CORRECT');
                            this.setLigthVisible(true);
                            for (let c of this.gameplayScreen.cars) {
                                if (c != this) {
                                    c.wordText.visible = false;
                                }
                            }
                            this.gameplayScreen.onCorrectAnswer();
                        } else {
                            this._WIDE_SCREEN.setTexture('WIDE SCREEN Wrong');
                            this.gameplayScreen.onWrongAnswer();
                        }
                    });
                }

                _setInteractive() {
                    this._carBG.setInteractive({ cursor: 'pointer' });
                }

                _disableInteractive() {
                    this._carBG.disableInteractive();
                }

                setLigthVisible(visible) {
                    this.ligthL.visible = this.ligthR.visible = visible;
                }

                reset() {
                    this.setLigthVisible(false);
                    this.wordText.visible = false;
                    this._WIDE_SCREEN.setTexture('WIDE SCREEN');
                }
            }

            screen.Car = Car;
        })(screen = garage_1.screen || (garage_1.screen = {}));
    })(garage || (garage = {}));

    (function (garage) {
        var screen;
        (function (screen) {
            class CompleteWindow extends Phaser.GameObjects.Container {
                constructor(scene, onBack, onReplay, onNext) {
                    super(scene);
                    this.music = null;
                    this.setPosition(-104.5, -48);
                    this._bgComplete = new Phaser.GameObjects.Image(this.scene, 0, 0, 'Completion page LATEST UPDATED');
                    this._bgComplete.setOrigin(0, 0);
                    this._bgComplete.setInteractive();
                    this._cup = new Phaser.GameObjects.Image(this.scene, 400, 410, 'Trophy');
                    this._btnBack = new Phaser.GameObjects.Image(this.scene, 570, 570, 'btnBACK1');
                    this._btnReplay = new Phaser.GameObjects.Image(this.scene, 720, 570, 'btnReplay1');
                    this._btnNext = new Phaser.GameObjects.Image(this.scene, 870, 570, 'btnNEXT1');
                    let _CollectedPoints = new Phaser.GameObjects.Image(this.scene, 620, 440, 'Collected Points');
                    this.totalScoreTxt = this.scene.add.text(845, 352, "", {
                        "fontFamily": "Kids Rock Demo",
                        "fontSize": 35,
                        "color": "#F49F1C",
                        "align": 'center',
                        'stroke': '#70451A',
                        'strokeThickness': 6
                    });
                    this.totalScoreTxt.setOrigin(0.5, 0.5);
                    let grd = this.totalScoreTxt.context.createLinearGradient(0, 0, 0, this.totalScoreTxt.height);
                    grd.addColorStop(0, '#FFFF00');
                    grd.addColorStop(1, '#C17316');
                    this.totalScoreTxt.setFill(grd);
                    this.starScoreTxt = this.scene.add.text(648, 433, "", {
                        "fontFamily": "Kids Rock Demo",
                        "fontSize": 24,
                        "color": "#FFFFFF",
                        "align": 'center'
                    });
                    this.starScoreTxt.setOrigin(0.5, 0.5);
                    this.add([
                        this._bgComplete,
                        _CollectedPoints,
                        this._cup,
                        this._btnBack,
                        this._btnReplay,
                        this._btnNext,
                        this.totalScoreTxt,
                        this.starScoreTxt
                    ]);
                    this._btnBack.setInteractive({ cursor: 'pointer' });
                    this._btnBack.on('pointerup', () => {
                        onBack(this._btnBack);
                        window.angularComponentRef.backToMenu();
                        // if (this.music) {
                        //     this.music.stop();
                        // }
                    });
                    setupButtonTextureBased(this._btnBack, 'btnBACK1', 'btnBACK2');
                    this._btnReplay.setInteractive({ cursor: 'pointer' });
                    this._btnReplay.once('pointerup', () => {
                        onReplay(this._btnReplay);
                        if (this.music) {
                            this.music.stop();
                        }
                    });
                    setupButtonTextureBased(this._btnReplay, 'btnReplay1', 'btnReplay2');
                    this._btnNext.setInteractive({ cursor: 'pointer' });
                    this._btnNext.on('pointerup', () => {
                        onNext(this._btnNext);
                        window.angularComponentRef.nextActivity();
                        // if (this.music) {
                        //     this.music.stop();
                        // }
                    });
                    setupButtonTextureBased(this._btnNext, 'btnNEXT1', 'btnNEXT2');
                }

                show(score, starScore) {
                    window.angularComponentRef.gameFinish({ score, starScore });
                    this._cup.scale = 1.25;
                    this.scene.tweens.add({
                        targets: this._cup,
                        "scale": 1,
                        duration: 500,
                        ease: Phaser.Math.Easing.Back.Out
                    });
                    this.totalScoreTxt.text = String(window.angularComponentRef.component.userInfo.totalScore);
                    this.starScoreTxt.text = String(starScore);
                    // let music = this.scene.sound.add("viktory");
                    this.music = this.scene.sound.add("Activity completion fantastic");
                    this.music.play();
                }
            }

            screen.CompleteWindow = CompleteWindow;
        })(screen = garage.screen || (garage.screen = {}));
    })(garage || (garage = {}));

    (function (garage) {
        var screen;
        (function (screen) {
            class GameplayScreen extends Phaser.GameObjects.Container {
                constructor(scene, gameplay) {
                    super(scene);
                    this.bgMusic = null;
                    this.firstHeartDefPos = { x: 60, y: 100 };
                    this.soundGooseYes = null;
                    this.soundGooseNo = null;
                    this.correctAudio = null;
                    this.wfsnd = null;
                    this.showCompleteWindow = (score, starScore) => {
                        let completeWindow = new screen.CompleteWindow(this.scene, (target) => {
                            playBtnClickAnim(target);
                        }, (target) => {
                            playBtnClickAnim(target);
                            this.destroyGameplay();
                            this.remove(completeWindow);
                            this.showInstructionPage();
                        }, (target) => {
                            playBtnClickAnim(target);
                        });
                        this.setInputEnabled(false);
                        delayedCall(3000, () => {
                            setPageBackground("bg-blue");
                            this.add(completeWindow);
                            completeWindow.show(score, starScore);
                            this.bgMusic.stop();
                        });
                    };
                    this.showLoseWindow = (score, starScore) => {
                        let tryAgainWindow = new screen.TryAgainWindow(this.scene, (target) => {
                            playBtnClickAnim(target);
                        }, (target) => {
                            playBtnClickAnim(target);
                            this.destroyGameplay();
                            this.remove(tryAgainWindow);
                            this.showInstructionPage();
                        });
                        this.setInputEnabled(false);
                        delayedCall(750, () => {
                            setPageBackground("bg-blue");
                            this.add(tryAgainWindow);
                            tryAgainWindow.show(score, starScore);
                            this.bgMusic.stop();
                        });
                    };
                    this.gameplay = gameplay;
                    window["gs"] = this;
                }

                showGameplay() {
                    setPageBackground("bg-australia");
                    this.bgMusic = this.scene.sound.add("Bachground ambience");
                    this.bgMusic.play();
                    this.bgMusic.loop = true;
                    this.gameplayContainer = new Phaser.GameObjects.Container(this.scene);
                    this.add(this.gameplayContainer);
                    this.gameplayContainer.setScale(0.5);
                    this._gameStage = new Phaser.GameObjects.Image(this.scene, game.scale.width / 2 * 2, game.scale.height / 2 * 2, 'Garage');
                    this._gameStage.setOrigin(0.5, 0.5);
                    this._gameStage.setInteractive();
                    this.gameplayContainer.add(this._gameStage);
                    this._btnClose = new Phaser.GameObjects.Image(this.scene, /*1025-105*/ 1865, /*100-50*/ 95, 'x Button');
                    this._btnClose.setInteractive({ cursor: 'pointer' });
                    this._btnClose.setScale(2);
                    this._btnClose["defScale"] = this._btnClose.scale;
                    setupButtonTextureBased(this._btnClose, 'x Button', 'x Button HOVER EFFECT');
                    this.gameplayContainer.add(this._btnClose);
                    this.gameplay.reset();
                    this.garages = [];
                    this.cars = [];
                    let l = this.gameplay.garageLettersNames.length;
                    for (let i = 0; i < l; i++) {
                        let g = new screen.Garage(this.scene, this, i);
                        g.setPosition(970 + (l % 2 == 0 ? i / 2 - 0.5 : (i - 1) / 2) * 1050, 380);
                        this.gameplayContainer.add(g);
                        this.garages.push(g);
                    }
                    let carBGs = ['Car Purple', 'Car Red', 'Car Green'];
                    for (let i = 0; i < l; i++) {
                        let c = new screen.Car(this.scene, this, carBGs[i % carBGs.length]);
                        // c.setScale(1.3)
                        this.cars.push(c);
                        this.garages[i].addCar(c);
                    }
                    this.createInput();
                    this.prepareGarages();
                    this.gameplay.setupCallbacks(this.showCompleteWindow, this.showLoseWindow, () => {
                        this.onNewRound(true);
                    });
                    this.hearts = [];
                }

                showHeart(active) {
                    let h = new Phaser.GameObjects.Image(this.scene, this.firstHeartDefPos['x'] + this.hearts.length * 68, this.firstHeartDefPos['y'], active ? 'GreyHeartActive' : 'GreyHeartDef');
                    this.gameplayContainer.add(h);
                    this.hearts.push(h);
                }

                prepareGarages() {
                    this.setInputEnabled(false);
                    for (let g of this.garages)
                        g.reset();
                    this.garages[this.garages.length - 1].showHandIconAnim();
                    delayedCall(1200, () => {
                        if (this.gameplay.isNewRound()) {
                            if (!this.gameplay.isRoundsComplete()) {
                                delayedCall(2000, () => {
                                    this.setInputEnabled(true);
                                });
                            }
                        } else {
                            this.setInputEnabled(true);
                        }
                    });
                }

                onNewRound(showOut) {
                    this.setInputEnabled(false);
                    if (showOut) {
                        for (let g of this.garages) {
                            g.closeDoor(true);
                        }
                        delayedCall(screen.Garage.DOOR_OPEN_CLOSE_DURATION, () => {
                            this.prepareGarages();
                            for (let c of this.cars) {
                                c.visible = false;
                            }
                        });
                    }
                }

                createInput() {
                    for (let g of this.garages)
                        g.createInput();
                    for (let c of this.cars)
                        c.createInput();
                    this._btnClose.on('pointerup', () => {
                        playBtnClickAnim(this._btnClose);
                        this.onCloseClick();
                    });
                }

                onCorrectAnswer() {
                    let completed = this.gameplay.onCorrectAnswer();
                    this.soundGooseYes = this.scene.sound.add("correct click ignition");
                    this.soundGooseYes.play();
                    this.showHeart(true);
                    this.setInputEnabled(false);
                    return completed;
                }

                onWrongAnswer() {
                    let lost = this.gameplay.onWrongAnswer();
                    this.soundGooseNo = this.scene.sound.add("Warning sound - horn");
                    this.soundGooseNo.play();
                    this.showHeart(false);
                    this.setInputEnabled(false);
                    return lost;
                }

                playCorrectAudio(index) {
                    if (this.correctAudio) {
                        this.correctAudio.stop();
                    }
                    this.correctAudio = this.scene.sound.add(this.gameplay.getCorrectAudioKey(index));
                    this.correctAudio.play();
                    if (this.areYouSureWindow && this.areYouSureWindow.parentContainer == this) {
                        this.correctAudio.pause();
                    }
                }

                onCloseClick() {
                    this.showAreYouSurePage();
                    this.scene.sound.add('warning page pop up sfx').play();
                }

                showInstructionPage() {
                    setPageBackground("bg-blue");
                    let playInstructionSound = () => {
                        if (this.wfsnd) {
                            this.wfsnd.stop();
                        }
                        this.wfsnd = this.scene.sound.add("Car Repairs Choose the word that matches");
                        this.wfsnd.play();
                    };
                    this.instructionPage = new screen.InstructionPage(this.scene, (target) => {
                        playBtnClickAnim(target);
                        this.remove(this.instructionPage);
                        this.showGameplay();
                        if (this.wfsnd) {
                            this.wfsnd.stop();
                        }
                    }, (target) => {
                        playBtnClickAnim(target);
                        playInstructionSound();
                    });
                    this.add(this.instructionPage);
                    try {
                        playInstructionSound();
                    }
                    catch (e) {
                        console.error(e);
                    }
                }

                showAreYouSurePage() {
                    pauseAllDelayedCalls();
                    setPageBackground("bg-blue");
                    this.pauseSounds();
                    this.areYouSureWindow = new screen.AreYouSureWindow(this.scene, () => {
                        this.remove(this.areYouSureWindow);
                        this.destroyGameplay();
                        this.showInstructionPage();
                    }, () => {
                        this.remove(this.areYouSureWindow);
                        this.unpauseSounds();
                        resumeAllDelayedCalls();
                        setPageBackground("bg-australia");
                    });
                    this.add(this.areYouSureWindow);
                }

                setInputEnabled(enabled) {
                    if (enabled) {
                        for (let g of this.garages)
                            g._setInteractive();
                        for (let c of this.cars)
                            c._setInteractive();
                    } else {
                        for (let g of this.garages)
                            g._disableInteractive();
                        for (let c of this.cars)
                            c._disableInteractive();
                    }
                }

                pauseSounds() {
                    this.scene.sound.pauseAll();
                }

                unpauseSounds() {
                    this.scene.sound.resumeAll();
                }

                destroyGameplay() {
                    this.scene.tweens.killAll();
                    this.setInputEnabled(false);

                    for (let g of this.garages) {
                        g.destroy(true);
                    }
                    this.remove(this.gameplayContainer, true);
                    this.scene.sound.stopAll();
                    destroyAllDelayedCalls();
                }
            }

            screen.GameplayScreen = GameplayScreen;
        })(screen = garage.screen || (garage.screen = {}));
    })(garage || (garage = {}));

    (function (garage) {
        var screen;
        (function (screen) {
            class Garage extends Phaser.GameObjects.Container {
                constructor(scene, gameplayScreen, index) {
                    super(scene);
                    this.garageDoorDY = 15;
                    this.isDoorOpened = true;
                    this.gameplayScreen = gameplayScreen;
                    this.index = index;
                    this._btnSound = new Phaser.GameObjects.Image(this.scene, 0, 0, 'SoundButtonNormal');
                    setupButtonTextureBased(this._btnSound, 'SoundButtonNormal', 'SoundButtonHover');
                    this.add(this._btnSound);
                    this._btnSound.visible = false;
                    this._btnOpen = new Phaser.GameObjects.Image(this.scene, 0, 0, 'OpenButttonNormal');
                    setupButtonTextureBased(this._btnOpen, 'OpenButttonNormal', 'OpenButttonHover');
                    this.add(this._btnOpen);
                    if (this.gameplayScreen.gameplay.useImages) {
                        this.letterImage = new Phaser.GameObjects.Image(this.scene, 0, 210, null);
                        this.add(this.letterImage);
                    } else {
                        this.letterImage = this.scene.add.text(0, 210, "", {
                            "fontFamily": "QuranErafont",
                            "fontSize": 130,
                            "color": "#000000",
                            "align": 'center'
                        });
                        this.letterImage.setOrigin(0.5, 0.5);
                        this.letterImage.style.fixedHeight = 170;
                        this.add(this.letterImage);
                    }
                    this.garageDoorContainer = new Phaser.GameObjects.Container(this.scene);
                    this.garageDoorContainer.y = 70;
                    this.add(this.garageDoorContainer);
                    this.garageDoor = [];
                    for (let i = 0; i < 19; i++) {
                        let garageDoor = new Phaser.GameObjects.Image(this.scene, 0, 0, 'garageDoor');
                        garageDoor.scaleY = i % 2 == 0 ? 1 : 1;
                        garageDoor.setOrigin(0.5, 0);
                        this.garageDoor.push(garageDoor);
                        this.garageDoorContainer.addAt(garageDoor, 0);
                    }
                    this.closeDoor(false);
                    this._HandIcon = new Phaser.GameObjects.Image(this.scene, 80, 65, 'Hand Icon');
                    this._HandIcon.setOrigin(0, 1);
                    this._HandIcon.visible = false;
                    this.add(this._HandIcon);
                }

                showHandIconAnim() {
                    this._btnOpen.setInteractive({ cursor: 'pointer', pixelPerfect: true });
                    this._HandIcon.visible = true;
                    this._HandIcon.angle = 0;
                    this._t = this.scene.tweens.add({
                        targets: this._HandIcon,
                        "angle": 10,
                        yoyo: true,
                        repeat: -1,
                        duration: 500
                    });
                }

                hideHandIcon() {
                    this._HandIcon.visible = false;
                    this.scene.tweens.remove(this._t);
                }

                opedDoor(animate) {
                    if (animate) {
                        this.scene.sound.add('Garage door opening sound').play();
                        for (let i = 0; i < this.garageDoor.length; i++) {
                            this.scene.tweens.add({
                                targets: this.garageDoor[i],
                                "y": 0,
                                "scaleX": 1,
                                duration: Garage.DOOR_OPEN_CLOSE_DURATION,
                                onComplete: () => {
                                    this.gameplayScreen.playCorrectAudio(this.index);
                                    this.isDoorOpened = true;
                                }
                            });
                        }
                    } else {
                        for (let i = 0; i < this.garageDoor.length; i++) {
                            this.garageDoor[i].y = 0;
                            this.garageDoor[i].scaleX = 1;
                        }
                        this.isDoorOpened = true;
                    }
                }

                closeDoor(animate) {
                    if (animate) {
                        this.scene.sound.add('Garage door opening sound').play();
                        for (let i = 0; i < this.garageDoor.length; i++) {
                            this.scene.tweens.add({
                                targets: this.garageDoor[i],
                                "y": 0 + i * this.garageDoorDY,
                                "scaleX": 1 + i * 0.004,
                                duration: Garage.DOOR_OPEN_CLOSE_DURATION,
                                onComplete: () => {
                                    this.isDoorOpened = false;
                                }
                            });
                        }
                    } else {
                        for (let i = 0; i < this.garageDoor.length; i++) {
                            this.garageDoor[i].y = 0 + i * this.garageDoorDY;
                            this.garageDoor[i].scaleX = 1 + i * 0.004;
                        }
                        this.isDoorOpened = false;
                    }
                }

                createInput() {
                    this._btnSound.on('pointerup', () => {
                        playBtnClickAnim(this._btnSound);
                        this.onSoundClick();
                    });
                    this._btnOpen.on('pointerup', () => {
                        playBtnClickAnim(this._btnOpen);
                        this._btnOpen.visible = false;
                        this.opedDoor(true);
                        this.hideHandIcon();
                        delayedCall(Garage.DOOR_OPEN_CLOSE_DURATION + 1000, () => {
                            for (let i = this.gameplayScreen.garages.length - 1; i >= 0; i--) {
                                let g = this.gameplayScreen.garages[i];
                                if (!g.isDoorOpened) {
                                    g._btnOpen.setInteractive({ cursor: 'pointer', pixelPerfect: true });
                                    g.showHandIconAnim();
                                    this._btnSound.visible = true;
                                    return;
                                }
                            }
                            if (!this._btnSound.visible) {
                                let wasAllCarsInvisible = true;
                                for (let c of this.gameplayScreen.cars) {
                                    if (c.visible)
                                        wasAllCarsInvisible = false;
                                    c.wordText.visible = true;
                                    c.visible = true;
                                }
                                if (wasAllCarsInvisible)
                                    this.scene.sound.add("car appear sfx").play();
                                this.scene.sound.add("Choose the word that matches").play();
                            }
                            this._btnSound.visible = true;
                        });
                    });
                }

                onSoundClick() {
                    this.gameplayScreen.playCorrectAudio(this.index);
                }

                _setInteractive() {
                    this._btnSound.setInteractive({ cursor: 'pointer', pixelPerfect: true });
                    // this._btnOpen.setInteractive({cursor: 'pointer', pixelPerfect:true});
                }

                _disableInteractive() {
                    this._btnSound.disableInteractive();
                    this._btnOpen.disableInteractive();
                }

                addCar(car) {
                    this.car = car;
                    this.car.setPosition((this.gameplayScreen.gameplay.garageLettersNames.length % 2 == 0 ? this.index / 2 - 0.5 : (this.index - 1) / 2) * 200, 600);
                    this.addAt(this.car, this.getIndex(this.garageDoorContainer));
                    this.car.setGarage(this);
                    this.car.visible = false;
                }

                reset() {
                    this._btnOpen.visible = true;
                    this._btnSound.visible = false;
                    this.car.reset();
                    let gameplay = this.gameplayScreen.gameplay;
                    if (gameplay.useImages) {
                        this.letterImage.setTexture(gameplay.garageLettersNames[this.index]);
                        this.letterImage.setScale(1.5);
                    } else {
                        this.letterImage.setText(gameplay.garageLettersNames[this.index]);
                    }
                    this.car.setup(gameplay.carWords[this.index], gameplay.carWords[this.index] == gameplay.correctWord);
                    this._btnOpen.disableInteractive();
                }
            }

            Garage.DOOR_OPEN_CLOSE_DURATION = 1000;
            screen.Garage = Garage;
        })(screen = garage.screen || (garage.screen = {}));
    })(garage || (garage = {}));

    (function (garage) {
        var screen;
        (function (screen) {
            class InstructionPage extends Phaser.GameObjects.Container {
                constructor(scene, onPlayClick, onSndClick) {
                    super(scene);
                    this._instructionPage = new Phaser.GameObjects.Image(this.scene, 0 - 105, 0 - 48, 'Instructions page  ALL ACTIVITY  TITLEs');
                    this._instructionPage.setOrigin(0, 0);
                    this._instructionPage.setInteractive();
                    this._instructionPageTitle = new Phaser.GameObjects.Image(this.scene, 495, 105, 'welcome');
                    this._instructionPageTitle.setScale(0.8);
                    this._btnPlay = new Phaser.GameObjects.Image(this.scene, game.scale.width / 2, 480 - 50, 'btnPLAY1');
                    this._btnPlay.setInteractive({ cursor: 'pointer' });
                    this._btnPlay.once('pointerup', onPlayClick);
                    setupButtonTextureBased(this._btnPlay, 'btnPLAY1', 'btnPLAY2');
                    this.instrTxt = this.scene.add.text(game.scale.width / 2, game.scale.height / 2, "A Word is hidden in the garage.\nTap the buttons to uncover the letters.\nThen choose the word that matches.", {
                        "fontFamily": "Kids Rock DEMO",
                        "fontSize": 30,
                        "color": "#43425D",
                        "align": 'center'
                    });
                    this.instrTxt.setOrigin(0.5, 0.5);
                    this._btnSoundInstruction = new Phaser.GameObjects.Image(this.scene, 800 - 105, 156 - 50, 'Sound');
                    this._btnSoundInstruction.setInteractive({ cursor: 'pointer' });
                    this._btnSoundInstruction.on('pointerup', onSndClick);
                    setupButtonTextureBased(this._btnSoundInstruction, 'Sound', 'Sound HOVER EFFECT');
                    this.add(this._instructionPage);
                    this.add(this._instructionPageTitle);
                    this.add(this.instrTxt);
                    this.add(this._btnPlay);
                    this.add(this._btnSoundInstruction);
                }
            }

            screen.InstructionPage = InstructionPage;
        })(screen = garage.screen || (garage.screen = {}));
    })(garage || (garage = {}));

    (function (garage) {
        var screen;
        (function (screen) {
            class TryAgainWindow extends Phaser.GameObjects.Container {
                constructor(scene, onBack, onReplay) {
                    super(scene);
                    this.music = null;
                    this.setPosition(-106, -48);
                    this._bg = new Phaser.GameObjects.Image(this.scene, 0, 0, 'Try again page');
                    this._bg.setOrigin(0, 0);
                    this._bg.setInteractive();
                    this._star = new Phaser.GameObjects.Image(this.scene, 400, 415, 'Break Star');
                    this._btnBack = new Phaser.GameObjects.Image(this.scene, 600, 580, 'btnBACK1');
                    this._btnReplay = new Phaser.GameObjects.Image(this.scene, 765, 580, 'btnReplay1');
                    this.totalScoreTxt = this.scene.add.text(830, 355, "", {
                        "fontFamily": "Kids Rock Demo",
                        "fontSize": 35,
                        "color": "#F49F1C",
                        "align": 'center',
                        'stroke': '#70451A',
                        'strokeThickness': 6
                    });
                    this.totalScoreTxt.setOrigin(0.5, 0.5);
                    let grd = this.totalScoreTxt.context.createLinearGradient(0, 0, 0, this.totalScoreTxt.height);
                    grd.addColorStop(0, '#FFFF00');
                    grd.addColorStop(1, '#C17316');
                    this.totalScoreTxt.setFill(grd);
                    this.starScoreTxt = this.scene.add.text(635, 431, "", {
                        "fontFamily": "Kids Rock Demo",
                        "fontSize": 24,
                        "color": "#FFFFFF",
                        "align": 'center'
                    });
                    this.starScoreTxt.setOrigin(0.5, 0.5);
                    this.add([
                        this._bg,
                        this._star,
                        this._btnBack,
                        this._btnReplay,
                        this.totalScoreTxt,
                        this.starScoreTxt
                    ]);
                    this._btnBack.setInteractive({ cursor: 'pointer' });
                    this._btnBack.on('pointerup', () => {
                        onBack(this._btnBack);
                        window.angularComponentRef.backToMenu();
                        // if (this.music) {
                        //     this.music.stop();
                        // }
                    });
                    setupButtonTextureBased(this._btnBack, 'btnBACK1', 'btnBACK2');
                    this._btnReplay.setInteractive({ cursor: 'pointer' });
                    this._btnReplay.once('pointerup', () => {
                        onReplay(this._btnReplay);
                        if (this.music) {
                            this.music.stop();
                        }
                    });
                    setupButtonTextureBased(this._btnReplay, 'btnReplay1', 'btnReplay2');
                }

                show(score, starScore) {
                    this._star.scale = 1.25;
                    this.scene.tweens.add({
                        targets: this._star,
                        "scale": 1,
                        duration: 500,
                        ease: Phaser.Math.Easing.Back.Out
                    });
                    this.totalScoreTxt.text = String(window.angularComponentRef.component.userInfo.totalScore);
                    this.starScoreTxt.text = String(0);
                    this.music = this.scene.sound.add("Fail - close one");
                    this.music.play();
                }
            }

            screen.TryAgainWindow = TryAgainWindow;
        })(screen = garage.screen || (garage.screen = {}));
    })(garage || (garage = {}));

    game = new garage.App(json);
};

function destroyCarRepair() {
    if (game) {
        game.destroy();
        game = null;
    }
}
