import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../shared/auth.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-zone-1',
    templateUrl: './zone-1.component.html'
})
export class Zone1Component implements OnInit, OnDestroy, AfterViewInit {
    zones = [
        {
            hover: false,
            id: '1-1-1',
            locked: false,
            active: false
        },
        {
            hover: false,
            id: '1-1-2',
            locked: false,
            active: false
        },
        {
            hover: false,
            id: '1-1-3',
            locked: false,
            active: false
        },
        {
            hover: false,
            id: '1-1-4',
            locked: false,
            active: false
        },
        {
            hover: false,
            id: '1-1-5',
            locked: false,
            active: false
        },
        {
            hover: false,
            id: '1-1-2',
            locked: false,
            active: false
        }
    ];

    plane: any = {
        position: 1
    };

    activeLevel: string;

    itemsStyle: any;
    activeZone: number = 1;

    user: any;

    openZones: number = 1;
    loading: boolean = true;
    tick2Quiz: boolean = true;
    adminUserData;
    quizLevel: number = 0;
    activeContinent: number;

    constructor(private router: Router, private auth: AuthService) { }

    ngOnInit() {
        this.tick2Quiz = true;
        this.userSubscription = this.auth.onUserUpdate.subscribe(userData => {
            if (userData != null && userData) this.loadChildData(userData);
        }, error => {
            console.log('error', error);
        });
    }
    userSubscription: Subscription;

    ngOnDestroy() {
        this.userSubscription.unsubscribe();
    }

    loadChildData(userData) {
        this.zones = [
            {
                hover: false,
                id: '1-1-1',
                locked: false,
                active: false
            },
            {
                hover: false,
                id: '1-1-2',
                locked: false,
                active: false
            },
            {
                hover: false,
                id: '1-1-3',
                locked: false,
                active: false
            },
            {
                hover: false,
                id: '1-1-4',
                locked: false,
                active: false
            },
            {
                hover: false,
                id: '1-1-5',
                locked: false,
                active: false
            },
            {
                hover: false,
                id: '1-1-2',
                locked: false,
                active: false
            }
        ];

        this.user = userData;
        // this.openZones = userData.zone1Lvl;
        this.adminUserData = JSON.parse(localStorage.getItem('quranera_admin_userData'));
        if (!userData) {
            if (this.adminUserData) {
                userData = this.adminUserData;
                userData.activeLevel = '9-9-9';
            }
        }

        if (userData.activities["1-2-1"]) {
            this.tick2Quiz = false;
        }

        this.plane.position = this.openZones;
        this.activeLevel = userData.activeLevel;
        this.activeContinent = parseInt(userData.activeLevel.split('-')[0], 10);

        this.activeZone = this.adminUserData ? 99 : parseInt(userData.activeLevel.split('-')[1]);
        if (this.activeZone > 1) {
            this.openZones = 5;
        } else {
            this.openZones = parseInt(userData.activeLevel.split('-')[2]);
        }
        if (userData.quizLevel) {
            this.quizLevel = parseInt(userData.quizLevel, 10);
        }
        if (this.adminUserData) {
            this.quizLevel = 100;
        }

        this.zones.map((zone: Zone, i) => {
            this.zones[i].locked = this.adminUserData ? false : !(userData.activities[zone.id]);
            this.zones[i].active = this.adminUserData ? false : (userData.activeLevel === zone.id && userData.activities[zone.id]);
        });
    }

    ngAfterViewInit() {
        setTimeout(() => {
            if (this.loading) {
                this.instructionLoaded();
            }
        }, 3000);
    }

    mouseEnter(index: number) {
        this.zones[index].hover = true;
        let audio = <HTMLAudioElement>document.getElementById('continentHover');
        audio.pause();
        audio.currentTime = 0;
        if (audio) audio.play();
    }

    mouseLeave(index: number) {
        this.zones[index].hover = false;
    }

    poiClick(zoneNumber: number) {
        console.log('this.zones', this.zones);
        const foundZone = this.zones.find(item => parseInt(item.id.split('-')[2]) == zoneNumber);
        console.log('foundZone', foundZone);
        if (foundZone && !foundZone.locked) {
            let audio = <HTMLAudioElement>document.getElementById('continentClick');
            if (audio) audio.play();
            setTimeout(() => {
                this.router.navigate(['/game/activity-list/1-1-' + zoneNumber]);
            }, 1000);

            // switch (zoneNumber) {
            // 	case 2:
            // 		this.itemsStyle = {top: '-110px'};
            // 		break;
            // 	case 3:
            // 		this.itemsStyle = {top: '-60px'};
            // 		break;
            // 	case 4:
            // 		this.itemsStyle = {top: '0'};
            // 		break;

            // 	default:
            // 		this.itemsStyle = {top: '-150px'};
            // 		break;
            // }
            // if(zoneNumber == this.plane.position)
            // {
            // 	this.router.navigate(['/game/activity-list/1-1-'+zoneNumber]);
            // }
            // else
            // {
            // 	this.plane.position = zoneNumber;
            // }
        }
    }

    playBgAudio() {
        let audio = <HTMLAudioElement>document.getElementById('bgAudio');
        if (audio) audio.play();
    }

    playIntroductionAudio() {
        let audio = <HTMLAudioElement>document.getElementById('introductionAudio');
        if (audio) audio.play();
    }

    instructionLoaded() {
        if (this.loading) {
            this.loading = false;
            let audio = <HTMLAudioElement>document.getElementById('introductionAudio');
            if (audio) audio.play();
        }
    }

    haveAccess(activityLevel) {
        if (this.adminUserData) return true;
        if (this.user == undefined) return false;
        if (!this.user['activities'][activityLevel + '-1']
            && !this.user['activities'][activityLevel + '-2']
            && !this.user['activities'][activityLevel + '-3']
            && !this.user['activities'][activityLevel + '-4']
        ) return false;

        for (var a = 1; a <= 4; a++) {
            for (var i = 1; i <= 15; i++) {
                if (this.user["activities"][activityLevel + '-' + a.toString()]) {
                    if (this.user["activities"][activityLevel + '-' + a.toString()][i.toString()]) return true;
                }
            }
        }

        return false;
    }
}
