var game;
function getFrameRate( defFrameRate ) {
    return game.loop.actualFps / 60 * defFrameRate;
}
function getTimeSyncForFrameRate( defTime ) {
    return defTime / (game.loop.actualFps / 60);
}
function matchTheWord(json) {
    var currentQuestionIndex = 0;
    var isCloseDoor = false;
    var quizTimer = 0;

    function setupButton(btn, frame_pointerdown, frame_pointerover, frame_pointerout, frame_pointerup, textureBased) {
        if ( typeof frame_pointerout === "undefined" ) frame_pointerout = frame_pointerdown;
        if ( typeof frame_pointerup === "undefined" ) frame_pointerup = frame_pointerdown;

        btn['frame_pointerdown'] = frame_pointerdown;
        btn['frame_pointerover'] = frame_pointerover;
        btn['frame_pointerout'] = frame_pointerout;
        btn['frame_pointerup'] = frame_pointerup;

        if (!btn["alreadySetupEvents"]) {
            btn["alreadySetupEvents"] = true;
            let fn = textureBased ? "setTexture" : "setFrame";
            btn.on('pointerdown', () => { btn[fn](btn['frame_pointerdown']); });
            btn.on('pointerover', () => { btn[fn](btn['frame_pointerover']); });
            btn.on('pointerout', () => { btn[fn](btn['frame_pointerout']); });
            btn.on('pointerup', () => { btn[fn](btn['frame_pointerup']); });
        }
    };

    //boot
    var boot = function (game) {
        console.log('%cBoot', 'color:white; background:red');
    };

    boot.prototype = {
        init: function () {
            this.apiData = json;

            //fix input
            this.game.scale.transformX = (pageX) => {
                let offsetLeft = 0;
                let parentElement = this.game.canvas.parentElement;
                while (parentElement) {
                    if (parentElement.offsetLeft) {
                        offsetLeft = parentElement.offsetLeft;
                        break;
                    }
                    parentElement = parentElement.parentElement;
                }
                return (pageX - offsetLeft) * this.game.scale.displayScale.x;
            };
            this.game.scale.transformY = (pageY) => {
                let offsetTop = 0;
                let parentElement = this.game.canvas.parentElement;
                while (parentElement) {
                    if (parentElement.offsetTop) {
                        offsetTop = parentElement.offsetTop;
                        break;
                    }
                    parentElement = parentElement.parentElement;
                }
                return (pageY - offsetTop) * this.game.scale.displayScale.y;
            };
        },
        preload: function () {
            this.load.atlas('assets', 'assets/match-the-word/assets.png', 'assets/match-the-word/assets.json');
            this.load.atlas('soundBtn', 'assets/qa/sound-icon/sound-btn-spritesheet.png', 'assets/qa/sound-icon/sound-btn-spritesheet.json');

            this.load.image('Ellipse.png', 'assets/match-the-word/img/Ellipse.png');

            // if (this.apiData.bgImage) {
            //     this.load.image('bg-image', this.apiData.bgImage);
            // } else {
                this.load.image('bg-image', 'assets/match-the-word/img/BG 1 Wood Pattern.png');
            // }

            this.load.image('red-arrow-image', 'assets/images/red-arrow.png');
            // this.load.spritesheet('idle_default', 'assets/match-the-word/spritesheets/Idle_sheet.png', { frameWidth: 420, frameHeight: 370, endFrame: 32 });
            this.load.spritesheet('idle_no', 'assets/match-the-word/spritesheets/Laugh.png', { frameWidth: 420, frameHeight: 370, endFrame: 32 });
            this.load.spritesheet('idle_yes', 'assets/match-the-word/spritesheets/Yes_sheet.png', { frameWidth: 420, frameHeight: 370, endFrame: 32 });
            // this.load.spritesheet('idle_yes', 'assets/match-the-word/spritesheets/Go_inside.png', 420, 370, 32);
            this.load.spritesheet('go_out', 'assets/match-the-word/spritesheets/Go out_v3.png', { frameWidth: 420, frameeight: 370, endFrame: 32 });
            this.load.spritesheet('Enter_scene', 'assets/match-the-word/spritesheets/Enter_scene.png', { frameWidth: 3360/8, frameHeight: 1480/4, endFrame: 32 });
            this.load.spritesheet('door_open', 'assets/match-the-word/spritesheets/Door_anim_open.png', { frameWidth: 217, frameHeight: 187, endFrame: 6 });

            this.cache.binary.add('questions', this.apiData.questions);
            this.cache.binary.add('instruction', this.apiData.instruction);
            this.cache.binary.add('totalPoints', '0');
            this.cache.binary.add('qAnsPoints', {
                correctAnsPoint: 2,
                secTimeCorrectAnsPoint: 2,
                wrongAnsPoint: 0
            });

            this.load.image('instruction-bg', 'assets/match-the-word/img/modal-bg-1.png');
            this.load.image('completion-bg', 'assets/match-the-word/img/complete-page-bg.jpg');
            this.load.image('try-again-bg', 'assets/match-the-word/img/Try-again-bg.jpg');
            this.load.image('try-again-trophy', 'assets/match-the-word/img/Try-again-trophy-w-bg.png');
            this.load.image('complete-trophy', 'assets/match-the-word/img/complete-trophy.png');

            this.load.image('modal-overlay', 'assets/match-the-word/img/modal-overlay.png');
            this.load.image('door-frame', 'assets/match-the-word/img/doorframe.png');
            this.load.image('door-half-frame-overlay', 'assets/match-the-word/img/door-half-frame-overlay.png');
            this.load.image('door-dark-bg', 'assets/match-the-word/img/door-dark-bg.jpg');
            this.load.image('door-half-frame-fadeout', 'assets/match-the-word/img/door-half-frame-fadeout.png');

            this.load.audio('answerCorrectGoingIn', 'assets/sounds/match-the-word/Correct answer - dolphin swims inside.mp3');
            this.load.audio('answerCorrectGoingOut', 'assets/sounds/match-the-word/Correct answer - dolphin simming back.mp3');
            this.load.audio('answerWrongSwimmingOut', 'assets/sounds/match-the-word/wrong answer and dolphin swims back.mp3');
            this.load.audio('answerWrongSwimmingOutAndSwimIn', 'assets/sounds/match-the-word/Evil laugh and dolphin swims in.mp3');
            this.load.audio('answerCorrectGoingInBgSound', 'assets/sounds/match-the-word/enters.mp3');

            this.load.audio('door-open', 'assets/match-the-word/sounds/door-open.mp3');
            this.load.audio('door-close', 'assets/match-the-word/sounds/door-close.mp3');
            this.load.audio('bg-music', this.apiData.bgAudio);

            if (this.apiData.complete && this.apiData.complete.audios && this.apiData.complete.audios.length > 0) {
                var index = Math.floor(Math.random() * Math.floor(this.apiData.complete.audios.length));
                this.load.audio('completionSound', this.apiData.complete.audios[index]);
            }
            else {
                this.load.audio('completionSound', 'assets/match-the-word/sounds/viktory.mp3');
            }

            this.load.audio('tryAgainSound', 'assets/match-the-word/sounds/fail.mp3');

            this.load.audio('selectionSound', 'assets/sounds/selection.mp3');
            this.load.audio('hoverSound', 'assets/sounds/hover.mp3');

            this.load.audio('openButtonHover', 'assets/match-the-word/sounds/open-button-hover.mp3');
            this.load.audio('exitSound', 'assets/sounds/warning-pop-up.mp3');

            this.load.audio('openBtnSelectionSound', 'assets/sounds/match-the-word/selection.mp3');
            this.load.audio('openBtnHoverSound', 'assets/sounds/match-the-word/dolphin open button hover.mp3');
        },
        create: function () {
            this.isMobile = !this.game.device.desktop;
            // this.scale.scaleMode = Phaser.ScaleManager.USER_SCALE;
            // if (this.isMobile) {
            //     this.scaleGame();
            //     this.scale.setResizeCallback(this.scaleGame);
            // }
            //Local storing
            if (window.localStorage.getItem('currentState')) {
                window.localStorage.removeItem('currentState');
            }
            this.scene.start('StartGame');
        },

        scaleGame: function () {
            const padding = this.isMobile ? 0 : 0; // include padding around the canvas frame for desktop
            const parentWidth = document.getElementById('game-container').offsetWidth;
            const xScale = (parentWidth - padding) / this.game.width;
            const scalePoint = Math.min(xScale, 1);
            // if (this.scale) {
            //     this.scale.setUserScale(scalePoint, scalePoint);
            // }
        }
    };

    //startGame
    var startgame = function (game) {
        console.log('%cStartGame', 'color:white; background:blue');
    };

    startgame.prototype = {
        init: function () {
            this.goUp = false;
            var storage = {
                questions: [],
                currentPoints: 0
            };
            window.localStorage.setItem('payload', JSON.stringify(storage));
        },
        preload: function () {
            this.fdata = this.cache.binary.get('instruction');

            if (this.fdata && this.fdata.audio) {
                this.load.audio('startSound', this.fdata.audio);
            }

            this.questionData = { questions: this.cache.binary.get('questions') };
            for (var i = 0; i < this.questionData.questions.length; i++) {
                this.load.image('doorCode_' + i, this.questionData.questions[i].doorCode);
                this.load.image('code_' + i, this.questionData.questions[i].code);
            }
        },
        update: function () {
            if (this.redArrowImage) {
                if (this.goUp) {
                    this.redArrowImage.y -= 1;
                    if (this.redArrowImage.y <= -4) {
                        this.goUp = false
                    }
                } else {
                    this.redArrowImage.y += 1;
                    if (this.redArrowImage.y >= 18) {
                        this.goUp = true;
                    }
                }
            }
        },
        create: function () {
            window.angularComponentRef.loadingFinished();
            // if(this.bgMusic){
            //     this.bgMusic.stop();
            // }

            this.setupState();

            this.addSounds();

            this.playQuestionSound();

            var modalTextStyle = {
                backgroundColor: 'transparent',
                stroke: '#ffffff',
                strokeThickness: 2,
                fontFamily: 'Kids Rock DEMO',
                fontSize: 25,
                color: '#43425d',
                wordWrap: true,
                align: 'center'
            };

            var modalHeaderStyle = {
                backgroundColor: 'transparent',
                stroke: '#ffffff',
                strokeThickness: 16,
                fontFamily: 'Kids Rock DEMO',
                fontSize: 38,
                wordWrap: false,
                align: 'center'
            };

            this.add.sprite(0, 0, 'instruction-bg').setOrigin(0, 0);

            this.modal = this.add.container();

            var modalBox = this.add.sprite(this.game.scale.width/2, this.game.scale.height/2, 'assets', 'activity-Instruction-board.png');
            modalBox.setOrigin(0.5, 0.5);

            this.modalHeaderGroup = this.add.container();
            var modalBoxHeader = this.add.sprite(0, 0, 'assets', 'activity-Instruction-board-header.png');
            modalBoxHeader.setOrigin(0, 0);

            var modalHeaderText = this.add.text(0, 0, this.fdata.title, modalHeaderStyle);
            if (!(this.game.device.mobileSafari || this.game.device.safari)) {
                modalHeaderText.setShadow(3, 3, '#43425d', 0, false, true);
            }

            var grd = modalHeaderText.context.createLinearGradient(0, 0, 0, modalHeaderText.height);
            grd.addColorStop(0, '#edee62');
            grd.addColorStop(0.2, '#edee62');
            grd.addColorStop(0.7, '#dd7b33');
            modalHeaderText.setFill(grd);

            var bgSoundButton = this.add.sprite(0, 0, 'soundBtn', 'introduction-sound_02.png');
            bgSoundButton.setOrigin(0, 0);
            bgSoundButton.on('pointerup', function () {
                if (this.redArrowImage) {
                    this.redArrowImage.alpha = 0;
                }
                this.playQuestionSound()
            }, this);

            setupButton(bgSoundButton, 'introduction-sound_02.png', 'introduction-sound_01.png');
            bgSoundButton.setInteractive({ cursor: 'pointer' });

            bgSoundButton.on('pointerover', this.playHoverSound, this);
            bgSoundButton.on('pointerdown', this.playSelectionSound, this );

            this.modalHeaderGroup.add(modalBoxHeader);
            this.modalHeaderGroup.add(modalHeaderText);
            this.modalHeaderGroup.add(bgSoundButton);
            this.modalHeaderGroup.setPosition((modalBox.width - modalBoxHeader.width) / 2 + modalBox.getBounds().left, modalBox.getBounds().top - 21);

            if (this.game.device.os.iOS) {
                this.redArrowImage = this.add.sprite(0, 0, 'red-arrow-image');
                this.redArrowImage.setOrigin(0, 0)
                this.modalHeaderGroup.add(this.redArrowImage);
            }

            modalHeaderText.setPosition(modalBoxHeader.getCenter().x, modalBoxHeader.getCenter().y);
            modalHeaderText.setOrigin(0.5, 0.5);
            modalBoxHeader.setPosition(this.modalHeaderGroup.getBounds().left, this.modalHeaderGroup.getBounds().top);
            bgSoundButton.setPosition(modalBoxHeader.getCenter().x + 30, modalBoxHeader.getCenter().y - 40);

            if (this.game.device.os.iOS) {
                this.redArrowImage.setPosition(modalBoxHeader.getCenter().x + 34, modalBoxHeader.getCenter().y - 110);
            }

            var modalText = this.add.text(this.game.scale.width/2, this.game.scale.height/2, this.fdata.content, modalTextStyle);
            modalText.setWordWrapWidth(modalBox.width - 180);//modalText.wordWrapWidth = modalBox.width - 110;
            modalText.setOrigin(0.5, 0.5);

            console.log('startButton');
            var startButton = this.add.sprite(this.game.scale.width/2, modalBox.getBounds().bottom - 103, 'assets', 'activity-Instruction-play-button.png');
            startButton.setOrigin(0, 0)
            startButton.on('pointerup', function () {
                console.log('startButton clicked');
                // if (!this.game.device.desktop) {
                //     this.game.scale.startFullScreen(false);
                // }
                this.fsound.stop();
                currentQuestionIndex = 0;
                this.scene.start("Questions");//this.game.state.start('Questions', true, false, 0);
                console.log('startButton clicked22');
            }, this);

            setupButton(startButton, 'activity-Instruction-play-button.png', 'activity-Instruction-play-button-hover.png');
            startButton.setInteractive({ cursor: 'pointer' });

            startButton.on('pointerover', this.playHoverSound, this);
            startButton.on('pointerdown', this.playSelectionSound, this);

            startButton.input.pixelPerfectOver = true;
            startButton.input.pixelPerfectClick = true;
            startButton.setOrigin(0.5, 0.5);

            var leavesRightSprite = this.add.sprite(0, 0, 'assets', 'leaves.png');
            leavesRightSprite.setOrigin(0, 0)
            leavesRightSprite.setPosition(modalBox.getBounds().right - 145, modalBox.getBounds().top + 15);

            var leavesLeftSprite = this.add.sprite(0, 0, 'assets', 'leaves.png');
            leavesLeftSprite.setOrigin(0.5, 0.5);
            leavesLeftSprite.scaleX *= -1;
            leavesLeftSprite.setPosition(modalBox.getBounds().left + 34, modalBox.getBounds().bottom - 140);

            this.modal.add(leavesLeftSprite);
            this.modal.add(leavesRightSprite);
            this.modal.add(modalBox);
            this.modal.add(modalBoxHeader);
            this.modal.add(modalText);
            this.modal.add(startButton);
        },

        setupState: function () {
            // this.game.scale.fullScreenScaleMode = Phaser.ScaleManager.SHOW_ALL;

            // this.game.stage.disableVisibilityChange = true; // keep animation run on focus out
            // this.scale.pageAlignHorizontally = true;

            // this.scale.pageAlignVertically = true;
        },

        addSounds: function () {
            this.fsound = this.sound.add('startSound');
            this.hoverSound = this.sound.add('hoverSound');
            this.selectionSound = this.sound.add('selectionSound');
            // this.game.time.events.add(Phaser.Timer.SECOND * 2, this.playQuestionSound, this);
        },

        playHoverSound: function () {
            this.hoverSound.play();
        },
        playSelectionSound: function () {
            this.selectionSound.play();
        },

        playQuestionSound: function () {
            this.fsound.play();
        }
    };

    //endGame
    var endgame = function (game) {
        console.log("%cEndGame", "color:white; background:blue");
    };

    endgame.prototype = {
        init: function (/*quizTimer*/) {
            this.saveActivityAns();
            // this.quizTimer = quizTimer || 0;
        },
        preload: function () {
            this.totalPoints = window.angularComponentRef.getTotalScore();
            // this.fdata = this.cache.binary.get('complete')
        },
        create: function () {
            if (!this.completionSound) {
                this.completionSound = this.sound.add('completionSound');
            }
            this.completionSound.play();

            this.hoverSound = this.sound.add('hoverSound');
            this.selectionSound = this.sound.add('selectionSound');

            window.localStorage.removeItem('currentState');
            window.localStorage.removeItem('wrongButtonKey');

            var storageJson = window.localStorage.getItem('payload');
            var storage = JSON.parse(storageJson);
            var currentPoints = 0;
            if (storage) {
                currentPoints = storage.currentPoints || 0;
            }
            // this.scale.pageAlignHorizontally = true;
            // this.scale.pageAlignVertically = true;

            this.add.sprite(0, 0, 'completion-bg').setOrigin(0, 0);

            // this.game.stage.disableVisibilityChange = true; // keep animation run on focus out

            var totalScoreTextStyle = {
                backgroundColor: 'transparent',
                stroke: '#91301a',
                strokeThickness: 6,
                fontFamily: 'Kids Rock DEMO',
                fontSize: 39,
                wordWrap: false,
                align: 'left'
            };
            var totalScoreTextStyleOutline = {
                backgroundColor: 'transparent',
                stroke: '#fff',
                strokeThickness: 12,
                fontFamily: 'Kids Rock DEMO',
                fontSize: 39,
                wordWrap: false,
                align: 'left'
            };
            var collectedPointsTextStyle = {
                backgroundColor: 'transparent',
                stroke: '#91301a',
                strokeThickness: 2,
                fontFamily: 'Kids Rock DEMO',
                fontSize: 25,
                wordWrap: false,
                align: 'left',
                color: '#ffffff'
            };

            var modalBox = this.add.sprite(this.game.scale.width/2, this.game.scale.height/2, 'assets', 'activity-Instruction-board.png');
            modalBox.setOrigin(0.5, 0.5);
            var completeRibbon = this.add.sprite(modalBox.getCenter().x, modalBox.getBounds().top + 222, 'assets', 'complete-ribbon-full.png');
            completeRibbon.setOrigin(0.5, 0.5);

            var totalScoreOutline = this.add.text(modalBox.getBounds().left + 340, modalBox.getBounds().bottom - 302, "Total Score: " + " " + this.totalPoints, totalScoreTextStyleOutline);
            var totalScore = this.add.text(modalBox.getBounds().left + 342, modalBox.getBounds().bottom - 300, "Total Score: " + " " + this.totalPoints, totalScoreTextStyle);

            var grd = totalScore.context.createLinearGradient(0, 0, 0, totalScore.height);
            grd.addColorStop(0, '#f4fa38');
            grd.addColorStop(0.3, '#edee62');
            grd.addColorStop(0.7, '#dd7b33');
            totalScore.setFill(grd);

            var timerTotalRibbon = this.add.sprite(modalBox.getBounds().left + 342, modalBox.getBounds().bottom - 232, 'assets', 'timer-total.png');
            timerTotalRibbon.setOrigin(0, 0)
            var timerTotalText = this.add.text(modalBox.getBounds().left + 403, modalBox.getBounds().bottom - 203, this.formatTime(quizTimer), collectedPointsTextStyle);
            timerTotalText.setOrigin(0, 0)

            var collectedPointsMedal = this.add.sprite(modalBox.getBounds().left + 516, modalBox.getBounds().bottom - 244, 'assets', 'collected-poins-medal.png');
            collectedPointsMedal.setOrigin(0, 0)
            var collectedPoints = this.add.text(modalBox.getBounds().left + 605, modalBox.getBounds().bottom - 204, "+" + currentPoints, collectedPointsTextStyle);
            collectedPoints.setOrigin(0, 0)

            // var trophy = this.add.sprite(modalBox.getBounds().left+66, modalBox.bottom-355, 'trophy');
            var trophy = this.add.sprite(modalBox.getBounds().left + 210, modalBox.getBounds().bottom - 215, 'complete-trophy');
            trophy.setScale(0.5, 0.5);
            trophy.setOrigin(0.5, 0.5);
            trophy.alpha = 0;
            this.tweens.add({
                targets: trophy,
                scaleX: 1,
                scaleY: 1,
                duration: 400,
                ease: Phaser.Math.Easing.Linear
            });
            this.tweens.add({
                targets: trophy,
                alpha: 1,
                duration: 400,
                ease: Phaser.Math.Easing.Linear
            });

            var backToMenuButton = this.add.sprite(modalBox.getBounds().left + 332, modalBox.getBounds().bottom - 89, 'assets', 'back-to-menu-button.png');
            backToMenuButton.setOrigin(0, 0)
            setupButton(backToMenuButton, 'back-to-menu-button.png', 'back-to-menu-button-hover.png');
            backToMenuButton.setInteractive({ cursor: 'pointer' });
            backToMenuButton.on('pointerup', this.backToMenu, this );

            var playAgainButton = this.add.sprite(modalBox.getBounds().left + 479, modalBox.getBounds().bottom - 89, 'assets', 'play-again-button.png');
            playAgainButton.setOrigin(0, 0)
            setupButton(playAgainButton, 'play-again-button.png', 'play-again-button-hover.png');
            playAgainButton.setInteractive({ cursor: 'pointer' });
            playAgainButton.on('pointerup', this.playAgain, this );

            var nextActivityButton = this.add.sprite(modalBox.getBounds().left + 626, modalBox.getBounds().bottom - 89, 'assets', 'next-activity-button.png');
            nextActivityButton.setOrigin(0, 0)
            setupButton(nextActivityButton, 'next-activity-button.png', 'next-activity-button-hover.png');
            nextActivityButton.setInteractive({ cursor: 'pointer' });
            nextActivityButton.on('pointerup', this.nextActivity, this );

            backToMenuButton.on('pointerover', this.playHoverSound, this);
            backToMenuButton.on('pointerdown', this.playSelectionSound, this );

            playAgainButton.on('pointerover', this.playHoverSound, this);
            playAgainButton.on('pointerdown', this.playSelectionSound, this );

            nextActivityButton.on('pointerover', this.playHoverSound, this);
            nextActivityButton.on('pointerdown', this.playSelectionSound, this );
        },

        backToMenu: function () {
            console.log('back to menu button clicked');
            this.completionSound.stop();
            window.angularComponentRef.backToMenu();
        },
        playAgain: function () {
            console.log('play again button clicked');
            this.completionSound.stop()
            this.scene.start('StartGame');
        },
        nextActivity: function () {
            console.log('next activity button clicked');
            window.angularComponentRef.nextActivity();
            // this.scene.start('StartGame', true, false);
        },

        formatTime: function (s) {
            var minutes = "0" + Math.floor(s / 60);
            var seconds = "0" + (s - minutes * 60);
            return minutes.substr(-2) + ":" + seconds.substr(-2);
        },

        playHoverSound: function () {
            this.hoverSound.play();
        },
        playSelectionSound: function () {
            this.selectionSound.play();
        },
        saveActivityAns: function () {
            var storageJson = window.localStorage.getItem('payload');
            var storage = JSON.parse(storageJson);
            var _obj = {
                activity_id: 1,
                country_id: 1,
                zone_id: 1,
                continent_id: 1,
                totalScore: 0,
                questions: []
            }
            if (storage) {
                for (var i = 0; i < storage.questions.length; i++) {
                    var questionKey = storage.questions[i].questionKey;
                    var answerKey = storage.questions[i].answerKey;
                    var attempts = storage.questions[i].attempts;
                    var points = storage.questions[i].points;
                    var question = {
                        [questionKey]: {
                            [answerKey]: {
                                try_count: attempts,
                                score: points
                            }
                        }
                    }
                    _obj.questions.push(question);
                    _obj.totalScore += points
                }
                window.angularComponentRef.gameFinish(_obj);
            }
        }
    };

    //startGame
    var resumegame = function (game) {
        console.log("%cStartGame", "color:white; background:blue");
    };

    resumegame.prototype = {
        init: function (/*currentQuestionIndex*/) {
            // this.currentQuestionIndex = currentQuestionIndex;
            this.fdata = { questions: this.cache.binary.get('questions') };
        },
        create: function () {
            // this.scale.pageAlignHorizontally = true;
            // this.scale.pageAlignVertically = true;

            this.add.sprite(0, 0, 'bg-image').setOrigin(0, 0);

            // this.game.stage.disableVisibilityChange = true; // keep animation run on focus out

            this.hoverSound = this.sound.add('hoverSound');
            this.selectionSound = this.sound.add('selectionSound');

            var startButton = this.add.sprite(this.game.scale.width/2, this.game.scale.height/2, 'assets', 'next-button.png');
            startButton.setOrigin(0, 0)
            setupButton(startButton, 'next-button.png', 'next-button-hover.png');
            startButton.setInteractive({ cursor: 'pointer' });

            startButton.on('pointerup', function () {
                if (currentQuestionIndex < this.fdata.questions.length) {
                    this.scene.start("Questions");//this.game.state.start("Questions", true, false, this.currentQuestionIndex);
                } else {
                    this.scene.start("EndGame");//this.game.state.start("EndGame", true, false);
                }
            }, this);

            startButton.on('pointerover', this.playHoverSound, this);
            startButton.on('pointerdown', this.playSelectionSound, this );
            startButton.setOrigin(0.5, 0.5);

            var style = {
                backgroundColor: 'transparent',
                stroke: '#074a83',
                strokeThickness: 6,
                fontFamily: "Kids Rock DEMO",
                fontSize: 40,
                color: "#ffffff",
                wordWrap: false,
                align: "left"
            };
            var startButtonText = this.add.text(this.game.scale.width/2, this.game.scale.height/2 - 100, 'Resume quiz', style);
            startButtonText.setOrigin(0.5, 0.5);
        },
        playHoverSound: function () {
            this.hoverSound.play();
        },
        playSelectionSound: function () {
            this.selectionSound.play();
        }
    };

    //questions
    var questions = function (game) {
        console.log("%cQuestions", "color:white; background:blue");
    };

    questions.prototype = {
        init: function (/*currentQuestionIndex, isCloseDoor*/) {
            // this.currentQuestionIndex = currentQuestionIndex || 0;
            // this.isCloseDoor = isCloseDoor || false;
            this.wrongAnswersCount = 0;
            this.maxWrongAnswers = 1;
            this.openBtnInputEnabled = false;
            window.localStorage.setItem('currentState', currentQuestionIndex);
        },
        preload: function () {
            this.fdata = { questions: this.cache.binary.get('questions') };
            this.qAnsPoints = this.cache.binary.get('qAnsPoints');
            //images
            // this.load.image('doorCode', this.fdata.questions[this.currentQuestionIndex].doorCode);
            // this.load.image('code', this.fdata.questions[this.currentQuestionIndex].code);
        },
        create: function () {
            if (!this.bgMusic) {
                this.bgMusic = this.sound.add('bg-music', {volume: 1, loop: true});
                this.bgMusic.play();
            }
            else {
                if (!this.bgMusic.isPlaying) {
                    this.bgMusic.play();
                }
            }

            this.questionItem = this.fdata.questions[currentQuestionIndex];

            this.hoverSound = this.sound.add('hoverSound');
            this.selectionSound = this.sound.add('selectionSound');
            this.hoverOpenButton = this.sound.add('openButtonHover');

            this.drawBg();
            this.drawDoor();
            this.drawPuppet();
            this.doorHalfFrameFadeOut = this.add.sprite(0, 272, 'door-half-frame-fadeout');
            this.doorHalfFrameFadeOut.setOrigin(0, 0);
            this.doorHalfFrameFadeOut.alpha = 0;
            this.doorHalfFrame = this.add.sprite(0, 272, 'door-half-frame-overlay');
            this.doorHalfFrame.setOrigin(0, 0);
            this.drawStaticScene();
            this.setProgressBar(currentQuestionIndex);
            this.addAnswerSounds();
            this.drawOpenBtn(this.questionItem.match);
            this.progressPanel();
            this.addEvents();
            this.exitButton();
            this.exitModal();
        },
        update: function () {
            if (this.puppetIdle.x > 573) {
                this.puppetIdle.x -= 8;
            }
            if (this.puppetYes.anims && this.puppetYes.anims.isPlaying) {
                this.puppetYes.x -= 7.5;
                this.puppetYes.y += 2.25;
                this.puppetYes.scaleY -= 0.005;
                this.puppetYes.scaleX -= 0.005;
            }
            if (this.puppetGoOut.anims && this.puppetGoOut.anims.isPlaying) {
                this.puppetGoOut.x += 4;
                this.puppetGoOut.y += 3;
            }
        },
        drawStaticScene: function () {
            this.add.sprite(263, 198, 'doorCode_' + currentQuestionIndex).setOrigin(0.5, 0.5);
            this.answerTab = this.add.sprite(485, 285, 'Ellipse.png').setOrigin(0, 0.2);
            this.answerCode = this.add.sprite(this.answerTab.x+100, this.answerTab.y+45, 'code_' + currentQuestionIndex).setOrigin(0.5, 0.5);
            this.answerTabGroup = this.add.container();
            this.answerTabGroup.add(this.answerTab)
            this.answerTabGroup.add(this.answerCode)
            this.answerTabGroup.alpha = 0;
            this.add.sprite(832, 160, 'assets', 'bubble.png').setOrigin(0, 0);
        },
        drawDoor: function () {
            this.doorDarkBg = this.add.sprite(142, 273, 'door-dark-bg').setOrigin(0, 0);
            this.door = this.add.sprite(148, 300, 'door_open').setOrigin(0, 0);
            this.doorFrame = this.add.sprite(142, 273, 'door-frame').setOrigin(0, 0);
            this.anims.create({
                key: 'doorOpen',
                frames: this.anims.generateFrameNumbers('door_open', { start: 0, end: 5, first: 0 }),
                frameRate: getFrameRate(10),
                repeat: 0
            });
            this.anims.create({
                key: 'doorClose',
                frames: this.anims.generateFrameNumbers('door_open', { start: 0, end: 5, first: 0 }).reverse(),
                frameRate: getFrameRate(12),
                repeat: 0
            });

            if (isCloseDoor && currentQuestionIndex !== 0) {
                this.door.play('doorClose');
                this.doorCloseAudio.play();

                this.doorHalfFrameFadeOut.alpha = 0;//fix for this.door.on("animationstart", function (animation, frame) {
            }
            var self = this;
            this.door.on("animationcomplete", function (animation, frame) {
                if (animation.key == 'doorOpen') {
                    self.doorHalfFrameFadeOut.alpha = 1;
                }
            });
        },
        drawOpenBtn: function (isMatch) {
            this.openBtn = this.add.sprite(120, 464, 'assets', 'open-btn.png');
            this.openBtn.setOrigin(0, 0);
            this.openBtn["context"] = this;
            this.openBtn["isMatch"] = isMatch;
            setupButton(this.openBtn, 'open-btn.png', 'open-btn-hover.png');
            this.openBtn.setInteractive({ cursor: 'pointer' });
            this.openBtn.on('pointerup', this.openBtnAction, this.openBtn );
            this.openBtn.on('pointerover', this.playHoverSound, this);
            this.openBtn.on('pointerdown', this.playSelectionSound, this );
            // this.openBtn.inputEnabled = false;
        },
        openBtnAction: function () {
            var context = this.context;

            if (context.openBtnInputEnabled) {
                context.door.play('doorOpen');
                context.doorOpenAudio.play();

                context.questionTimerLoop.remove(false);//context.game.time.events.remove(context.questionTimerLoop);

                context.puppetIdle.alpha = 0;
                context.puppetGoOut.alpha = 0;

                if (this.isMatch) {
                    context.puppetYes.alpha = 1;
                    context.puppetYes.play('yesAnimate', 30);
                    context.answerTabGroup.alpha = 0;
                    context.answerCorrectGoingIn.play();
                } else {
                    context.puppetNo.alpha = 1;
                    context.puppetNo.play('noAnimate');
                    context.answerTabGroup.alpha = 0;
                    context.answerWrongSwimmingOutAndSwimIn.play();
                }
                // context.game.time.events.remove(context.puppetOutOnNoAnswerEvent);
                // context.openBtn.inputEnabled = false;
                context.openBtnInputEnabled = false;

                var points = 0;
                if (this.isMatch) {
                    points = +context.qAnsPoints.correctAnsPoint;
                } else {
                    points = +context.qAnsPoints.wrongAnsPoint;
                }
                context.calculatePoints.call(context, this.isMatch, points);
            }
        },
        progressPanel: function () {
            var modalTextStyle = {
                backgroundColor: 'transparent',
                fontFamily: 'Kids Rock DEMO',
                fontSize: 16,
                color: '#ffffff',
                wordWrap: false,
                align: 'center'
            };
            //questions current/total
            var totalQuestionsCount = this.fdata.questions.length;
            var questionsTotalProgress = this.add.text(625, 8, "Questions " + (+currentQuestionIndex + 1) + "/" + totalQuestionsCount, modalTextStyle);
            questionsTotalProgress.setOrigin(0, 0);
            questionsTotalProgress.setShadow(0, 2, '#243f5d', 0);
            questionsTotalProgress.alpha = 0;
            var storageJson = window.localStorage.getItem('payload');
            var storage = JSON.parse(storageJson);

            var answeredCount = storage.questions.length;
            var correctAnswersCount = 0;
            for (var i = 0; i < storage.questions.length; i++) {
                if (storage.questions[i].correctAnswer) {
                    correctAnswersCount++;
                }
            }
            // correct/wrong answers
            var correctAnswerCountText = this.add.text(444, 34, correctAnswersCount, modalTextStyle);
            correctAnswerCountText.setOrigin(0,0);
            var wrongAnswerCountText = this.add.text(523, 34, answeredCount - correctAnswersCount, modalTextStyle);
            wrongAnswerCountText.setOrigin(0,0);

            //timer
            this.questionTimeOut = 4;
            quizTimer = 0;
            if (storage.quizTimer !== undefined) {
                quizTimer = storage.quizTimer;
            } else {
                storage.quizTimer = quizTimer;
                window.localStorage.setItem('payload', JSON.stringify(storage));
            }
            this.questionTimerText = this.add.text(779, 34, this.formatTime(quizTimer), modalTextStyle);
            this.questionTimerText.setOrigin(0,0);
            this.time.addEvent({ delay: 1000, callback: this.updateTimer, callbackScope: this, loop: true });//this.game.time.events.loop(Phaser.Timer.SECOND, this.updateTimer, this);
        },
        updateTimer: function () {
            quizTimer++;
            this.questionTimerText.setText(this.formatTime(quizTimer));
        },
        drawBg: function () {
            this.bg = this.add.sprite(0, 0, 'bg-image').setOrigin(0,0)
        },
        setProgressBar: function (step) {
            var totalQuestionsCount = this.fdata.questions.length;
            var progressBarMaxWidth = 117;
            var progressBarWidth = (progressBarMaxWidth / totalQuestionsCount) * step;
            // rect for full bar
            var rrectBg = this.add.graphics();
            rrectBg.setPosition(587, 31);
            rrectBg.fillStyle(0x233e5b);
            rrectBg.fillRoundedRect(0, 0, 117, 22, 15);

            // rect for progress bar itself
            var rrect = this.add.graphics();
            rrect.setPosition(587, 31);
            rrect.fillStyle(0xFFA500, 1);
            rrect.fillRoundedRect(0, 0, progressBarWidth, 22, progressBarWidth >= 15 ? 10 : progressBarWidth > 0 ? 5 : 0);
        },
        drawPuppet: function () {
            var frameWidth = 320;
            var frameHeight = 411;
            var framePosX = 650;
            var framePosY = 122;

            this.puppetIdle = this.add.sprite(framePosX + 960, framePosY, 'Enter_scene');
            this.puppetIdle.setOrigin(0, 0)
            this.puppetIdle.height = frameHeight;
            this.puppetIdle.width = frameWidth;

            var self = this;
            this.anims.create({
                key: 'idleAnimate',
                frames: this.anims.generateFrameNumbers('Enter_scene', { start: 0, end: 31, first: 0 }),//this.getExtendedStartFrames('idle_default', 23, 31),
                frameRate: getFrameRate(40),
                repeat: 0
            });

            this.puppetIdle.on('animationcomplete', function () {
                self.answerTabGroup.alpha = 1;
                // self.openBtn.inputEnabled = true;
                self.openBtnInputEnabled = true;
                self.questionTimerLoop = this.time.addEvent({ delay: 1000, callback: self.updateCounter, callbackScope: self, loop: true });//self.questionTimerLoop = self.game.time.events.loop(Phaser.Timer.SECOND, self.updateCounter, self);
            }, this);


            // puppet no
            this.puppetNo = this.add.sprite(framePosX, framePosY, 'idle_no');
            this.puppetNo.setOrigin(0, 0)
            this.puppetNo.height = frameHeight;
            this.puppetNo.width = frameWidth;
            this.puppetNo.alpha = 0;

            this.anims.create({
                key: 'noAnimate',
                frames: this.anims.generateFrameNumbers('idle_no', { start: 0, end: 31, first: 0 }),
                frameRate: getFrameRate(45),
                repeat: 0
            });
            this.puppetNo.on('animationcomplete', function () {
                self.answerTabGroup.alpha = 0;
                self.puppetNo.alpha = 0;
                self.puppetYes.alpha = 1;
                self.puppetYes.play('yesAnimate');
            }, this);

            // puppet yes
            this.puppetYes = this.add.sprite(framePosX, framePosY, 'idle_yes');
            this.puppetYes.setOrigin(0, 0)
            this.puppetYes.height = frameHeight;
            this.puppetYes.width = frameWidth;
            this.puppetYes.alpha = 0;

            this.anims.create({
                key: 'yesAnimate',
                frames: this.getExtendedEndFrames('idle_yes', 8, 31, false, 3),
                frameRate: getFrameRate(40),
                repeat: 0
            });
            this.puppetYes.on('animationcomplete', function () {
                self.onClickNextButton(true)
            }, this);

            // puppet go out
            this.puppetGoOut = this.add.sprite(framePosX - 50, framePosY, 'go_out');
            this.puppetGoOut.setOrigin(0, 0)
            this.puppetGoOut.height = frameHeight;
            this.puppetGoOut.width = frameWidth;
            this.puppetGoOut.alpha = 0;

            this.anims.create({
                key: 'goOutAnimate',
                frames: this.anims.generateFrameNumbers('go_out', { start: 0, end: 31, first: 0 }),//this.getExtendedEndFrames('go_out', 8, 31),
                frameRate: getFrameRate(20),
                repeat: 0
            });
            this.puppetGoOut.on('animationcomplete', function () {
                self.onClickNextButton(false)
            }, this);
        },
        updateCounter: function () {
            this.questionTimeOut--;

            if (this.questionTimeOut === 0) {
                this.questionTimerLoop.remove(false);//this.game.time.events.remove(this.questionTimerLoop);
                this.puppetOut();
            }
        },
        getExtendedStartFrames: function (spritesheetKey, repeatTil, endFrame, reverse) {
            var dolphinFrames = [];
            for (var i = 1; i <= 3; i++) {
                for (var j = 1; j <= repeatTil; j++) {
                    dolphinFrames.push(j);
                }
            }
            for (var i = repeatTil + 1; i <= endFrame; i++) {
                dolphinFrames.push(i);
            }
            if (reverse) {
                return dolphinFrames.map(function(frame) {return {key: spritesheetKey, frame: frame}}).reverse()
            }
            return dolphinFrames.map(function(frame) {return {key: spritesheetKey, frame: frame}});
        },
        getExtendedEndFrames: function (spritesheetKey, repeatfrom, endFrame, reverse, times) {
            var dolphinFrames = [];
            times = times || 3;
            for (var i = 1; i <= repeatfrom - 1; i++) {
                dolphinFrames.push(i);
            }
            for (var i = 1; i <= times; i++) {
                for (var j = repeatfrom; j <= endFrame; j++) {
                    dolphinFrames.push(j);
                }
            }
            if (reverse) {
                return dolphinFrames.map(function(frame) {return {key: spritesheetKey, frame: frame}}).reverse()
            }
            return dolphinFrames.map(function(frame) {return {key: spritesheetKey, frame: frame}});
        },
        puppetEnter: function () {
            this.puppetNo.alpha = 0;
            this.puppetYes.alpha = 0;
            this.puppetGoOut.alpha = 0;
            this.puppetIdle.alpha = 1;
            self = this;

            this.time.addEvent({ delay: getTimeSyncForFrameRate(1500), callback: function () {
                    self.puppetIdle.play('idleAnimate');
                }, callbackScope: this, loop: false });
            this.time.addEvent({ delay: getTimeSyncForFrameRate(600), callback: function () {
                    this.answerCorrectGoingInBgSound.play();
                }, callbackScope: this, loop: false });
        },
        puppetOut: function () {
            this.puppetNo.alpha = 0;
            this.puppetYes.alpha = 0;
            this.puppetIdle.alpha = 0;
            this.puppetGoOut.alpha = 1;
            this.answerTabGroup.alpha = 0;
            // this.openBtn.inputEnabled = false;
            this.openBtnInputEnabled = false;
            this.puppetGoOut.play('goOutAnimate');

            if (this.questionItem.match) {
                this.answerWrongSwimmingOut.play();
                this.calculatePoints.call(this, false, this.qAnsPoints.wrongAnsPoint)
            }
            else {
                this.answerCorrectGoingOut.play();
                this.calculatePoints.call(this, true, this.qAnsPoints.correctAnsPoint);
            }
        },
        addEvents: function () {
            this.puppetEnter();
        },
        exitButton: function () {
            this.extiBtn = this.add.sprite(900, 0 + 11, 'assets', 'exit-button.png');
            this.extiBtn.setOrigin(0, 0)
            setupButton(this.extiBtn, 'exit-button.png', 'exit-button-hover.png');
            this.extiBtn.setInteractive({ cursor: 'pointer' });
            this.extiBtn.on('pointerup', this.showExitModal, this);
            this.extiBtn.on('pointerover', this.playHoverSound, this);
            this.extiBtn.on('pointerdown', this.playSelectionSound, this );
        },

        exitModal: function () {
            var modalBg = this.add.sprite(0, 0, 'modal-overlay').setOrigin(0, 0);
            modalBg.setInteractive();

            this.modal = this.add.container();
            var modalBox = this.add.sprite(this.game.scale.width/2, this.game.scale.height/2, 'assets', 'activity-Instruction-board.png');
            modalBox.setOrigin(0.5, 0.5);

            var leavesRightSprite = this.add.sprite(0, 0, 'assets', 'leaves.png');
            leavesRightSprite.setOrigin(0, 0)
            leavesRightSprite.setPosition(modalBox.right - 145, modalBox.getBounds().top + 15);

            var leavesLeftSprite = this.add.sprite(0, 0, 'assets', 'leaves.png');
            leavesLeftSprite.setOrigin(0.5, 0.5);
            leavesLeftSprite.scaleX *= -1;
            leavesLeftSprite.setPosition(modalBox.getBounds().left + 34, modalBox.getBounds().bottom - 140);

            var warningIcon = this.add.sprite(modalBox.getCenter().x, modalBox.getBounds().top + 74, 'assets', 'warning-icon.png');
            warningIcon.setOrigin(0.5, 0);

            var modalTextStyle = {
                backgroundColor: 'transparent',
                stroke: '#ffffff',
                strokeThickness: 2,
                lineSpacing: 15,
                fontFamily: 'Kids Rock DEMO',
                fontSize: 30,
                color: '#43425d',
                wordWrap: true,
                align: 'center'
            };
            var modalText = this.add.text(this.game.scale.width/2, this.game.scale.height/2 + 10, "Your progress in this activity will not be saved. Are you sure you want to exit? ", modalTextStyle);
            modalText.setWordWrapWidth(modalBox.width - 110);//modalText.wordWrapWidth = modalBox.width - 110;
            modalText.setOrigin(0.5, 0.5);

            var modalYesBtn = this.add.sprite(modalBox.getBounds().left + 217, modalBox.getBounds().bottom - 179, 'assets', 'button-yes.png');
            modalYesBtn.setOrigin(0, 0)
            setupButton(modalYesBtn,  'button-yes.png', 'button-yes-hover.png');
            modalYesBtn.setInteractive({ cursor: 'pointer' });
            modalYesBtn.on('pointerup', this.exitGame, this);
            var modalNoBtn = this.add.sprite(modalYesBtn.getBounds().right - 43, modalBox.getBounds().bottom - 179, 'assets', 'button-no.png');
            modalNoBtn.setOrigin(0, 0)
            setupButton(modalNoBtn,  'button-no.png','button-no-hover.png');
            modalNoBtn.setInteractive({ cursor: 'pointer' });
            modalNoBtn.on('pointerup', this.exitGameCancel, this);

            modalYesBtn.on('pointerover', this.playHoverSound, this);
            modalYesBtn.on('pointerdown', this.playSelectionSound, this );

            modalNoBtn.on('pointerover', this.playHoverSound, this);
            modalNoBtn.on('pointerdown', this.playSelectionSound, this );

            this.modal.add(modalBg);
            this.modal.add(leavesLeftSprite);
            this.modal.add(leavesRightSprite);
            this.modal.add(modalBox);
            this.modal.add(warningIcon);
            this.modal.add(modalText);
            this.modal.add(modalYesBtn);
            this.modal.add(modalNoBtn);

            this.modal.visible = false;
            this.modal.alpha = 0;
        },

        showExitModal: function () {
            this.extiBtn.visible = false;
            this.modal.visible = true;
            this.modal.alpha = 1;
            this.time.paused = true;
            this.anims.pauseAll();
            this.tweens.pauseAll();
            this.sound.pauseAll();
            this.exitSound.play();
        },

        exitGame: function () {
            this.time.paused = false;
            this.anims.resumeAll();
            this.tweens.resumeAll();
            this.sound.resumeAll();
            window.localStorage.removeItem('currentState');

            if (this.bgMusic) {
                this.bgMusic.stop();
            }
            // window.localStorage.removeItem('wrongButtonKey');
            // this.game.state.start('StartGame', true, false);
            window.angularComponentRef.backToMenu();
        },
        exitGameCancel: function () {
            this.extiBtn.visible = true;
            this.modal.alpha = 0;
            this.modal.visible = false;
            this.time.paused = false;
            this.anims.resumeAll();
            this.tweens.resumeAll();
            this.sound.resumeAll();// this.scene.resume();//this.game.paused = false;
            this.bgMusic.resume();
        },
        onClickNextButton: function (p_isCloseDoor) {
            // this.game.sound.stopAll();
            currentQuestionIndex++;
            var storage = window.localStorage.getItem('payload');
            storage = JSON.parse(storage);
            storage.quizTimer = quizTimer;
            window.localStorage.setItem('payload', JSON.stringify(storage));
            if (storage && storage.questions) {
                var incorrectArr = [];
                for (var i = 0; i < storage.questions.length; i++) {
                    if (!storage.questions[i].correctAnswer) {
                        incorrectArr[incorrectArr.length] = storage.questions[i]
                    }
                }
                if (incorrectArr.length >= 4) {
                    if (this.bgMusic) {
                        this.bgMusic.stop();
                    }
                    this.scene.start("TryGame"/*, true, false*/);
                } else if (currentQuestionIndex < this.fdata.questions.length) {
                    isCloseDoor = p_isCloseDoor;
                    this.scene.start("Questions"/*, true, false, currentQuestionIndex, isCloseDoor*/);
                } else {
                    if (this.bgMusic) {
                        this.bgMusic.stop();
                    }
                    this.scene.start("EndGame"/*, true, false, this.quizTimer*/);
                }
            } else {
                if (this.bgMusic) {
                    this.bgMusic.stop();
                }
                this.scene.start("EndGame");
            }
        },
        calculatePoints: function (isMatch, points) {
            var storage = window.localStorage.getItem('payload');
            storage = JSON.parse(storage);

            if (storage) {
                var question = {
                    questionKey: this.questionItem.key,
                    correctAnswer: isMatch || false,
                    attempts: 1
                };
                storage.questions.push(question)
                storage.currentPoints = storage.currentPoints + points;
                storage.questions[currentQuestionIndex].points = points;

                window.localStorage.setItem('payload', JSON.stringify(storage));
            }
        },
        formatTime: function (s) {
            var minutes = "0" + Math.floor(s / 60);
            var seconds = "0" + (s - minutes * 60);
            return minutes.substr(-2) + ":" + seconds.substr(-2);
        },
        addAnswerSounds: function () {
            this.answerCorrectGoingIn = this.sound.add('answerCorrectGoingIn');
            this.answerCorrectGoingOut = this.sound.add('answerCorrectGoingOut');

            this.answerCorrectGoingInBgSound = this.sound.add('answerCorrectGoingInBgSound');

            this.answerWrongSwimmingOut = this.sound.add('answerWrongSwimmingOut');
            this.answerWrongSwimmingOutAndSwimIn = this.sound.add('answerWrongSwimmingOutAndSwimIn');

            this.doorOpenAudio = this.sound.add('door-open');
            this.doorCloseAudio = this.sound.add('door-close');
            this.exitSound = this.sound.add('exitSound');
            var self = this;
            /*this.game.onPause.add(function () {
                self.game.sound.unsetMute();
                // self.sound.stop();
                self.bgMusic.pause();
            })*/

            this.openBtnHoverSound = this.sound.add('openBtnHoverSound');
            this.openBtnSelectionSound = this.sound.add('openBtnSelectionSound');
        },
        playOpenHoverSound: function () {
            this.openBtnHoverSound.play();
        },
        playOpenSelectionSound: function () {
            this.openBtnSelectionSound.play();
        },
        playHoverSound: function () {
            this.hoverSound.play();
        },
        playSelectionSound: function () {
            this.selectionSound.play();
        },
        playOpenButtonHoverSound: function () {
            this.hoverOpenButton.play();
        }
    };

    //endGame
    var trygame = function (game) {
        console.log("%cTryGame", "color:white; background:blue");
    };

    trygame.prototype = {
        preload: function () {
            this.totalPoints = window.angularComponentRef.getTotalScore();
            // this.fdata = this.cache.binary.get('complete')
        },
        create: function () {
            console.log('trygame create');
            if (!this.tryAgainSound) {
                this.tryAgainSound = this.sound.add('tryAgainSound');
            }
            this.tryAgainSound.play();

            this.hoverSound = this.sound.add('hoverSound');
            this.selectionSound = this.sound.add('selectionSound');

            window.localStorage.removeItem('currentState');
            window.localStorage.removeItem('wrongButtonKey');

            var storageJson = window.localStorage.getItem('payload');
            var storage = JSON.parse(storageJson);
            var currentPoints = 0;
            if (storage) {
                // currentPoints = storage.currentPoints || 0;
            }
            // this.scale.pageAlignHorizontally = true;
            // this.scale.pageAlignVertically = true;

            this.add.sprite(0, 0, 'try-again-bg').setOrigin(0, 0);

            // this.game.stage.disableVisibilityChange = true; // keep animation run on focus out

            var totalScoreTextStyle = {
                backgroundColor: 'transparent',
                stroke: '#91301a',
                strokeThickness: 6,
                fontFamily: 'Kids Rock DEMO',
                fontSize: 39,
                wordWrap: false,
                align: 'left'
            };
            var totalScoreTextStyleOutline = {
                backgroundColor: 'transparent',
                stroke: '#fff',
                strokeThickness: 12,
                fontFamily: 'Kids Rock DEMO',
                fontSize: 39,
                wordWrap: false,
                align: 'left'
            };
            var collectedPointsTextStyle = {
                backgroundColor: 'transparent',
                stroke: '#91301a',
                strokeThickness: 2,
                fontFamily: 'Kids Rock DEMO',
                fontSize: 25,
                wordWrap: false,
                align: 'left',
                color: '#ffffff'
            };

            var modalBox = this.add.sprite(this.game.scale.width/2, this.game.scale.height/2, 'assets', 'activity-Instruction-board.png');
            modalBox.setOrigin(0.5, 0.5);
            var completeRibbon = this.add.sprite(modalBox.getCenter().x, modalBox.getBounds().top + 222, 'assets', 'try-again-ribbon.png');
            completeRibbon.setOrigin(0.5, 0.5);

            var totalScoreOutline = this.add.text(modalBox.getBounds().left + 340, modalBox.getBounds().bottom - 302, "Total Score: " + " " + this.totalPoints, totalScoreTextStyleOutline);
            var totalScore = this.add.text(modalBox.getBounds().left + 342, modalBox.getBounds().bottom - 300, "Total Score: " + " " + this.totalPoints, totalScoreTextStyle);

            var grd = totalScore.context.createLinearGradient(0, 0, 0, totalScore.height);
            grd.addColorStop(0, '#edee62');
            grd.addColorStop(0.2, '#edee62');
            grd.addColorStop(0.7, '#dd7b33');
            totalScore.setFill(grd);

            var collectedPointsMedal = this.add.sprite(modalBox.getBounds().left + 324, modalBox.getBounds().bottom - 244, 'assets', 'collected-poins-medal.png');
            collectedPointsMedal.setOrigin(0, 0)

            var collectedPoints = this.add.text(modalBox.getBounds().left + 422, modalBox.getBounds().bottom - 204, currentPoints, collectedPointsTextStyle);
            collectedPointsMedal.setOrigin(0, 0);

            // var trophy = this.add.sprite(modalBox.left+66, modalBox.bottom-355, 'trophy');
            var trophy = this.add.sprite(modalBox.getBounds().left + 210, modalBox.getBounds().bottom - 215, 'try-again-trophy');
            trophy.setScale(0.5, 0.5);
            trophy.setOrigin(0.5, 0.5);
            trophy.alpha = 0;
            this.tweens.add({
                targets: trophy,
                scaleX: 1,
                scaleY: 1,
                duration: 400,
                ease: Phaser.Math.Easing.Linear
            });
            this.tweens.add({
                targets: trophy,
                alpha: 1,
                duration: 400,
                ease: Phaser.Math.Easing.Linear
            });

            var backToMenuButton = this.add.sprite(modalBox.getBounds().left + 332, modalBox.getBounds().bottom - 89, 'assets', 'back-to-menu-button-hover.png');
            backToMenuButton.setOrigin(0, 0)
            setupButton(backToMenuButton,  'back-to-menu-button-hover.png','back-to-menu-button.png');
            backToMenuButton.setInteractive({ cursor: 'pointer' });
            backToMenuButton.on('pointerup', this.backToMenu, this);
            var playAgainButton = this.add.sprite(modalBox.getBounds().left + 479, modalBox.getBounds().bottom - 89, 'assets', 'play-again-button-hover.png');
            playAgainButton.setOrigin(0, 0)
            setupButton(playAgainButton,  'play-again-button-hover.png','play-again-button.png');
            playAgainButton.setInteractive({ cursor: 'pointer' });
            playAgainButton.on('pointerup', this.playAgain, this);

            backToMenuButton.on('pointerover', this.playHoverSound, this);
            backToMenuButton.on('pointerdown', this.playSelectionSound, this );

            playAgainButton.on('pointerover', this.playHoverSound, this);
            playAgainButton.on('pointerdown', this.playSelectionSound, this );
        },

        backToMenu: function () {
            console.log('back to menu button clicked');
            this.tryAgainSound.stop();
            window.angularComponentRef.backToMenu();
        },
        playAgain: function () {
            console.log('play again button clicked');
            this.tryAgainSound.stop()
            this.scene.start('StartGame');
        },
        playHoverSound: function () {
            this.hoverSound.play();
        },
        playSelectionSound: function () {
            this.selectionSound.play();
        }
    };

    //main
    game = new Phaser.Game({
        type: Phaser.AUTO,
        width: 980,
        height: 600,
        parent: 'game-container',
        dom: {
            createContainer: false
        },
        scale: {
            mode: Phaser.Scale.NONE,
            // autoCenter: Phaser.Scale.CENTER_BOTH
        },
        fps: {
            target: 60,
            forceSetTimeOut: true
        },
        transparent: true,
        scene: {
            create: () => {
                game.scene.add("Boot", boot, false);//game.state.add("Boot", boot);
                game.scene.add("StartGame", startgame);//game.state.add("StartGame", startgame);
                game.scene.add("ResumeGame", resumegame);//game.state.add("ResumeGame", resumegame);
                game.scene.add("Questions", questions);//game.state.add("Questions", questions);
                game.scene.add("EndGame", endgame);//game.state.add("EndGame", endgame);
                game.scene.add("TryGame", trygame);//game.state.add("TryGame", trygame);

                game.scene.start("Boot");//game.state.start("Boot");
            }
        }
    });
}
function destroyMatchTheWord() {
    if (game)
        game.destroy();
}
