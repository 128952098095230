import { COMPILER_OPTIONS, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
    selector: 'app-north-america-1',
    templateUrl: './north-america-1.component.html',
    styleUrls: ['./north-america-1.component.scss']
})
export class NorthAmerica1Component implements OnInit {
    zones = [
        {
            id: '5-1-1',
            number: 53,
            class: 'panama',
            locked: true,
            active: false,
            hover: false
        },
        {
            id: '5-1-2',
            number: 54,
            class: 'costa-rica',
            locked: true,
            active: false,
            hover: false
        },
        {
            id: '5-1-3',
            number: 55,
            class: 'honduras',
            locked: true,
            active: false,
            hover: false
        },
        {
            id: '5-1-4',
            number: 56,
            class: 'guatimala',
            locked: true,
            active: false,
            hover: false
        }
    ];

    public activeZone = 1;
    user: any;
    private userData: any;
    openZones: number;
    tick2Quiz: boolean = true;
    isAdmin: boolean = false;

    activeContinent: number;

    quizLevel: any;
    plane: any = {
        position: 1
    };
    loading = true;
    adminUserData;

    constructor(private router: Router, private afs: AngularFirestore) { }

    ngOnInit() {
        this.tick2Quiz = true;
        const user = JSON.parse(localStorage.getItem('currentUser'));
        this.userData = this.afs.collection('userData').doc(user.uid);

        this.userData.valueChanges().subscribe(
            userData => {
                this.adminUserData = JSON.parse(localStorage.getItem('quranera_admin_userData'));

                if (this.adminUserData) {
                    this.isAdmin = true;
                    userData.activeLevel = '9-9-9';
                }

                this.user = userData;
                if (userData.activities["4-2-1"]) {
                    this.tick2Quiz = false;
                }

                // this.openZones = userData.zone1Lvl;
                this.plane.position = this.openZones;
                this.activeZone = this.adminUserData ? 99 : parseInt(userData.activeLevel.split('-')[1], 10);
                this.activeContinent = this.isAdmin ? 4 : parseInt(userData.activeLevel.split('-')[0], 10);
                if (this.activeZone > 3) {
                    this.openZones = 5;
                } else {
                    this.openZones = parseInt(userData.activeLevel.split('-')[2], 10);
                }
                if (userData.quizLevel) {
                    this.quizLevel = userData.quizLevel;
                }
                if (this.adminUserData) {
                    this.quizLevel = 100;
                }
                this.remapZones();
            }
        );
    }

    goQuiz() {
        // this.showNotification = true;
        // return;
        if (this.quizLevel >= 11) {
            this.router.navigate(['/game/quiz-activity/5-1']);
        }
    }

    remapZones() {
        this.zones = [
            {
                id: '5-1-1',
                number: 53,
                class: 'panama',
                locked: true,
                active: false,
                hover: false
            },
            {
                id: '5-1-2',
                number: 54,
                class: 'costarika',
                locked: true,
                active: false,
                hover: false
            },
            {
                id: '5-1-3',
                number: 55,
                class: 'hunduras',
                locked: true,
                active: false,
                hover: false
            },
            {
                id: '5-1-4',
                number: 56,
                class: 'guatimala',
                locked: true,
                active: false,
                hover: false
            }
        ];
        this.zones.map(zone => {
            if (this.activeContinent < 4) return;

            if (!this.user.activities[zone.id]) {
                zone.locked = this.isAdmin ? false : true;
            } else {
                zone.locked = this.isAdmin ? false : false;
            }
            if (this.user.activeLevel === zone.id && this.user.activities[zone.id]) {
                zone.active = this.isAdmin ? false : true;
            }
        });
    }

    mouseEnter(zone) {
        const audio = document.getElementById('continentHover') as HTMLAudioElement;
        audio.pause();
        audio.currentTime = 0;
        audio.play();
        zone.hover = true;
    }

    mouseLeave(zone) {
        zone.hover = false;
    }

    selectZone(zone) {
        if (!zone.locked) {
            const audio = document.getElementById('continentClick') as HTMLAudioElement;
            audio.play();
            setTimeout(() => {
                this.router.navigate(['/game/activity-list/' + zone.id]);
            }, 1000);
        }
    }

    playBgAudio() {
        let audio = <HTMLAudioElement>document.getElementById('bgAudio');
        audio.play();
    }

    playIntroductionAudio() {
        let audio = <HTMLAudioElement>document.getElementById('introductionAudio');
        audio.play();
    }

    instructionLoaded() {
        if (this.loading) {
            this.loading = false;
            let audio = <HTMLAudioElement>document.getElementById('introductionAudio');
            audio.play();
        }
    }

    ngAfterViewInit() {
        setTimeout(() => {
            if (this.loading) {
                this.instructionLoaded();
            }
        }, 2000);
    }

    haveAccess(activityLevel) {
        if (this.adminUserData) return true;

        if (this.user == undefined) return false;
        if (!this.user['activities'][activityLevel + '-1']
            && !this.user['activities'][activityLevel + '-2']
            && !this.user['activities'][activityLevel + '-3']
            && !this.user['activities'][activityLevel + '-4']
        ) return false;

        for (var a = 1; a <= 4; a++) {
            for (var i = 1; i <= 15; i++) {
                if (this.user["activities"][activityLevel + '-' + a.toString()]) {
                    if (this.user["activities"][activityLevel + '-' + a.toString()][i.toString()]) return true;
                }
            }
        }

        return false;
    }
}
