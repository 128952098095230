import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from "@angular/router";
import { AngularFireAuth } from '@angular/fire/auth';
import { User } from 'firebase';
import * as firebase from 'firebase/app';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { AssignmentService } from "./assignment.service";
import { HttpService } from '../admin/service';
import { Subject } from 'rxjs';
import { AngularFirestore } from '@angular/fire/firestore';

export interface GoogleUser {
    uid: string;
    email: string;
    photoURL?: string;
    displayName?: string;
    myCustomData?: string;
}

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    user: User;

    userData = null;
    isActiveUser = true;
    isFromStripe = true;
    alreadyLoading = false;
    isConstructed = false;
    parentEmail = "";

    public onUserUpdate = new BehaviorSubject<any>(null);

    public onPaymentUpdate = new BehaviorSubject<any>(null);

    public onAssignmentsLoaded = new BehaviorSubject<any>(null);

    constructor(
        public afAuth: AngularFireAuth,
        private httpService: HttpService,
        public router: Router,
        private http: HttpClient,
        private afs: AngularFirestore,
        private assignmentService: AssignmentService
    ) {
        router.events.subscribe((val) => {
            if (val instanceof NavigationEnd && this.isConstructed) {
                setTimeout(() => {
                    this.loadChildData();
                }, 500)
            }
        });

        this.afAuth.authState.subscribe(user => {
            if (user) {
                this.user = user;
                localStorage.setItem('currentUser', JSON.stringify(this.user));

                try {
                    this.afs.collection('userData').doc(user.uid).valueChanges().subscribe(w => { this.loadChildData(true); });
                    this.afs.collection('payment_statuses').doc(user.uid).valueChanges().subscribe(w => { this.loadChildData(true); });
                } catch (e) { }

                this.checkToken(async () => {
                    if (!assignmentService.loaded && !assignmentService.loading) {
                        this.assignmentService.load(this.user.uid).then(res => {
                            this.onAssignmentsLoaded.next(false);
                        }).catch(err => {
                            console.log('err', err);
                        })
                    }
                });
            } else {
                localStorage.setItem('currentUser', null);
            }
            this.isConstructed = true;
        })
    }

    async loadChildData(force = false) {
        if (force) {
            this.checkToken(async () => {
                var childData = await this.getSelfData({ collectionID: "userData" }).toPromise();
                this.userData = childData.result;

                setTimeout(() => {
                    this.onUserUpdate.next(this.userData);
                }, 1000);

                var paymentStatus = await this.getSelfData({ collectionID: "payment_statuses" }).toPromise();

                this.isActiveUser = paymentStatus.result.paymentStatus;
                this.isFromStripe = paymentStatus.result.fromStripe;
                this.onPaymentUpdate.next(paymentStatus.result);
            });
        } else if (!this.alreadyLoading && !this.userData) {
            this.alreadyLoading = true;
            this.checkToken(async () => {
                var childData = await this.getSelfData({ collectionID: "userData" }).toPromise();
                this.userData = childData.result;

                setTimeout(() => {
                    this.onUserUpdate.next(this.userData);
                }, 1000);

                var paymentStatus = await this.getSelfData({ collectionID: "payment_statuses" }).toPromise();

                this.isActiveUser = paymentStatus.result.paymentStatus;
                this.isFromStripe = paymentStatus.result.fromStripe;
                this.onPaymentUpdate.next(paymentStatus.result);
            });
        } else {
            this.onUserUpdate.next(this.userData);
        }
    }

    login(email: string, password: string) {
        return this.afAuth.auth.signInWithEmailAndPassword(email, password);
    }

    register(email: string, password: string) {
        return this.afAuth.auth.createUserWithEmailAndPassword(email, password);
    }

    loginWithToken(token) {
        return this.afAuth.auth.signInWithCustomToken(token);
    }

    handleEmailVerification(actionCode: string): Promise<boolean> {
        return this.afAuth.auth.applyActionCode(actionCode).then(x => {
            return true;
        }).catch(w => {
            return false;
        });
    }

    handleResetPasswordEmail(actionCode: string): Promise<string> {
        return this.afAuth.auth.verifyPasswordResetCode(actionCode).then(x => {
            return x;
        }).catch(w => {
            return "Error";
        });
    }

    handleResetPassword(actionCode: string, password: string): Promise<boolean> {
        return this.afAuth.auth.confirmPasswordReset(actionCode, password).then(x => {
            return true;
        }).catch(w => {
            return false;
        });
    }

    async googleSignin() {
        const provider = new firebase.auth.GoogleAuthProvider();
        const credential = await this.afAuth.auth.signInWithPopup(provider);
        return credential;
    }

    async appleSignin() {
        const provider = new firebase.auth.OAuthProvider("apple.com");

        provider.addScope('email');
        provider.addScope('name');

        const credential = await this.afAuth.auth.signInWithPopup(provider);
        return credential;
    }

    async signOut() {
        await this.afAuth.auth.signOut();
    }

    public getUserToken(data): Observable<any> {
        return this.http.post<any>(environment.firebaseFunctionUrl + 'getUserToken', data, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            })
        });
    }

    public getUserTokenViaID(data): Observable<any> {
        return this.http.post<any>(environment.firebaseFunctionUrl + 'getUserTokenViaID', data, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            })
        });
    }

    public parentCreated(): Observable<any> {
        return this.httpService.postParentRequest('parentCreated', { password: "11111" });
    }

    async changePassword(oldPassword: string, password: string) {
        const auth = this.afAuth.auth;
        const cpUser = auth.currentUser;
        const credentials = firebase.auth.EmailAuthProvider.credential(cpUser.email, oldPassword);
        await cpUser.reauthenticateWithCredential(credentials);
        return cpUser.updatePassword(password);
    }
    async changeChildPassword(email: string, oldPassword: string, password: string) {
        const auth = this.afAuth.auth;
        const cpUser = auth.currentUser;
        const credentials = firebase.auth.EmailAuthProvider.credential(email, oldPassword);
        const childUser = await cpUser.reauthenticateWithCredential(credentials);
        return childUser.user.updatePassword(password);
    }

    async sendEmailVerification() {
        await this.afAuth.auth.currentUser.sendEmailVerification();
    }

    sendPasswordResetEmail(passwordResetEmail: string) {
        return this.afAuth.auth.sendPasswordResetEmail(passwordResetEmail);
    }

    isParent(email: string) {
        return this.afAuth.auth.fetchSignInMethodsForEmail(email);
    }

    async logout() {
        this.assignmentService.reset();
        await this.afAuth.auth.signOut();
        const mobileAppKey = 'mobile-app';
        const isMobileApp = localStorage.getItem(mobileAppKey);
        localStorage.clear();
        if (isMobileApp) {
            localStorage.setItem(mobileAppKey, 'true');
        }
    }

    isLoggedIn(): boolean {
        let user = this.afAuth.auth.currentUser;

        return user !== null ? true : false
    }

    getAdminUserData(token): Observable<any> {
        return this.http.post<any>(environment.firebaseFunctionUrl + 'adminUserData', {}, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'authorization': 'Bearer ' + token
            })
        });
    }

    private getSelfData(data): Observable<any> {
        var token = localStorage.getItem('accessToken');

        if (data.token) token = data.token;

        delete data.token;

        return this.http.post<any>(environment.firebaseFunctionUrl + 'getSelfData', data, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'authorization': 'Bearer ' + token
            })
        });
    }

    getStudentSchoolData(data): Observable<any> {
        var token = localStorage.getItem('accessToken');

        if (data.token) token = data.token;

        delete data.token;

        return this.http.post<any>(environment.firebaseFunctionUrl + 'getStudentSchoolData', data, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'authorization': 'Bearer ' + token
            })
        });
    }

    updateSelfData(data): Observable<any> {
        var token = localStorage.getItem('accessToken');

        if (data.token) token = data.token;

        delete data.token;

        return this.http.post<any>(environment.firebaseFunctionUrl + 'updateSelfData', data, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'authorization': 'Bearer ' + token
            })
        });
    }

    updateParentNotifyfData(data): Observable<any> {
        var token = localStorage.getItem('accessToken');

        if (data.token) token = data.token;

        delete data.token;

        return this.http.post<any>(environment.firebaseFunctionUrl + 'updateParentNotifyfData', data, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'authorization': 'Bearer ' + token
            })
        });
    }

    public getParentChildData(): Observable<any> {
        var token = localStorage.getItem('accessToken');

        return this.http.post<any>(environment.firebaseFunctionUrl + 'getChildData', { data: 'data' }, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'authorization': "Bearer " + token
            })
        });
    }

    checkToken(callback) {
        const expirationTime = parseInt(localStorage.getItem('expirationTime') || "0");
        const nowTime = (new Date()).getTime();

        if (nowTime > expirationTime) {
            this.afAuth.auth.onAuthStateChanged(user => {
                this.afAuth.auth.currentUser.getIdToken(true).then(response => {
                    localStorage.setItem('accessToken', response);

                    localStorage.setItem('expirationTime', (nowTime + 3590000).toString());
                    callback();
                }).catch(error => {
                    console.log('error', error);
                })
            });
        } else {
            callback();
        }
    }
}
