import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../shared/auth.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-continents',
    templateUrl: './continents.component.html'
})
export class ContinentsComponent implements OnInit, OnDestroy, AfterViewInit {
    show = false;
    adminUserData: any;

    zones: any[] = [
        {
            hover: false
        },
        {
            hover: false
        },
        {
            hover: false
        },
        {
            hover: false
        },
        {
            hover: false
        }
    ];

    openZones: number = 1;
    activeContinent = 1;

    loading: boolean = true;
    showNotification = false;

    ps = [];
    users = [];

    constructor(
        private router: Router,
        private auth: AuthService

    ) { }

    ngOnInit() {
        this.adminUserData = JSON.parse(localStorage.getItem('quranera_admin_userData'));

        this.userSubscription = this.auth.onUserUpdate.subscribe(userData => {
            if (userData != null && userData) this.loadChildData(userData);
        }, error => {
            console.log('error', error);
        });
    }

    userSubscription: Subscription;

    ngOnDestroy() {
        this.userSubscription.unsubscribe();
    }

    loadChildData(userData) {
        if (!userData) {
            const adminUserData = localStorage.getItem('quranera_admin_userData');
            if (adminUserData) {
                userData = JSON.parse(adminUserData);
                userData.continentsLvl = 1;
            }
        }

        const adminUserData = JSON.parse(localStorage.getItem('quranera_admin_userData'));
        const level = adminUserData || userData.activeLevel.split('-');
        this.activeContinent = adminUserData ? 10 : parseInt(level[0], 10);
        this.openZones = adminUserData ? 10 : userData.continentsLvl;
    }

    closeNotification() {
        this.showNotification = false;
    }

    ngAfterViewInit() {
        setTimeout(() => {
            if (this.loading) {
                this.instructionLoaded();
            }
        }, 3000);
    }

    mouseEnter(index: number) {
        this.zones[index].hover = true;
        let audio = <HTMLAudioElement>document.getElementById('continentHover');
        audio.pause();
        audio.currentTime = 0;
        if (audio) audio.play();
    }

    mouseLeave(index: number) {
        this.zones[index].hover = false;
    }

    poiClick(zoneNumber: number, route: string = '') {
        let audio = <HTMLAudioElement>document.getElementById('continentClick');
        if (audio) audio.play();
        setTimeout(() => {
            if (this.adminUserData || zoneNumber <= this.openZones) {
                this.router.navigate([route ? route : 'game/zone' + zoneNumber]);
            }
        }, 1000);
    }

    playBgAudio() {
        let audio = <HTMLAudioElement>document.getElementById('bgAudio');
        if (audio) audio.play();
    }

    instructionLoaded() {
        this.loading = false;
        let audio = <HTMLAudioElement>document.getElementById('introductionAudio');
        if (audio) audio.play();
    }
    openPopup() {
        this.show = true;
    }
    Clodepop(event) {
        this.show = false;
    }
}
