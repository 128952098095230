import { Injectable } from '@angular/core';
import { AngularFireAuth } from "@angular/fire/auth";
import { auth } from 'firebase/app';
import { StorageService } from '../storage/storage.service';
import { LoggerService } from '../logger/logger.service';
import { AppService } from '../app/app.service';
import { Router } from "@angular/router";
import { PAGE } from '../../config';
import * as firebase from 'firebase';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    constructor(
        private fireAuth: AngularFireAuth,
        private storageService: StorageService,
        private loggerService: LoggerService,
        private router: Router,
        private appService: AppService
    ) {
        this.fireAuth.authState.subscribe(user => {
            this.changeUser(user);
        });
        /*this.fireAuth.authState.subscribe(user => {
          if (user) {
            this.user = user;
            localStorage.setItem('currentUser', JSON.stringify(this.user));
          } else {
            localStorage.setItem('currentUser', null);
          }
        })*/
    }

    public changeUser(user) {
        this.storageService.set('user', user);
        this.storageService.set('currentUser', this.user);

        if (user) {
            const userData = this.storageService.get('userData');
            this.setAndRedirect(user, userData);
        }

        if (!user && !this.router.url.startsWith(PAGE.login)) {
            this.router.navigate([PAGE.login]);
        }
    }

    private setAndRedirect(user, userData) {
        if (userData) {
            userData.displayName = user.displayName;
            userData.email = user.email;
            this.storageService.set('userData', userData);

            if (this.router.url.startsWith(PAGE.login)) {
                this.router.navigate([PAGE.default]);
            }
        } else {
            setTimeout(() => {
                this.setAndRedirect(user, userData);
            }, 50);
        }
    }

    public login(email, password) {
        return //this.fireAuth.signInWithEmailAndPassword(email, password);
    }

    public resetPassword(email) {
        return //this.fireAuth.sendPasswordResetEmail(email);
    }

    public updatePassword(password) {
        return this.fireAuth.auth.currentUser.updatePassword(password);
    }

    public logout() {
        this.appService.startLoading();
        this.fireAuth.auth.signOut().then(() => {
            this.storageService.clear();
            //this.appService.snack('Logged out');
            this.appService.stopLoading();
            this.router.navigate([PAGE.login]);
        });
    }

    public get isLogged(): boolean {
        return !!this.storageService.get('user');
    }

    public get isSuperLogged(): boolean {
        return !!this.storageService.getSuper('userData');
    }

    public get user() {
        return this.storageService.get('userData') || this.storageService.get('currentUser', false);
    }

    public get superUser() {
        return this.storageService.getSuper('userData') || this.storageService.get('currentUser', false);
    }

    public get displayName(): string {
        if (this.user.firstname || this.user.lastname) {
            return this.user.firstname + ' ' + this.user.lastname;
        }

        return this.user.email.split('@')[0];
    }

    public get displayNameSuper(): string {
        if (this.superUser.firstname || this.superUser.lastname) {
            return this.superUser.firstname + ' ' + this.superUser.lastname;
        }

        return this.user.email.split('@')[0];
    }

    public get role() {
        return this.user.role || 'parent';
    }

    public get superRole() {
        return this.superUser.role;
    }

    public get isAdmin(): boolean {
        return this.role && this.role.startsWith('admin');
    }

    public get isSuperAdmin(): boolean {
        return this.superRole && this.superRole.startsWith('super_');
    }
}
