var ctb;
var game;
var demolishTheBuilding = (json) => {
    (function (ctb) {
        class App extends Phaser.Game {
            constructor() {
                console.warn("UNCOMMENT BELOW!");
                App.json = json;

                let config = {
                    type: Phaser.AUTO,
                    width: App.CANVAS_WIDTH,
                    height: App.CANVAS_HEIGHT,
                    parent: 'game-container',
                    dom: {
                        createContainer: false
                    },
                    scale: {
                        mode: FitScaleManager.detectBestScaleMode(),
                        autoCenter: Phaser.Scale.CENTER_BOTH
                    },
                    transparent: true,
                    scene: {
                        create: () => {
                            this.scene.add('Boot', ctb.scene.Boot, true);
                        }
                    }
                };
                super(config);
            }
        }
        App.CANVAS_WIDTH = 980;
        App.CANVAS_HEIGHT = 600;
        ctb.App = App;
    })(ctb || (ctb = {}));
    var delayedCalls = [];
    function delayedCall(delay, callback, args, callbackScope) {
        let scene = game.scene.getAt(0);
        if (scene) {
            let dc = scene.time.delayedCall(delay, callback, args, callbackScope);
            delayedCalls.push(dc);
            return dc;
        }
        let t = setTimeout(callback, delay);
        delayedCalls.push(t);
        return t;
    }
    function pauseAllDelayedCalls() {
        for (let dc of delayedCalls) {
            if (dc instanceof Phaser.Time.TimerEvent) {
                dc.paused = true;
            }
        }
    }
    function resumeAllDelayedCalls() {
        for (let dc of delayedCalls) {
            if (dc instanceof Phaser.Time.TimerEvent) {
                dc.paused = false;
            }
        }
    }
    function destroyAllDelayedCalls() {
        for (let dc of delayedCalls) {
            if (dc instanceof Phaser.Time.TimerEvent) {
                dc.remove(false);
            }
            else {
                clearTimeout(dc);
            }
        }
        delayedCalls = [];
    }
    function destroyDelayedCall(dc) {
        if (dc instanceof Phaser.Time.TimerEvent) {
            dc.remove(false);
        }
        else {
            clearTimeout(dc);
        }
        let ind = delayedCalls.indexOf(dc);
        if (ind >= 0)
            delayedCalls.splice(ind, 1);
    }
    function setPageBackground(bg) {
    }
    function setupButton(btn, frame) {
        btn.on('pointerdown', () => { btn.setFrame(frame + '_hover' + '0000'); });
        btn.on('pointerover', () => { btn.setFrame(frame + '_hover' + '0000'); game.scene.getAt(0).sound.add("button hover").play(); });
        btn.on('pointerout', () => { btn.setFrame(frame + '0000'); });
        btn.on('pointerup', () => { btn.setFrame(frame + '0000'); game.scene.getAt(0).sound.add('activity selection - button selection').play(); });
    }
    function setupButtonTextureBased(btn, texture, hoverTexture) {
        btn.on('pointerdown', () => { btn.setTexture(hoverTexture); });
        btn.on('pointerover', () => { btn.setTexture(hoverTexture); game.scene.getAt(0).sound.add("button hover").play(); });
        btn.on('pointerout', () => { btn.setTexture(texture); });
        btn.on('pointerup', () => {
            if (btn.parentContainer)
                btn.setTexture(texture); game.scene.getAt(0).sound.add('activity selection - button selection').play();
        });
    }
    function playBtnClickAnim(target) {
        let sc = target.hasOwnProperty("defScale") ? target["defScale"] : 1;
        target.scaleX = target.scaleY = sc;
        let scene = game.scene.getAt(0);
        scene.tweens.add({
            targets: target,
            "scaleX": 0.9 * sc,
            "scaleY": 0.9 * sc,
            duration: 100,
            yoyo: true
        });
    }
    /**
     * @author Roman Parada
     * This class is created to fix overlapping of bottom part of canvas by navigation bar in iOS.
     * It make a delayed resize of the canvas (like Phaser-3 FIT methods does) and it overrides Phaser-3 input window to Phaser-3 core transform methods
     *
     * How to use:
     * Just call the code line below in Boot scene's init() method of your project:
     * new FitScaleManager(this.game).setup();
     */
    class FitScaleManager {
        constructor(game) {
            this.doResize = () => {
                let scale = this.calculateScale();
                let newCanvasWidth = this.phaserScaleManager.width * scale;
                let newCanvasHeight = this.phaserScaleManager.height * scale;
                this.canvasStyle.width = newCanvasWidth + 'px';
                this.canvasStyle.height = newCanvasHeight + 'px';
                this.canvasStyle.marginLeft = (window.innerWidth - newCanvasWidth) / 2 + 'px';
                this.canvasStyle.marginTop = (window.innerHeight - newCanvasHeight) / 2 + 'px';
            };
            this.game = game;
            this.canvasStyle = this.game.canvas.style;
            this.phaserScaleManager = this.game.scale;
        }
        static detectBestScaleMode() {
            let iOS = /iPad|iPhone|iPod/.test(navigator.platform || "");
            let isAndroid = window.navigator.userAgent.toLowerCase().indexOf("android") > -1;
            return iOS || isAndroid ? Phaser.Scale.FIT : Phaser.Scale.NONE;
        }
        ;
        /**
         * Just call this method once in Boot scene's init() method
         */
        setup() {
            this.phaserScaleManager.addListener(Phaser.Scale.Events.RESIZE, this.onResize, this);
            this.overridePhaserTransformMethods();
            this.onResize();
        }
        calculateScale() {
            if (game.scale.scaleMode == Phaser.Scale.NONE)
                return 1;
            return Math.min(window.innerWidth / this.phaserScaleManager.width, window.innerHeight / this.phaserScaleManager.height);
        }
        overridePhaserTransformMethods() {
            this.game.scale.transformX = (pageX) => {
                return (pageX - parseInt(this.canvasStyle.marginLeft.split("px")[0])) / this.calculateScale();
            };
            this.game.scale.transformY = (pageY) => {
                return (pageY - parseInt(this.canvasStyle.marginTop.split("px")[0])) / this.calculateScale();
            };
        }
        onResize() {
            setTimeout(this.doResize, FitScaleManager.RESIZE_DELAY);
        }
    }
    FitScaleManager.RESIZE_DELAY = 500;
    var ctb;
    (function (ctb) {
        var core;
        (function (core) {
            class Gameplay {
                constructor() {
                    this.currentRound = 0;
                    this.correctAnswersCount = 0;
                    this.wrongAnswersCount = 0;
                    this.correctAnswersCountThisRound = 0;
                    this.wrongAnswersCountThisRound = 0;
                    this.onNewRound = null;
                    this.nextLetterDelay = 0;
                    this.failsNumToLose = Number(game.cache.json.get('gameplay')["failsNumToLose"]);
                    this.numberOfQuestionsPerRound = 1;
                    this.useImages = Boolean(game.cache.json.get('gameplay')["useImages"]);
                }
                setupCallbacks(onComplete, onLose, onNewRound) {
                    this.onComplete = onComplete;
                    this.onLose = onLose;
                    this.onNewRound = onNewRound;
                }
                calculateScore() {
                    return this.totalRoundsNum - this.wrongAnswersCount;
                }
                onLetterChosen(proceedNextRoundAllowed) {
                    if (this.isNewRound()) {
                        if (this.correctAnswersCount >= this.totalRoundsNum) {
                            this.onComplete(this.correctAnswersCount, this.correctAnswersCount);
                            return true;
                        }
                        else {
                            if (proceedNextRoundAllowed) this.nextLetter();
                        }
                    }
                    return false;
                }
                nextLetter() {
                    let fn = () => {
                        this.currentWordData = Phaser.Utils.Array.RemoveRandomElement(this.words);
                        this.correctAnswersCountThisRound = 0;
                        this.wrongAnswersCountThisRound = 0;
                        if (this.onNewRound)
                            this.onNewRound();
                    };
                    if (this.nextLetterDelay == 0) {
                        fn();
                    }
                    else {
                        delayedCall(this.nextLetterDelay, fn);
                    }
                }
                onCorrectAnswer(delay) {
                    this.correctAnswersCount++;
                    this.correctAnswersCountThisRound++;
                    this.nextLetterDelay = delay || 2800;
                    return this.onLetterChosen(true);
                }
                onWrongAnswer() {
                    this.wrongAnswersCount++;
                    this.wrongAnswersCountThisRound++;
                    this.nextLetterDelay = 0;
                    if (this.wrongAnswersCount >= this.failsNumToLose) {
                        this.onLose(0, 0);
                        return true;
                    }
                    else {
                        this.onLetterChosen(false);
                    }
                    return false;
                }
                getMaxTotalAnswersCount() {
                    return this.failsNumToLose + this.totalRoundsNum - 1;
                }
                getCurrentTotalAnswersCount() {
                    return this.correctAnswersCount + this.wrongAnswersCount;
                }
                getCurrentTotalAnswersCountThisRound() {
                    return this.correctAnswersCountThisRound + this.wrongAnswersCountThisRound;
                }
                isNewRound() {
                    return this.getCurrentTotalAnswersCount() % this.numberOfQuestionsPerRound == 0;
                }
                isRoundsComplete() {
                    return this.wrongAnswersCount >= this.failsNumToLose || this.correctAnswersCount >= this.totalRoundsNum;
                }
                reset() {
                    this.nextLetterDelay = 0;
                    this.setupCallbacks(null, null, null);
                    let json = game.cache.json.get('gameplay');
                    this.words = json["words"].slice();
                    this.allWorlds = json["words"].slice();
                    this.totalRoundsNum = this.words.length;
                    this.nextLetter();
                    this.currentRound = 0;
                    this.correctAnswersCount = 0;
                    this.wrongAnswersCount = 0;
                    this.correctAnswersCountThisRound = 0;
                    this.wrongAnswersCountThisRound = 0;
                }
            }
            core.Gameplay = Gameplay;
        })(core = ctb.core || (ctb.core = {}));
    })(ctb || (ctb = {}));
    var ctb;
    (function (ctb) {
        var scene;
        (function (scene) {
            class Boot extends Phaser.Scene {
                init() {
                    this.game.scale.transformX = (pageX) => {
                        let offsetLeft = 0;
                        let parentElement = game.canvas.parentElement;
                        while (parentElement) {
                            if (parentElement.offsetLeft) {
                                offsetLeft = parentElement.offsetLeft;
                                break;
                            }
                            parentElement = parentElement.parentElement;
                        }
                        return (pageX - offsetLeft) * this.game.scale.displayScale.x;
                    };
                    this.game.scale.transformY = (pageY) => {
                        let offsetTop = 0;
                        let parentElement = game.canvas.parentElement;
                        while (parentElement) {
                            if (parentElement.offsetTop) {
                                offsetTop = parentElement.offsetTop;
                                break;
                            }
                            parentElement = parentElement.parentElement;
                        }
                        return (pageY - offsetTop) * this.game.scale.displayScale.y;
                    };
                }
                create() {
                    game.scene.remove('Boot');
                    game.scene.add('Preloader', ctb.scene.Preloader, true);
                }
            }
            scene.Boot = Boot;
        })(scene = ctb.scene || (ctb.scene = {}));
    })(ctb || (ctb = {}));
    var ctb;
    (function (ctb) {
        var scene;
        (function (scene) {
            var Gameplay = ctb.core.Gameplay;
            class MainScene extends Phaser.Scene {
                create() {
                    this.gameplay = new Gameplay();
                    this.gameplayScreen = new ctb.screen.GameplayScreen(this, this.gameplay);
                    this.children.add(this.gameplayScreen);
                    this.gameplayScreen.showInstructionPage();
                }
            }
            scene.MainScene = MainScene;
        })(scene = ctb.scene || (ctb.scene = {}));
    })(ctb || (ctb = {}));
    var ctb;
    (function (ctb) {
        var scene;
        (function (scene) {
            class Preloader extends Phaser.Scene {
                preload() {
                    if (typeof ctb.App.json == "string") this.load.json('gameplay', ctb.App.json); else this.game.cache.json.add('gameplay', ctb.App.json);
                }
                create() {
                    let json = game.cache.json.get('gameplay');
                    if (json["useImages"]) {
                        for (let w of json["words"]) {
                            if (w["imageKey"].includes('http')) {
                                this.load.image(w["word"], w["imageKey"]);
                            } else {
                                this.load.image(w["word"], "assets/games/demolish-building/imgs/words/" + w["imageKey"] + ".png");
                            }
                        }
                    }
                    for (let w of json["words"]) {
                        if (w["audioKey"].includes('http')) {
                            this.load.audio(w["audioKey"], w["audioKey"]);
                        } else {
                            this.load.audio(w["audioKey"], "assets/games/demolish-building/sound/mp3/words/" + w["audioKey"] + ".mp3");
                        }
                    }
                    let progressTxt = this.add.text(game.scale.width / 2, game.scale.height / 2 + 50, "", {
                        "fontFamily": "QuranErafont",
                        "fontSize": 25,
                        "color": "#000000",
                        "align": 'center'
                    });
                    progressTxt.setOrigin(0.5, 0.5);
                    this.load.pack('preloader', 'assets/games/demolish-building/pack.json');
                    this.load.on('progress', (value) => {
                        progressTxt.text = Math.ceil(value * 100) + "%";
                    }, this);
                    this.load.on('complete', () => {
                        this.nextScene();
                    });
                    this.load.start();
                }
                static playAnim(animKey, sprite, onComplete = null) {
                    let mainScene = game.scene.getScene('ScreenMain');
                    if (!mainScene.anims.exists(animKey)) {
                        let data = Preloader.ANIMS_DATA[animKey];
                        mainScene.anims.create({
                            key: animKey,
                            frames: mainScene.anims.generateFrameNames(data['atlas'], {
                                start: data['start'], end: data['end'], zeroPad: data['padNum'],
                                prefix: data['prefix'], suffix: ''
                            }),
                            frameRate: data['frameRate'],
                            repeat: data['repeat']
                        });
                    }
                    if (sprite.anims.currentAnim) {
                        sprite.off('animationcomplete');
                    }
                    sprite.anims.stop();
                    sprite.play(animKey);
                    sprite.on('animationcomplete', () => {
                        if (onComplete)
                            onComplete();
                    });
                    return sprite;
                }
                nextScene() {
                    game.scene.remove('Preloader');
                    game.scene.add('ScreenMain', ctb.scene.MainScene, true);
                }
            }
            Preloader.ANIMS_DATA = {
                'building_collapse': {
                    'start': 1,
                    'end': 60,
                    'padNum': 4,
                    'prefix': 'Building collapse ',
                    'repeat': 0,
                    'frameRate': 24,
                    'atlas': 'building_collapse'
                },
                'snake_idle': {
                    'start': 1,
                    'end': 72,
                    'padNum': 4,
                    'prefix': 'Iddle',
                    'repeat': 0,
                    'frameRate': 24,
                    'atlas': 'snake_idle'
                },
                'snake_nahh': {
                    'start': 8,
                    'end': 72,
                    'padNum': 4,
                    'prefix': 'Naahh v5 ',
                    'repeat': 0,
                    'frameRate': 24,
                    'atlas': 'snake_idle'
                },
                'window_smash': {
                    'start': 1,
                    'end': 42,
                    'padNum': 4,
                    'prefix': 'windows Smash ',
                    'repeat': 0,
                    'frameRate': 24,
                    'atlas': 'window_smash'
                },


                'bubble_idle': {
                    'start': 0,
                    'end': 50,
                    'padNum': 4,
                    'prefix': 'idle',
                    'repeat': -1,
                    'frameRate': 24,
                    'atlas': 'bubble-atlas'
                },
            };
            scene.Preloader = Preloader;
        })(scene = ctb.scene || (ctb.scene = {}));
    })(ctb || (ctb = {}));
    var ctb;
    (function (ctb) {
        var screen;
        (function (screen) {
            class AreYouSureWindow extends Phaser.GameObjects.Container {
                constructor(scene, onYes, onNo) {
                    super(scene);
                    this._areYouSurePage = new Phaser.GameObjects.Image(this.scene, -105, 0 - 48, 'Exit warning');
                    this._areYouSurePage.setOrigin(0, 0);
                    //this._areYouSurePage.setInteractive();
                    this._btnSureYes = new Phaser.GameObjects.Image(this.scene, game.scale.width / 2 - 95, 485 - 50, 'btnYES1');
                    this._btnSureYes.setInteractive({ cursor: 'pointer' });
                    this._btnSureYes.once('pointerup', () => {
                        onYes;
                        window.angularComponentRef.backToMenu();
                    });
                    var modalTextStyle = {
                        backgroundColor: 'transparent',
                        stroke: '#ffffff',
                        strokeThickness: 2,
                        lineSpacing: 15,
                        fontFamily: 'Kids Rock DEMO',
                        fontSize: 27,
                        color: '#43425d',
                        wordWrap: true,
                        align: 'center'
                    };
                    var modalText = this.scene.add.text(game.scale.width / 2 + 12, game.scale.height / 2, "Your progress in this activity will not be saved. \n Are you sure you want to exit? ", modalTextStyle);
                    modalText.setOrigin(0.5, 0.5);


                    setupButtonTextureBased(this._btnSureYes, 'btnYES1', 'btnYES2');
                    this._btnSureNo = new Phaser.GameObjects.Image(this.scene, game.scale.width / 2 + 95, 485 - 50, 'btnNO1');
                    this._btnSureNo.setInteractive({ cursor: 'pointer' });
                    this._btnSureNo.once('pointerup', onNo);
                    setupButtonTextureBased(this._btnSureNo, 'btnNO1', 'btnNO2');
                    this.add(this._areYouSurePage);
                    this.add(this._btnSureYes);
                    this.add(this._btnSureNo);
                    this.add(modalText);

                }
            }
            screen.AreYouSureWindow = AreYouSureWindow;
        })(screen = ctb.screen || (ctb.screen = {}));
    })(ctb || (ctb = {}));
    var ctb;
    (function (ctb) {
        var screen;
        (function (screen) {
            class CompleteWindow extends Phaser.GameObjects.Container {
                constructor(scene, onBack, onReplay, onNext) {
                    super(scene);
                    this.music = null;
                    this.setPosition(-104.5, -48);
                    this._bgComplete = new Phaser.GameObjects.Image(this.scene, 0, 0, 'Completion page LATEST UPDATED');
                    this._bgComplete.setOrigin(0, 0);
                    //this._bgComplete.setInteractive();
                    this._cup = new Phaser.GameObjects.Image(this.scene, 400, 410, 'Trophy');
                    this._btnBack = new Phaser.GameObjects.Image(this.scene, 570, 570, 'btnBACK1');
                    this._btnReplay = new Phaser.GameObjects.Image(this.scene, 720, 570, 'btnReplay1');
                    this._btnNext = new Phaser.GameObjects.Image(this.scene, 870, 570, 'btnNEXT1');
                    let _CollectedPoints = new Phaser.GameObjects.Image(this.scene, 620, 440, 'Collected Points');
                    this.totalScoreTxt = this.scene.add.text(845, 352, "", {
                        "fontFamily": "Kids Rock Demo",
                        "fontSize": 35,
                        "color": "#F49F1C",
                        "align": 'center',
                        'stroke': '#70451A',
                        'strokeThickness': 6
                    });
                    this.totalScoreTxt.setOrigin(0.5, 0.5);
                    let grd = this.totalScoreTxt.context.createLinearGradient(0, 0, 0, this.totalScoreTxt.height);
                    grd.addColorStop(0, '#FFFF00');
                    grd.addColorStop(1, '#C17316');
                    this.totalScoreTxt.setFill(grd);
                    this.starScoreTxt = this.scene.add.text(648, 433, "", {
                        "fontFamily": "Kids Rock Demo",
                        "fontSize": 24,
                        "color": "#FFFFFF",
                        "align": 'center'
                    });
                    this.starScoreTxt.setOrigin(0.5, 0.5);
                    this.add([
                        this._bgComplete,
                        _CollectedPoints,
                        this._cup,
                        this._btnBack,
                        this._btnReplay,
                        this._btnNext,
                        this.totalScoreTxt,
                        this.starScoreTxt
                    ]);
                    this._btnBack.setInteractive({ cursor: 'pointer' });
                    this._btnBack.on('pointerup', () => {
                        onBack(this._btnBack);
                        window.angularComponentRef.backToMenu();
                        // if (this.music) {
                        //     this.music.stop();
                        // }
                    });
                    setupButtonTextureBased(this._btnBack, 'btnBACK1', 'btnBACK2');
                    this._btnReplay.setInteractive({ cursor: 'pointer' });
                    this._btnReplay.once('pointerup', () => {
                        onReplay(this._btnReplay);
                        if (this.music) {
                            this.music.stop();
                        }
                    });
                    setupButtonTextureBased(this._btnReplay, 'btnReplay1', 'btnReplay2');
                    this._btnNext.setInteractive({ cursor: 'pointer' });
                    this._btnNext.on('pointerup', () => {
                        onNext(this._btnNext);
                        window.angularComponentRef.nextActivity();
                        // if (this.music) {
                        //     this.music.stop();
                        // }
                    });
                    setupButtonTextureBased(this._btnNext, 'btnNEXT1', 'btnNEXT2');
                }
                show(score, starScore) {
                    window.angularComponentRef.gameFinish({ score, starScore })
                    this._cup.scale = 1.25;
                    this.scene.tweens.add({
                        targets: this._cup,
                        "scale": 1,
                        duration: 500,
                        ease: Phaser.Math.Easing.Back.Out
                    });
                    this.totalScoreTxt.text = String(window.angularComponentRef.component.userInfo.totalScore);
                    this.starScoreTxt.text = String(starScore);
                    // let music = this.scene.sound.add("viktory");
                    this.music = this.scene.sound.add("Activity completion fantastic");
                    this.music.play();
                }
            }
            screen.CompleteWindow = CompleteWindow;
        })(screen = ctb.screen || (ctb.screen = {}));
    })(ctb || (ctb = {}));
    var ctb;
    (function (ctb) {
        var screen;
        (function (screen) {
            var Preloader = ctb.scene.Preloader;
            class GameplayScreen extends Phaser.GameObjects.Container {
                constructor(scene, gameplay) {
                    super(scene);
                    this.bgMusic = null;
                    this.correctAudio = null;
                    this.idleDelayedCall = null;
                    this.playIdle = () => {
                        Preloader.playAnim('snake_idle', this.character, () => {
                            this.idleDelayedCall = delayedCall(Math.floor(Math.random() * 5) * 500, this.playIdle);
                        });
                    };
                    this.sfxBubblePopCounter = 0;
                    this.wfsnd = null;
                    this.showCompleteWindow = (score, starScore) => {
                        let completeWindow = new screen.CompleteWindow(this.scene, (target) => {
                            playBtnClickAnim(target);
                        }, (target) => {
                            playBtnClickAnim(target);
                            this.destroyGameplay();
                            completeWindow.destroy(true);
                            this.showInstructionPage();
                        }, (target) => {
                            playBtnClickAnim(target);
                        });
                        this.setInputEnabled(false);
                        delayedCall(5500, () => {
                            setPageBackground("bg-blue");
                            this.add(completeWindow);
                            completeWindow.show(score, starScore);
                            this.bgMusic.stop();
                        });
                    };
                    this.showLoseWindow = (score, starScore) => {
                        let tryAgainWindow = new screen.TryAgainWindow(this.scene, (target) => {
                            playBtnClickAnim(target);
                        }, (target) => {
                            playBtnClickAnim(target);
                            this.destroyGameplay();
                            tryAgainWindow.destroy(true);
                            this.showInstructionPage();
                        });
                        this.setInputEnabled(false);
                        delayedCall(3000, () => {
                            setPageBackground("bg-blue");
                            this.add(tryAgainWindow);
                            tryAgainWindow.show(score, starScore);
                            this.bgMusic.stop();
                        });
                    };
                    this.gameplay = gameplay;
                    window["gs"] = this;
                    this._gameStage = new Phaser.GameObjects.Image(this.scene, game.scale.width / 2, game.scale.height / 2, 'BG');
                    this._gameStage.setOrigin(0.5, 0.5);
                    this._gameStage.setScale(1.02);
                    //this._gameStage.setInteractive();
                    this.add(this._gameStage);
                    this._btnClose = new Phaser.GameObjects.Image(this.scene, 1025 - 105, 100 - 50, 'x Button');
                    this._btnClose.setInteractive({ cursor: 'pointer' });
                    this._btnClose["defScale"] = this._btnClose.scale;
                    setupButtonTextureBased(this._btnClose, 'x Button', 'x Button HOVER EFFECT');
                    this.add(this._btnClose);
                    this._btnClose.on('pointerup', () => {
                        playBtnClickAnim(this._btnClose);
                        this.onCloseClick();
                    });
                    this._btnSound = new Phaser.GameObjects.Image(this.scene, 160 - 105, 100 - 50, 'Sound');
                    this._btnSound.setInteractive({ cursor: 'pointer' });
                    this._btnSound["defScale"] = this._btnSound.scale;
                    setupButtonTextureBased(this._btnSound, 'Sound', 'Sound HOVER EFFECT');
                    this.add(this._btnSound);
                    this._btnSound.on('pointerup', () => {
                        playBtnClickAnim(this._btnSound);
                        this.onSoundClick();
                    });
                }
                playCorrectAudio() {
                    if (this.correctAudio) {
                        this.correctAudio.stop();
                    }
                    this.correctAudio = this.scene.sound.add(this.gameplay.currentWordData["audioKey"]);
                    this.correctAudio.play();
                    if (this.areYouSureWindow && this.areYouSureWindow.parentContainer == this) {
                        this.correctAudio.pause();
                    }
                }
                onSoundClick() {
                    this.playCorrectAudio();
                }
                destroyIdleDelayedCallIfExists() {
                    if (this.idleDelayedCall != null) {
                        destroyDelayedCall(this.idleDelayedCall);
                        this.idleDelayedCall = null;
                    }
                }
                showGameplay() {
                    setPageBackground("bg-australia");
                     this.bgMusic = this.scene.sound.add("Bachground ambience");
                     this.bgMusic.play();
                     this.bgMusic.loop = true;
                    this.gameplayContainer = new Phaser.GameObjects.Container(this.scene);
                    this.addAt(this.gameplayContainer, this.getIndex(this._btnClose));
                    this.gameplay.reset();
                    this.createRounds();
                    this.prepareRound();
                    this.gameplay.setupCallbacks(this.showCompleteWindow, this.showLoseWindow, () => {
                        this.onNewRound(true);
                    });
                    this.createTallies();
                }
                createRounds() {
                    this.gameplayContainer.removeAll();

                    window["gs"] = this;

                    this.cranemovingparts = new Phaser.GameObjects.Container(this.scene);
                    this.cranemovingparts.setPosition(975, 440);
                    this.gameplayContainer.add(this.cranemovingparts);
                    window["cranemovingparts"] = this.cranemovingparts;
                    this.cranearrow = new Phaser.GameObjects.Image(this.scene, 0, 0, 'cranearrow');
                    this.cranearrow.setPosition(0, 0);
                    this.cranemovingparts.add(this.cranearrow);
                    this.cranearrow.setOrigin(1, 1);
                    window["cranearrow"] = this.cranearrow;
                    this.chainBall = new Phaser.GameObjects.Container(this.scene);
                    this.chainBall.setPosition(-235, -410);
                    this.cranemovingparts.add(this.chainBall);
                    this.chain = new Phaser.GameObjects.Image(this.scene, 0, 0, 'chain');
                    this.chainBall.add(this.chain);
                    this.chain.setOrigin(0.5, 0);
                    window["this.chainBall"] = this.chainBall;
                    window["chain.png"] = this.chain;
                    this.ball = new Phaser.GameObjects.Image(this.scene, 0, 0, 'ball');
                    this.chainBall.add(this.ball);
                    this.ball.setOrigin(0.5, 0);
                    this.ball.setPosition(0, 265);
                    window["ball"] = this.ball;

                    this.truckCrane = new Phaser.GameObjects.Image(this.scene, 860, 497, 'truck crane');
                    this.gameplayContainer.add(this.truckCrane);
                    this.truckCrane.setPosition(860, 497)
                    window["truck crane.png"] = this.truckCrane;

                    this.character = this.scene.add.sprite(500, 314, null);
                    this.playIdle();
                    this.gameplayContainer.add(this.character);

                    this._Shape835copy = this.scene.add.sprite(783, 469, 'Shape 835 copy');
                    this.gameplayContainer.add(this._Shape835copy);
                    window["this._Shape835copy"] = this._Shape835copy;

                    this.buildingContainer = new Phaser.GameObjects.Container(this.scene);
                    this.buildingContainer.setPosition(0, 0);
                    this.gameplayContainer.add(this.buildingContainer);

                    this.Bldg = new Phaser.GameObjects.Image(this.scene, 495, 311, 'Bldg');
                    this.buildingContainer.add(this.Bldg);
                    window["this.Bldg"] = this.Bldg;

                    this.building_collapse = this.scene.add.sprite(495, 309, null);
                    this.building_collapse.setScale(1.33);
                    this.buildingContainer.add(this.building_collapse);
                    this.building_collapse.visible = false;
                    window["building_collapse"] = this.building_collapse;
                    window["fn"] = () => {
                        Preloader.playAnim('building_collapse', this.building_collapse);
                        this.scene.sound.add("Building collapse").play();
                    }


                    let randomized = this.gameplay.allWorlds.slice(); //Phaser.Utils.Array.Shuffle(this.gameplay.allWorlds.slice());
                    this.words = [];
                    let ballOriginalPosition = { x: 740, y: 375 };
                    let positions = [
                        { x: 152, y: 170 }, { x: 359, y: 170 }, { x: 554, y: 170 },
                        { x: 152, y: 331 }, { x: 359, y: 331 }, { x: 554, y: 331 },
                        { x: 152, y: 486 }, { x: 359, y: 486 }, { x: 554, y: 486 },
                    ];
                    let cranAngles = [
                        { "cranemovingparts": -35, "chainBall": 136 }, { "cranemovingparts": -10, "chainBall": 84 }, { "cranemovingparts": 15, "chainBall": 43 },
                        { "cranemovingparts": -50, "chainBall": 145 }, { "cranemovingparts": -17, "chainBall": 70 }, { "cranemovingparts": -2, "chainBall": 35 },
                        { "cranemovingparts": -55, "chainBall": 131 }, { "cranemovingparts": -31, "chainBall": 68 }, { "cranemovingparts": -20, "chainBall": 30 }
                    ];
                    for (let i = 0; i < randomized.length; i++) {
                        let w = new Phaser.GameObjects.Container(this.scene, positions[i]['x'], positions[i]['y']);
                        w.add(w["-image-"] = this.scene.add.sprite(0, 0, 'Windows Normal'));
                        w["-image-"].setOrigin(0.5, 0.49);
                        this.words.push(w);
                        let txt;
                        if (this.gameplay.useImages) {
                            txt = new Phaser.GameObjects.Image(this.scene, 0, 0, randomized[i]["word"]);
                            w.add(txt);
                        }
                        else {
                            txt = this.scene.add.text(0, 10, "", {
                                "fontFamily": "QuranErafont",
                                "fontSize": 55,
                                "color": "#000000",
                                "align": 'center'
                            });
                            txt.setOrigin(0.5, 0.5);
                            txt.style.fixedHeight = 75;
                            txt.setText(randomized[i]["word"] + ' ');
                            w.add(txt);
                        }
                        w["-letter-"] = txt;
                        w["-word-text-"] = randomized[i]["word"];
                        this.gameplayContainer.add(w);

                        w["-cranAngles-"] = cranAngles[i];
                        // Preloader.playAnim('bubble_idle', w["-image-"]);
                    }

                    /*window["aa"]=(s)=>{
                        this.building_collapse.setScale(s);
                        this.building_collapse.visible = true;
                        Preloader.playAnim('building_collapse', this.building_collapse);
                    };*/

                    for (let word of this.words) {
                        word.setSize(word["-image-"].width, word["-image-"].height);
                        word.setInteractive({ cursor: 'pointer' });
                        word.on('pointerdown', () => {
                            this.setInputEnabled(false);
                            if (word["-word-text-"] == this.gameplay.currentWordData["word"]) {

                                const dx = word.x - ballOriginalPosition.x;
                                const targetDistance = Math.abs(dx);
                                const ballSpeed = 700;
                                const duration = targetDistance / ballSpeed * 1000 + 400;
                                let complete = this.onCorrectAnswer(duration + 1100);
                                word.disableInteractive();
                                this.scene.tweens.add({
                                    targets: this.cranemovingparts,
                                    "angle": word["-cranAngles-"]["cranemovingparts"],
                                    duration: duration,
                                    ease: Phaser.Math.Easing.Back.In,
                                    yoyo: true
                                });
                                this.scene.tweens.add({
                                    targets: this.chainBall,
                                    "angle": word["-cranAngles-"]["chainBall"],
                                    duration: duration,
                                    ease: Phaser.Math.Easing.Back.In,
                                    yoyo: true
                                });

                                this.scene.sound.add("Crane movement").play();

                                delayedCall(Math.max(duration - 50, 50), () => {
                                    word.destroy(true);
                                    this.shakeBuilding();
                                    this.crashWindow(word);
                                    if (complete) {
                                        delayedCall(1000, () => {
                                            this.Bldg.visible = false;
                                            for (let w of this.words) {
                                                w["bubble_poping"].visible = false;
                                                w.visible = false;
                                            }
                                            this.building_collapse.visible = true;
                                            Preloader.playAnim('building_collapse', this.building_collapse);
                                            this.scene.sound.add("Building collapse").play();
                                        })
                                    }
                                });

                                word["-image-"].setTexture('WindowGreen');
                            }
                            else {
                                let lost = this.onWrongAnswer();
                                delayedCall(150, () => {
                                    this.destroyIdleDelayedCallIfExists();
                                    Preloader.playAnim('snake_nahh', this.character, () => {
                                        this.playIdle();
                                    });
                                    this.scene.sound.add("Turtle animation sfx").play();
                                });
                                delayedCall(3200, () => {
                                    if (!lost) {
                                        this.setInputEnabled(true);
                                    }
                                });
                                word["-image-"].setTexture('Windows Red');
                                delayedCall(1000, () => {
                                    if (!lost) {
                                        word["-image-"].setTexture('Windows Normal');
                                    }
                                });
                            }
                        });
                    }
                    this._btnClose.setInteractive({ cursor: 'pointer', pixelPerfect: true });
                    this.setInputEnabled(false);

                    this.rr = new Phaser.GameObjects.Image(this.scene, 153, 45, 'Rounded Rectangle 1143')
                    this.gameplayContainer.add(this.rr);
                    this.rr.setOrigin(0, 0.5);
                    this.rr.setScale(this.gameplay.getMaxTotalAnswersCount() / 8, 1);
                }

                shakeBuilding() {
                    this.scene.tweens.add({
                        targets: this.buildingContainer,
                        x: this.buildingContainer.x - 2,
                        duration: 45,
                        yoyo: true,
                        repeat: 5
                    });
                    this.scene.tweens.add({
                        targets: this.buildingContainer,
                        y: this.buildingContainer.y + 2,
                        duration: 30,
                        yoyo: true,
                        repeat: 5
                    });
                }

                crashWindow(bubble) {
                    let bubble_poping = this.scene.add.sprite(bubble.x + 134, bubble.y + 125, null);
                    this.gameplayContainer.add(bubble_poping);
                    bubble["bubble_poping"] = bubble_poping;
                    Preloader.playAnim('window_smash', bubble_poping);
                    this.scene.sound.add("Windows smash").play();
                }
                 prepareRound() {
                    delayedCall(750, () => {
                        this.playCorrectAudio();
                        delayedCall(750, () => { this.setInputEnabled(true); });

                        /*//HELPER!
                        for (let word of this.words) {
                        //HELPER!
                        /*for (let word of this.words) {
                            if (word["-word-text-"] == this.gameplay.currentWordData["word"]) {
                                word["-image-"].alpha = 0.75;
                            } else {
                                word["-image-"].alpha = 1;
                            }
                        }*/
                    });
                }
                onNewRound(showOut) {
                    this.setInputEnabled(false);
                    if (showOut) {
                        this.prepareRound();
                    }
                }
                onCorrectAnswer(delay) {
                    let i = this.gameplay.getCurrentTotalAnswersCount();
                    this.tallyEmptyArray[i].visible = false;
                    this.tally[i].visible = true;
                    let completed = this.gameplay.onCorrectAnswer(delay);
                    this.scene.sound.add('Correct selection').play();
                    return completed;
                }
                onWrongAnswer() {
                    let i = this.gameplay.getCurrentTotalAnswersCount();
                    this.tallyEmptyArray[i].visible = true;
                    this.tally[i].visible = false;
                    let lost = this.gameplay.onWrongAnswer();
                    this.scene.sound.add("Wrong click").play();
                    this.destroyIdleDelayedCallIfExists();
                    if (!lost)
                        delayedCall(3500, () => { this.playCorrectAudio(); });
                    return lost;
                }
                onCloseClick() {
                    this.showAreYouSurePage();
                    this.scene.sound.add('warning page pop up sfx').play();
                }
                createTallies() {
                    if (this.tally) {
                        for (let te of this.tallyEmptyArray) {
                            te.visible = false;
                            this.bringToTop(te);
                        }
                        for (let t of this.tally) {
                            t.visible = false;
                            this.bringToTop(t);
                        }
                        return;
                    }
                    let startX = 200;
                    let startY = 45;
                    let dx = 43;
                    let tallyEmptyArrayPositions = [];
                    for (let i = 0; i < this.gameplay.getMaxTotalAnswersCount(); i++) {
                        tallyEmptyArrayPositions.push({ x: startX + i * dx, y: startY });
                    }
                    this.tallyEmptyArray = [];
                    this.tally = [];
                    for (let p of tallyEmptyArrayPositions) {
                        let _BeehiveEmpty = new Phaser.GameObjects.Image(this.scene, p['x'], p['y'], 'ballempty');
                        this.tallyEmptyArray.push(_BeehiveEmpty);
                        _BeehiveEmpty.visible = false;
                        let _Beehive = new Phaser.GameObjects.Image(this.scene, p['x'], p['y'], 'ballCorrect');
                        this.tally.push(_Beehive);
                    }
                    for (let bea of this.tallyEmptyArray) {
                        this.add(bea);
                    }
                    for (let bee of this.tally) {
                        this.add(bee);
                        bee.visible = false;
                    }
                }
                hideAllUnusedTallies() {
                    let i = this.gameplay.getCurrentTotalAnswersCount();
                    for (let k = i + 1; k < this.tallyEmptyArray.length; k++) {
                        this.tallyEmptyArray[k].visible = false;
                        this.tally[k].visible = false;
                    }
                }
                showInstructionPage() {
                    setPageBackground("bg-blue");
                    let playInstructionSound = () => {
                        if (this.wfsnd) {
                            this.wfsnd.stop();
                        }
                        this.wfsnd = this.scene.sound.add("Help Salty");
                        this.wfsnd.play();
                    };
                    this.instructionPage = new screen.InstructionPage(this.scene, (target) => {
                        playBtnClickAnim(target);
                        this.instructionPage.destroy(true);
                        this.showGameplay();
                        if (this.wfsnd) {
                            this.wfsnd.stop();
                        }
                    }, (target) => {
                        playBtnClickAnim(target);
                        playInstructionSound();
                    });
                    this.add(this.instructionPage);
                    try {
                        playInstructionSound();
                    }
                    catch (e) {
                        console.error(e);
                    }
                }
                showAreYouSurePage() {
                    pauseAllDelayedCalls();
                    setPageBackground("bg-blue");
                    this.scene.tweens.pauseAll();
                    this.pauseSounds();
                    this.areYouSureWindow = new screen.AreYouSureWindow(this.scene, () => {
                        this.scene.tweens.resumeAll();
                        this.areYouSureWindow.destroy(true);
                        this.destroyGameplay();
                        this.showInstructionPage();
                    }, () => {
                        this.scene.tweens.resumeAll();
                        this.areYouSureWindow.destroy(true);
                        this.unpauseSounds();
                        resumeAllDelayedCalls();
                        setPageBackground("bg-australia");
                    });
                    this.add(this.areYouSureWindow);
                }
                setInputEnabled(enabled) {
                    if (enabled) {
                        for (let a of this.words)
                            if (a.parentContainer)
                                a.setInteractive({ cursor: 'pointer' });
                    }
                    else {
                        for (let a of this.words)
                            a.disableInteractive();
                    }
                }
                pauseSounds() {
                    this.scene.sound.pauseAll();
                }
                unpauseSounds() {
                    this.scene.sound.resumeAll();
                }
                destroyGameplay() {
                    this.setInputEnabled(false);
                    this.remove(this.gameplayContainer);
                    this.scene.sound.stopAll();
                    destroyAllDelayedCalls();
                }
            }
            screen.GameplayScreen = GameplayScreen;
        })(screen = ctb.screen || (ctb.screen = {}));
    })(ctb || (ctb = {}));
    var ctb;
    (function (ctb) {
        var screen;
        (function (screen) {
            class InstructionPage extends Phaser.GameObjects.Container {
                constructor(scene, onPlayClick, onSndClick) {
                    super(scene);
                    this._instructionPage = new Phaser.GameObjects.Image(this.scene, 0 - 105, 0 - 48, 'Instructions page  ALL ACTIVITY  TITLEs');
                    this._instructionPage.setOrigin(0, 0);
                    //this._instructionPage.setInteractive();
                    this._instructionPageTitle = new Phaser.GameObjects.Image(this.scene, 460, 110, 'Logo');
                    this._instructionPageTitle.setScale(0.94);
                    this._btnPlay = new Phaser.GameObjects.Image(this.scene, game.scale.width / 2, 480 - 50, 'btnPLAY1');
                    this._btnPlay.setInteractive({ cursor: 'pointer' });
                    this._btnPlay.once('pointerup', onPlayClick);
                    setupButtonTextureBased(this._btnPlay, 'btnPLAY1', 'btnPLAY2');
                    this.instrTxt = this.scene.add.text(game.scale.width / 2, game.scale.height / 2, "Help Funny Snake smash the windows by\n tapping on the right word.", {
                        "fontFamily": "Kids Rock DEMO",
                        "fontSize": 30,
                        "color": "#43425D",
                        "align": 'center'
                    });
                    this.instrTxt.setOrigin(0.5, 0.5);
                    this.instrTxt.setWordWrapWidth(710);
                    this.instrTxt.setLineSpacing(10);
                    this._btnSoundInstruction = new Phaser.GameObjects.Image(this.scene, 800 - 95, 156 - 50, 'Sound');
                    this._btnSoundInstruction.setInteractive({ cursor: 'pointer' });
                    this._btnSoundInstruction.on('pointerup', onSndClick);
                    this._btnSoundInstruction["defScale"] = this._btnSoundInstruction.scale;
                    setupButtonTextureBased(this._btnSoundInstruction, 'Sound', 'Sound HOVER EFFECT');
                    this.add(this._instructionPage);
                    this.add(this._instructionPageTitle);
                    this.add(this.instrTxt);
                    this.add(this._btnPlay);
                    this.add(this._btnSoundInstruction);
                }
            }
            screen.InstructionPage = InstructionPage;
        })(screen = ctb.screen || (ctb.screen = {}));
    })(ctb || (ctb = {}));
    var ctb;
    (function (ctb) {
        var screen;
        (function (screen) {
            class TryAgainWindow extends Phaser.GameObjects.Container {
                constructor(scene, onBack, onReplay) {
                    super(scene);
                    this.music = null;
                    this.setPosition(-106, -48);
                    this._bg = new Phaser.GameObjects.Image(this.scene, 0, 0, 'Try again page');
                    this._bg.setOrigin(0, 0);
                    //this._bg.setInteractive();
                    this._star = new Phaser.GameObjects.Image(this.scene, 400, 415, 'Break Star');
                    this._btnBack = new Phaser.GameObjects.Image(this.scene, 600, 580, 'btnBACK1');
                    this._btnReplay = new Phaser.GameObjects.Image(this.scene, 765, 580, 'btnReplay1');
                    this.totalScoreTxt = this.scene.add.text(830, 355, "", {
                        "fontFamily": "Kids Rock Demo",
                        "fontSize": 35,
                        "color": "#F49F1C",
                        "align": 'center',
                        'stroke': '#70451A',
                        'strokeThickness': 6
                    });
                    this.totalScoreTxt.setOrigin(0.5, 0.5);
                    let grd = this.totalScoreTxt.context.createLinearGradient(0, 0, 0, this.totalScoreTxt.height);
                    grd.addColorStop(0, '#FFFF00');
                    grd.addColorStop(1, '#C17316');
                    this.totalScoreTxt.setFill(grd);
                    this.starScoreTxt = this.scene.add.text(635, 431, "", {
                        "fontFamily": "Kids Rock Demo",
                        "fontSize": 24,
                        "color": "#FFFFFF",
                        "align": 'center'
                    });
                    this.starScoreTxt.setOrigin(0.5, 0.5);
                    this.add([
                        this._bg,
                        this._star,
                        this._btnBack,
                        this._btnReplay,
                        this.totalScoreTxt,
                        this.starScoreTxt
                    ]);
                    this._btnBack.setInteractive({ cursor: 'pointer' });
                    this._btnBack.on('pointerup', () => {
                        onBack(this._btnBack);
                        window.angularComponentRef.backToMenu();
                        // if (this.music) {
                        //     this.music.stop();
                        // }
                    });
                    setupButtonTextureBased(this._btnBack, 'btnBACK1', 'btnBACK2');
                    this._btnReplay.setInteractive({ cursor: 'pointer' });
                    this._btnReplay.once('pointerup', () => {
                        onReplay(this._btnReplay);
                        if (this.music) {
                            this.music.stop();
                        }
                    });
                    setupButtonTextureBased(this._btnReplay, 'btnReplay1', 'btnReplay2');
                }
                show(score, starScore) {
                    this._star.scale = 1.25;
                    this.scene.tweens.add({
                        targets: this._star,
                        "scale": 1,
                        duration: 500,
                        ease: Phaser.Math.Easing.Back.Out
                    });
                    this.totalScoreTxt.text = String(window.angularComponentRef.component.userInfo.totalScore);
                    this.starScoreTxt.text = String(starScore);
                    this.music = this.scene.sound.add("Fail - close one");
                    this.music.play();
                }
            }
            screen.TryAgainWindow = TryAgainWindow;
        })(screen = ctb.screen || (ctb.screen = {}));
    })(ctb || (ctb = {}));

    game = new ctb.App();
};

var destroyDemolishTheBuilding = () => {
    if (game) {
        game.destroy();
        game = null;
    }
}
