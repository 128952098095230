import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, Observable } from 'rxjs';
import { AngularFireStorage } from '@angular/fire/storage';
import { PointsMechanismService } from 'src/app/shared/point-mechanism.service';
import { BoardComponent } from './board.component';
import { ActivityService } from 'src/app/shared/activity.service';

@Component({
    selector: 'app-do-to-dot',
    templateUrl: './dot-to-dot.component.html'
})
export class DotToDotActivityComponent implements OnInit, OnDestroy, AfterViewInit {
    protected subscription: Subscription;
    userData: any;
    id: string;
    activityId: string;

    gameStatus: string;
    data: any;
    fullData: any;
    totalScore: number;

    activity: any;
    activities: Observable<any>;

    titles: any = {};
    userInfo: any;

    bgSoundPause: boolean = false;
    brushSize: number = 18;
    selectedColor: string = '#fc50a6';

    currentIndex: number;

    getUrl: any;
    loading: boolean = true;
    oldColor: string;
    ios;
    userTouch = false;

    @ViewChild(BoardComponent, { static: false })
    boardComponent: BoardComponent;

    constructor(
        private activatedRoute: ActivatedRoute,
        public storage: AngularFireStorage,
        private router: Router,
        public activityService: ActivityService,
        private pm: PointsMechanismService) { }

    ngOnInit() {
        this.ios = this.pm.isIOS();
        this.subscription = this.activatedRoute.params.subscribe((data) => {
            if (data && data['id'] && data['activityId']) {
                this.id = data['id']
                this.activityId = data['activityId']

                this.activityService.getActivityData({ zoneId: this.id, activityId: this.activityId }).subscribe(aData => {
                    this.fullData = aData.activityData;
                    if (this.fullData.letters) {
                        this.currentIndex = 0;
                        this.data = this.fullData.letters[0];
                    }
                    this.changeGameStatus('introduction');
                });
            }

            this.pm.getUserInfo.subscribe(data => {
                this.userInfo = data;
            });
            this.pm.start(this.id, this.activityId);
        });
    }

    setbuttonEvents() {
        var buttons = document.querySelectorAll('button');
        for (var i = 0; i < buttons.length; i++) {
            buttons[i].addEventListener('click', function (event) {
                let audio = <HTMLAudioElement>document.getElementById('buttonClick');
                if (audio) {
                    if (audio) audio.play();
                }
            });
            buttons[i].addEventListener('mouseover', function (event) {
                let audio = <HTMLAudioElement>document.getElementById('buttonHover');
                if (audio) {
                    if (audio) audio.play();
                }
            });
        }
        buttons = document.querySelectorAll('.color-box li');
        for (var i = 0; i < buttons.length; i++) {
            buttons[i].addEventListener('click', function (event) {
                let audio = <HTMLAudioElement>document.getElementById('buttonClick');
                if (audio) {
                    if (audio) audio.play();
                }
            });
            buttons[i].addEventListener('mouseover', function (event) {
                let audio = <HTMLAudioElement>document.getElementById('colorHover');
                if (audio) {
                    if (audio) audio.play();
                }
            });
        }
        buttons = document.querySelectorAll('.brush-size .brush');
        for (var i = 0; i < buttons.length; i++) {
            buttons[i].addEventListener('click', function (event) {
                let audio = <HTMLAudioElement>document.getElementById('buttonClick');
                if (audio) {
                    if (audio) audio.play();
                }
            });
            buttons[i].addEventListener('mouseover', function (event) {
                let audio = <HTMLAudioElement>document.getElementById('colorHover');
                if (audio) {
                    if (audio) audio.play();
                }
            });
        }

        buttons = document.querySelectorAll('.eraser');
        for (var i = 0; i < buttons.length; i++) {
            buttons[i].addEventListener('click', function (event) {
                let audio = <HTMLAudioElement>document.getElementById('buttonClick');
                if (audio) {
                    if (audio) audio.play();
                }
            });
            buttons[i].addEventListener('mouseover', function (event) {
                let audio = <HTMLAudioElement>document.getElementById('colorHover');
                if (audio) {
                    if (audio) audio.play();
                }
            });
        }
    }

    changeGameStatus(status: string) {
        if (status == 'leave') {
            this.pauseBg();
            let audio = <HTMLAudioElement>document.getElementById('warningAudio');
            if (audio) audio.play();
        }
        else if (status == 'save') {
            this.pauseBg();
            let audio = <HTMLAudioElement>document.getElementById('imageSaveAudio');
            if (audio) audio.play();
        }
        else if (status == 'start') {
            if (this.data.introduction && this.data.introduction.audio) {
                let audio = <HTMLAudioElement>document.getElementById('letterIntroAudio');
                if (audio) audio.play();
            }
            else if (!this.bgSoundPause) {
                let audio = <HTMLAudioElement>document.getElementById('bgAudio');
                if (audio) audio.play();
            }
        }
        this.gameStatus = status;
        setTimeout(() => {
            this.setbuttonEvents();
        }, 300);
    }

    ngAfterViewInit(): void {
        this.setbuttonEvents();
        setTimeout(() => {
            this.instructionLoaded();
        }, 3000);
    }

    gameStart() {
        let player = <HTMLAudioElement>document.getElementById('introductionAudio');
        if (player.paused === false) {
            player.pause();
        }
        this.changeGameStatus('start');
        this.playBg();
    }

    end() {
        this.gameFinish();
        this.changeGameStatus('complete');
        this.pauseBg();
        let audio = <HTMLAudioElement>document.getElementById('completeAudio');
        if (audio) audio.play();
    }

    gameFinish() {
        this.pm.collectPoint(this.fullData.maxStars, this.fullData.progressType);
    }

    backToMenu() {
        this.router.navigate(['/game/activity-list', this.id]);
    }

    ngOnDestroy(): void {
        if (this.subscription) this.subscription.unsubscribe();
        // if(window.angularComponent)
        // 	window.angularComponent = null;
    }

    previousState() {
        this.pm.backToActivityList();
    }

    playPause() {
        let audio: any = document.getElementById('bgAudio');
        if (this.bgSoundPause) {
            if (audio) audio.play();
        }
        else {
            audio.pause();
        }
        this.bgSoundPause = !this.bgSoundPause;
    }

    pauseBg() {
        if (!this.bgSoundPause) {
            let audio: any = document.getElementById('bgAudio');
            audio.pause();
            this.bgSoundPause = !this.bgSoundPause;
        }
    }
    playBg() {
        if (this.bgSoundPause) {
            let audio: any = document.getElementById('bgAudio');
            if (audio) audio.play();
            this.bgSoundPause = !this.bgSoundPause;
        }
    }

    save() {
        let canvas = (<HTMLCanvasElement>this.boardComponent.myCanvas.nativeElement);
        let img1 = new Image();
        let context = this.boardComponent.context;
        let bg = this.boardComponent.bg;
        img1.crossOrigin = "anonymous";
        img1.src = bg;
        let upload = this.upload;
        let imageUrl = '/uploads/' + this.userInfo.uid + '/gallery/' + this.id + '/' + this.currentIndex + '.jpg';
        let storage = this.storage.ref(imageUrl);
        img1.onload = () => {
            context.drawImage(img1, 0, 0);

            let rusult = upload(canvas.toDataURL("image/jpeg"), storage);
        };
        this.saveToUserGallery(imageUrl);
        this.changeGameStatus('save');
    }

    saveOk() {
        if (this.fullData.letters.length > this.currentIndex + 1) {
            this.data = this.fullData.letters[this.currentIndex + 1];
            this.changeGameStatus('start');
            this.currentIndex = this.currentIndex + 1;
            this.playBg();
        }
        else {
            this.end();
        }
    }

    again() {
        this.currentIndex = 0;
        this.data = this.fullData.letters[0];
        this.changeGameStatus('introduction');
        this.setbuttonEvents();
    }

    nextActivity() {
        this.pm.nextActivity();
    }

    playBgAudio() {
        let audio = <HTMLAudioElement>document.getElementById('bgAudio');
        if (!this.bgSoundPause)
            audio.pause();
    }

    playIntroductionAudio() {
        let audio = <HTMLAudioElement>document.getElementById('introductionAudio');
        if (audio) audio.play();
        this.userTouch = true;
    }

    private upload(url, storageRef) {
        // let storageRef = component.storage.ref().child('/uploads/'+component.userInfo.uid+'/gallery/'+component.id+'/'+ component.currentIndex +'.jpg');
        storageRef.putString(url, 'data_url', { contentType: 'image/jpeg' }).then(function (snapshot) {
            return true;
        });
    }

    private saveToUserGallery(imageUrl) {
        let data = {
            arabicLetter: this.data.data.arabicLetter,
            imageUrl,
            id: this.id,
            index: this.currentIndex
        };
        this.pm.saveToGallery(data);
    }

    instructionLoaded() {
        if (this.loading) {
            this.loading = false;
            let audio = <HTMLAudioElement>document.getElementById('introductionAudio');
            if (audio) audio.play();
        }
    }

    eraserClick() {
        this.oldColor = this.selectedColor;
        this.selectedColor = '#ffffff'
    }

    brushSizeClick() {
        if (this.oldColor) {
            this.selectedColor = this.oldColor;
            this.oldColor = null;
        }
    }
}
