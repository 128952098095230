import { Component, OnInit, OnDestroy, ViewChild, ElementRef, AfterViewInit, NgZone } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription, interval } from 'rxjs';
import { map } from 'rxjs/operators';
import { AngularFireStorage } from '@angular/fire/storage';
import { DatePipe } from '@angular/common';
import { PointsMechanismService } from 'src/app/shared/point-mechanism.service';
import { FrameShowService } from '../../../shared/FrameShowService';
import { Location } from '@angular/common';
import { AssignmentService } from 'src/app/shared/assignment.service';
import { ActivityService } from 'src/app/shared/activity.service';
import { AuthService } from '../../../shared/auth.service';

declare function quiz(data: any): any;
declare function destoryQuiz();

@Component({
	selector: 'app-quiz-activity',
	templateUrl: './quiz.component.html'
})
export class QuizActivityComponent implements OnInit, OnDestroy, AfterViewInit {
	id: string;

	gameStatus: string;
	data: any;
	totalScore: number;

	activity: any;
	activities: Observable<any>;

	titles: any = {};
	userInfo: any;

	questionSoundPause: boolean = true;

	currentQuestionIndex: number = 0;
	timer: number;
	numbers: any;
	maxStars = 0;
	totalCount: number;

	loading: boolean = true;
	isFromAssigment: boolean = false;
	ios;
	userTouch = false;
	isStarted = false;

	startTime: any;

	today: Date = new Date();
	answerAudio: HTMLAudioElement;

	showCongratsAssignment = false;
	userSubscription: Subscription;

	constructor(
		private _location: Location,
		private activatedRoute: ActivatedRoute,
		private frame: FrameShowService,
		public storage: AngularFireStorage,
		private router: Router,
		private ngZone: NgZone,
		private pm: PointsMechanismService,
		public assignmentService: AssignmentService,
		public activityService: ActivityService,
		private auth: AuthService,
		private datePipe: DatePipe
	) { }

	ngOnInit() {
		this.startTime = new Date();
		this.today = new Date();
		this.frame.useV3();
		this.ios = this.pm.isIOS();
		this.activatedRoute.params.subscribe((data) => {
			if (data && data['id']) {
				this.id = data['id'];
				const levelNo = this.id.split('-')[0];

				if (!this.assignmentService.canAccessAnotherGames() && Object.keys(this.assignmentService.canAccess).length) {
					if (!(this.assignmentService.canAccess[levelNo] && this.assignmentService.canAccess[levelNo][this.id])) {
						this.backtoMap();

						setTimeout(() => {
							const assignmentBell = <HTMLAudioElement>document.querySelector('.assignment-bell-warning');
							assignmentBell.click();
						}, 1500);
					} else {
						this.activityService.getQuizData({ quizID: this.id }).subscribe(aData => {
							this.data = aData;

							this.userSubscription = this.auth.onUserUpdate.subscribe(userData => {
								if (userData != null && userData) this.loadChildData(userData);
							}, error => {
								console.log('error', error);
							});
						});
					}
				} else {
					this.activityService.getQuizData({ quizID: this.id }).subscribe(aData => {
						this.data = aData;

						this.userSubscription = this.auth.onUserUpdate.subscribe(userData => {
							if (userData != null && userData) this.loadChildData(userData);
						}, error => {
							console.log('error', error);
						});
					});
				}
			}
		});

		window['angularComponentRef'] = {
			component: this,
			zone: this.ngZone,
			gameFinish: (value) => this.gameFinish(),
			gameFinishWithLoose: (value) => this.gameFinishWithLoose(),
			backToMenu: () => this.backToMenu(),
			nextActivity: () => this.nextActivity(),
			changeCorrectAnswer: val => this.changeCorrectAnswer(val)
		};
	}

	backtoMap() {
		/*let id = this.id.split('-')[1];
		this.router.navigate(['/game/zone' + id]);*/
		const id = this.id.split('-')[1];
		let zoneKey = 'zone';
		if (this.id.split('-')[0] == '3') {
			zoneKey = 'africa-';
		} else if (this.id.split('-')[0] == '4') {
			zoneKey = 'south-america-';
		} else if (this.id.split('-')[0] == '5') {
			zoneKey = 'north-america-';
		}

		this.router.navigate(['/game/' + zoneKey + id]);
	}


	loadChildData(userData) {
		let userAdmin = JSON.parse(localStorage.getItem('quranera_admin_user'));

		this.userInfo = userData;

		let isAssignment = false;
		for (let i = 0; i < this.assignmentService.list.length; i++) {
			if (this.assignmentService.list[i].gameType === 'quiz' && this.assignmentService.list[i].gameId == this.id) {
				isAssignment = true;
				break;
			}
		}

		if (!isAssignment && !userAdmin) {
			let levels = this.id.split('-');
			if (parseInt(levels[0], 10) === 1) {
				if (levels[1] > userData.quizLevel) {
					this.router.navigate(['/game']);
				}
			} else if (parseInt(levels[0], 10) === 2) {
				if ((parseInt(levels[1], 10) + 4) > userData.quizLevel) {
					this.router.navigate(['/game']);
				}
			} else if (parseInt(levels[0], 10) === 3) {
				if ((parseInt(levels[1], 10) + 4) > userData.quizLevel) {
					this.router.navigate(['/game']);
				}
			}
		}

		this.data['displayName'] = this.userInfo ? this.userInfo.displayName : '';
		this.data['continentId'] = this.id;

		if (this.data.data && this.data.data.length > 0) {
			this.currentQuestionIndex = 0;
			this.totalCount = this.data.data.length;
		}
		setTimeout(() => {
			if (!this.isStarted) {
				this.isStarted = true;
				quiz(this.data);
			}
		}, 500);
	}

	changeCorrectAnswer(correctAnswer) {
		this.maxStars = correctAnswer;
	}

	setbuttonEvents() {
		var buttons = document.querySelectorAll('button');
		for (var i = 0; i < buttons.length; i++) {
			buttons[i].addEventListener('click', function (event) {
				let audio = <HTMLAudioElement>document.getElementById('buttonClick');
				if (audio) audio.play();
			});
			buttons[i].addEventListener('mouseover', function (event) {
				let audio = <HTMLAudioElement>document.getElementById('buttonHover');
				if (audio) audio.play();
			});
		}
	}

	changeGameStatus(status: string) {
		if (status == 'leave') {
			this.answerAudio.pause();
			this.questionSoundPause = true;
			let audio = <HTMLAudioElement>document.getElementById('warningAudio');
			if (audio) audio.play();
		}
		this.gameStatus = status;
		setTimeout(() => {
			this.setbuttonEvents();
		}, 300);
	}

	ngAfterViewInit(): void {
	}

	getProgressLength() {
		if (this.totalCount > 0) {
			return (this.currentQuestionIndex) * 100 / this.totalCount;
		} else {
			return 0;
		}
	}

	gameStart() {
		let player = <HTMLAudioElement>document.getElementById('introductionAudio');
		if (player.paused === false) {
			player.pause();
		}
		this.changeGameStatus('start');
		if (!this.numbers) {
			this.numbers = interval(1000).pipe(
				map(v => v * 1000)
			).subscribe(x => this.timer = x);
		}
		this.playAnswerSound();
	}

	end() {
		if (this.maxStars > 4) {
			this.changeGameStatus('complete');
			let audio = <HTMLAudioElement>document.getElementById('completeAudio');
			if (audio) audio.play();
			this.gameFinish();
		} else {
			this.changeGameStatus('failed');
			let audio = <HTMLAudioElement>document.getElementById('faileAudio');
			if (audio) audio.play();
		}
		if (this.numbers) {
			this.numbers.unsubscribe();
			this.numbers = null;
		}
	}

	async gameFinishWithLoose() {
		let endTime: any = new Date();

		let timeData = Math.trunc(endTime - this.startTime);

		this.userInfo.sessionDurations.push({ failed: true, duration: Math.round(timeData / 1000), date: Math.trunc(Date.now() / 1000), quiz: true, activityId: this.id, totalCorrectAnswers: this.maxStars, totalQuestions: this.data.data.length });

		await this.activityService.updateSessionDurations({ failed: true, duration: Math.round(timeData / 1000), date: Math.trunc(Date.now() / 1000), quiz: true, activityId: this.id, totalCorrectAnswers: this.maxStars, totalQuestions: this.data.data.length }).toPromise();
	}

	async gameFinish() {
		const list = this.assignmentService.list.filter(item => {
			return !item.done && item.assignment.lesson == this.id && item.gameId == this.id;
		});
		if (list.length) {
			this.showCongratsAssignment = true;

			let index = -1;
			let continentCert = -1;
			if (!this.userInfo.certs) {
				this.userInfo.certs = [];
			} else {
				let id = this.id;
				index = this.userInfo.certs.map(data => data.id).indexOf(id);
			}

			this.isFromAssigment = true;

			let endTime: any = new Date();

			let timeData = Math.trunc(endTime - this.startTime);

			if (this.userInfo.sessionDurations === undefined) {
				this.userInfo.sessionDurations = [];
			}

			this.userInfo.sessionDurations.push({ duration: Math.round(timeData / 1000), date: Math.trunc(Date.now() / 1000), quiz: true, activityId: this.id, totalCorrectAnswers: this.maxStars, totalQuestions: this.data.data.length });

			this.auth.checkToken(async () => {
				await this.activityService.updateSessionDurations({ duration: Math.round(timeData / 1000), date: Math.trunc(Date.now() / 1000), quiz: true, activityId: this.id, totalCorrectAnswers: this.maxStars, totalQuestions: this.data.data.length }).toPromise();
			});

			index = -1;
			if (!this.userInfo.certs) {
				this.userInfo.certs = [];
			} else {
				let id = this.id;
				index = this.userInfo.certs.map(data => data.id).indexOf(id);
			}

			if (index > -1) {
				this.userInfo.certs[index] =
				{
					id: this.id,
					quizName: this.data.title,
					date: this.datePipe.transform(this.today, 'dd-MM-yyyy'),
					totalCorrectAnswers: this.maxStars,
					totalQuestions: this.data.data.length
				};
			} else {
				this.userInfo.certs.push(
					{
						id: this.id,
						quizName: this.data.title,
						date: this.datePipe.transform(this.today, 'dd-MM-yyyy'),
						totalCorrectAnswers: this.maxStars,
						totalQuestions: this.data.data.length
					});
			}

			this.auth.checkToken(async () => {
				await this.auth.updateSelfData({ collectionID: "userData", data: { certs: this.userInfo.certs } }).toPromise();
			});

			this.assignmentService.updateToDone(list[0].id, 0).then(async res => {
				for (let index in this.assignmentService.list) {
					if (this.assignmentService.list[index].id == list[0].id) {
						this.assignmentService.list[index].done = true;
					}
				}

				await this.assignmentService.load(this.userInfo.uid, true);
			}).catch(err => {
				console.log('err', err);
			});
		} else {
			this.isFromAssigment = false;
			let endTime: any = new Date();
			let timeData = Math.trunc(endTime - this.startTime);

			const activityLvl = this.id.split('-');
			let nextLvl = '';
			if (!this.data.lastOfContinent) {
				nextLvl = activityLvl[0] + '-' + (parseInt(activityLvl[1]) + 1) + '-1';
			} else {
				nextLvl = (parseInt(activityLvl[0], 10) + 1) + '-1-1';
				let nextContLevel = (parseInt(activityLvl[0], 10) + 1);
				if (nextContLevel > this.userInfo.continentsLvl) this.userInfo.continentsLvl = nextContLevel;
			}

			if (!this.userInfo.activities[nextLvl]) {
				this.userInfo.activeLevel = nextLvl;
				this.userInfo.activities[nextLvl] = { '1': {} };
			}

			this.userInfo.sessionDurations.push({ duration: Math.round(timeData / 1000), date: Math.trunc(Date.now() / 1000), quiz: true, activityId: this.id, totalCorrectAnswers: this.maxStars, totalQuestions: this.data.data.length });

			this.auth.checkToken(async () => {
				await this.activityService.updateSessionDurations({ duration: Math.round(timeData / 1000), date: Math.trunc(Date.now() / 1000), quiz: true, activityId: this.id, totalCorrectAnswers: this.maxStars, totalQuestions: this.data.data.length }).toPromise();
			});

			let index = -1;
			let continentCert = -1;
			if (!this.userInfo.certs) {
				this.userInfo.certs = [];
			} else {
				let id = this.id;
				index = this.userInfo.certs.map(data => data.id).indexOf(id);
			}

			if (!this.userInfo.continentCerts) {
				this.userInfo.continentCerts = [];
			}

			if (this.data.lastOfContinent) {
				let id = this.id;
				continentCert = this.userInfo.continentCerts.map(data => data.id).indexOf(id);

				if (continentCert > -1) {
					this.userInfo.continentCerts[continentCert] = {
						id: this.id,
						quizName: this.data.continent,
						date: this.datePipe.transform(this.today, 'dd-MM-yyyy'),
						totalCorrectAnswers: this.maxStars,
						totalQuestions: this.data.data.length
					};
				} else {
					this.userInfo.continentCerts.push({
						id: this.id,
						quizName: this.data.continent,
						date: this.datePipe.transform(this.today, 'dd-MM-yyyy'),
						totalCorrectAnswers: this.maxStars,
						totalQuestions: this.data.data.length
					});
				}
			}

			let newCert = {
				id: this.id,
				quizName: this.data.title,
				date: this.datePipe.transform(this.today, 'dd-MM-yyyy'),
				totalCorrectAnswers: this.maxStars,
				totalQuestions: this.data.data.length
			};

			if (index > -1) {
				this.userInfo.certs[index] = newCert;
			} else {
				this.userInfo.certs.push(newCert);
			}

			this.auth.checkToken(async () => {
				await this.auth.updateSelfData({
					collectionID: "userData",
					data: {
						certs: this.userInfo.certs,
						continentCerts: this.userInfo.continentCerts,
						activities: this.userInfo.activities,
						continentsLvl: this.userInfo.continentsLvl,
						activeLevel: this.userInfo.activeLevel
					}
				}).toPromise();
			});
		}
	}

	backToMenu() {
		this._location.back();
	}

	ngOnDestroy(): void {
		if (this.userSubscription) this.userSubscription.unsubscribe();
		destoryQuiz();
		window['angularComponent'] = null;
	}

	previousState() {
		window.history.back();
	}

	playPause() {
		if (this.questionSoundPause) {
			this.answerAudio.play();;
			this.questionSoundPause;
		} else {
			this.answerAudio.pause();
			this.questionSoundPause = true;
		}
	}

	answerClick(answer, event) {
		event.preventDefault();
		let buttons = document.getElementsByClassName('answer-button');
		for (var i = 0; i < buttons.length; i++) {
			buttons[i].setAttribute('disabled', 'true');
		}
		if (answer.correct) {
			answer.class = 'correnct';
			this.maxStars += 1;
			let audio = <HTMLAudioElement>document.getElementById('successAudio');
			if (audio) audio.play();
		} else {
			answer.class = 'incorrenct';
			let audio = <HTMLAudioElement>document.getElementById('failAudio');
			if (audio) audio.play();
		}
		setTimeout(() => {
			if (this.currentQuestionIndex < this.data.data.length - 1) {
				this.currentQuestionIndex += 1;
				this.playAnswerSound();
			} else {
				this.end();
			}
			for (var i = 0; i < buttons.length; i++) {
				buttons[i].removeAttribute('disabled');
				buttons[i].addEventListener('click', function (event) {
					let audio = <HTMLAudioElement>document.getElementById('buttonClick');
					if (audio) audio.play();
				});
				buttons[i].addEventListener('mouseover', function (event) {
					let audio = <HTMLAudioElement>document.getElementById('buttonHover');
					if (audio) audio.play();
				});
			}
		}, 1500);
		return true;
	}

	again() {
		this.data.data.forEach(element => {
			element.answers.forEach(answer => {
				answer.class = false;
			});
		});
		this.currentQuestionIndex = 0;
		this.maxStars = 0;
		this.gameStart();
	}
	nextActivity() {
		if (this.showCongratsAssignment) {
			localStorage.setItem("showAssignmentPopUp", this.data.title);
		}

		let activityLvl = this.id.split('-');
		if (this.data.lastOfContinent && !this.isFromAssigment) {
			if (this.id[0] == "1") {
				this.congratulationsAsia();
			} else if (this.id[0] == "2") {
				this.congratulationsEurope();
			}
			else if (this.id[0] == "3") {
				this.congratulationsAfrica();
			}
			this.frame.continentCompletion.next({
				continent: this.data.continent,
				id: this.id,
				userInfo: this.userInfo,
				date: this.datePipe.transform(this.today, 'dd-MM-yyyy'),
				totalCorrectAnswers: this.maxStars,
				totalQuestions: this.data.data.length
			});
		} else if (this.isFromAssigment) {
			this.router.navigate(['/game/continents']);
		} else {
			let zone = (parseInt(activityLvl[1]) + 1);

			if (parseInt(activityLvl[0]) == 3) {
				this.router.navigate(['/game/africa-' + zone]);
				return;
			}
			if (parseInt(activityLvl[0]) == 4) {
				this.router.navigate(['/game/south-america-' + zone]);
				return;
			}

			if (parseInt(activityLvl[0], 10) === 2) {
				zone = zone + 4;
			}
			if (zone === 8) {
				this.router.navigate(['/game']);
			} else {
				this.router.navigate(['/game/zone' + zone]);
			}
		}
	}

	playIntroductionAudio() {
		let audio = <HTMLAudioElement>document.getElementById('introductionAudio');
		if (audio) audio.play();
		this.userTouch = true;
	}

	instructionLoaded() {
		if (this.loading) {
			this.loading = false;
			let audio = <HTMLAudioElement>document.getElementById('introductionAudio');
			if (audio) audio.play();
		}
	}
	congratulationsAsia() {
		let audio = <HTMLAudioElement>document.getElementById('congratulationsAsia');
		audio.autoplay = false;
		if (audio) audio.play();
	} congratulationsEurope() {
		let audio = <HTMLAudioElement>document.getElementById('congratulationsEurope');
		if (audio) audio.play();
	}
	congratulationsAfrica() {
		let audio = <HTMLAudioElement>document.getElementById('congratulationsAfrica');
		if (audio) audio.play();
	}

	private playAnswerSound() {
		this.answerAudio = document.createElement('audio');
		this.answerAudio.src = this.data.data[this.currentQuestionIndex].audio;
		let audio = this.answerAudio;
		this.answerAudio.addEventListener('canplaythrough', () => {
			if (audio) audio.play();
		}, false);
	}
}
