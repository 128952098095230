import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, Observable } from 'rxjs';
import { AngularFireStorage } from '@angular/fire/storage';
import { PointsMechanismService } from 'src/app/shared/point-mechanism.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ActivityService } from 'src/app/shared/activity.service';

@Component({
    selector: 'app-drag-and-drop',
    templateUrl: './drag-drop.component.html'
})
export class DragDropActivityComponent implements OnInit, OnDestroy, AfterViewInit {
    protected subscription: Subscription;
    userData: any;

    id: string;
    activityId: string;

    gameStatus: string;
    data: any;
    totalScore: number;
    currentUid: number;

    activity: any;
    activities: Observable<any>;

    titles: any = {};
    userInfo: any;

    photoArray;
    stars = [];

    failCount: number = 0;
    collectPoints: number;
    targetData1 = [];
    targetData2 = [];
    targetData3 = [];
    targetData4 = [];
    targetData5 = [];
    targetData6 = [];

    loading: boolean = true;
    ios;
    userTouch = false;

    constructor(
        private activatedRoute: ActivatedRoute,
        public storage: AngularFireStorage,
        private router: Router,
        public activityService: ActivityService,
        private pm: PointsMechanismService) { }

    ngOnInit() {
        this.ios = this.pm.isIOS();
        this.startGame();
    }

    startGame() {
        this.stars = [];

        this.failCount = 0;
        this.targetData1 = [];
        this.targetData2 = [];
        this.targetData3 = [];
        this.targetData4 = [];
        this.targetData5 = [];
        this.targetData6 = [];
        this.loading = true;

        this.subscription = this.activatedRoute.params.subscribe((data) => {
            if (data && data['id'] && data['activityId']) {
                this.id = data['id']
                this.activityId = data['activityId']

                this.activityService.getActivityData({ zoneId: this.id, activityId: this.activityId }).subscribe(aData => {
                    this.data = aData.activityData;
                    this.setSets();
                    this.photoArray = Array.from(this.data.data.letters);
                });

                this.changeGameStatus('introduction');
            }

            this.pm.getUserInfo.subscribe(data => {
                this.userInfo = data;
            });
            this.pm.start(this.id, this.activityId);
        });

        setTimeout(() => {
            this.instructionLoaded();
        }, 3000);
    }

    setbuttonEvents() {
        var buttons = document.querySelectorAll('button');
        for (var i = 0; i < buttons.length; i++) {
            buttons[i].addEventListener('click', function (event) {
                let audio = <HTMLAudioElement>document.getElementById('buttonClick');
                if (audio) {
                    if (audio) audio.play();
                }
            });
            buttons[i].addEventListener('mouseover', function (event) {
                let audio = <HTMLAudioElement>document.getElementById('buttonHover');
                if (audio) {
                    if (audio) audio.play();
                }
            });
        }
        buttons = document.querySelectorAll('.drag-drop-book');
        for (var i = 0; i < buttons.length; i++) {
            buttons[i].addEventListener('mouseover', function (event) {
                let audio = <HTMLAudioElement>document.getElementById('buttonHover');
                if (audio) {
                    if (audio) audio.play();
                }
            });
        }
    }
    changeGameStatus(status: string) {
        if (status == 'leave') {
            let audio = <HTMLAudioElement>document.getElementById('warningAudio');
            if (audio) {
                if (audio) audio.play();
            }
        }
        this.gameStatus = status;
        setTimeout(() => {
            this.setbuttonEvents();
        }, 300);
    }

    ngAfterViewInit() {
        this.setbuttonEvents();
    }

    gameStart() {
        let player = <HTMLAudioElement>document.getElementById('introductionAudio');
        if (player.paused === false) {
            player.pause();
        }
        this.changeGameStatus('start');
        return true;
    }

    gameFinish() {
        this.collectPoints = parseInt(this.data.maxStars) - (this.failCount * 2);
        this.pm.collectPoint(this.collectPoints, this.data.progressType);
    }

    gameError() {
        this.totalScore = this.userInfo.totalScore;
    }

    backToMenu() {
        this.router.navigate(['/game/activity-list', this.id]);
    }

    ngOnDestroy(): void {
        if (this.subscription) this.subscription.unsubscribe();
        // if(window.angularComponent)
        // 	window.angularComponent = null;
    }

    previousState() {
        this.pm.backToActivityList();
    }

    random() {
        return 0.5 - Math.random();
    }

    playAgain() {
        this.startGame();
    }

    setSets() {
        this.data.data.letters.forEach((item, index) => {
            item.set = false;
            if (index + 1 == this.data.data.letters.length) {
                this.changeGameStatus('introduction');
            }
        });
    }

    checkStars() {
        if (this.failCount >= 3) {
            this.gameError();
            this.changeGameStatus('try-again');
            let audio = <HTMLAudioElement>document.getElementById('faileAudio');
            if (audio)
                if (audio) audio.play();
        }
        else if (this.stars.length == this.data.data.letters.length) {
            setTimeout(() => {
                this.gameFinish();
                this.changeGameStatus('complete');
                let audio = <HTMLAudioElement>document.getElementById('completeAudio');
                if (audio)
                    if (audio) audio.play();
            }, 4000);
        }
    }

    nextActivity() {
        this.pm.nextActivity();
    }

    playIntroductionAudio() {
        let audio = <HTMLAudioElement>document.getElementById('introductionAudio');
        if (audio)
            if (audio) audio.play();

        this.userTouch = true;
    }

    drop(event: CdkDragDrop<any[]>) {
        if (event.container.id === "all" && event.previousContainer.id === "all") {
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        } else if (event.container.data == event.item.data.uid) {
            event.item.data.set = true;
            this.stars.push('success');
            let audio: HTMLAudioElement = <HTMLAudioElement>document.getElementById("successDrop");
            if (audio)
                if (audio) audio.play();
            setTimeout(() => {
                let audio1 = <HTMLAudioElement>document.getElementById("audio-" + event.item.data.uid);
                if (audio1)
                    audio1.play();
            }, 200);
        } else {
            this.failCount += 1;
            let audio: HTMLAudioElement = <HTMLAudioElement>document.getElementById("faileDrop");
            if (audio)
                if (audio) audio.play();
        }
        this.checkStars();
        return true;
    }
    drag(event: CdkDragDrop<any[]>) {
        let audio = <HTMLAudioElement>document.getElementById('drag');
        if (audio)
            if (audio) audio.play();
        return true;
    }

    instructionLoaded() {
        if (this.loading) {
            this.loading = false;
            let audio = <HTMLAudioElement>document.getElementById('introductionAudio');
            if (audio)
                if (audio) audio.play();
        }
    }
}
