var PAYPAL_CUSTOM = {
	paypal: {},
	plan: "",
	childID: "",
	canuseTrl: false,
	canuseDscnt: false,
	payPalOnApprove: function (data, actions) { },
	payPalCreateSub: function (data, actions) { },
};

function PayPal() {
	PAYPAL_CUSTOM.paypal = paypal;

	PAYPAL_CUSTOM.createButtons = function () {
		paypal.Buttons({
			style: {
				shape: 'rect',
				color: 'gold',
				layout: 'vertical',
				label: 'subscribe'
			},
			createSubscription: function (data, actions) {
				var planID = "P-8XJ22236PX272203JM5V5RGY";

				if (PAYPAL_CUSTOM.plan == "monthly") {
					if (PAYPAL_CUSTOM.canuseTrl) planID = "P-9PY81474095390345M5V5F7A";
					else planID = "P-8XJ22236PX272203JM5V5RGY";
				} else if (PAYPAL_CUSTOM.plan == "yearly") {
					if (PAYPAL_CUSTOM.canuseTrl) planID = "P-5CB420104S377841CM5V5LMI";
					else planID = "P-2BD78067E9871242RM5V5S3Y";

					if (PAYPAL_CUSTOM.canuseDscnt) planID = "P-10B03672M54497358M5V5PLY";
				}

				return actions.subscription.create({
					plan_id: "P-94834509X1550764PM5ZJWIY",
					custom_id: PAYPAL_CUSTOM.childID
				});
			},
			onApprove: function (data, action) {
				console.log("DATA", data);
				console.log("ACTION", action);

				window.angularComponentRef.payPalOnApprove(data, action);
			}
		}).render('#paypal-button-container-P-9PY81474095390345M5V5F7A');
	};

	return PAYPAL_CUSTOM;
}
