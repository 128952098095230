import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Subscription, Observable, combineLatest } from 'rxjs';
import { AngularFireStorage } from '@angular/fire/storage';
import { map } from 'rxjs/operators';
import { ActivityService } from 'src/app/shared/activity.service';


@Component({
	selector: 'app-songs',
	templateUrl: './songs.component.html'
})
export class SongsComponent implements OnInit, OnDestroy, AfterViewInit {


	protected subscription: Subscription;
	data: any;
	totalScore: number;

	activity: any;
	activities: Observable<any>;

	titles: any = {};
	userInfo: any;
	activeWindow: number = 1;

	currentValue;

	photoArray;
	tabs = [];
	tabCount: number;

	loading: boolean = true;

	constructor(
		private afs: AngularFirestore,
		private auth: ActivityService,
		public storage: AngularFireStorage) { }

	async ngOnInit() {

		let user = JSON.parse(localStorage.getItem('currentUser'));

		var childData = await this.auth.getSelfData({ collectionID: "userData", token: localStorage.getItem("accessToken") }).toPromise();
		this.userInfo = childData.result;


		const adminUserData = JSON.parse(localStorage.getItem('quranera_admin_userData'));

		this.afs.collection('activity-lists').snapshotChanges().pipe(map(changes => {
			return combineLatest(changes.map(a => {
				return this.afs.collection('activity-lists').doc(a.payload.doc.id).collection('activities', ref => { return ref.where('type', '==', 'letter-song') }).get().pipe(map(games => {
					let data = [];
					games.forEach(game => {
						let gameData = game.data();
						let url;
						let lock = false;
						if (adminUserData || (this.userInfo.activities && this.userInfo.activities[a.payload.doc.id] && this.userInfo.activities[a.payload.doc.id][gameData.orderNumber])) {
							url = ['/game/activity-letter-song', a.payload.doc.id, gameData.orderNumber];
						}
						else {
							lock = true;
						}
						let doc: any = a.payload.doc.data();
						data.push({
							countryTitle: doc.countryTitle,
							type: gameData.type,
							title: gameData.title,
							songTitle: gameData.songTitle,
							url,
							lock
						});
					});
					return data
				}))
			}))
		})).subscribe(data => {
			data.subscribe(data => {
				let games = [];
				data.forEach(activities => {
					games = games.concat(activities);
				});
				this.createArray(games);
			});

		});
	}

	private createArray(songs) {
		this.tabCount = Math.ceil(songs.length / 5);

		for (let i = 0; i < this.tabCount; i++) {
			let inarray = [];
			for (let j = (5 * i); j < (5 * i) + 5; j++) {
				if (songs[j]) {
					inarray.push(songs[j]);
				}

			}
			this.tabs.push(inarray)
		}
		this.loading = false;
	}

	ngAfterViewInit(): void {
		var buttons = document.querySelectorAll('button');
		for (var i = 0; i < buttons.length; i++) {
			buttons[i].addEventListener('click', function (event) {
				let audio = <HTMLAudioElement>document.getElementById('buttonClick');
				if (audio) audio.play();
			});
			buttons[i].addEventListener('mouseover', function (event) {
				let audio = <HTMLAudioElement>document.getElementById('buttonHover');
				if (audio) audio.play();
			});
		}
	}



	backToMenu() {
		this.previousState();
	}

	ngOnDestroy(): void {
		if (this.subscription) this.subscription.unsubscribe();
		// if(window.angularComponent)
		// 	window.angularComponent = null;
	}

	previousState() {
		window.history.back();
	}

	previous() {
		if (this.activeWindow > 1)
			this.activeWindow = this.activeWindow - 1
	}
	next() {
		if (this.activeWindow < this.tabCount)
			this.activeWindow = this.activeWindow + 1
	}


}
