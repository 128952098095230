import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from "rxjs";
import { ActivatedRoute } from "@angular/router";
import { PointsMechanismService } from "../../../shared/point-mechanism.service";
import { FrameShowService } from '../../../shared/FrameShowService';
import { ActivityService } from 'src/app/shared/activity.service';

declare function loadTrain(data: any);
declare function destroyLoadTrain();

@Component({
    selector: 'app-load-train',
    templateUrl: './load-train.component.html',
    styleUrls: ['./load-train.component.scss']
})
export class LoadTrainComponent implements OnInit, OnDestroy {
    protected subscription: Subscription;
    userInfo: any;
    loading: boolean;
    data: any;
    id: any;

    constructor(private activatedRoute: ActivatedRoute,
        private ngZone: NgZone,
        private frame: FrameShowService,
        private pm: PointsMechanismService,
        public activityService: ActivityService) {
        window['angularComponentRef'] = {
            component: this,
            zone: this.ngZone,
            gameFinish: (value) => this.gameFinish(value),
            backToMenu: () => this.backToMenu(),
            nextActivity: () => this.nextActivity(),
            getTotalScore: () => this.getTotalScore(),
            loadingFinished: () => this.loadingFinished()
        };
    }

    ngOnInit() {
        this.frame.useV3();
        this.frame.callAfterDone(() => {
            this.subscription = this.activatedRoute.params.subscribe((data) => {
                this.id = data['id'];

                this.pm.getUserInfo.subscribe(userInfo => {
                    this.userInfo = userInfo;
                });

                this.pm.start(this.id, data['activityId']);

                this.activityService.getActivityData({ zoneId: this.id, activityId: data.activityId }).subscribe(aData => {
                    setTimeout(() => {
                        loadTrain(aData.activityData['data']);
                    }, 1000);
                    this.data = aData.activityData;
                });
            });
        });
    }

    gameFinish(value: any) {
        this.pm.collectPoint(value.score, this.data.progressType);
    }

    backToMenu() {
        this.pm.backToActivityList();
    }

    nextActivity() {
        this.pm.nextActivity();
    }

    getTotalScore() {
        return this.userInfo.totalScore;
    }

    loadingFinished() {
        this.loading = false;
    }

    ngOnDestroy(): void {
        destroyLoadTrain();
        window['angularComponentRef'] = null;
        if(this.subscription) this.subscription.unsubscribe();
    }
}
