import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../shared/auth.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-zone-3',
    templateUrl: './zone-3.component.html'
})
export class Zone3Component implements OnInit, OnDestroy, AfterViewInit {
    zones = [
        {
            hover: false,
            id: '1-3-1',
            locked: false,
            active: false
        },
        {
            hover: false,
            id: '1-3-2',
            locked: false,
            active: false
        },
        {
            hover: false,
            id: '1-3-3',
            locked: false,
            active: false
        },
        {
            hover: false,
            id: '1-3-4',
            locked: false,
            active: false
        }
    ];

    plane: any = {
        position: 1
    };

    user: UserData;

    itemsStyle: any;

    activeZone: number = 1;
    openZones: number = 1;
    tick2Quiz: boolean = true;
    adminUserData;
    loading: boolean = true;
    quizLevel: number = 0;
    activeContinent: number;

    constructor(private router: Router, private auth: AuthService) { }

    ngOnInit() {
        this.tick2Quiz = true;
        this.userSubscription = this.auth.onUserUpdate.subscribe(userData => {
            if (userData != null && userData) this.loadChildData(userData);
        }, error => {
            console.log('error', error);
        });
    }
    userSubscription: Subscription;

    ngOnDestroy() {
        this.userSubscription.unsubscribe();
    }

    loadChildData(userData) {
        this.zones = [
            {
                hover: false,
                id: '1-3-1',
                locked: false,
                active: false
            },
            {
                hover: false,
                id: '1-3-2',
                locked: false,
                active: false
            },
            {
                hover: false,
                id: '1-3-3',
                locked: false,
                active: false
            },
            {
                hover: false,
                id: '1-3-4',
                locked: false,
                active: false
            }
        ];

        this.user = userData;
        if (this.user.activities["1-4-1"]) {
            this.tick2Quiz = false;
        }
        this.adminUserData = JSON.parse(localStorage.getItem('quranera_admin_userData'));

        if (!this.user) {
            if (this.adminUserData) {
                userData = this.adminUserData;
                userData.activeLevel = '9-9-9';
            }
        }

        this.plane.position = this.openZones;
        this.activeZone = this.adminUserData ? 99 : parseInt(userData.activeLevel.split('-')[1]);
        this.activeContinent = parseInt(userData.activeLevel.split('-')[0], 10);

        if (this.activeZone > 3) {
            this.openZones = 5;
        } else {
            this.openZones = parseInt(userData.activeLevel.split('-')[2]);
        }

        if (this.user && this.user.quizLevel) {
            this.quizLevel = parseInt(this.user.quizLevel, 10);
        }

        if (this.adminUserData) {
            this.quizLevel = 100;
        }

        if (!this.adminUserData) {
            this.zones.map(zone => {
                if (!this.user.activities[zone.id]) {
                    zone.locked = true;
                }
                if (this.user.activeLevel === zone.id && this.user.activities[zone.id]) {
                    zone.active = true;
                }
            });
        }
    }

    ngAfterViewInit() {
        setTimeout(() => {
            if (this.loading) {
                this.instructionLoaded();
            }
        }, 3000);
    }

    mouseEnter(index: number) {
        this.zones[index].hover = true;
        let audio = <HTMLAudioElement>document.getElementById('continentHover');
        audio.pause();
        audio.currentTime = 0;
        if (audio) audio.play();
    }

    mouseLeave(index: number) {
        this.zones[index].hover = false;
    }

    poiClick(zoneNumber: number) {
        const foundZone = this.zones.find(item => parseInt(item.id.split('-')[2]) == zoneNumber);
        if (foundZone && !foundZone.locked) {
            let audio = <HTMLAudioElement>document.getElementById('continentClick');
            if (audio) audio.play();
            setTimeout(() => {
                this.router.navigate(['/game/activity-list/1-3-' + zoneNumber]);
            }, 1000);
        }
    }

    playBgAudio() {
        let audio = <HTMLAudioElement>document.getElementById('bgAudio');
        if (audio) audio.play();
    }

    playIntroductionAudio() {
        let audio = <HTMLAudioElement>document.getElementById('introductionAudio');
        if (audio) audio.play();
    }

    instructionLoaded() {
        if (this.loading) {
            this.loading = false;
            let audio = <HTMLAudioElement>document.getElementById('introductionAudio');
            if (audio) audio.play();
        }
    }

    haveAccess(activityLevel) {
        if (this.adminUserData) return true;

        if (this.user == undefined) return false;
        if (!this.user['activities'][activityLevel + '-1']
            && !this.user['activities'][activityLevel + '-2']
            && !this.user['activities'][activityLevel + '-3']
            && !this.user['activities'][activityLevel + '-4']
        ) return false;

        for (var a = 1; a <= 4; a++) {
            for (var i = 1; i <= 15; i++) {
                if (this.user["activities"][activityLevel + '-' + a.toString()]) {
                    if (this.user["activities"][activityLevel + '-' + a.toString()][i.toString()]) return true;
                }
            }
        }

        return false;
    }
}
