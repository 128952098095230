import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from "@angular/common/http";

import { AdminRoutingModule } from './admin-routing.module';
import { AdminComponent } from './admin.component';
import { ServiceModule } from './service/service.module';
import { MaterialModule } from './plugin/material.module';

@NgModule({
  declarations: [
    AdminComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    HttpClientModule,

    AdminRoutingModule,
    ServiceModule,
    MaterialModule
  ]
})
export class AdminModule { }
