import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/shared/auth.service';
import { Meta } from '@angular/platform-browser';
import { LoaderService } from 'src/app/shared/loader.service';

@Component({
    selector: 'app-registration-steps',
    templateUrl: './registration-steps.component.html'
})
export class RegistrationStepsComponent implements OnInit {
    step: number = 1;

    showSuccessPopUp: boolean;
    loaderStatus: boolean = false;

    constructor(
        private auth: AuthService,
        private meta: Meta,
        private loaderService: LoaderService
    ) {
        this.meta.updateTag({ name: 'viewport', content: 'width=device-width, initial-scale=1.0' });
    }

    ngOnInit() {
        this.auth.logout();
        this.loaderService.loaderStatus.subscribe(status => {
            this.loaderStatus = status;
        })
    }

    next() {
        this.step++
    }
}
