var gameData = {
    score: 0,
    winSound: null,
};

var game = null;

var dragNDrop = (json) => {
    class Letter extends Phaser.GameObjects.Sprite {
        constructor(config) {
            super(config.scene, config.x, config.y, config.key);

            this.origX = config.x;
            this.origY = config.y;
            this.id = config.id;
            this.letter = config.letter;
            this.setOrigin(0.5, 0.5);
            this.setInteractive();
            this.completed = false;
            config.scene.add.existing(this);

            var textStyle = {
                fontSize: "33px", fontWeight: "bold", align: "center", fontFamily: 'QuranErafont',
                fill: "#000000", stroke: "#ffffff"
            }

            this.letterText = null;
            this.img = null;

            if (config.letter != "") {
                this.letterText = this.scene.add.text(config.x, config.y, this.letter, textStyle);

                this.letterText.setOrigin(0.5, 0.5);
                this.letterText.setSize(this.width, this.height);
            } else {
                this.img = this.scene.add.sprite(config.x, config.y, config.img);
                this.img.setOrigin(0.5, 0.5);
            }

            this.on("pointerdown", function () {
                console.log("letter click");
            });
        }

        update() {
            if (this.letterText != null) {
                this.letterText.x = this.x;
                this.letterText.y = this.y;
            } else if (this.img != null) {
                this.img.x = this.x;
                this.img.y = this.y;
            }
        }

        setPos(x, y) {
            this.x = x;
            this.y = y;
            this.origX = x;
            this.origY = y;

            if (this.letterText != null) {
                this.letterText.x = x;
                this.letterText.y = y;
            } else if (this.img != null) {
                this.img.x = x;
                this.img.y = y;
            }
        }

        setToTop(index) {
            this.setDepth(index);
            if (this.letterText != null)
                this.letterText.setDepth(index);
            else if (this.img != null)
                this.img.setDepth(index);
        }
    }

    class DropZone extends Phaser.GameObjects.Sprite {
        constructor(config) {
            super(config.scene, config.x, config.y, config.key);

            this.id = config.id;
            this.setOrigin(0.5, 0.5);

            config.scene.add.existing(this);

            this.character = config.scene.add.image(this.x, this.y, config.character).setOrigin(0.5, 0.5).setScale(0.8);
            this.dropRect = config.scene.add.image(this.x, this.y + 110, 'dropRect');
            this.dropRect.setOrigin(0.5, 0.5);
        }

        setPos(x, y) {
            this.x = x;
            this.y = y;
            this.character.x = x;
            this.character.y = y;
            this.dropRect.x = x;
            this.dropRect.y = y + 110;
        }
    }

    class Boot extends Phaser.Scene {
        constructor() {
            super({ key: "Boot" });
        }

        init() {
            this.game.scale.transformX = (pageX) => {
                let offsetLeft = 0;
                let parentElement = game.canvas.parentElement;
                while (parentElement) {
                    if (parentElement.offsetLeft) {
                        offsetLeft = parentElement.offsetLeft;
                        break;
                    }
                    parentElement = parentElement.parentElement;
                }
                return (pageX - offsetLeft) * this.game.scale.displayScale.x;
            };
            this.game.scale.transformY = (pageY) => {
                let offsetTop = 0;
                let parentElement = game.canvas.parentElement;
                while (parentElement) {
                    if (parentElement.offsetTop) {
                        offsetTop = parentElement.offsetTop;
                        break;
                    }
                    parentElement = parentElement.parentElement;
                }
                return (pageY - offsetTop) * this.game.scale.displayScale.y;
            };
        }

        preload() {
            var instance = this;
            var progress = this.add.graphics();
            this.load.on('progress', function (value) {
                progress.clear();
            });

            this.load.on('complete', function () {
                progress.destroy();
                this.scene.scene.start('Welcome');
            });

            //this.load.start();

            //load all assets here

            this.load.image("frame", "assets/games/drag_drop/assets/Frame.png")

            /*WELCOME SCENE ASSETS*/
            this.load.image("board", "assets/games/drag_drop/assets/welcome/board.png");
            this.load.image("greyRect", "assets/games/drag_drop/assets/welcome/greyRect.png");
            this.load.image("roundRect", "assets/games/drag_drop/assets/welcome/roundRect.png");
            this.load.image("titleRect", "assets/games/drag_drop/assets/welcome/titlerect.png");
            this.load.image("title", "assets/games/drag_drop/assets/welcome/welcomeText.png");
            this.load.image("leaf", "assets/games/drag_drop/assets/welcome/leaf.png");
            this.load.image("playBtn", "assets/games/drag_drop/assets/welcome/playBtn.png");
            this.load.image("playBtnselected", "assets/games/drag_drop/assets/welcome/playBtnselected.png");
            this.load.image("soundBtn", "assets/games/drag_drop/assets/welcome/soundBtn.png");
            this.load.image("soundBtnHover", "assets/games/drag_drop/assets/welcome/soundBtnHover.png");
            console.log("ASdasdas", json);
            /*GAME SCENE ASSETS*/
            if (json.zone == "ASIA") {
                this.load.image("blueRect", "assets/games/drag_drop/assets/game/boardbg-1.png");
                this.load.image("letterRect", "assets/games/drag_drop/assets/game/letterRect-1.png");
                this.load.image("letterTab", "assets/games/drag_drop/assets/game/letterTab-1.png");
                this.load.image("starBar", "assets/games/drag_drop/assets/game/starBar-1.png");
            } else if ("EUROPE") {
                this.load.image("blueRect", "assets/games/drag_drop/assets/game/boardbg.png");
                this.load.image("letterRect", "assets/games/drag_drop/assets/game/letterRect.png");
                this.load.image("letterTab", "assets/games/drag_drop/assets/game/letterTab.png");
                this.load.image("starBar", "assets/games/drag_drop/assets/game/starBar.png");
            }

            this.load.image("1", "assets/games/drag_drop/assets/game/1.png");
            this.load.image("2", "assets/games/drag_drop/assets/game/2.png");
            this.load.image("3", "assets/games/drag_drop/assets/game/3.png");
            this.load.image("4", "assets/games/drag_drop/assets/game/4.png");
            this.load.image("5", "assets/games/drag_drop/assets/game/5.png");
            this.load.image("6", "assets/games/drag_drop/assets/game/6.png");
            this.load.image("7", "assets/games/drag_drop/assets/game/7.png");
            this.load.image("25", "assets/games/drag_drop/assets/game/25.png");
            this.load.image("28", "assets/games/drag_drop/assets/game/28.png");
            this.load.image("closeBtn", "assets/games/drag_drop/assets/game/closeBtn.png");
            this.load.image("closeBtnHover", "assets/games/drag_drop/assets/game/closeBtnHover.png");
            this.load.image("dropRect", "assets/games/drag_drop/assets/game/dropRect.png");
            this.load.image("incorrectRed", "assets/games/drag_drop/assets/game/incorrectRed.png");
            this.load.image("incorrectYellow", "assets/games/drag_drop/assets/game/incorrectYellow.png");
            this.load.image("stardisable", "assets/games/drag_drop/assets/game/stardisable.png");
            this.load.image("starenable", "assets/games/drag_drop/assets/game/starenable.png");
            this.load.image("frame1", "assets/games/drag_drop/assets/game/f1.png");
            this.load.image("frame2", "assets/games/drag_drop/assets/game/f2.png");
            this.load.image("frame3", "assets/games/drag_drop/assets/game/f3.png");
            this.load.image("frame4", "assets/games/drag_drop/assets/game/f4.png");
            this.load.image("frame5", "assets/games/drag_drop/assets/game/f5.png");
            this.load.image("frame6", "assets/games/drag_drop/assets/game/f6.png");

            /*TRY AGAIN SCENE ASSETS*/
            this.load.image("looseBg", "assets/games/drag_drop/assets/loose/looseBg.png");
            this.load.image("playagainBtn", "assets/games/drag_drop/assets/loose/playagainBtn.png");
            this.load.image("playagainBtnHover", "assets/games/drag_drop/assets/loose/playagainBtnHover.png");
            this.load.image("backtoMenu", "assets/games/drag_drop/assets/loose/backtomenu.png");
            this.load.image("backtoMenuHover", "assets/games/drag_drop/assets/loose/backtomenuHover.png");
            this.load.image("badge", "assets/games/drag_drop/assets/loose/badge.png");
            this.load.image("bigStar", "assets/games/drag_drop/assets/loose/bigstar.png");
            this.load.image("ribbon", "assets/games/drag_drop/assets/loose/ribbon.png");
            this.load.image("totalScoreText", "assets/games/drag_drop/assets/loose/totalScoreText.png");
            this.load.image("tryagainBoard", "assets/games/drag_drop/assets/loose/tryagainBoard.png");

            /*WIN SCREEN ASSETS */
            this.load.image("completionBg", "assets/games/drag_drop/assets/win/completionBg.png");
            this.load.image("completionRibbon", "assets/games/drag_drop/assets/win/ribboncomplete.png");
            this.load.image("trophy", "assets/games/drag_drop/assets/win/trophy.png");
            this.load.image("winstar", "assets/games/drag_drop/assets/win/winstar.png");
            this.load.image("backbtn", "assets/games/drag_drop/assets/win/backbtn.png");
            this.load.image("backbtnHover", "assets/games/drag_drop/assets/win/backbtnHover.png");
            this.load.image("nextBtn", "assets/games/drag_drop/assets/win/nextBtn.png");
            this.load.image("nextBtnHover", "assets/games/drag_drop/assets/win/nextBtnHover.png");

            /* WARNING SCREEN ASSETS*/
            this.load.image("noBtn", "assets/games/drag_drop/assets/warning/noBtn.png");
            this.load.image("noBtnHover", "assets/games/drag_drop/assets/warning/noBtnHover.png");
            this.load.image("yesBtn", "assets/games/drag_drop/assets/warning/yesBtn.png");
            this.load.image("yesBtnHover", "assets/games/drag_drop/assets/warning/yesBtnHover.png");
            this.load.image("sign", "assets/games/drag_drop/assets/warning/sign.png");
            this.load.image("warningBg", "assets/games/drag_drop/assets/warning/warningbg.png");
            this.load.image("warningBoard", "assets/games/drag_drop/assets/warning/warningboard.png");

            this.load.json('jsonData', json);

            /*LOAD SOUNDS*/
            this.load.audio('drag from its spot', 'assets/games/drag_drop/assets/audio/drag from its spot.mp3');
            this.load.audio('When placed wrong', 'assets/games/drag_drop/assets/audio/When placed wrong.mp3');
            this.load.audio('warning page pop up sfx', 'assets/games/drag_drop/assets/audio/warning page pop up sfx.mp3');

            this.load.audio("switchback", 'assets/games/drag_drop/assets/audio/switchback.mp3')
            this.load.audio("warning", 'assets/games/drag_drop/assets/audio/warning.mp3');
            this.load.audio('dragdropInst', 'assets/games/drag_drop/assets/audio/Instruction.mp3');
            this.load.audio('wrongDrop', 'assets/games/drag_drop/assets/audio/wrongdrop.mp3');
            this.load.audio('correctDrop', 'assets/games/drag_drop/assets/audio/correctdrop.mp3');
            this.load.audio('fail', 'assets/games/drag_drop/assets/audio/fail.mp3');

            this.load.audio('buttonclick', 'assets/games/drag_drop/assets/audio/buttonclick.mp3');
            this.load.audio('buttonhover', 'assets/games/drag_drop/assets/audio/buttonhover.mp3');
            this.load.audio('win', 'assets/games/drag_drop/assets/audio/win.mp3');
        }

        create() {
        }

        update() {
        }
    }

    let welcomeInstance = null;
    class Welcome extends Phaser.Scene {
        constructor() {
            super({ key: "Welcome" });

            welcomeInstance = this;
        }

        preload() {
            var jdata = this.cache.json.get('jsonData');
            for (var i = 0; i < jdata.data.length; i++) {
                this.load.image("img_" + i, 'assets/games/drag_drop/assets/game/answerImg/' + jdata.data[i].img);
                this.load.image("character_" + jdata.data[i].referenceImage, 'assets/games/drag_drop/assets/game/' + jdata.data[i].referenceImage + '.png');
                this.load.audio('sound' + jdata.data[i].referenceImage, 'assets/games/drag_drop/assets/audio/voiceover/' + jdata.data[i].referenceImage + '.mp3');
            }
        }

        create() {
            gameData = {
                score: 0,
                winSound: null,
            };

            this.buttonClick = this.sound.add('buttonclick');
            this.buttonHover = this.sound.add('buttonhover');

            var instructionSound = this.sound.add('dragdropInst');

            instructionSound.play();

            var greyRect = this.add.sprite(this.game.config.width / 2, this.game.config.height / 2, 'greyRect');
            greyRect.setOrigin(0.5, 0.5);

            var leaf = this.add.sprite(this.game.config.width / 2 - 380, this.game.config.height / 2 + 130, 'leaf');
            leaf.setOrigin(0.5, 0.5);

            var leaf1 = this.add.sprite(this.game.config.width / 2 + 380, this.game.config.height / 2 - 130, 'leaf');
            leaf1.setOrigin(0.5, 0.5);
            leaf1.setFlipX(-1);

            var roundRect = this.add.sprite(this.game.config.width / 2, this.game.config.height / 2, 'roundRect');
            roundRect.setOrigin(0.5, 0.5);

            var titleRect = this.add.sprite(this.game.config.width / 2, this.game.config.height / 2 - 200, 'titleRect');
            titleRect.setOrigin(0.5, 0.5);

            //var title = this.add.text(this.game.config.width/2,this.game.config.height/2-200, "Drag and Drop", { fontFamily: 'kidsrock', fontSize: 28, color: '#ffde00', align:'center', strokeThickness:2});
            //title.setOrigin(0.5,0.5);

            var title = this.add.sprite(this.game.config.width / 2, this.game.config.height / 2 - 200, 'title');
            title.setOrigin(0.5, 0.5);
            title.setScale(0.8);

            var playBtn = this.add.sprite(this.game.config.width / 2, this.game.config.height / 2 + 160, 'playBtn').setInteractive();
            playBtn.setOrigin(0.5, 0.5);

            var tween = this.tweens.add({
                targets: playBtn,
                scale: 0.8,
                duration: 100,
                ease: 'Power2',
                yoyo: true,
                paused: true
            });

            var tween1 = this.tweens.add({
                targets: playBtn,
                scale: 1.1,
                duration: 100,
                ease: 'Power2',
                paused: true,
            });

            playBtn.on('pointerdown', function () {
                instructionSound.stop();

                this.scene.scene.start("Game");
                this.scene.buttonClick.play();
            });

            playBtn.on('pointerover', function () {
                playBtn.setTexture("playBtnselected");
                this.scene.buttonHover.play();
            });

            playBtn.on('pointerout', function () {
                playBtn.setTexture("playBtn");
            });

            var paragraph = this.add.text(this.game.config.width / 2, this.game.config.height / 2, "Drag the letters to match the\n\n characters.", { fontFamily: 'Kids Rock DEMO', fontSize: 32, color: '#312f2f', align: 'center', strokeThickness: 2 });
            paragraph.setOrigin(0.5, 0.5);

            var soundBtn = this.add.sprite(this.game.config.width / 2 + 230, this.game.config.height / 2 - 200, 'soundBtn').setInteractive();
            soundBtn.setOrigin(0.5, 0.5);

            soundBtn.on("pointerover", function () {
                soundBtn.setTexture('soundBtnHover');
                this.scene.buttonHover.play();
            })

            soundBtn.on("pointerout", function () {
                soundBtn.setTexture('soundBtn');
            })

            soundBtn.on('pointerdown', function () {
                this.scene.buttonClick.play();
                instructionSound.play();
            })
        }

        update(time, delta) {
        }
    }

    let gameInstance = null;
    class Game extends Phaser.Scene {
        constructor() {
            super({ key: "Game" });
            gameInstance = this;
        }
        preload() {
        }

        create() {
            this.incorrect = 0;
            this.correct = 0;
            this.voiceOver = [];

            var jdata = this.cache.json.get('jsonData');
            var btnclickSound = this.sound.add('buttonclick');
            var btnhoverSound = this.sound.add('buttonhover');
            var btnInst = this.sound.add('dragdropInst');

            for (var i = 0; i < 6; i++) {
                var sound = this.sound.add('sound' + (jdata.data[i].referenceImage));
                this.voiceOver.push(sound);
            }

            this.switchBackSound = this.sound.add('switchback');
            this.wrongDropSound = this.sound.add('wrongDrop');
            this.correctDropSound = this.sound.add('correctDrop');

            var blueRect = this.add.sprite(this.game.config.width / 2, this.game.config.height / 2, 'blueRect');
            blueRect.setOrigin(0.5, 0.5);

            this.createStarBar();

            //var board = this.add.sprite(this.game.config.width/2, this.game.config.height/2, 'board');
            //board.setOrigin(0.5, 0.5);

            var letterTab = this.add.sprite(this.game.config.width / 2 - 350, this.game.config.height / 2 + 25, 'letterTab');
            letterTab.setOrigin(0.5, 0.5);

            this.createDropZones();
            this.createLetters();
            this.createIncorectElements();

            var closeBtn = this.add.sprite(this.game.config.width - 50, 40, 'closeBtn').setInteractive();
            closeBtn.setOrigin(0.5, 0.5);

            closeBtn.on("pointerdown", function () {
                btnclickSound.play();
                this.scene.scene.launch('Warning');
                this.scene.scene.pause('Game');

                this.scene.sound.add('warning page pop up sfx').play();
            })

            closeBtn.on("pointerover", function () {
                btnhoverSound.play();
                closeBtn.setTexture('closeBtnHover');
            })

            closeBtn.on("pointerout", function () {
                closeBtn.setTexture('closeBtn');
            })

            this.createWarningMsg();
        }

        update(time, delta) {
            for (var i = 0; i < this.letters.length; i++) {
                this.letters[i].update();
            }
        }

        createLetters() {
            this.letters = [];
            var defaultPos = [];
            var jdata = this.cache.json.get('jsonData');
            for (var i = 0; i < jdata.data.length; i++) {
                var x = 145;
                var y = 130 + (78 * i);

                var letter = new Letter({
                    scene: this,
                    x: x,
                    y: y,
                    key: 'letterRect',
                    id: i + 1,
                    letter: jdata.data[i].letter,
                    img: "img_" + i
                });

                this.input.setDraggable(letter, true);

                defaultPos.push({ x: x, y: y });
                this.letters.push(letter);
            }

            this.input.on('dragstart', function (pointer, gameObject, dragX, dragY) {
                gameObject.setToTop(1);
                this.scene.sound.add('drag from its spot').play();
            });

            this.input.on('drag', function (pointer, gameObject, dragX, dragY) {
                gameObject.x = dragX;
                gameObject.y = dragY;
            });

            this.input.on('dragend', function (pointer, gameObject) {
                if (!this.scene.checkOveralpWithZones(gameObject)) {
                    gameObject.x = gameObject.origX;
                    gameObject.y = gameObject.origY;
                    this.scene.wrongDropSound.play();
                }

                gameObject.setToTop(0);
            });

            defaultPos = this.shuffle(defaultPos);
            for (var i = 0; i < defaultPos.length; i++) {
                var letter = this.letters[i];
                var pos = defaultPos[i];
                letter.setPos(pos.x, pos.y);
                this.letters[i] = letter;
            }
        }

        createDropZones() {
            this.zones = [];
            var defaultPos = [];
            var gap = 0;
            var jdata = this.cache.json.get('jsonData');
            for (var i = 0; i < 6; i++) {
                var y = 150;

                if (i >= 3) {
                    y = 400;
                }

                if (i == 3)
                    gap = 0;

                var x = 370 + (250 * gap);
                var zone = new DropZone({
                    scene: this,
                    x: x,
                    y: y,
                    key: "frame" + (i + 1),
                    id: i + 1,
                    character: 'character_' + jdata.data[i].referenceImage
                });

                this.zones.push(zone);
                defaultPos.push({ x: x, y: y });
                gap++;
            }

            defaultPos = this.shuffle(defaultPos);
            for (var i = 0; i < defaultPos.length; i++) {
                var zone = this.zones[i];
                var pos = defaultPos[i];
                zone.setPos(pos.x, pos.y);
                this.zones[i] = zone;
            }
        }

        checkOveralp(x1, y1, x2, y2, w1, w2, h1, h2) {
            var topleftX1 = x1 - (w1 / 2)
            var topleftY1 = y1 - (h1 / 2);

            var bottomRightX1 = x1 + (w1 / 2);
            var bottomRightY1 = y1 + (h1 / 2);

            var topleftX2 = x2 - (w2 / 2)
            var topleftY2 = y2 - (h2 / 2);

            var bottomRightX2 = x2 + (w2 / 2);
            var bottomRightY2 = y2 + (h2 / 2);

            var rect1 = new Phaser.Geom.Rectangle(topleftX1, topleftY1, w1, h1);
            var rect2 = new Phaser.Geom.Rectangle(topleftX2, topleftY2, w2, h2);

            if (Phaser.Geom.Intersects.RectangleToRectangle(rect1, rect2)) {
                return true;
            }

            return false;
        }

        checkOveralpWithZones(gameObject) {
            for (var i = 0; i < this.zones.length; i++) {
                var zone = this.zones[i];
                if (this.checkOveralp(gameObject.x, gameObject.y, zone.dropRect.x, zone.dropRect.y, gameObject.width, zone.dropRect.width, gameObject.height, zone.dropRect.height)) {
                    if ((gameObject.id == zone.id)) {
                        gameData.score += 2;
                        this.correctDropSound.play();
                        this.voiceOver[gameObject.id - 1].play();
                        gameObject.x = zone.dropRect.x;
                        gameObject.y = zone.dropRect.y;
                        this.input.setDraggable(gameObject, false);

                        this.correct++;
                        this.stars[this.correct - 1].setTexture('starenable');

                        if (this.correct >= 6) {
                            this.time.addEvent({
                                delay: 3000,
                                callback: () => {
                                    this.scene.start("Win");
                                },
                                loop: false
                            });
                        }
                    } else {
                        if (gameData.score > 0)
                            gameData.score -= 2;

                        this.sound.add('When placed wrong').play();

                        var delayEvent = new Phaser.Time.TimerEvent({
                            delay: 1000, callback: () => {
                                gameObject.x = gameObject.origX;
                                gameObject.y = gameObject.origY;
                                this.switchBackSound.play();
                                this.incorrect++;
                                this.incorrectIcons[this.incorrect - 1].setTexture('incorrectRed');

                                if (this.incorrect >= 3)
                                    this.scene.start("Loose");
                            }, loop: false
                        });

                        this.time.addEvent(delayEvent);
                    }

                    return true;
                }
            }

            return false;
        }

        shuffle(arra1) {
            var ctr = arra1.length, temp, index;

            // While there are elements in the array
            while (ctr > 0) {
                // Pick a random index
                index = Math.floor(Math.random() * ctr);
                // Decrease ctr by 1
                ctr--;
                // And swap the last element with it
                temp = arra1[ctr];
                arra1[ctr] = arra1[index];
                arra1[index] = temp;
            }
            return arra1;
        }

        createIncorectElements() {
            this.incorrectIcons = [];
            for (var i = 0; i < 3; i++) {
                var incorrectIcon = this.add.image(80 + (60 * i), 40, 'incorrectYellow');
                incorrectIcon.setOrigin(0.5, 0.5);
                this.incorrectIcons.push(incorrectIcon);
            }
        }

        createStarBar() {
            this.stars = [];
            var bar = this.add.image(this.game.config.width / 2 + 30, 30, 'starBar');
            bar.setOrigin(0.5, 0.5);
            for (var i = 0; i < 6; i++) {
                var star = this.add.image(370 + (60 * i), 35, 'stardisable');
                star.setOrigin(0.5, 0.5);
                this.stars.push(star);
            }
        }

        createWarningMsg() {
        }
    }

    class Loose extends Phaser.Scene {
        constructor() {
            super({ key: "Loose" });
        }

        preload() {
        }

        create() {
            var failsound = this.sound.add('fail');
            failsound.play();

            var btnclickSound = this.sound.add('buttonclick');
            var btnhoverSound = this.sound.add('buttonhover');

            //var board = this.add.sprite(this.game.config.width/2, this.game.config.height/2, 'board');
            //board.setOrigin(0.5, 0.5);

            var blueRect = this.add.sprite(this.game.config.width / 2, this.game.config.height / 2, 'looseBg');
            blueRect.setOrigin(0.5, 0.5);

            var tryAgainBoard = this.add.sprite(this.game.config.width / 2, this.game.config.height / 2, 'tryagainBoard');
            tryAgainBoard.setOrigin(0.5, 0.5);

            var ribbon = this.add.sprite(this.game.config.width / 2, this.game.config.height / 2 - 100, 'ribbon');
            ribbon.setOrigin(0.5, 0.5);

            var bigStar = this.add.sprite(this.game.config.width / 2 - 220, this.game.config.height / 2 + 50, 'bigStar');
            bigStar.setOrigin(0.5, 0.5);
            bigStar.setScale(0);

            var scoreLabel = this.add.sprite(this.game.config.width / 2 + 30, this.game.config.height / 2 - 10, 'totalScoreText');
            scoreLabel.setOrigin(0.5, 0.5);

            var badge = this.add.sprite(this.game.config.width / 2 - 15, this.game.config.height / 2 + 100, 'badge');
            badge.setOrigin(0.5, 0.5);

            var backtomenuBtn = this.add.sprite(this.game.config.width / 2 - 35, this.game.config.height / 2 + 220, 'backtoMenu').setInteractive();
            backtomenuBtn.setOrigin(0.5, 0.5);

            backtomenuBtn.on("pointerdown", function () {
                btnclickSound.play();
                window.angularComponentRef.backToMenu();
                //this.scene.scene.start('Welcome');
            })

            backtomenuBtn.on("pointerover", function () {
                btnhoverSound.play();
                backtomenuBtn.setTexture('backtoMenuHover');
            })

            backtomenuBtn.on("pointerout", function () {
                backtomenuBtn.setTexture('backtoMenu');
            })

            var playagainBtn = this.add.sprite(this.game.config.width / 2 + 120, this.game.config.height / 2 + 220, 'playagainBtn').setInteractive();
            playagainBtn.setOrigin(0.5, 0.5);

            playagainBtn.on("pointerdown", function () {
                btnclickSound.play();
                this.scene.scene.start('Welcome');
            })

            playagainBtn.on("pointerover", function () {
                btnhoverSound.play();
                playagainBtn.setTexture('playagainBtnHover');
            })

            playagainBtn.on("pointerout", function () {
                playagainBtn.setTexture('playagainBtn');
            })

            var textStyle = {
                fontSize: "28px", fontFamily: 'Kids Rock DEMO', align: "center",
                fill: "#fed210", stroke: "#91301a", strokeThickness: 7
            }

            this.scoreText = this.add.text(this.game.config.width / 2 + 170, this.game.config.height / 2 - 5, String(window.angularComponentRef.component.userInfo.totalScore), textStyle);
            this.scoreText.setOrigin(0, 0.5);
            this.scoreText.setScale(0);

            var textStyle1 = {
                fontSize: "22px", fontFamily: 'Kids Rock DEMO', align: "center",
                fill: "#ffffff", stroke: "#ffffff"
            }

            this.badgeText = this.add.text(this.game.config.width / 2, this.game.config.height / 2 + 90, "+0", textStyle1);
            this.badgeText.setOrigin(0.5, 0.5);

            var tween = this.tweens.add({
                targets: bigStar,
                scale: 1,
                ease: 'Power1',
                duration: 500,
            });

            var tween2 = this.tweens.add({
                targets: this.scoreText,
                scale: 1,
                ease: 'Power1',
                duration: 200,
            });
        }

        update(time, delta) {
        }
    }
    class Win extends Phaser.Scene {
        constructor() {
            super({ key: "Win" });
        }

        init() {
            window.angularComponentRef.gameFinish({ score: gameData.score })
        }

        preload() {
        }

        create() {
            var winSound = this.sound.add('win');
            winSound.play();

            var btnclickSound = this.sound.add('buttonclick');
            var btnhoverSound = this.sound.add('buttonhover');

            //var board = this.add.sprite(this.game.config.width/2, this.game.config.height/2, 'board');
            //board.setOrigin(0.5, 0.5);

            var greenRect = this.add.sprite(this.game.config.width / 2, this.game.config.height / 2, 'completionBg');
            greenRect.setOrigin(0.5, 0.5);

            var tryAgainBoard = this.add.sprite(this.game.config.width / 2, this.game.config.height / 2, 'tryagainBoard');
            tryAgainBoard.setOrigin(0.5, 0.5);

            var ribbon = this.add.sprite(this.game.config.width / 2, this.game.config.height / 2 - 110, 'completionRibbon');
            ribbon.setOrigin(0.5, 0.5);

            var stars = this.add.sprite(this.game.config.width / 2, this.game.config.height / 2 - 250, 'winstar');
            stars.setOrigin(0.5, 0.5);

            var trophy = this.add.sprite(this.game.config.width / 2 - 220, this.game.config.height / 2 + 50, 'trophy');
            trophy.setOrigin(0.5, 0.5);
            trophy.setScale(0);

            var scoreLabel = this.add.sprite(this.game.config.width / 2 + 30, this.game.config.height / 2 - 10, 'totalScoreText');
            scoreLabel.setOrigin(0.5, 0.5);

            var badge = this.add.sprite(this.game.config.width / 2 - 15, this.game.config.height / 2 + 100, 'badge');
            badge.setOrigin(0.5, 0.5);

            var backBtn = this.add.sprite(this.game.config.width / 2 - 35, this.game.config.height / 2 + 220, 'backbtn').setInteractive();
            backBtn.setOrigin(0.5, 0.5);

            backBtn.on("pointerdown", function () {
                btnclickSound.play();
                this.scene.scene.start('Welcome');
                window.angularComponentRef.backToMenu();
            })

            backBtn.on("pointerover", function () {
                btnhoverSound.play();
                backBtn.setTexture('backbtnHover');
            })

            backBtn.on("pointerout", function () {
                backBtn.setTexture('backbtn');
            })

            var playagainBtn = this.add.sprite(this.game.config.width / 2 + 120, this.game.config.height / 2 + 220, 'playagainBtn').setInteractive();
            playagainBtn.setOrigin(0.5, 0.5);

            playagainBtn.on("pointerdown", function () {
                btnclickSound.play();
                this.scene.scene.start('Welcome');
            });

            playagainBtn.on("pointerover", function () {
                btnhoverSound.play();
                playagainBtn.setTexture('playagainBtnHover');
            });

            playagainBtn.on("pointerout", function () {
                playagainBtn.setTexture('playagainBtn');
            });

            var nextBtn = this.add.sprite(this.game.config.width / 2 + 280, this.game.config.height / 2 + 220, 'nextBtn').setInteractive();
            nextBtn.setOrigin(0.5, 0.5);

            nextBtn.on("pointerdown", function () {
                btnclickSound.play();
                window.angularComponentRef.nextActivity();
            })

            nextBtn.on("pointerover", function () {
                btnhoverSound.play();
                nextBtn.setTexture('nextBtnHover');
            })

            nextBtn.on("pointerout", function () {
                nextBtn.setTexture('nextBtn');
            })

            var textStyle = {
                fontSize: "28px", fontFamily: "Kids Rock DEMO", align: "center",
                fill: "#fed210", stroke: "#91301a", strokeThickness: 7
            }

            this.scoreText = this.add.text(this.game.config.width / 2 + 170, this.game.config.height / 2 - 5,
                String(window.angularComponentRef.component.userInfo.totalScore), textStyle);
            this.scoreText.setOrigin(0, 0.5);
            this.scoreText.setScale(0);

            var textStyle1 = {
                fontSize: "22px", fontFamily: "Kids Rock DEMO", align: "center",
                fill: "#ffffff", stroke: "#ffffff"
            }

            this.badgeText = this.add.text(this.game.config.width / 2, this.game.config.height / 2 + 90, gameData.score, textStyle1);
            this.badgeText.setOrigin(0.5, 0.5);

            var tween = this.tweens.add({
                targets: trophy,
                scale: 1,
                ease: 'Power1',
                duration: 500,
            });

            var tween2 = this.tweens.add({
                targets: this.scoreText,
                scale: 1,
                ease: 'Power1',
                duration: 200,
            });
        }

        update(time, delta) {
        }
    }
    class Warning extends Phaser.Scene {
        constructor() {
            super({ key: "Warning" });
            gameInstance = this;
        }

        preload() {
        }

        create() {
            var btnclickSound = this.sound.add('buttonclick');
            var btnhoverSound = this.sound.add('buttonhover');

            var bg = this.add.sprite(this.game.config.width / 2, this.game.config.height / 2, 'warningBg');
            bg.setOrigin(0.5);

            var leaf = this.add.sprite(this.game.config.width / 2 - 380, this.game.config.height / 2 + 130, 'leaf');
            leaf.setOrigin(0.5, 0.5);

            var leaf1 = this.add.sprite(this.game.config.width / 2 + 380, this.game.config.height / 2 - 130, 'leaf');
            leaf1.setOrigin(0.5, 0.5);
            leaf1.setFlipX(-1);

            var board = this.add.sprite(this.game.config.width / 2, this.game.config.height / 2, 'warningBoard');
            board.setOrigin(0.5);

            var sign = this.add.sprite(this.game.config.width / 2, this.game.config.height / 2 - 130, 'sign');
            sign.setOrigin(0.5);

            var yesBtn = this.add.sprite(this.game.config.width / 2 - 100, this.game.config.height / 2 + 150, 'yesBtn').setInteractive();
            yesBtn.setOrigin(0.5);

            yesBtn.on("pointerdown", function () {
                btnclickSound.play();
                window.angularComponentRef.backToMenu();
            });

            yesBtn.on("pointerover", function () {
                btnhoverSound.play();
                yesBtn.setTexture('yesBtnHover');
            });

            yesBtn.on("pointerout", function () {
                yesBtn.setTexture('yesBtn');
            });

            var noBtn = this.add.sprite(this.game.config.width / 2 + 100, this.game.config.height / 2 + 150, 'noBtn').setInteractive();
            noBtn.setOrigin(0.5);

            noBtn.on("pointerdown", function () {
                btnclickSound.play();
                this.scene.scene.stop('Warning');
                this.scene.scene.resume('Game');
            });

            noBtn.on("pointerover", function () {
                btnhoverSound.play();
                noBtn.setTexture('noBtnHover');
            });

            noBtn.on("pointerout", function () {
                noBtn.setTexture('noBtn');
            });

            var paragraph = this.add.text(this.game.config.width / 2, this.game.config.height / 2, "Your progress in this activity will not be saved.\nAre you sure you want to exit?", { fontFamily: 'Kids Rock DEMO', fontSize: 26, color: '#43425d', stroke: '#ffffff', align: 'center', strokeThickness: 2 });
            paragraph.setOrigin(0.5, 0.5);
        }
    }

    class FitScaleManager {
        constructor(game) {
            this.doResize = () => {
                let scale = this.calculateScale();
                let newCanvasWidth = this.phaserScaleManager.width * scale;
                let newCanvasHeight = this.phaserScaleManager.height * scale;
                this.canvasStyle.width = newCanvasWidth + 'px';
                this.canvasStyle.height = newCanvasHeight + 'px';
                this.canvasStyle.marginLeft = (window.innerWidth - newCanvasWidth) / 2 + 'px';
                this.canvasStyle.marginTop = (window.innerHeight - newCanvasHeight) / 2 + 'px';
            };
            this.game = game;
            this.canvasStyle = this.game.canvas.style;
            this.phaserScaleManager = this.game.scale;
        }
        static detectBestScaleMode() {
            let iOS = /iPad|iPhone|iPod/.test(navigator.platform || "");
            let isAndroid = window.navigator.userAgent.toLowerCase().indexOf("android") > -1;
            return iOS || isAndroid ? Phaser.Scale.FIT : Phaser.Scale.NONE;
        }
        ;
        /**
         * Just call this method once in Boot scene's init() method
         */
        setup() {
            this.phaserScaleManager.addListener(Phaser.Scale.Events.RESIZE, this.onResize, this);
            this.overridePhaserTransformMethods();
            this.onResize();
        }
        calculateScale() {
            if (game.scale.scaleMode == Phaser.Scale.NONE)
                return 1;
            return Math.min(window.innerWidth / this.phaserScaleManager.width, window.innerHeight / this.phaserScaleManager.height);
        }
        overridePhaserTransformMethods() {
            this.game.scale.transformX = (pageX) => {
                return (pageX - parseInt(this.canvasStyle.marginLeft.split("px")[0])) / this.calculateScale();
            };
            this.game.scale.transformY = (pageY) => {
                return (pageY - parseInt(this.canvasStyle.marginTop.split("px")[0])) / this.calculateScale();
            };
        }
        onResize() {
            setTimeout(this.doResize, FitScaleManager.RESIZE_DELAY);
        }
    }
    FitScaleManager.RESIZE_DELAY = 500;

    var config = {
        type: Phaser.AUTO,
        width: 980,
        height: 600,

        parent: 'game-holder',

        scale: {
            mode: FitScaleManager.detectBestScaleMode(),
            autoCenter: Phaser.Scale.CENTER_BOTH
        },

        physics: {
            default: 'arcade',
            arcade: {
                gravity: { y: 200 }
            }
        },
        dom: {
            createContainer: false
        },
        audio: {
            disableWebAudio: true
        },
        render: {
            imageSmoothingEnabled: false,
            transparent: true,
        },

        scene: [Boot, Welcome, Game, Loose, Win, Warning]
    };
    game = new Phaser.Game(config);
};

var destroyDragNDrop = () => {
    if (game) {
        game.destroy();
        game = null;
    }
}
