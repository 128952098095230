import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { PointsMechanismService } from '../../../shared/point-mechanism.service';
import { FrameShowService } from '../../../shared/FrameShowService';
import { ActivityService } from 'src/app/shared/activity.service';


declare function soundhive(data: any);

declare function destroySoundHive();

@Component({
    selector: 'app-sound-hive',
    templateUrl: './sound-hive.component.html',
    styleUrls: ['./sound-hive.component.scss']
})
export class SoundHiveComponent implements OnInit, OnDestroy {
    protected subscription: Subscription;
    userInfo: any;
    loading: boolean;
    data: any;
    id: any;

    secondaryImages: any[];
    lConfig: {};

    constructor(private activatedRoute: ActivatedRoute,
        private _ngZone: NgZone,
        private frame: FrameShowService,
        private pm: PointsMechanismService,
        public activityService: ActivityService) {
        window['angularComponentRef'] = {
            component: this,
            zone: this._ngZone,
            gameFinish: (value) => this.gameFinish(value),
            backToMenu: () => this.backToMenu(),
            nextActivity: () => this.nextActivity(),
            getTotalScore: () => this.getTotalScore(),
            loadingFinished: () => this.loadingFinished()
        };
    }

    ngOnInit() {

        this.secondaryImages = [];
        this.lConfig = {};

        this.frame.useV3();
        this.frame.callAfterDone(() => {
            this.subscription = this.activatedRoute.params.subscribe((data) => {
                this.id = data['id'];

                this.pm.getUserInfo.subscribe(userInfo => {
                    this.userInfo = userInfo;
                });

                this.pm.start(this.id, data['activityId']);

                this.activityService.getActivityData({ zoneId: this.id, activityId: data.activityId }).subscribe(aData => {
                    var activity = aData.activityData;
                    setTimeout(() => {
                        for (var i = 0; i < activity['data']['rounds'].length; i++) {
                            activity['data']['rounds'][i]['audioQuestionEnable'] = true;
                            activity['data']['rounds'][i]['correctLetterName'] = activity['data']['rounds'][i]['correctLetterName'].toLowerCase();

                            for (var l = 0; l < activity['data']['rounds'][i]['letters'].length; l++) {
                                this.secondaryImages.push(activity['data']['rounds'][i]['letters'][l].toLowerCase());
                                activity['data']['rounds'][i]['letters'][l] = activity['data']['rounds'][i]['letters'][l].toLowerCase();
                            }
                        }

                        for (var letter in activity['data']['lettersConfig']) {
                            this.lConfig[letter.toLowerCase()] = activity['data']['lettersConfig'][letter];
                        }

                        const unique = [...new Set(this.secondaryImages.map(item => item))];

                        activity['data']['secondaryImages'] = unique;
                        activity['data']['lettersConfig'] = this.lConfig;

                        soundhive(activity['data']);
                    }, 500);
                    this.data = activity;
                });
            });
        });
    }

    gameFinish(value: any) {
        this.pm.collectPoint(value.score, this.data.progressType);
    }

    backToMenu() {
        this.pm.backToActivityList();
    }

    nextActivity() {
        this.pm.nextActivity();
    }

    getTotalScore() {
        return this.userInfo.totalScore;
    }

    loadingFinished() {
        this.loading = false;
    }

    ngOnDestroy(): void {
        destroySoundHive();
        window['angularComponentRef'] = null;
        if(this.subscription) this.subscription.unsubscribe();
    }
}
