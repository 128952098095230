import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Subscription, Observable, combineLatest } from 'rxjs';
import { AngularFireStorage } from '@angular/fire/storage';
import { map } from 'rxjs/operators';
import { ActivityService } from 'src/app/shared/activity.service';

@Component({
    selector: 'app-books',
    templateUrl: './books.component.html',
    styleUrls: ['./books.component.scss']
})
export class BooksComponent implements OnInit, OnDestroy, AfterViewInit {
    protected subscription: Subscription;

    data: any;
    totalScore: number;

    activity: any;
    activities: Observable<any>;

    titles: any = {};
    userInfo: any;
    activeWindow: number = 1;

    styleLeftPx = 11;

    currentValue;

    photoArray;
    tabs = [];
    tabCount: number;

    loading: boolean = true;

    constructor(
        private afs: AngularFirestore,
        private auth: ActivityService,
        public storage: AngularFireStorage) { }

    async ngOnInit() {
        let user = JSON.parse(localStorage.getItem('currentUser'));

        var childData = await this.auth.getSelfData({ collectionID: "userData", token: localStorage.getItem("accessToken") }).toPromise();
        this.userInfo = childData.result;


        const adminUserData = JSON.parse(localStorage.getItem('quranera_admin_userData'));

        this.afs.collection('activity-lists').snapshotChanges().pipe(map(changes => {
            return combineLatest(changes.map(a => {
                return this.afs.collection('activity-lists').doc(a.payload.doc.id).collection('activities', ref => { return ref.where('type', '==', 'reading') }).get().pipe(map(games => {
                    let data = [];
                    games.forEach(game => {
                        let gameData = game.data();
                        let url;
                        let lock = false;
                        if (adminUserData || (this.userInfo.activities && this.userInfo.activities[a.payload.doc.id] && this.userInfo.activities[a.payload.doc.id][gameData.orderNumber])) {
                            url = ['/game/activity-reading', a.payload.doc.id, gameData.orderNumber];
                        }
                        else {
                            lock = true;
                        }
                        let doc: any = a.payload.doc.data();
                        data.push({
                            countryTitle: doc.countryTitle,
                            type: gameData.type,
                            title: gameData.title,
                            url,
                            lock
                        });
                    });
                    return data
                }))
            }))
        })).subscribe(data => {
            data.subscribe(data => {
                let games = [];
                data.forEach(activities => {
                    games = games.concat(activities);
                });
                this.createArray(games);
            });
        });

    }
    private createArray(books) {
        this.tabCount = Math.ceil(books.length / 10);

        for (let i = 0; i < this.tabCount; i++) {
            let inarray = [];
            for (let j = (10 * i); j < (10 * i) + 10; j++) {
                if (books[j]) {
                    inarray.push(books[j]);
                }
            }
            this.tabs.push(inarray)
        }

        this.styleLeftPx = this.tabs.length * 11;
        this.loading = false;

        setTimeout(() => {
            this.setbuttonEvents();
        }, 300);
    }

    backToMenu() {
        this.previousState();
    }

    ngOnDestroy(): void {
        if (this.subscription) this.subscription.unsubscribe();
        // if(window.angularComponent)
        // 	window.angularComponent = null;
    }

    ngAfterViewInit(): void {
        this.setbuttonEvents();
    }

    setbuttonEvents() {
        let buttons = document.querySelectorAll('button');
        for (var i = 0; i < buttons.length; i++) {
            buttons[i].addEventListener('click', function (event) {
                let audio = <HTMLAudioElement>document.getElementById('buttonClick');
                if (audio) audio.play();
            });
            buttons[i].addEventListener('mouseover', function (event) {
                let audio = <HTMLAudioElement>document.getElementById('buttonHover');
                if (audio) audio.play();
            });
        }

        buttons = document.querySelectorAll('.item');
        for (var i = 0; i < buttons.length; i++) {
            buttons[i].addEventListener('click', function (event) {
                let audio = <HTMLAudioElement>document.getElementById('buttonClick');
                if (audio) audio.play();
            });
            buttons[i].addEventListener('mouseover', function (event) {
                let audio = <HTMLAudioElement>document.getElementById('buttonHover');
                if (audio) audio.play();
            });
        }
    }

    previousState() {
        window.history.back();
    }

    previous() {
        if (this.activeWindow > 1)
            this.activeWindow = this.activeWindow - 1

        setTimeout(() => {
            this.setbuttonEvents();
        }, 300);
    }
    next() {
        if (this.activeWindow < this.tabCount)
            this.activeWindow = this.activeWindow + 1

        setTimeout(() => {
            this.setbuttonEvents();
        }, 300);
    }
}
