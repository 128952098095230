import { Injectable } from '@angular/core';
import { Router } from "@angular/router";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class EmailService {
    private headers: HttpHeaders;

    constructor(public router: Router, private http: HttpClient) {
    }

    add(parentUid: string, email: string) {
        let data = { parentUid, email };
        this.http.post<any>(environment.firebaseFunctionUrl + 'addToMailchimp', data, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            })
        }).subscribe(data => {
            console.log(data);
        },
            error => {
                console.log(error);
            });
    }

    update(parentUid: string) {
        let data = { parentUid };
        this.http.post<any>(environment.firebaseFunctionUrl + 'updateToMailchimp', data, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            })
        }).subscribe(data => {
            console.log(data);
        },
            error => {
                console.log(error);
            });
    }
}
