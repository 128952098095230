import { NgModule } from '@angular/core';

import {
    LoggerService,
    AppService,
    ViewService,
    AuthService,
    StorageService,
    ProfileService,
    HttpService,
    TeacherService
} from './index';

@NgModule({
    providers: [
        LoggerService,
        AppService,
        ViewService,
        AuthService,
        StorageService,
        ProfileService,
        HttpService,
        TeacherService
    ]
})
export class ServiceModule { }
