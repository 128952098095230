import { Component, OnInit, OnDestroy, AfterViewInit, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, Observable } from 'rxjs';
import { AngularFireStorage } from '@angular/fire/storage';
import { PointsMechanismService } from 'src/app/shared/point-mechanism.service';
import { ActivityService } from 'src/app/shared/activity.service';

@Component({
    selector: 'app-welcome',
    templateUrl: './welcome.component.html'
})
export class WelcomeActivityComponent implements OnInit, OnDestroy, AfterViewInit {
    protected subscription: Subscription;
    userData: any;

    id: string;
    activityId: string;

    gameStatus: string;
    data: any;
    totalScore: number;
    isSeeked: boolean = false;

    activity: any;
    activities: Observable<any>;

    titles: any = {};

    loading: boolean = true;
    canSlide: boolean = false;

    progressValue: number;
    isPause: boolean = true;
    timer: string;
    userActive: boolean;

    constructor(
        private activatedRoute: ActivatedRoute,
        public storage: AngularFireStorage,
        public activityService: ActivityService,
        private router: Router,
        private pm: PointsMechanismService) { }

    ngOnInit() {
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;

        this.subscription = this.activatedRoute.params.subscribe((data) => {
            if (data && data['id'] && data['activityId']) {
                this.id = data['id'];
                this.activityId = data['activityId'];
                this.pm.start(this.id, this.activityId);
            }
        });
    }

    setbuttonEvents() {
        var buttons = document.querySelectorAll('button');
        for (var i = 0; i < buttons.length; i++) {
            buttons[i].addEventListener('click', function (event) {
                let audio = <HTMLAudioElement>document.getElementById('buttonClick');
                if (audio) audio.play();
            });
            buttons[i].addEventListener('mouseover', function (event) {
                let audio = <HTMLAudioElement>document.getElementById('buttonHover');
                if (audio) audio.play();
            });
        }
    }

    changeGameStatus(status: string) {
        if (status == 'leave') {
            let audio = <HTMLAudioElement>document.getElementById('warningAudio');
            if (audio) audio.play();
            let video = <HTMLVideoElement>document.getElementById('video');
            if (video.paused === false) {
                video.pause();
            }
        }

        if (status == 'start') {
            let player = <HTMLVideoElement>document.getElementById('video');
            player.currentTime = 0;
            player.play();
        }
        this.gameStatus = status;
        setTimeout(() => {
            this.setbuttonEvents();
        }, 300);
    }

    ngAfterViewInit(): void {
        this.setbuttonEvents();

        this.activityService.getActivityData({ zoneId: this.id, activityId: this.activityId }).subscribe(aData => {

            aData.activityData.data.videoMp4 = aData.activityData.data.videoMp4.replace(".m4v", ".mp4");

            this.data = aData.activityData;

            this.loading = false;

            this.progressValue = 0;
            this.timer = '00:00';
            this.changeGameStatus('introduction');
        });
    }

    gameStart() {
        this.changeGameStatus('start');
        this.progressValue = 0;
        this.timer = '00:00';
        this.play();
    }

    videoEnd() {
        this.gameFinish();
        this.changeGameStatus('complete');
        let audio = <HTMLAudioElement>document.getElementById('completeAudio');
        if (audio) audio.play();
    }

    gameFinish() {
        this.pm.collectPoint(this.data.maxStars);
    }

    backToMenu() {
        this.router.navigate(['/game/activity-list', this.id]);
    }

    ngOnDestroy(): void {
        if (this.subscription) this.subscription.unsubscribe();
    }

    previousState() {
        this.pm.backToActivityList();
    }

    nextActivity() {
        this.pm.nextActivity();
    }

    instructionLoaded() {
        this.loading = false;
    }

    togglePlay() {
        let player = <HTMLVideoElement>document.getElementById('video');
        if (player) {
            if (player.paused === false) {
                player.pause();
                this.isPause = false;
            } else {
                player.play();
                this.isPause = true;
            }
        }
        else {
            setTimeout(this.togglePlay, 200);
        }
    }

    play() {
        let player = <HTMLVideoElement>document.getElementById('video');
        if (player) {
            player.play();
            this.isPause = true;
        } else {
            setTimeout(this.play, 200);
        }
    }

    initProgressBar() {
        let player = <HTMLVideoElement>document.getElementById('video');
        // calculate current value time
        this.timer = this.calculateCurrentValue(player.currentTime);

        this.progressValue = (player.currentTime / player.duration) * 100;
    };

    public endVideo() {
        this.isPause = false;
        this.videoEnd();
    }

    private calculateCurrentValue(currentTime: number) {
        let current_minute = Math.floor((currentTime / 60) % 60),
            current_seconds_long = currentTime % 60,
            current_seconds = Math.floor(current_seconds_long),
            current_time = (current_minute < 10 ? "0" + current_minute : current_minute) + ":" + (current_seconds < 10 ? "0" + current_seconds : current_seconds);

        return current_time;
    }

    @HostListener('document:mousemove', ['$event'])
    public documentMouseDown(event: any): void {
        if (this.canSlide && event.target.id != "sliderZone") {
            let vid = <HTMLVideoElement>document.getElementById('video');

            var slider = document.getElementById("sliderZone");

            var percentage = ((event.clientX - slider.getBoundingClientRect().left) / slider.offsetWidth);
            vid.currentTime = vid.duration * percentage;

            this.progressValue = (vid.currentTime / vid.duration) * 100;
        }
    }

    @HostListener('document:mouseup', ['$event'])
    public documentMouseUp(event: any): void {
        this.canSlide = false;

        this.isSeeked = false;
    }

    mouseDown(event) {
        this.canSlide = true;
    }

    mouseUp(event, width) {
        this.canSlide = false;

        if (!this.isSeeked) {
            let player = <HTMLVideoElement>document.getElementById('video');
            let percent = event.offsetX / width;
            player.currentTime = percent * player.duration;

            this.progressValue = (player.currentTime / player.duration) * 100;
            this.isSeeked = false;
        }
    }

    seek(event, width) {
        if (this.canSlide) {
            this.isSeeked = true;
            let player = <HTMLVideoElement>document.getElementById('video');
            let percent = event.offsetX / width;
            player.currentTime = percent * player.duration;

            this.progressValue = (player.currentTime / player.duration) * 100;
        }
    }

    setActiveUser() {
        this.userActive = true;
        setTimeout(() => {
            this.userActive = false;
        }, 2000);
    }

    @HostListener('mousedown', ['$event'])
    onMousedown(evt) {
        this.setActiveUser();
    }

    @HostListener('mousemove', ['$event'])
    onMousemove(evt) {
        this.setActiveUser();
    }
}
