import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router, NavigationStart } from '@angular/router';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'quranera';

  constructor(location: Location, private router: Router) {
    // within our constructor we can define our subscription
    // and then decide what to do when this event is triggered.
    // in this case I simply update my route string.
    router.events.subscribe(val => {
      if (location.path() != "") {
        if (val instanceof NavigationStart) {
          localStorage.setItem('fromPath', location.path());
        }
      } else {
      }
    });
  }

  ngOnInit() {
    var currentLocation = window.location.pathname;

    if (!currentLocation.includes("/registration") && !currentLocation.includes("/actions") && !currentLocation.includes("/forgot-password") && currentLocation != "/login") {

      if (localStorage.getItem("superAdmin_userData")) {

        if (!currentLocation.includes("game") && !currentLocation.includes("/admin")) this.router.navigate(['/admin/super/dashboard']);

      } else if (localStorage.getItem("quranera_admin_userData")) {

        if (!currentLocation.includes("game") && !currentLocation.includes("/admin/")) this.router.navigate(['/admin/dashboard']);

      } else if (localStorage.getItem("currentUser")) {

        var localUser = JSON.parse(localStorage.getItem("currentUser"));

        if ((localUser == null || localUser.uid == undefined || localUser.uid == null || localUser.uid == "") && currentLocation != "/registration") {
          localStorage.clear();
          sessionStorage.clear();
          this.router.navigate(['/login']);
          return;
        }

        if (currentLocation.includes("game") && localUser.email) {
          this.router.navigate(['/admin/cabinet/students/list']);
        }

        if (currentLocation == "/") {
          if (localUser.email) this.router.navigate(['/admin/cabinet/']);
          else this.router.navigate(['/game'])
        }
      } else {
        localStorage.clear();
        sessionStorage.clear();
        this.router.navigate(['/login']);
        return;
      }
    }
  }
}
